import React, { useState, useEffect, useRef } from "react";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import RadioSwitch from "../custom/radio-switch/RadioSwitch";
import { Validators } from "../../utilities/Validator";
import backIcon from "../../images/back.png";
import akunah_img from "../../images/akunah.png";
import InputField from "../custom/inputfield/InputField";
import nextWhiteIcon from "../../images/next-white.png";
import nextIcon from "../../images/next.png";
import close from "../../images/close.png";
import "./conssent.css";
import useAxios from "../../axiosinstance";
import SignaturePad from "react-signature-pad-wrapper";
import { DateFormate, UserDateFormat,DateFormatWithTimeZone, UserDateFormat2, ConsentFormDateFormat } from "../../utilities/DateFormate";
import useTranslation from "../customHooks/translations";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
const ConsentForm = () => {
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let isdoctorForm = searchParams.get("is_doctor_form");
  let clinical = searchParams.get("clinical");
  let medi_status = searchParams.get("status");
  const { akPt, akDc, akOu, formId } = useParams();
  const formIds = window.atob(formId);
  const [isLoading, setIsLoading] = useState(true);
  const [signType, setsignType] = useState("0");
  const [fullName, setName] = useState(null);
  const [relation, setRelation] = useState(null);
  const [signText, setsignText] = useState(null);
  const [consentCheck, setConsentCheck] = useState("");
  const [status, setStatus] = useState(false);
  const [nameErr, setnameErr] = useState(false);
  const [showModalForm, setshowModalForm] = useState(false);
  let jsonHip = useSelector((state) => state.hipScoreReducer);
  let adultKneeScore = useSelector((state) => state.adultKneeScore);
  let footAnkle = useSelector((state) => state.footAnkle);
  let pediaKnee = useSelector((state) => state.pediaKnee);
  let elbowScore = useSelector((state) => state.elbowScore);
  let handWrist = useSelector((state) => state.handWrist);
  let shoulderJson = useSelector((state) => state.shoulderScore);
  const genjson = useSelector((state) => state.GeneralFormReduces);
  const signaturePadRef = useRef({});
  const signTypeRef = useRef();
  const signTextRef = useRef();
  //   const signaturePadRef =(e)=>{

  // console.log(e)
  //   }
const [isApiPending, setIsApiPending] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  // const save = () => {
  //   // console.log(signaturePadRef.getTrimmedCanvas().toDataURL("image/png"))
  //   const dd = signaturePadRef.current.toDataURL();
  //   const data = signaturePadRef.current.toData();
  //   signaturePadRef.current.clear();
  // };

  const chooseSign = (e) => {
    setsignType(e);
  };

  const handleSignText = (e) => {
    setsignText(e);
  };
  const [nameerror, setNameerror] = useState("");
  const handleName = (e, err) => {
    //   if( /[^a-zA-Z0-9\-\/]/.test(fullName) ) {
    //     setNameerror("text in note require")
    // }if(fullName<3){
    //   setNameerror("text in note require")
    // }
    setnameErr(err);
    setName(e);
  };
  const handleRelation = (e) => {
    setRelation(e);
  };
  useEffect(()=>{
    const getData1 = async () => {
      try {
        const result = await axiosInstance.current.get(
          `extapp/forms/getUserLanguage?res_id=&pat_id=${window.atob(
            akPt
          )}&is_patient="1"
          `
        );
        // console.log(result)
        if (result.status === 200) {
          sessionStorage.setItem("dateformat",result.data.date)
        }
      } catch (err) {
        console.log(err.Messages);
      } finally {
        console.log("api response finally");
      }
    };
    getData1()
  },[])
  useEffect(() => {
    const checkConsent = async () => {
      const response = await axiosInstance.current.get(
        `extapp/patients/constent_form?org_id=${window.atob(
          akOu
        )}&ak_id=${window.atob(akDc)}&type=14&akpt_id=${window.atob(
          akPt
        )}&formType=${window.atob(formId)}`
      );
      // console.log(response);
      setStatus(response.data.status);
      setConsentCheck(response.data.data[0]);
    };
    checkConsent();
  }, []);

  useEffect(() => {
    if (status) {
      reDir();
    }
  }, [status]);

  useEffect(()=>{
    localStorage.setItem("isdoctorForm",isdoctorForm)
  },[isdoctorForm])
  const reDir = () => {
    if( typeof isdoctorForm === 'object'){
      navigate("/personal-data/" + akPt + "/" + akDc + "/" + akOu + "/" + formId +`status=${medi_status}`);

    }else{
      navigate("/personal-data/" + akPt + "/" + akDc + "/" + akOu + "/" + formId+`?is_doctor_form=${isdoctorForm}&doctorvalue=${clinical?"1":"0"}&status=${medi_status}`);
      
    }
    // if(searchParams.size === 0){
    //   navigate("/personal-data/" + akPt + "/" + akDc + "/" + akOu + "/" + formId );
    // }else{
    //   navigate("/personal-data/" + akPt + "/" + akDc + "/" + akOu + "/" + formId+`?is_doctor_form=${isdoctorForm}&doctorvalue=${clinical?"1":"0"}`);
    // }
  };
  const [image1, setImage1] = useState(false);
  const saveData = (e) => {
    let image = "";
    if (signType === "1") {
      image = signaturePadRef.current.toDataURL();
      // if (signaturePadRef.current.isEmpty()) {
      //   setImage1(true);
      // } else {
      // }
    }

    const json = JSON.stringify({
      name: fullName,
      relationship: relation,
      sign: signText,
      sign_image: image,
      sign_type: signType === "0" ? "1" : "2",
      skip_consent: 0,
    });

    if (!nameErr) {
      setIsApiPending(true)
      axiosInstance.current
        .post(
          `extapp/patients/constent_form?org_id=${window.atob(
            akOu
          )}&ak_id=${window.atob(akPt)}&doc=${window.atob(
            akDc
          )}&resource_type=14&formType=${formIds}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (e) {
              // alert(e)
              reDir();
            } else {
              createResID();
            }
          }
        }).catch((error)=>{
          if (error.response.status === 401) {
            navigate("/un-authorized-access");
          }

        }) .finally(() => {
          setIsApiPending(false); // Set API request to not pending after it completes
        })
    }
  };

  // console.log(formIds);
  const continueRed = () => {
    if (!signType) {
      signTypeRef.current.errUpdate();
    }
    if (signType == "0") {
      if (!signText) {
        signTextRef.current.errUpdate();
      } else {
        // saveData();
        // handleChk()
        setshowModalForm(true);
      }
    } else if (signType == "1") {
      if (signaturePadRef.current.isEmpty()) {
        setImage1(true);
      } else {
        // saveData();
        // handleChk()
        setshowModalForm(true);
      }
    }
    // else {
    //   saveData();
    // }
  };

  // const continueRed = () => {
  //   if (!signType) {
  //     signTypeRef.current.errUpdate();
  //   }
  //   if (signType == "0") {
  //     if (!signText) {
  //       signTextRef.current.errUpdate();
  //     } else {
  //       saveData();
  //     }
  //   } else if (signType == "1") {
  //     if (signaturePadRef.current.isEmpty()) {
  //       setImage1(true);
  //     } else {
  //       saveData();
  //     }
  //   }

  // };

  const Back = () => {
    // window.open(process.env.REACT_APP_PATIENT_URL);
    if (isdoctorForm === "true") {
      window.open(process.env.REACT_APP_DOCTOR_URL + 'patient-list', "_blank");
      // localStorage.removeItem("URL")

      window.close()

    } else {
      window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
      // localStorage.removeItem("URL")          

      window.close()

    }
  };

  let hipOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      procedure: "",
      evlHipPain: "",
      followups: "",
      unaffected_hip: "",
    },
  ];
  let adultOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      age: "",
      evlAdultKnee: "",
      unaffected_adult: "",
      followups: "",
    },
  ];
  let footAnkleOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_footAnkle: "",
      anklePain: "",
      followups: "",
    },
  ];
  let pediaKneeOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_pedia: "",
      evlPediaPain: "",
      followups: "",
    },
  ];
  let elbowOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_elbow: "",
      elbowPain: "",
      followups: "",
    },
  ];
  let handWristOtherData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_hand: "",
      evaluated: "",
      followups: "",
      isDisease: "0",
      isSyndrome: "0",
    },
  ];
  let ShoulderData = [
    {
      dominant: "",
      evaluted_value: "",
      unaffected_shoulder: "",
      shoulserScore: "",
      followups: "",
    },
  ];
  let GeneralData = [
    {
      dominant: "",
      evaluted_value: "0",
      unaffected_shoulder: "0",
      shoulserScore: "0",
      followups: "",
    },
  ];

  const createResID = () => {
    let json;
    let otherData;

    if (formIds === "6") {
      json = jsonHip;
      otherData = JSON.stringify(hipOtherData);
    }
    if (formIds === "5") {
      json = adultKneeScore;
      otherData = JSON.stringify(adultOtherData);
    }
    if (formIds === "20") {
      json = pediaKnee;
      otherData = JSON.stringify(pediaKneeOtherData);
    }
    if (formIds === "21") {
      json = footAnkle;
      otherData = JSON.stringify(footAnkleOtherData);
    }
    if (formIds === "22") {
      json = elbowScore;
      otherData = JSON.stringify(elbowOtherData);
    }
    if (formIds === "23") {
      json = handWrist;
      otherData = JSON.stringify(handWristOtherData);
    }
    if (formIds === "4") {
      json = shoulderJson;
      // console.log(json)
      otherData = JSON.stringify(ShoulderData);
    }
    if (formIds === "33") {
      json = genjson;
      // console.log(json)
      otherData = JSON.stringify(GeneralData);
    }

    axiosInstance.current
      .post(
        `extapp/forms/create_all_types_of_form?ak_id=${window.atob(
          akPt
        )}&ak_dc=${window.atob(akDc)}&ak_ou=${window.atob(
          akOu
        )}&type=${formIds}&resource_id=${""}&otherData=${otherData}&doctor=${typeof isdoctorForm === 'object'? "": "0"}&isDoctor=${typeof isdoctorForm === 'object'? "": "1"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // reDir(res.data.resource_id);
        if (res.status === 201) {
          if(typeof isdoctorForm === 'object'){
            window.open(process.env.REACT_APP_PATIENT_URL);
            window.close();
          }else{
            window.open(process.env.REACT_APP_DOCTOR_URL+'patient-view'+ "/"+ akPt);
            window.close();
          }
        }
        // alert("saved");
      }).catch((error)=>{
        if (error.response.status === 401) {
          navigate("/un-authorized-access");
        }
      })
  };

  const hideModalsForm = () => {
    setshowModalForm(false);
  };
  const handleChk = (e) => {
    if (e) {
      saveData(e);
    } else {
      // api
      saveData(e);

      // createResID();
    }
  };
  let dateformat = sessionStorage.getItem("dateformat")
  let timeZone = sessionStorage.getItem("timeZone")
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="complete_screen mb_100">
                <div className="panel">
                  <div className="panel-body p_25">
                    <div className="">
                      <div className="inner-content-box">
                        <div className="d-flex justify-content-between mb-4">
                          <div className="consent_txt">
                            <h2>{translation.Patient_Consent}</h2>
                          </div>
                          <div className="consent_imglogo">
                  
                              <img src={akunah_img} width='200' alt=''></img>
                  
                          </div>
                        </div>
                        <form
                          className="formbox patient-information-form"
                          method="post"
                          id="consentform"
                          action=""
                        >
                          <div className="input-row">
                            <div className="input-box input-box-fwidth input-box-text">
                      
                              <div className="formfill">
                                <div className="form-fieldtext fieldtext-name">
                                  <span className="left_label_txt">
                                    {translation.Patient_Last_Name}
                                  </span>
                                  <span className="rt_input_txt">
                                    {consentCheck.patient_lastname}
                                  </span>
                                </div>
                                <div className="form-fieldtext fieldtext-name">
                                  <span className="left_label_txt">
                                    {translation.Patient_First_Name}
                                  </span>
                                  <span className="rt_input_txt">
                                    {consentCheck.patient_firstname}
                                  </span>
                                </div>
                                <div className="form-fieldtext fieldtext-date">
                                  <span className="left_label_txt">
                                    {translation.Patient_Date_of_Birth}
                                  </span>
                                  <span className="right_dt">
                                    {" "}
                                    {UserDateFormat(consentCheck.dob ,dateformat )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="input-box-text text-center mt_40 ">
                              <h3 className="dt__cs__ftext">
                                <strong className="d__block">
                                  {translation.information}
                                </strong>
                              </h3>
                            </div>
                            <br />
                            {/* <div className="input-box input-box-fwidth input-box-text"> */}
                            <div className=" input-box-text">
                              <div className="pt__info__consent__ppup">
                                <p className="dt__cs__ftext">
                                  {translation.I_am_informedDDD}{" "}
                                  <span className="dc_name">{consentCheck.doctor_name}</span>,
                                  {translation.Clinician}
                                </p>
                                <p className="dt__cs__ftext">{translation.For_that}</p>
                                <p className="dt__cs__ftext">{translation.Akunah_softawre}</p>
                                <p className="dt__cs__ftext">{translation.the_clinic}</p>
                                <p className="dt__cs__ftext">{translation.oportunnity}</p>

                                <p className="dt__cs__ftext"> <strong className="d__block">{translation.akunah_approch}</strong>
                                  {translation.akunah_approch1}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.akunah_approch2}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.akunah_approch3}
                                  "<strong>{translation.akunah_approch4}</strong>" ).
                                  {translation.akunah_approch5}
                                  "<strong>{translation.akunah_approch6}</strong>" ).
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.akunah_approch7}
                                  <a className="text-primary" href="https://akunah.com/termsofservices" target="_blank"> {translation.terms_} </a>
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.akunah_approch8}
                                </p>
                                <p className="dt__cs__ftext">
                                  <strong className="d__block">
                                    {translation.Data_Collection_and_Processing}
                                  </strong>
                                  {translation.I_am_informed}
                                  <br />
                                  <br />
                                  <span className="ml-15 text_dec_none d__block">
                                    {translation.Assessment_and_evaluation}
                                  </span>
                                  <br />
                                  <span className="ml-15 text_dec_none d__block">
                                    {translation.Previous_surgery}
                                  </span>
                                  <br />
                                  <span className="ml-15 text_dec_none d__block">
                                    {translation.Health_and_medical_history}
                                  </span>
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_have_been_assured}
                                </p>
                                <p className="dt__cs__ftext">{translation.My_data}</p>

                                <p className="dt__cs__ftext">{translation.your_data}</p>
                                <p className="dt__cs__ftext">
                                  <strong className="d__block">
                                    {translation.Data_Access_and_Storage}
                                  </strong>
                                  {translation.Personal_data_provided}
                                  <br />
                                  <br />

                                </p>
                                <p className="dt__cs__ftext">{translation.data1}</p>
                                <p className="dt__cs__ftext">{translation.data2}</p>
                                <p className="dt__cs__ftext">{translation.data3}</p>
                                <p className="dt__cs__ftext">{translation.data4}</p>

                                <p className="dt__cs__ftext">
                                  <i>
                                    {translation.Personal_Data}
                                  </i>
                                </p>
                                <p>{translation.p_data1}</p>
                                <p>
                                  <i>
                                    {translation.Deidentified_Data}
                                  </i>
                                </p>
                                <p>{translation.d_txt1}</p>

                                <p className="dt__cs__ftext">
                                  <strong className="d__block">
                                    {translation.Patient_Rights}
                                  </strong>
                                  {translation.I_understand_that}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_1txt}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_2txt}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_3txt}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_4txt}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_5txt}
                                </p>

                                <p className="dt__cs__ftext mt-4">
                                  <strong className="d__block text-center">
                                    {translation.Contact}
                                  </strong>
                                  {translation.I_am_able_to_contact}{" "}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post}
                                </p>

                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post2}
                                  <span className="dc_name"> {consentCheck.doctor_name}</span>,
                                  {translation.or_by_post2_1}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post3}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post4}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post5}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post6}
                                  <a className="text-primary" href="https://info@akunah.com" target="_blank"> {translation.info_akunah} </a>


                                  {translation.or_by_post7}
                                </p>
                                {/* <p className="dt__cs__ftext">
                            <strong className="d__block">
                              {translation.Agreement}
                            </strong>
                            {translation.agree_that_I_have_read}
                          </p> */}
                              </div>
                            </div>
                          </div>

                          <div className="input-row mt-5">
                            <div className="input-box input-tab-boxin input-box-fwidth">
                              <div className="input-box input-box-fwidth">
                                <InputField
                                  label={
                                    translation.Name_if_consent_on_behalf_of_patient
                                  }
                                  name="nameOnBehalf"
                                  value={fullName}
                                  type="input"
                                  placeholder={translation.full_name}
                                  onChange={handleName}
                                  styleClass=" mxw_100 fill-here trim"
                                  formGroupClass="label_back"
                                  validators={[
                                    {
                                      check: Validators.spacial,
                                      message: translation.spacial_characters_not_allow
                                    },
                                  ]}
                                />

                              </div>
                              <div className="input-box input-box-fwidth">
                                <InputField
                                  label={
                                    translation.Relationship_to_patient_if_applicable
                                  }
                                  name="nameOnBehalf"
                                  value={relation}
                                  type="input"
                                  placeholder={translation.relationship_to_patient}
                                  onChange={handleRelation}
                                  styleClass=" mxw_100 fill-here trim"
                                  formGroupClass="label_back"
                                />
                              </div>

                              <div className="note-box">
                                <p>
                                  {translation.NOTE} {translation.If_the_patient}
                                </p>
                                <p>
                                  {translation.NOTE}{" "}
                                  {translation.If_the_patient_is_deceased}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="input-row sign-type signup__sign_type">

                            <div className="input-box mt-20">
                              <RadioSwitch
                                labelLeft={translation.Type}
                                labelRight={translation.Draw}
                                idLeft="feelUnstableNo"
                                idRight="feelUnstableYes"
                                name="feelUnstable"
                                title={<>{translation.Choose_Sign_Type}</>}
                                styleClass=""
                                value={signType}
                                ref={signTypeRef}
                                onChange={chooseSign}
                                placeholder="select"
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required
                                  },
                                ]}
                              />
                            </div>
                          </div>
                          {signType == "0" && (
                            <div className="input-row" style={{ display: "" }}>
                              <InputField
                                label={translation.Signed_by_Keyboard}
                                value={signText}
                                ref={signTextRef}
                                type="textarea"
                                placeholder={translation.patient_signature}
                                onChange={handleSignText}
                                styleClass="h_150"
                                formGroupClass="label_back"
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: translation.This_field_is_required
                                  },
                                ]}
                              />
                            </div>
                          )}
                          {signType == "1" && (
                            <>
                              <label>{translation.Signed_by_Mouse}</label>
                              <div
                                className="SignaturePad "
                                style={{ marginBottom: "15px" }}
                              >
                                <a
                                  href="javaScript:void(0)"
                                  className="clearlink"
                                  onClick={() => {
                                    signaturePadRef.current.clear();
                                  }}
                                >
                                  <img alt="" src={close} />
                                  {translation.Clear}
                                </a>
                                <SignaturePad
                                  height={140}
                                  ref={signaturePadRef}
                                  options={{
                                    minWidth: 2,
                                    maxWidth: 4,
                                    penColor: "rgb(000, 000, 000 ,1)",
                                  }}
                                />
                              </div>
                              {image1 && (
                                <span className="text-danger">
                                  {" "}
                                  {translation.This_field_is_required}
                                </span>
                              )}
                            </>
                          )}

                        </form>
                        <div className="input-row consent__btm_date">
                          <div className="input-box input-box-fwidth input-box-mbhalf">
                            <label>{translation.Date}</label>
                            <div className="date-inputbox">
                            {ConsentFormDateFormat(dateformat)}
                            {/* {new Date().getFullYear()}/{(new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)}/{(new Date().getDate() < 10 ? '0' : '') + new Date().getDate()} */}
                            {/* {UserDateFormat2(new Date().toLocaleDateString() ,dateformat )} */}
                              {/* {UserDateFormat(new Date(), dateformat )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb_30 button_box">
                        <div className="col-md-6 col-sm-6 col-6">
                          <span onClick={() => Back()} className="assm_back_btn">
                            <img src={backIcon} alt="" />
                            <span>{translation.Close}</span>
                          </span>
                        </div>
                        {/* <div className="col-md-12 col-sm-12 col-12 text-end"> */}
                        <div className="col-md-6 col-sm-6 col-6 text-end">
                          <button className="next_btn btn_fill" onClick={continueRed}>
                            {translation.next_button}
                            <span>
                              <img src={nextIcon} alt="" />
                              <img src={nextWhiteIcon} alt="" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* <button onClick={save} className="signButtons">
                submit
              </button> */}

                  </div>
                </div>
                <Modal
                  show={showModalForm}
                  onHide={hideModalsForm}
                  className={`delete_modal_main`}
                  centered
                >
                  <Modal.Body>
                    <div className="text-center">
                      {/* <img src={DeleteIcon} className="delete_pp_icon" alt="icon" /> */}
                      <h4 className="center_title">
                        {/* {translation.areyousurewanttocontinueyourform} */}
                        {translation.Do_you_want_to_submit_patient_form_or_not}
                      </h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="text-center">
                    <Button
                      variant="default"
                      className="btn_outline"
                      onClick={() => handleChk(false)}
                      disabled={isApiPending}
                    >
                      {translation.No}
                      
                    </Button>
                    <Button
                      variant="default"
                      className="btn_green"
                      onClick={() => handleChk(true)}
                      disabled={isApiPending}
                    >
                      {translation.Yes}
                      
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default ConsentForm;
