import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import Button from "../../../custom/button/Button";
import {
  updateFootSatisTotalscrore,
  updateFootAnklePainData,
  updateFootSymptomsData,
  updateFootAnkle,
  updateFootInstabilityData,
  updateFootQolData,
  updateFootFunctionData,
  updateFootSatisfactionData,
  updateFootFeedbackfootankle,
  updateFootComberlandData,
} from "../../../../redux/actions/index";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import NameCustom from "../../../custom/name/NameCustom";
import SubmitModel from "../../SubmissionModel/SubmitModel";

function FootAnkleComplete() {
  const translation = useTranslation();
  const axiosinstance = useAxios();
  const { rId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [painSco] = useSelector((state) => state.footAnkle.painScore);
  const [instabilitySco] = useSelector(
    (state) => state.footAnkle.instabilityScore
  );
  const [symptomsSco] = useSelector((state) => state.footAnkle.symptomsScore);
  const [functionSco] = useSelector((state) => state.footAnkle.functionScore);
  const [qolSco] = useSelector((state) => state.footAnkle.qolScore);
  const [satisfactionSco] = useSelector(
    (state) => state.footAnkle.satisfactionScore
  );
  const [cumberland] = useSelector((state) => state.footAnkle.comberland);
  const docFeedback = useSelector((state) => state.footAnkle.doctorFeedback[0]);
  const [initialAssesment, setInitialAssesment] = useState(1);
  const [chk, setChk] = useState(false);
  const [isdoctorform, setisdoctorform] = useState(localStorage.getItem('isdoctorForm')==='true'?1:0)

  const [patientID, setpatientID] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }
  const json = useSelector((state) => state.footAnkle);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        setpatientID(res.data.data2[0].patient_id);
        // setisdoctorform(res.data.data2[0].is_doctor_form);
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  // FAAM (Foot and Ankle Ability Measure)

  // FAAM ADL(start)
  const faamDesStairR =
    functionSco.desStairR === "0"
      ? 4
      : functionSco.desStairR === "1"
      ? 3
      : functionSco.desStairR === "2"
      ? 2
      : functionSco.desStairR === "3"
      ? 1
      : functionSco.desStairR === "4"
      ? 0
      : 0;

  const faamDesStairL =
    functionSco.desStairL === "0"
      ? 4
      : functionSco.desStairL === "1"
      ? 3
      : functionSco.desStairL === "2"
      ? 2
      : functionSco.desStairL === "3"
      ? 1
      : functionSco.desStairL === "4"
      ? 0
      : 0;

  const faamAscStairR =
    functionSco.ascStairR === "0"
      ? 4
      : functionSco.ascStairR === "1"
      ? 3
      : functionSco.ascStairR === "2"
      ? 2
      : functionSco.ascStairR === "3"
      ? 1
      : functionSco.ascStairR === "4"
      ? 0
      : 0;

  const faamAscStairL =
    functionSco.ascStairL === "0"
      ? 4
      : functionSco.ascStairL === "1"
      ? 3
      : functionSco.ascStairL === "2"
      ? 2
      : functionSco.ascStairL === "3"
      ? 1
      : functionSco.ascStairL === "4"
      ? 0
      : 0;

  const faamStandingR =
    functionSco.standingR === "0"
      ? 4
      : functionSco.standingR === "1"
      ? 3
      : functionSco.standingR === "2"
      ? 2
      : functionSco.standingR === "3"
      ? 1
      : functionSco.standingR === "4"
      ? 0
      : 0;

  const faamStandingL =
    functionSco.standingL === "0"
      ? 4
      : functionSco.standingL === "1"
      ? 3
      : functionSco.standingL === "2"
      ? 2
      : functionSco.standingL === "3"
      ? 1
      : functionSco.standingL === "4"
      ? 0
      : 0;

  const faamWalkingFlatR =
    functionSco.walkingFlatR === "0"
      ? 4
      : functionSco.walkingFlatR === "1"
      ? 3
      : functionSco.walkingFlatR === "2"
      ? 2
      : functionSco.walkingFlatR === "3"
      ? 1
      : functionSco.walkingFlatR === "4"
      ? 0
      : 0;

  const faamWalkingFlatL =
    functionSco.walkingFlatL === "0"
      ? 4
      : functionSco.walkingFlatL === "1"
      ? 3
      : functionSco.walkingFlatL === "2"
      ? 2
      : functionSco.walkingFlatL === "3"
      ? 1
      : functionSco.walkingFlatL === "4"
      ? 0
      : 0;

  const faamWalkingWithoutShoesR =
    functionSco.walkingWithoutShoesR === "0"
      ? 4
      : functionSco.walkingWithoutShoesR === "1"
      ? 3
      : functionSco.walkingWithoutShoesR === "2"
      ? 2
      : functionSco.walkingWithoutShoesR === "3"
      ? 1
      : functionSco.walkingWithoutShoesR === "4"
      ? 0
      : 0;

  const faamWalkingWithoutShoesL =
    functionSco.walkingWithoutShoesL === "0"
      ? 4
      : functionSco.walkingWithoutShoesL === "1"
      ? 3
      : functionSco.walkingWithoutShoesL === "2"
      ? 2
      : functionSco.walkingWithoutShoesL === "3"
      ? 1
      : functionSco.walkingWithoutShoesL === "4"
      ? 0
      : 0;

  const faamWalkingHillsR =
    functionSco.walkingHillsR === "0"
      ? 4
      : functionSco.walkingHillsR === "1"
      ? 3
      : functionSco.walkingHillsR === "2"
      ? 2
      : functionSco.walkingHillsR === "3"
      ? 1
      : functionSco.walkingHillsR === "4"
      ? 0
      : 0;

  const faamWalkingHillsL =
    functionSco.walkingHillsL === "0"
      ? 4
      : functionSco.walkingHillsL === "1"
      ? 3
      : functionSco.walkingHillsL === "2"
      ? 2
      : functionSco.walkingHillsL === "3"
      ? 1
      : functionSco.walkingHillsL === "4"
      ? 0
      : 0;

  const faamWalkingDownHillsR =
    functionSco.walkingDownHillsR === "0"
      ? 4
      : functionSco.walkingDownHillsR === "1"
      ? 3
      : functionSco.walkingDownHillsR === "2"
      ? 2
      : functionSco.walkingDownHillsR === "3"
      ? 1
      : functionSco.walkingDownHillsR === "4"
      ? 0
      : 0;

  const faamWalkingDownHillsL =
    functionSco.walkingDownHillsL === "0"
      ? 4
      : functionSco.walkingDownHillsL === "1"
      ? 3
      : functionSco.walkingDownHillsL === "2"
      ? 2
      : functionSco.walkingDownHillsL === "3"
      ? 1
      : functionSco.walkingDownHillsL === "4"
      ? 0
      : 0;

  const faamWalkingUnevenR =
    functionSco.walkingUnevenR === "0"
      ? 4
      : functionSco.walkingUnevenR === "1"
      ? 3
      : functionSco.walkingUnevenR === "2"
      ? 2
      : functionSco.walkingUnevenR === "3"
      ? 1
      : functionSco.walkingUnevenR === "4"
      ? 0
      : 0;

  const faamWalkingUnevenL =
    functionSco.walkingUnevenL === "0"
      ? 4
      : functionSco.walkingUnevenL === "1"
      ? 3
      : functionSco.walkingUnevenL === "2"
      ? 2
      : functionSco.walkingUnevenL === "3"
      ? 1
      : functionSco.walkingUnevenL === "4"
      ? 0
      : 0;

  const faamSteppingUpR =
    functionSco.steppingUpR === "0"
      ? 4
      : functionSco.steppingUpR === "1"
      ? 3
      : functionSco.steppingUpR === "2"
      ? 2
      : functionSco.steppingUpR === "3"
      ? 1
      : functionSco.steppingUpR === "4"
      ? 0
      : 0;

  const faamSteppingUpL =
    functionSco.steppingUpL === "0"
      ? 4
      : functionSco.steppingUpL === "1"
      ? 3
      : functionSco.steppingUpL === "2"
      ? 2
      : functionSco.steppingUpL === "3"
      ? 1
      : functionSco.steppingUpL === "4"
      ? 0
      : 0;
  const faamComingUpR =
    functionSco.comingUpR === "0"
      ? 4
      : functionSco.comingUpR === "1"
      ? 3
      : functionSco.comingUpR === "2"
      ? 2
      : functionSco.comingUpR === "3"
      ? 1
      : functionSco.comingUpR === "4"
      ? 0
      : 0;

  const faamComingUpL =
    functionSco.comingUpL === "0"
      ? 4
      : functionSco.comingUpL === "1"
      ? 3
      : functionSco.comingUpL === "2"
      ? 2
      : functionSco.comingUpL === "3"
      ? 1
      : functionSco.comingUpL === "4"
      ? 0
      : 0;

  const faamWalkFiveR =
    functionSco.walkFiveR === "0"
      ? 4
      : functionSco.walkFiveR === "1"
      ? 3
      : functionSco.walkFiveR === "2"
      ? 2
      : functionSco.walkFiveR === "3"
      ? 1
      : functionSco.walkFiveR === "4"
      ? 0
      : 0;

  const faamWalkFiveL =
    functionSco.walkFiveL === "0"
      ? 4
      : functionSco.walkFiveL === "1"
      ? 3
      : functionSco.walkFiveL === "2"
      ? 2
      : functionSco.walkFiveL === "3"
      ? 1
      : functionSco.walkFiveL === "4"
      ? 0
      : 0;

  const faamWalkIntiallyR =
    functionSco.walkIntiallyR === "0"
      ? 4
      : functionSco.walkIntiallyR === "1"
      ? 3
      : functionSco.walkIntiallyR === "2"
      ? 2
      : functionSco.walkIntiallyR === "3"
      ? 1
      : functionSco.walkIntiallyR === "4"
      ? 0
      : 0;

  const faamWalkIntiallyL =
    functionSco.walkIntiallyL === "0"
      ? 4
      : functionSco.walkIntiallyL === "1"
      ? 3
      : functionSco.walkIntiallyL === "2"
      ? 2
      : functionSco.walkIntiallyL === "3"
      ? 1
      : functionSco.walkIntiallyL === "4"
      ? 0
      : 0;

  const faamWalkTenR =
    functionSco.walkTenR === "0"
      ? 4
      : functionSco.walkTenR === "1"
      ? 3
      : functionSco.walkTenR === "2"
      ? 2
      : functionSco.walkTenR === "3"
      ? 1
      : functionSco.walkTenR === "4"
      ? 0
      : 0;

  const faamWalkTenL =
    functionSco.walkTenL === "0"
      ? 4
      : functionSco.walkTenL === "1"
      ? 3
      : functionSco.walkTenL === "2"
      ? 2
      : functionSco.walkTenL === "3"
      ? 1
      : functionSco.walkTenL === "4"
      ? 0
      : 0;

  const faamWalkFifteenR =
    functionSco.walkFifteenR === "0"
      ? 4
      : functionSco.walkFifteenR === "1"
      ? 3
      : functionSco.walkFifteenR === "2"
      ? 2
      : functionSco.walkFifteenR === "3"
      ? 1
      : functionSco.walkFifteenR === "4"
      ? 0
      : 0;

  const faamWalkFifteenL =
    functionSco.walkFifteenL === "0"
      ? 4
      : functionSco.walkFifteenL === "1"
      ? 3
      : functionSco.walkFifteenL === "2"
      ? 2
      : functionSco.walkFifteenL === "3"
      ? 1
      : functionSco.walkFifteenL === "4"
      ? 0
      : 0;

  const faamHomeR =
    functionSco.homeR === "0"
      ? 4
      : functionSco.homeR === "1"
      ? 3
      : functionSco.homeR === "2"
      ? 2
      : functionSco.homeR === "3"
      ? 1
      : functionSco.homeR === "4"
      ? 0
      : 0;

  const faamHomeL =
    functionSco.homeL === "0"
      ? 4
      : functionSco.homeL === "1"
      ? 3
      : functionSco.homeL === "2"
      ? 2
      : functionSco.homeL === "3"
      ? 1
      : functionSco.homeL === "4"
      ? 0
      : 0;

  const faamActivitiesR =
    functionSco.activitiesR === "0"
      ? 4
      : functionSco.activitiesR === "1"
      ? 3
      : functionSco.activitiesR === "2"
      ? 2
      : functionSco.activitiesR === "3"
      ? 1
      : functionSco.activitiesR === "4"
      ? 0
      : 0;

  const faamActivitiesL =
    functionSco.activitiesL === "0"
      ? 4
      : functionSco.activitiesL === "1"
      ? 3
      : functionSco.activitiesL === "2"
      ? 2
      : functionSco.activitiesL === "3"
      ? 1
      : functionSco.activitiesL === "4"
      ? 0
      : 0;

  const faamPersonalCareR =
    functionSco.personalCareR === "0"
      ? 4
      : functionSco.personalCareR === "1"
      ? 3
      : functionSco.personalCareR === "2"
      ? 2
      : functionSco.personalCareR === "3"
      ? 1
      : functionSco.personalCareR === "4"
      ? 0
      : 0;

  const faamPersonalCareL =
    functionSco.personalCareL === "0"
      ? 4
      : functionSco.personalCareL === "1"
      ? 3
      : functionSco.personalCareL === "2"
      ? 2
      : functionSco.personalCareL === "3"
      ? 1
      : functionSco.personalCareL === "4"
      ? 0
      : 0;

  const faamheavyWorkR =
    functionSco.heavyWorkR === "0"
      ? 4
      : functionSco.heavyWorkR === "1"
      ? 3
      : functionSco.heavyWorkR === "2"
      ? 2
      : functionSco.heavyWorkR === "3"
      ? 1
      : functionSco.heavyWorkR === "4"
      ? 0
      : 0;

  const faamheavyWorkL =
    functionSco.heavyWorkL === "0"
      ? 4
      : functionSco.heavyWorkL === "1"
      ? 3
      : functionSco.heavyWorkL === "2"
      ? 2
      : functionSco.heavyWorkL === "3"
      ? 1
      : functionSco.heavyWorkL === "4"
      ? 0
      : 0;

  const faammoderateWorkR =
    functionSco.moderateWorkR === "0"
      ? 4
      : functionSco.moderateWorkR === "1"
      ? 3
      : functionSco.moderateWorkR === "2"
      ? 2
      : functionSco.moderateWorkR === "3"
      ? 1
      : functionSco.moderateWorkR === "4"
      ? 0
      : 0;

  const faammoderateWorkL =
    functionSco.moderateWorkL === "0"
      ? 4
      : functionSco.moderateWorkL === "1"
      ? 3
      : functionSco.moderateWorkL === "2"
      ? 2
      : functionSco.moderateWorkL === "3"
      ? 1
      : functionSco.moderateWorkL === "4"
      ? 0
      : 0;

  const faamRecreationalR =
    functionSco.recreationalR === "0"
      ? 4
      : functionSco.recreationalR === "1"
      ? 3
      : functionSco.recreationalR === "2"
      ? 2
      : functionSco.recreationalR === "3"
      ? 1
      : functionSco.recreationalR === "4"
      ? 0
      : 0;

  const faamRecreationalL =
    functionSco.recreationalL === "0"
      ? 4
      : functionSco.recreationalL === "1"
      ? 3
      : functionSco.recreationalL === "2"
      ? 2
      : functionSco.recreationalL === "3"
      ? 1
      : functionSco.recreationalL === "4"
      ? 0
      : 0;

  const faamsquattingR =
    functionSco.squattingR === "0"
      ? 4
      : functionSco.squattingR === "1"
      ? 3
      : functionSco.squattingR === "2"
      ? 2
      : functionSco.squattingR === "3"
      ? 1
      : functionSco.squattingR === "4"
      ? 0
      : 0;

  const faamsquattingL =
    functionSco.squattingL === "0"
      ? 4
      : functionSco.squattingL === "1"
      ? 3
      : functionSco.squattingL === "2"
      ? 2
      : functionSco.squattingL === "3"
      ? 1
      : functionSco.squattingL === "4"
      ? 0
      : 0;

  //FAAM Sports(start)
  const faamJumpingR =
    functionSco.jumpingR === "0"
      ? 4
      : functionSco.jumpingR === "1"
      ? 3
      : functionSco.jumpingR === "2"
      ? 2
      : functionSco.jumpingR === "3"
      ? 1
      : functionSco.jumpingR === "4"
      ? 0
      : 0;

  const faamJumpingL =
    functionSco.jumpingL === "0"
      ? 4
      : functionSco.jumpingL === "1"
      ? 3
      : functionSco.jumpingL === "2"
      ? 2
      : functionSco.jumpingL === "3"
      ? 1
      : functionSco.jumpingL === "4"
      ? 0
      : 0;

  const faamRunningR =
    functionSco.runningR === "0"
      ? 4
      : functionSco.runningR === "1"
      ? 3
      : functionSco.runningR === "2"
      ? 2
      : functionSco.runningR === "3"
      ? 1
      : functionSco.runningR === "4"
      ? 0
      : 0;

  const faamRunningL =
    functionSco.runningL === "0"
      ? 4
      : functionSco.runningL === "1"
      ? 3
      : functionSco.runningL === "2"
      ? 2
      : functionSco.runningL === "3"
      ? 1
      : functionSco.runningL === "4"
      ? 0
      : 0;

  const faamLandingR =
    functionSco.landingR === "0"
      ? 4
      : functionSco.landingR === "1"
      ? 3
      : functionSco.landingR === "2"
      ? 2
      : functionSco.landingR === "3"
      ? 1
      : functionSco.landingR === "4"
      ? 0
      : 0;

  const faamLandingL =
    functionSco.landingL === "0"
      ? 4
      : functionSco.landingL === "1"
      ? 3
      : functionSco.landingL === "2"
      ? 2
      : functionSco.landingL === "3"
      ? 1
      : functionSco.landingL === "4"
      ? 0
      : 0;

  const faamstoppingR =
    functionSco.stoppingR === "0"
      ? 4
      : functionSco.stoppingR === "1"
      ? 3
      : functionSco.stoppingR === "2"
      ? 2
      : functionSco.stoppingR === "3"
      ? 1
      : functionSco.stoppingR === "4"
      ? 0
      : 0;

  const faamstoppingL =
    functionSco.stoppingL === "0"
      ? 4
      : functionSco.stoppingL === "1"
      ? 3
      : functionSco.stoppingL === "2"
      ? 2
      : functionSco.stoppingL === "3"
      ? 1
      : functionSco.stoppingL === "4"
      ? 0
      : 0;

  const faamcuttingR =
    functionSco.cuttingR === "0"
      ? 4
      : functionSco.cuttingR === "1"
      ? 3
      : functionSco.cuttingR === "2"
      ? 2
      : functionSco.cuttingR === "3"
      ? 1
      : functionSco.cuttingR === "4"
      ? 0
      : 0;

  const faamcuttingL =
    functionSco.cuttingL === "0"
      ? 4
      : functionSco.cuttingL === "1"
      ? 3
      : functionSco.cuttingL === "2"
      ? 2
      : functionSco.cuttingL === "3"
      ? 1
      : functionSco.cuttingL === "4"
      ? 0
      : 0;

  const faamabilityParticipateR =
    functionSco.abilityParticipateR === "0"
      ? 4
      : functionSco.abilityParticipateR === "1"
      ? 3
      : functionSco.abilityParticipateR === "2"
      ? 2
      : functionSco.abilityParticipateR === "3"
      ? 1
      : functionSco.abilityParticipateR === "4"
      ? 0
      : 0;

  const faamabilityParticipateL =
    functionSco.abilityParticipateL === "0"
      ? 4
      : functionSco.abilityParticipateL === "1"
      ? 3
      : functionSco.abilityParticipateL === "2"
      ? 2
      : functionSco.abilityParticipateL === "3"
      ? 1
      : functionSco.abilityParticipateL === "4"
      ? 0
      : 0;

  const faamabilityPerformR =
    functionSco.abilityPerformR === "0"
      ? 4
      : functionSco.abilityPerformR === "1"
      ? 3
      : functionSco.abilityPerformR === "2"
      ? 2
      : functionSco.abilityPerformR === "3"
      ? 1
      : functionSco.abilityPerformR === "4"
      ? 0
      : 0;

  const faamabilityPerformL =
    functionSco.abilityPerformL === "0"
      ? 4
      : functionSco.abilityPerformL === "1"
      ? 3
      : functionSco.abilityPerformL === "2"
      ? 2
      : functionSco.abilityPerformL === "3"
      ? 1
      : functionSco.abilityPerformL === "4"
      ? 0
      : 0;
  //FAAM Sports(end)

  //FADI (Foot and Ankle Disability Index)
  const FadiPainRating =
    painSco.painRating === "0"
      ? 4
      : painSco.painRating === "1"
      ? 3
      : painSco.painRating === "2"
      ? 3
      : painSco.painRating === "3"
      ? 3
      : painSco.painRating === "4"
      ? 2
      : painSco.painRating === "5"
      ? 2
      : painSco.painRating === "6"
      ? 2
      : painSco.painRating === "7"
      ? 1
      : painSco.painRating === "8"
      ? 1
      : painSco.painRating === "9"
      ? 1
      : painSco.painRating === "10"
      ? 0
      : 0;
  const FadiPainRatingL =
    painSco.painRatingL === "0"
      ? 4
      : painSco.painRatingL === "1"
      ? 3
      : painSco.painRatingL === "2"
      ? 3
      : painSco.painRatingL === "3"
      ? 3
      : painSco.painRatingL === "4"
      ? 2
      : painSco.painRatingL === "5"
      ? 2
      : painSco.painRatingL === "6"
      ? 2
      : painSco.painRatingL === "7"
      ? 1
      : painSco.painRatingL === "8"
      ? 1
      : painSco.painRatingL === "9"
      ? 1
      : painSco.painRatingL === "10"
      ? 0
      : 0;

  const genPainR =
    painSco.genPainR === "0"
      ? 4
      : painSco.genPainR === "1"
      ? 3
      : painSco.genPainR === "2"
      ? 2
      : painSco.genPainR === "3"
      ? 1
      : painSco.genPainR === "4"
      ? 0
      : 0;

  const genPainL =
    painSco.genPainL === "0"
      ? 4
      : painSco.genPainL === "1"
      ? 3
      : painSco.genPainL === "2"
      ? 2
      : painSco.genPainL === "3"
      ? 1
      : painSco.genPainL === "4"
      ? 0
      : 0;

  const AtRestR =
    painSco.restR === "0"
      ? 4
      : painSco.restR === "1"
      ? 3
      : painSco.restR === "2"
      ? 2
      : painSco.restR === "3"
      ? 1
      : painSco.restR === "4"
      ? 0
      : 0;

  const AtRestL =
    painSco.restL === "0"
      ? 4
      : painSco.restL === "1"
      ? 3
      : painSco.restL === "2"
      ? 2
      : painSco.restL === "3"
      ? 1
      : painSco.restL === "4"
      ? 0
      : 0;

  const NormalR =
    painSco.normalR === "0"
      ? 4
      : painSco.normalR === "1"
      ? 3
      : painSco.normalR === "2"
      ? 2
      : painSco.normalR === "3"
      ? 1
      : painSco.normalR === "4"
      ? 0
      : 0;

  const NormalL =
    painSco.normalL === "0"
      ? 4
      : painSco.normalL === "1"
      ? 3
      : painSco.normalL === "2"
      ? 2
      : painSco.normalL === "3"
      ? 1
      : painSco.normalL === "4"
      ? 0
      : 0;

  const FirstThingR =
    painSco.firstR === "0"
      ? 4
      : painSco.firstR === "1"
      ? 3
      : painSco.firstR === "2"
      ? 2
      : painSco.firstR === "3"
      ? 1
      : painSco.firstR === "4"
      ? 0
      : 0;

  const FirstThingL =
    painSco.firstL === "0"
      ? 4
      : painSco.firstL === "1"
      ? 3
      : painSco.firstL === "2"
      ? 2
      : painSco.firstL === "3"
      ? 1
      : painSco.firstL === "4"
      ? 0
      : 0;

  const StraighteningR =
    painSco.straightR === "0"
      ? 4
      : painSco.straightR === "1"
      ? 3
      : painSco.straightR === "2"
      ? 2
      : painSco.straightR === "3"
      ? 1
      : painSco.straightR === "4"
      ? 0
      : 0;

  const StraighteningL =
    painSco.straightL === "0"
      ? 4
      : painSco.straightL === "1"
      ? 3
      : painSco.straightL === "2"
      ? 2
      : painSco.straightL === "3"
      ? 1
      : painSco.straightL === "4"
      ? 0
      : 0;

  const BendingR =
    painSco.bendingR === "0"
      ? 4
      : painSco.bendingR === "1"
      ? 3
      : painSco.bendingR === "2"
      ? 2
      : painSco.bendingR === "3"
      ? 1
      : painSco.bendingR === "4"
      ? 0
      : 0;

  const BendingL =
    painSco.bendingL === "0"
      ? 4
      : painSco.bendingL === "1"
      ? 3
      : painSco.bendingL === "2"
      ? 2
      : painSco.bendingL === "3"
      ? 1
      : painSco.bendingL === "4"
      ? 0
      : 0;

  const WalkingR =
    painSco.walkingR === "0"
      ? 4
      : painSco.walkingR === "1"
      ? 3
      : painSco.walkingR === "2"
      ? 2
      : painSco.walkingR === "3"
      ? 1
      : painSco.walkingR === "4"
      ? 0
      : 0;

  const WalkingL =
    painSco.walkingL === "0"
      ? 4
      : painSco.walkingL === "1"
      ? 3
      : painSco.walkingL === "2"
      ? 2
      : painSco.walkingL === "3"
      ? 1
      : painSco.walkingL === "4"
      ? 0
      : 0;

  const GoingR =
    painSco.goingR === "0"
      ? 4
      : painSco.goingR === "1"
      ? 3
      : painSco.goingR === "2"
      ? 2
      : painSco.goingR === "3"
      ? 1
      : painSco.goingR === "4"
      ? 0
      : 0;

  const GoingL =
    painSco.goingL === "0"
      ? 4
      : painSco.goingL === "1"
      ? 3
      : painSco.goingL === "2"
      ? 2
      : painSco.goingL === "3"
      ? 1
      : painSco.goingL === "4"
      ? 0
      : 0;

  const NightR =
    painSco.nightR === "0"
      ? 4
      : painSco.nightR === "1"
      ? 3
      : painSco.nightR === "2"
      ? 2
      : painSco.nightR === "3"
      ? 1
      : painSco.nightR === "4"
      ? 0
      : 0;

  const NightL =
    painSco.nightL === "0"
      ? 4
      : painSco.nightL === "1"
      ? 3
      : painSco.nightL === "2"
      ? 2
      : painSco.nightL === "3"
      ? 1
      : painSco.nightL === "4"
      ? 0
      : 0;

  const SittingR =
    painSco.sittingR === "0"
      ? 4
      : painSco.sittingR === "1"
      ? 3
      : painSco.sittingR === "2"
      ? 2
      : painSco.sittingR === "3"
      ? 1
      : painSco.sittingR === "4"
      ? 0
      : 0;

  const SittingL =
    painSco.sittingL === "0"
      ? 4
      : painSco.sittingL === "1"
      ? 3
      : painSco.sittingL === "2"
      ? 2
      : painSco.sittingL === "3"
      ? 1
      : painSco.sittingL === "4"
      ? 0
      : 0;

  const StandingR =
    painSco.standingR === "0"
      ? 4
      : painSco.standingR === "1"
      ? 3
      : painSco.standingR === "2"
      ? 2
      : painSco.standingR === "3"
      ? 1
      : painSco.standingR === "4"
      ? 0
      : 0;

  const StandingL =
    painSco.standingL === "0"
      ? 4
      : painSco.standingL === "1"
      ? 3
      : painSco.standingL === "2"
      ? 2
      : painSco.standingL === "3"
      ? 1
      : painSco.standingL === "4"
      ? 0
      : 0;

  const fadiDesStairR =
    functionSco.desStairR === "0"
      ? 4
      : functionSco.desStairR === "1"
      ? 3
      : functionSco.desStairR === "2"
      ? 2
      : functionSco.desStairR === "3"
      ? 1
      : functionSco.desStairR === "4"
      ? 0
      : 0;

  const fadiDesStairL =
    functionSco.desStairL === "0"
      ? 4
      : functionSco.desStairL === "1"
      ? 3
      : functionSco.desStairL === "2"
      ? 2
      : functionSco.desStairL === "3"
      ? 1
      : functionSco.desStairL === "4"
      ? 0
      : 0;

  const fadiAscStairR =
    functionSco.ascStairR === "0"
      ? 4
      : functionSco.ascStairR === "1"
      ? 3
      : functionSco.ascStairR === "2"
      ? 2
      : functionSco.ascStairR === "3"
      ? 1
      : functionSco.ascStairR === "4"
      ? 0
      : 0;

  const fadiAscStairL =
    functionSco.ascStairL === "0"
      ? 4
      : functionSco.ascStairL === "1"
      ? 3
      : functionSco.ascStairL === "2"
      ? 2
      : functionSco.ascStairL === "3"
      ? 1
      : functionSco.ascStairL === "4"
      ? 0
      : 0;

  const fadiStandingR =
    functionSco.standingR === "0"
      ? 4
      : functionSco.standingR === "1"
      ? 3
      : functionSco.standingR === "2"
      ? 2
      : functionSco.standingR === "3"
      ? 1
      : functionSco.standingR === "4"
      ? 0
      : 0;

  const fadiStandingL =
    functionSco.standingL === "0"
      ? 4
      : functionSco.standingL === "1"
      ? 3
      : functionSco.standingL === "2"
      ? 2
      : functionSco.standingL === "3"
      ? 1
      : functionSco.standingL === "4"
      ? 0
      : 0;

  const fadiWalkingFlatR =
    functionSco.walkingFlatR === "0"
      ? 4
      : functionSco.walkingFlatR === "1"
      ? 3
      : functionSco.walkingFlatR === "2"
      ? 2
      : functionSco.walkingFlatR === "3"
      ? 1
      : functionSco.walkingFlatR === "4"
      ? 0
      : 0;

  const fadiWalkingFlatL =
    functionSco.walkingFlatL === "0"
      ? 4
      : functionSco.walkingFlatL === "1"
      ? 3
      : functionSco.walkingFlatL === "2"
      ? 2
      : functionSco.walkingFlatL === "3"
      ? 1
      : functionSco.walkingFlatL === "4"
      ? 0
      : 0;

  const fadiWalkingWithoutShoesR =
    functionSco.walkingWithoutShoesR === "0"
      ? 4
      : functionSco.walkingWithoutShoesR === "1"
      ? 3
      : functionSco.walkingWithoutShoesR === "2"
      ? 2
      : functionSco.walkingWithoutShoesR === "3"
      ? 1
      : functionSco.walkingWithoutShoesR === "4"
      ? 0
      : 0;

  const fadiWalkingWithoutShoesL =
    functionSco.walkingWithoutShoesL === "0"
      ? 4
      : functionSco.walkingWithoutShoesL === "1"
      ? 3
      : functionSco.walkingWithoutShoesL === "2"
      ? 2
      : functionSco.walkingWithoutShoesL === "3"
      ? 1
      : functionSco.walkingWithoutShoesL === "4"
      ? 0
      : 0;

  const fadiWalkingHillsR =
    functionSco.walkingHillsR === "0"
      ? 4
      : functionSco.walkingHillsR === "1"
      ? 3
      : functionSco.walkingHillsR === "2"
      ? 2
      : functionSco.walkingHillsR === "3"
      ? 1
      : functionSco.walkingHillsR === "4"
      ? 0
      : 0;

  const fadiWalkingHillsL =
    functionSco.walkingHillsL === "0"
      ? 4
      : functionSco.walkingHillsL === "1"
      ? 3
      : functionSco.walkingHillsL === "2"
      ? 2
      : functionSco.walkingHillsL === "3"
      ? 1
      : functionSco.walkingHillsL === "4"
      ? 0
      : 0;

  const fadiWalkingDownHillsR =
    functionSco.walkingDownHillsR === "0"
      ? 4
      : functionSco.walkingDownHillsR === "1"
      ? 3
      : functionSco.walkingDownHillsR === "2"
      ? 2
      : functionSco.walkingDownHillsR === "3"
      ? 1
      : functionSco.walkingDownHillsR === "4"
      ? 0
      : 0;

  const fadiWalkingDownHillsL =
    functionSco.walkingDownHillsL === "0"
      ? 4
      : functionSco.walkingDownHillsL === "1"
      ? 3
      : functionSco.walkingDownHillsL === "2"
      ? 2
      : functionSco.walkingDownHillsL === "3"
      ? 1
      : functionSco.walkingDownHillsL === "4"
      ? 0
      : 0;

  const fadiWalkingUnevenR =
    functionSco.walkingUnevenR === "0"
      ? 4
      : functionSco.walkingUnevenR === "1"
      ? 3
      : functionSco.walkingUnevenR === "2"
      ? 2
      : functionSco.walkingUnevenR === "3"
      ? 1
      : functionSco.walkingUnevenR === "4"
      ? 0
      : 0;

  const fadiWalkingUnevenL =
    functionSco.walkingUnevenL === "0"
      ? 4
      : functionSco.walkingUnevenL === "1"
      ? 3
      : functionSco.walkingUnevenL === "2"
      ? 2
      : functionSco.walkingUnevenL === "3"
      ? 1
      : functionSco.walkingUnevenL === "4"
      ? 0
      : 0;

  const fadiSteppingUpR =
    functionSco.steppingUpR === "0"
      ? 4
      : functionSco.steppingUpR === "1"
      ? 3
      : functionSco.steppingUpR === "2"
      ? 2
      : functionSco.steppingUpR === "3"
      ? 1
      : functionSco.steppingUpR === "4"
      ? 0
      : 0;

  const fadiSteppingUpL =
    functionSco.steppingUpL === "0"
      ? 4
      : functionSco.steppingUpL === "1"
      ? 3
      : functionSco.steppingUpL === "2"
      ? 2
      : functionSco.steppingUpL === "3"
      ? 1
      : functionSco.steppingUpL === "4"
      ? 0
      : 0;

  const fadiComingUpR =
    functionSco.comingUpR === "0"
      ? 4
      : functionSco.comingUpR === "1"
      ? 3
      : functionSco.comingUpR === "2"
      ? 2
      : functionSco.comingUpR === "3"
      ? 1
      : functionSco.comingUpR === "4"
      ? 0
      : 0;

  const fadiComingUpL =
    functionSco.comingUpL === "0"
      ? 4
      : functionSco.comingUpL === "1"
      ? 3
      : functionSco.comingUpL === "2"
      ? 2
      : functionSco.comingUpL === "3"
      ? 1
      : functionSco.comingUpL === "4"
      ? 0
      : 0;

  const fadiheavyWorkR =
    functionSco.heavyWorkR === "0"
      ? 4
      : functionSco.heavyWorkR === "1"
      ? 3
      : functionSco.heavyWorkR === "2"
      ? 2
      : functionSco.heavyWorkR === "3"
      ? 1
      : functionSco.heavyWorkR === "4"
      ? 0
      : 0;
  const fadiheavyWorkL =
    functionSco.heavyWorkL === "0"
      ? 4
      : functionSco.heavyWorkL === "1"
      ? 3
      : functionSco.heavyWorkL === "2"
      ? 2
      : functionSco.heavyWorkL === "3"
      ? 1
      : functionSco.heavyWorkL === "4"
      ? 0
      : 0;

  const fadiWalkFiveR =
    functionSco.walkFiveR === "0"
      ? 4
      : functionSco.walkFiveR === "1"
      ? 3
      : functionSco.walkFiveR === "2"
      ? 2
      : functionSco.walkFiveR === "3"
      ? 1
      : functionSco.walkFiveR === "4"
      ? 0
      : 0;

  const fadiWalkFiveL =
    functionSco.walkFiveL === "0"
      ? 4
      : functionSco.walkFiveL === "1"
      ? 3
      : functionSco.walkFiveL === "2"
      ? 2
      : functionSco.walkFiveL === "3"
      ? 1
      : functionSco.walkFiveL === "4"
      ? 0
      : 0;

  const fadiWalkIntiallyR =
    functionSco.walkIntiallyR === "0"
      ? 4
      : functionSco.walkIntiallyR === "1"
      ? 3
      : functionSco.walkIntiallyR === "2"
      ? 2
      : functionSco.walkIntiallyR === "3"
      ? 1
      : functionSco.walkIntiallyR === "4"
      ? 0
      : 0;

  const fadiWalkIntiallyL =
    functionSco.walkIntiallyL === "0"
      ? 4
      : functionSco.walkIntiallyL === "1"
      ? 3
      : functionSco.walkIntiallyL === "2"
      ? 2
      : functionSco.walkIntiallyL === "3"
      ? 1
      : functionSco.walkIntiallyL === "4"
      ? 0
      : 0;

  const fadiWalkTenR =
    functionSco.walkTenR === "0"
      ? 4
      : functionSco.walkTenR === "1"
      ? 3
      : functionSco.walkTenR === "2"
      ? 2
      : functionSco.walkTenR === "3"
      ? 1
      : functionSco.walkTenR === "4"
      ? 0
      : 0;

  const fadiWalkTenL =
    functionSco.walkTenL === "0"
      ? 4
      : functionSco.walkTenL === "1"
      ? 3
      : functionSco.walkTenL === "2"
      ? 2
      : functionSco.walkTenL === "3"
      ? 1
      : functionSco.walkTenL === "4"
      ? 0
      : 0;

  const fadiWalkFifteenR =
    functionSco.walkFifteenR === "0"
      ? 4
      : functionSco.walkFifteenR === "1"
      ? 3
      : functionSco.walkFifteenR === "2"
      ? 2
      : functionSco.walkFifteenR === "3"
      ? 1
      : functionSco.walkFifteenR === "4"
      ? 0
      : 0;

  const fadiWalkFifteenL =
    functionSco.walkFifteenL === "0"
      ? 4
      : functionSco.walkFifteenL === "1"
      ? 3
      : functionSco.walkFifteenL === "2"
      ? 2
      : functionSco.walkFifteenL === "3"
      ? 1
      : functionSco.walkFifteenL === "4"
      ? 0
      : 0;

  const fadiSleepingR =
    functionSco.sleepingR === "0"
      ? 4
      : functionSco.sleepingR === "1"
      ? 3
      : functionSco.sleepingR === "2"
      ? 2
      : functionSco.sleepingR === "3"
      ? 1
      : functionSco.sleepingR === "4"
      ? 0
      : 0;

  const fadiSleepingL =
    functionSco.sleepingL === "0"
      ? 4
      : functionSco.sleepingL === "1"
      ? 3
      : functionSco.sleepingL === "2"
      ? 2
      : functionSco.sleepingL === "3"
      ? 1
      : functionSco.sleepingL === "4"
      ? 0
      : 0;

  const fadiPersonalCareR =
    functionSco.personalCareR === "0"
      ? 4
      : functionSco.personalCareR === "1"
      ? 3
      : functionSco.personalCareR === "2"
      ? 2
      : functionSco.personalCareR === "3"
      ? 1
      : functionSco.personalCareR === "4"
      ? 0
      : 0;

  const fadiPersonalCareL =
    functionSco.personalCareL === "0"
      ? 4
      : functionSco.personalCareL === "1"
      ? 3
      : functionSco.personalCareL === "2"
      ? 2
      : functionSco.personalCareL === "3"
      ? 1
      : functionSco.personalCareL === "4"
      ? 0
      : 0;

  const fadiHomeR =
    functionSco.homeR === "0"
      ? 4
      : functionSco.homeR === "1"
      ? 3
      : functionSco.homeR === "2"
      ? 2
      : functionSco.homeR === "3"
      ? 1
      : functionSco.homeR === "4"
      ? 0
      : 0;

  const fadiHomeL =
    functionSco.homeL === "0"
      ? 4
      : functionSco.homeL === "1"
      ? 3
      : functionSco.homeL === "2"
      ? 2
      : functionSco.homeL === "3"
      ? 1
      : functionSco.homeL === "4"
      ? 0
      : 0;

  const fadiActivitiesR =
    functionSco.activitiesR === "0"
      ? 4
      : functionSco.activitiesR === "1"
      ? 3
      : functionSco.activitiesR === "2"
      ? 2
      : functionSco.activitiesR === "3"
      ? 1
      : functionSco.activitiesR === "4"
      ? 0
      : 0;

  const fadiActivitiesL =
    functionSco.activitiesL === "0"
      ? 4
      : functionSco.activitiesL === "1"
      ? 3
      : functionSco.activitiesL === "2"
      ? 2
      : functionSco.activitiesL === "3"
      ? 1
      : functionSco.activitiesL === "4"
      ? 0
      : 0;

  const fadiModerateWorkR =
    functionSco.moderateWorkR === "0"
      ? 4
      : functionSco.moderateWorkR === "1"
      ? 3
      : functionSco.moderateWorkR === "2"
      ? 2
      : functionSco.moderateWorkR === "3"
      ? 1
      : functionSco.moderateWorkR === "4"
      ? 0
      : 0;

  const fadiModerateWorkL =
    functionSco.moderateWorkL === "0"
      ? 4
      : functionSco.moderateWorkL === "1"
      ? 3
      : functionSco.moderateWorkL === "2"
      ? 2
      : functionSco.moderateWorkL === "3"
      ? 1
      : functionSco.moderateWorkL === "4"
      ? 0
      : 0;

  const fadiRecreationalR =
    functionSco.recreationalR === "0"
      ? 4
      : functionSco.recreationalR === "1"
      ? 3
      : functionSco.recreationalR === "2"
      ? 2
      : functionSco.recreationalR === "3"
      ? 1
      : functionSco.recreationalR === "4"
      ? 0
      : 0;

  const fadiRecreationalL =
    functionSco.recreationalL === "0"
      ? 4
      : functionSco.recreationalL === "1"
      ? 3
      : functionSco.recreationalL === "2"
      ? 2
      : functionSco.recreationalL === "3"
      ? 1
      : functionSco.recreationalL === "4"
      ? 0
      : 0;

  const fadiSquattingR =
    functionSco.squattingR === "0"
      ? 4
      : functionSco.squattingR === "1"
      ? 3
      : functionSco.squattingR === "2"
      ? 2
      : functionSco.squattingR === "3"
      ? 1
      : functionSco.squattingR === "4"
      ? 0
      : 0;

  const fadiSquattingL =
    functionSco.squattingL === "0"
      ? 4
      : functionSco.squattingL === "1"
      ? 3
      : functionSco.squattingL === "2"
      ? 2
      : functionSco.squattingL === "3"
      ? 1
      : functionSco.squattingL === "4"
      ? 0
      : 0;

  //FADI Sport(start)
  const fadiJumpingR =
    functionSco.jumpingR === "0"
      ? 4
      : functionSco.jumpingR === "1"
      ? 3
      : functionSco.jumpingR === "2"
      ? 2
      : functionSco.jumpingR === "3"
      ? 1
      : functionSco.jumpingR === "4"
      ? 0
      : 0;

  const fadiJumpingL =
    functionSco.jumpingL === "0"
      ? 4
      : functionSco.jumpingL === "1"
      ? 3
      : functionSco.jumpingL === "2"
      ? 2
      : functionSco.jumpingL === "3"
      ? 1
      : functionSco.jumpingL === "4"
      ? 0
      : 0;

  const fadiRunningR =
    functionSco.runningR === "0"
      ? 4
      : functionSco.runningR === "1"
      ? 3
      : functionSco.runningR === "2"
      ? 2
      : functionSco.runningR === "3"
      ? 1
      : functionSco.runningR === "4"
      ? 0
      : 0;

  const fadiRunningL =
    functionSco.runningL === "0"
      ? 4
      : functionSco.runningL === "1"
      ? 3
      : functionSco.runningL === "2"
      ? 2
      : functionSco.runningL === "3"
      ? 1
      : functionSco.runningL === "4"
      ? 0
      : 0;

  const fadilowActR =
    functionSco.lowActR === "0"
      ? 4
      : functionSco.lowActR === "1"
      ? 3
      : functionSco.lowActR === "2"
      ? 2
      : functionSco.lowActR === "3"
      ? 1
      : functionSco.lowActR === "4"
      ? 0
      : 0;

  const fadilowActL =
    functionSco.lowActL === "0"
      ? 4
      : functionSco.lowActL === "1"
      ? 3
      : functionSco.lowActL === "2"
      ? 2
      : functionSco.lowActL === "3"
      ? 1
      : functionSco.lowActL === "4"
      ? 0
      : 0;

  const fadiLandingR =
    functionSco.landingR === "0"
      ? 4
      : functionSco.landingR === "1"
      ? 3
      : functionSco.landingR === "2"
      ? 2
      : functionSco.landingR === "3"
      ? 1
      : functionSco.landingR === "4"
      ? 0
      : 0;

  const fadiLandingL =
    functionSco.landingL === "0"
      ? 4
      : functionSco.landingL === "1"
      ? 3
      : functionSco.landingL === "2"
      ? 2
      : functionSco.landingL === "3"
      ? 1
      : functionSco.landingL === "4"
      ? 0
      : 0;

  const fadistoppingR =
    functionSco.stoppingR === "0"
      ? 4
      : functionSco.stoppingR === "1"
      ? 3
      : functionSco.stoppingR === "2"
      ? 2
      : functionSco.stoppingR === "3"
      ? 1
      : functionSco.stoppingR === "4"
      ? 0
      : 0;

  const fadistoppingL =
    functionSco.stoppingL === "0"
      ? 4
      : functionSco.stoppingL === "1"
      ? 3
      : functionSco.stoppingL === "2"
      ? 2
      : functionSco.stoppingL === "3"
      ? 1
      : functionSco.stoppingL === "4"
      ? 0
      : 0;

  const fadicuttingR =
    functionSco.cuttingR === "0"
      ? 4
      : functionSco.cuttingR === "1"
      ? 3
      : functionSco.cuttingR === "2"
      ? 2
      : functionSco.cuttingR === "3"
      ? 1
      : functionSco.cuttingR === "4"
      ? 0
      : 0;

  const fadicuttingL =
    functionSco.cuttingL === "0"
      ? 4
      : functionSco.cuttingL === "1"
      ? 3
      : functionSco.cuttingL === "2"
      ? 2
      : functionSco.cuttingL === "3"
      ? 1
      : functionSco.cuttingL === "4"
      ? 0
      : 0;

  const fadiabilityParticipateR =
    functionSco.abilityParticipateR === "0"
      ? 4
      : functionSco.abilityParticipateR === "1"
      ? 3
      : functionSco.abilityParticipateR === "2"
      ? 2
      : functionSco.abilityParticipateR === "3"
      ? 1
      : functionSco.abilityParticipateR === "4"
      ? 0
      : 0;

  const fadiabilityParticipateL =
    functionSco.abilityParticipateL === "0"
      ? 4
      : functionSco.abilityParticipateL === "1"
      ? 3
      : functionSco.abilityParticipateL === "2"
      ? 2
      : functionSco.abilityParticipateL === "3"
      ? 1
      : functionSco.abilityParticipateL === "4"
      ? 0
      : 0;

  const fadiabilityPerformR =
    functionSco.abilityPerformR === "0"
      ? 4
      : functionSco.abilityPerformR === "1"
      ? 3
      : functionSco.abilityPerformR === "2"
      ? 2
      : functionSco.abilityPerformR === "3"
      ? 1
      : functionSco.abilityPerformR === "4"
      ? 0
      : 0;

  const fadiabilityPerformL =
    functionSco.abilityPerformL === "0"
      ? 4
      : functionSco.abilityPerformL === "1"
      ? 3
      : functionSco.abilityPerformL === "2"
      ? 2
      : functionSco.abilityPerformL === "3"
      ? 1
      : functionSco.abilityPerformL === "4"
      ? 0
      : 0;

  //FADI Sport(end)

  //FAOS

  //FAOS PAIN START
  const FaosPainRatingR =
    painSco.oftenR === "0"
      ? 4
      : painSco.oftenR === "1"
      ? 3
      : painSco.oftenR === "2"
      ? 2
      : painSco.oftenR === "3"
      ? 1
      : painSco.oftenR === "4"
      ? 0
      : 0;
  const FaosPainRatingL =
    painSco.oftenL === "0"
      ? 4
      : painSco.oftenL === "1"
      ? 3
      : painSco.oftenL === "2"
      ? 2
      : painSco.oftenL === "3"
      ? 1
      : painSco.oftenL === "4"
      ? 0
      : 0;

  const faosTwistingR =
    painSco.twistedR === "0"
      ? 4
      : painSco.twistedR === "1"
      ? 3
      : painSco.twistedR === "2"
      ? 2
      : painSco.twistedR === "3"
      ? 1
      : painSco.twistedR === "4"
      ? 0
      : 0;

  const faosTwistingL =
    painSco.twistedL === "0"
      ? 4
      : painSco.twistedL === "1"
      ? 3
      : painSco.twistedL === "2"
      ? 2
      : painSco.twistedL === "3"
      ? 1
      : painSco.twistedL === "4"
      ? 0
      : 0;

  //FAOS PAIN END

  // FAOS ADL(start)

  const faosDesStairR =
    functionSco.desStairR === "0"
      ? 4
      : functionSco.desStairR === "1"
      ? 3
      : functionSco.desStairR === "2"
      ? 2
      : functionSco.desStairR === "3"
      ? 1
      : functionSco.desStairR === "4"
      ? 0
      : 0;

  const faosDesStairL =
    functionSco.desStairL === "0"
      ? 4
      : functionSco.desStairL === "1"
      ? 3
      : functionSco.desStairL === "2"
      ? 2
      : functionSco.desStairL === "3"
      ? 1
      : functionSco.desStairL === "4"
      ? 0
      : 0;

  const faosAscStairR =
    functionSco.ascStairR === "0"
      ? 4
      : functionSco.ascStairR === "1"
      ? 3
      : functionSco.ascStairR === "2"
      ? 2
      : functionSco.ascStairR === "3"
      ? 1
      : functionSco.ascStairR === "4"
      ? 0
      : 0;

  const faosAscStairL =
    functionSco.ascStairL === "0"
      ? 4
      : functionSco.ascStairL === "1"
      ? 3
      : functionSco.ascStairL === "2"
      ? 2
      : functionSco.ascStairL === "3"
      ? 1
      : functionSco.ascStairL === "4"
      ? 0
      : 0;

  const faosRisingR =
    functionSco.risingR === "0"
      ? 4
      : functionSco.risingR === "1"
      ? 3
      : functionSco.risingR === "2"
      ? 2
      : functionSco.risingR === "3"
      ? 1
      : functionSco.risingR === "4"
      ? 0
      : 0;

  const faosRisingL =
    functionSco.risingL === "0"
      ? 4
      : functionSco.risingL === "1"
      ? 3
      : functionSco.risingL === "2"
      ? 2
      : functionSco.risingL === "3"
      ? 1
      : functionSco.risingL === "4"
      ? 0
      : 0;

  const faosStandingR =
    functionSco.standingR === "0"
      ? 4
      : functionSco.standingR === "1"
      ? 3
      : functionSco.standingR === "2"
      ? 2
      : functionSco.standingR === "3"
      ? 1
      : functionSco.standingR === "4"
      ? 0
      : 0;

  const faosStandingL =
    functionSco.standingL === "0"
      ? 4
      : functionSco.standingL === "1"
      ? 3
      : functionSco.standingL === "2"
      ? 2
      : functionSco.standingL === "3"
      ? 1
      : functionSco.standingL === "4"
      ? 0
      : 0;

  const faosBendingFloorR =
    functionSco.bendingFloorR === "0"
      ? 4
      : functionSco.bendingFloorR === "1"
      ? 3
      : functionSco.bendingFloorR === "2"
      ? 2
      : functionSco.bendingFloorR === "3"
      ? 1
      : functionSco.bendingFloorR === "4"
      ? 0
      : 0;

  const faosBendingFloorL =
    functionSco.bendingFloorL === "0"
      ? 4
      : functionSco.bendingFloorL === "1"
      ? 3
      : functionSco.bendingFloorL === "2"
      ? 2
      : functionSco.bendingFloorL === "3"
      ? 1
      : functionSco.bendingFloorL === "4"
      ? 0
      : 0;

  const faosWalkingFlatR =
    functionSco.walkingFlatR === "0"
      ? 4
      : functionSco.walkingFlatR === "1"
      ? 3
      : functionSco.walkingFlatR === "2"
      ? 2
      : functionSco.walkingFlatR === "3"
      ? 1
      : functionSco.walkingFlatR === "4"
      ? 0
      : 0;

  const faosWalkingFlatL =
    functionSco.walkingFlatL === "0"
      ? 4
      : functionSco.walkingFlatL === "1"
      ? 3
      : functionSco.walkingFlatL === "2"
      ? 2
      : functionSco.walkingFlatL === "3"
      ? 1
      : functionSco.walkingFlatL === "4"
      ? 0
      : 0;

  const faosGettingCarR =
    functionSco.gettingCarR === "0"
      ? 4
      : functionSco.gettingCarR === "1"
      ? 3
      : functionSco.gettingCarR === "2"
      ? 2
      : functionSco.gettingCarR === "3"
      ? 1
      : functionSco.gettingCarR === "4"
      ? 0
      : 0;

  const faosGettingCarL =
    functionSco.gettingCarL === "0"
      ? 4
      : functionSco.gettingCarL === "1"
      ? 3
      : functionSco.gettingCarL === "2"
      ? 2
      : functionSco.gettingCarL === "3"
      ? 1
      : functionSco.gettingCarL === "4"
      ? 0
      : 0;

  const faosGoingShoppingR =
    functionSco.goingShoppingR === "0"
      ? 4
      : functionSco.goingShoppingR === "1"
      ? 3
      : functionSco.goingShoppingR === "2"
      ? 2
      : functionSco.goingShoppingR === "3"
      ? 1
      : functionSco.goingShoppingR === "4"
      ? 0
      : 0;

  const faosGoingShoppingL =
    functionSco.goingShoppingL === "0"
      ? 4
      : functionSco.goingShoppingL === "1"
      ? 3
      : functionSco.goingShoppingL === "2"
      ? 2
      : functionSco.goingShoppingL === "3"
      ? 1
      : functionSco.goingShoppingL === "4"
      ? 0
      : 0;

  const faosSlyingR =
    functionSco.lyingR === "0"
      ? 4
      : functionSco.lyingR === "1"
      ? 3
      : functionSco.lyingR === "2"
      ? 2
      : functionSco.lyingR === "3"
      ? 1
      : functionSco.lyingR === "4"
      ? 0
      : 0;

  const faosSlyingL =
    functionSco.lyingL === "0"
      ? 4
      : functionSco.lyingL === "1"
      ? 3
      : functionSco.lyingL === "2"
      ? 2
      : functionSco.lyingL === "3"
      ? 1
      : functionSco.lyingL === "4"
      ? 0
      : 0;

  const faosPuttingSocksR =
    functionSco.puttingSocksR === "0"
      ? 4
      : functionSco.puttingSocksR === "1"
      ? 3
      : functionSco.puttingSocksR === "2"
      ? 2
      : functionSco.puttingSocksR === "3"
      ? 1
      : functionSco.puttingSocksR === "4"
      ? 0
      : 0;

  const faosPuttingSocksL =
    functionSco.puttingSocksL === "0"
      ? 4
      : functionSco.puttingSocksL === "1"
      ? 3
      : functionSco.puttingSocksL === "2"
      ? 2
      : functionSco.puttingSocksL === "3"
      ? 1
      : functionSco.puttingSocksL === "4"
      ? 0
      : 0;

  const faosSrisingBedR =
    functionSco.risingBedR === "0"
      ? 4
      : functionSco.risingBedR === "1"
      ? 3
      : functionSco.risingBedR === "2"
      ? 2
      : functionSco.risingBedR === "3"
      ? 1
      : functionSco.risingBedR === "4"
      ? 0
      : 0;

  const faosSrisingBedL =
    functionSco.risingBedL === "0"
      ? 4
      : functionSco.risingBedL === "1"
      ? 3
      : functionSco.risingBedL === "2"
      ? 2
      : functionSco.risingBedL === "3"
      ? 1
      : functionSco.risingBedL === "4"
      ? 0
      : 0;

  const faosTakingOffR =
    functionSco.takingOffR === "0"
      ? 4
      : functionSco.takingOffR === "1"
      ? 3
      : functionSco.takingOffR === "2"
      ? 2
      : functionSco.takingOffR === "3"
      ? 1
      : functionSco.takingOffR === "4"
      ? 0
      : 0;

  const faosTakingOffL =
    functionSco.takingOffL === "0"
      ? 4
      : functionSco.takingOffL === "1"
      ? 3
      : functionSco.takingOffL === "2"
      ? 2
      : functionSco.takingOffL === "3"
      ? 1
      : functionSco.takingOffL === "4"
      ? 0
      : 0;

  const faosGetBathR =
    functionSco.getBathR === "0"
      ? 4
      : functionSco.getBathR === "1"
      ? 3
      : functionSco.getBathR === "2"
      ? 2
      : functionSco.getBathR === "3"
      ? 1
      : functionSco.getBathR === "4"
      ? 0
      : 0;

  const faosGetBathL =
    functionSco.getBathL === "0"
      ? 4
      : functionSco.getBathL === "1"
      ? 3
      : functionSco.getBathL === "2"
      ? 2
      : functionSco.getBathL === "3"
      ? 1
      : functionSco.getBathL === "4"
      ? 0
      : 0;

  const faosSittingR =
    functionSco.sittingR === "0"
      ? 4
      : functionSco.sittingR === "1"
      ? 3
      : functionSco.sittingR === "2"
      ? 2
      : functionSco.sittingR === "3"
      ? 1
      : functionSco.sittingR === "4"
      ? 0
      : 0;

  const faosSittingL =
    functionSco.sittingL === "0"
      ? 4
      : functionSco.sittingL === "1"
      ? 3
      : functionSco.sittingL === "2"
      ? 2
      : functionSco.sittingL === "3"
      ? 1
      : functionSco.sittingL === "4"
      ? 0
      : 0;

  const faosGetToiletR =
    functionSco.getToiletR === "0"
      ? 4
      : functionSco.getToiletR === "1"
      ? 3
      : functionSco.getToiletR === "2"
      ? 2
      : functionSco.getToiletR === "3"
      ? 1
      : functionSco.getToiletR === "4"
      ? 0
      : 0;

  const faosGetToiletL =
    functionSco.getToiletL === "0"
      ? 4
      : functionSco.getToiletL === "1"
      ? 3
      : functionSco.getToiletL === "2"
      ? 2
      : functionSco.getToiletL === "3"
      ? 1
      : functionSco.getToiletL === "4"
      ? 0
      : 0;

  const faosLightWorkR =
    functionSco.lightWorkR === "0"
      ? 4
      : functionSco.lightWorkR === "1"
      ? 3
      : functionSco.lightWorkR === "2"
      ? 2
      : functionSco.lightWorkR === "3"
      ? 1
      : functionSco.lightWorkR === "4"
      ? 0
      : 0;

  const faosLightWorkL =
    functionSco.lightWorkL === "0"
      ? 4
      : functionSco.lightWorkL === "1"
      ? 3
      : functionSco.lightWorkL === "2"
      ? 2
      : functionSco.lightWorkL === "3"
      ? 1
      : functionSco.lightWorkL === "4"
      ? 0
      : 0;

  const faosModerateWorkR =
    functionSco.moderateWorkR === "0"
      ? 4
      : functionSco.moderateWorkR === "1"
      ? 3
      : functionSco.moderateWorkR === "2"
      ? 2
      : functionSco.moderateWorkR === "3"
      ? 1
      : functionSco.moderateWorkR === "4"
      ? 0
      : 0;

  const faosModerateWorkL =
    functionSco.moderateWorkL === "0"
      ? 4
      : functionSco.moderateWorkL === "1"
      ? 3
      : functionSco.moderateWorkL === "2"
      ? 2
      : functionSco.moderateWorkL === "3"
      ? 1
      : functionSco.moderateWorkL === "4"
      ? 0
      : 0;

  const faosheavyWorkR =
    functionSco.heavyWorkR === "0"
      ? 4
      : functionSco.heavyWorkR === "1"
      ? 3
      : functionSco.heavyWorkR === "2"
      ? 2
      : functionSco.heavyWorkR === "3"
      ? 1
      : functionSco.heavyWorkR === "4"
      ? 0
      : 0;

  const faosheavyWorkL =
    functionSco.heavyWorkL === "0"
      ? 4 /////////////////////////////////////////
      : functionSco.heavyWorkL === "1"
      ? 3
      : functionSco.heavyWorkL === "2"
      ? 2
      : functionSco.heavyWorkL === "3"
      ? 1
      : functionSco.heavyWorkL === "4"
      ? 0
      : 0;

  const faosSquattingR =
    functionSco.squattingR === "0"
      ? 4
      : functionSco.squattingR === "1"
      ? 3
      : functionSco.squattingR === "2"
      ? 2
      : functionSco.squattingR === "3"
      ? 1
      : functionSco.squattingR === "4"
      ? 0
      : 0;

  const faosSquattingL =
    functionSco.squattingL === "0"
      ? 4
      : functionSco.squattingL === "1"
      ? 3
      : functionSco.squattingL === "2"
      ? 2
      : functionSco.squattingL === "3"
      ? 1
      : functionSco.squattingL === "4"
      ? 0
      : 0;

  const faosJumpingR =
    functionSco.jumpingR === "0"
      ? 4
      : functionSco.jumpingR === "1"
      ? 3
      : functionSco.jumpingR === "2"
      ? 2
      : functionSco.jumpingR === "3"
      ? 1
      : functionSco.jumpingR === "4"
      ? 0
      : 0;

  const faosJumpingL =
    functionSco.jumpingL === "0"
      ? 4
      : functionSco.jumpingL === "1"
      ? 3
      : functionSco.jumpingL === "2"
      ? 2
      : functionSco.jumpingL === "3"
      ? 1
      : functionSco.jumpingL === "4"
      ? 0
      : 0;

  const faosKneelingR =
    functionSco.kneelingR === "0"
      ? 4
      : functionSco.kneelingR === "1"
      ? 3
      : functionSco.kneelingR === "2"
      ? 2
      : functionSco.kneelingR === "3"
      ? 1
      : functionSco.kneelingR === "4"
      ? 0
      : 0;

  const faosKneelingL =
    functionSco.kneelingL === "0"
      ? 4
      : functionSco.kneelingL === "1"
      ? 3
      : functionSco.kneelingL === "2"
      ? 2
      : functionSco.kneelingL === "3"
      ? 1
      : functionSco.kneelingL === "4"
      ? 0
      : 0;

  const faosRunningR =
    functionSco.runningR === "0"
      ? 4
      : functionSco.runningR === "1"
      ? 3
      : functionSco.runningR === "2"
      ? 2
      : functionSco.runningR === "3"
      ? 1
      : functionSco.runningR === "4"
      ? 0
      : 0;

  const faosRunningL =
    functionSco.runningL === "0"
      ? 4
      : functionSco.runningL === "1"
      ? 3
      : functionSco.runningL === "2"
      ? 2
      : functionSco.runningL === "3"
      ? 1
      : functionSco.runningL === "4"
      ? 0
      : 0;

  const faostwistingR =
    functionSco.twistingR === "0"
      ? 4
      : functionSco.twistingR === "1"
      ? 3
      : functionSco.twistingR === "2"
      ? 2
      : functionSco.twistingR === "3"
      ? 1
      : functionSco.twistingR === "4"
      ? 0
      : 0;

  const faostwistingL =
    functionSco.twistingL === "0"
      ? 4
      : functionSco.twistingL === "1"
      ? 3
      : functionSco.twistingL === "2"
      ? 2
      : functionSco.twistingL === "3"
      ? 1
      : functionSco.twistingL === "4"
      ? 0
      : 0;

  const FaoslifestyleR =
    qolSco.lifestyleR === "0"
      ? 4
      : qolSco.lifestyleR === "1"
      ? 3
      : qolSco.lifestyleR === "2"
      ? 2
      : qolSco.lifestyleR === "3"
      ? 1
      : qolSco.lifestyleR === "4"
      ? 0
      : 0;

  const FaoslifestyleL =
    qolSco.lifestyleL === "0"
      ? 4
      : qolSco.lifestyleL === "1"
      ? 3
      : qolSco.lifestyleL === "2"
      ? 2
      : qolSco.lifestyleL === "3"
      ? 1
      : qolSco.lifestyleL === "4"
      ? 0
      : 0;

  const FaostroubledR =
    qolSco.troubledR === "0"
      ? 4
      : qolSco.troubledR === "1"
      ? 3
      : qolSco.troubledR === "2"
      ? 2
      : qolSco.troubledR === "3"
      ? 1
      : qolSco.troubledR === "4"
      ? 0
      : 0;

  const FaostroubledL =
    qolSco.troubledL === "0"
      ? 4
      : qolSco.troubledL === "1"
      ? 3
      : qolSco.troubledL === "2"
      ? 2
      : qolSco.troubledL === "3"
      ? 1
      : qolSco.troubledL === "4"
      ? 0
      : 0;

  const FaosdifficultyR =
    qolSco.difficultyR === "0"
      ? 4
      : qolSco.difficultyR === "1"
      ? 3
      : qolSco.difficultyR === "2"
      ? 2
      : qolSco.difficultyR === "3"
      ? 1
      : qolSco.difficultyR === "4"
      ? 0
      : 0;

  const FaosdifficultyL =
    qolSco.difficultyL === "0"
      ? 4
      : qolSco.difficultyL === "1"
      ? 3
      : qolSco.difficultyL === "2"
      ? 2
      : qolSco.difficultyL === "3"
      ? 1
      : qolSco.difficultyL === "4"
      ? 0
      : 0;

  const FaosoftenAnkleR =
    qolSco.oftenAnkleR === "0"
      ? 4
      : qolSco.oftenAnkleR === "1"
      ? 3
      : qolSco.oftenAnkleR === "2"
      ? 2
      : qolSco.oftenAnkleR === "3"
      ? 1
      : qolSco.oftenAnkleR === "4"
      ? 0
      : 0;

  const FaosoftenAnkleL =
    qolSco.oftenAnkleL === "0"
      ? 4
      : qolSco.oftenAnkleL === "1"
      ? 3
      : qolSco.oftenAnkleL === "2"
      ? 2
      : qolSco.oftenAnkleL === "3"
      ? 1
      : qolSco.oftenAnkleL === "4"
      ? 0
      : 0;

  // FAOS ADL(end)

  //FAOS Symptoms(start)

  const FaosSwellingL =
    symptomsSco.swellingL === "0"
      ? 4
      : symptomsSco.swellingL === "1"
      ? 3
      : symptomsSco.swellingL === "2"
      ? 2
      : symptomsSco.swellingL === "3"
      ? 1
      : symptomsSco.swellingL === "4"
      ? 0
      : 0;

  const FaosSwellingR =
    symptomsSco.swellingR === "0"
      ? 4
      : symptomsSco.swellingR === "1"
      ? 3
      : symptomsSco.swellingR === "2"
      ? 2
      : symptomsSco.swellingR === "3"
      ? 1
      : symptomsSco.swellingR === "4"
      ? 0
      : 0;
  const FaosCatchingL =
    symptomsSco.catchingL === "0"
      ? 4
      : symptomsSco.catchingL === "1"
      ? 3
      : symptomsSco.catchingL === "2"
      ? 2
      : symptomsSco.catchingL === "3"
      ? 1
      : symptomsSco.catchingL === "4"
      ? 0
      : 0;

  const FaosCatchingR =
    symptomsSco.catchingR === "0"
      ? 4
      : symptomsSco.catchingR === "1"
      ? 3
      : symptomsSco.catchingR === "2"
      ? 2
      : symptomsSco.catchingR === "3"
      ? 1
      : symptomsSco.catchingR === "4"
      ? 0
      : 0;

  const FaosGrindingL =
    symptomsSco.grindingL === "0"
      ? 4
      : symptomsSco.grindingL === "1"
      ? 3
      : symptomsSco.grindingL === "2"
      ? 2
      : symptomsSco.grindingL === "3"
      ? 1
      : symptomsSco.grindingL === "4"
      ? 0
      : 0;

  const FaosGrindingR =
    symptomsSco.grindingR === "0"
      ? 4
      : symptomsSco.grindingR === "1"
      ? 3
      : symptomsSco.grindingR === "2"
      ? 2
      : symptomsSco.grindingR === "3"
      ? 1
      : symptomsSco.grindingR === "4"
      ? 0
      : 0;

  const FaosStiffnessL =
    symptomsSco.stiffnessL === "0"
      ? 4
      : symptomsSco.stiffnessL === "1"
      ? 3
      : symptomsSco.stiffnessL === "2"
      ? 2
      : symptomsSco.stiffnessL === "3"
      ? 1
      : symptomsSco.stiffnessL === "4"
      ? 0
      : 0;

  const FaosStiffnessR =
    symptomsSco.stiffnessR === "0"
      ? 4
      : symptomsSco.stiffnessR === "1"
      ? 3
      : symptomsSco.stiffnessR === "2"
      ? 2
      : symptomsSco.stiffnessR === "3"
      ? 1
      : symptomsSco.stiffnessR === "4"
      ? 0
      : 0;

  const FaosStiffnessSittingL =
    symptomsSco.stiffnessSittingL === "0"
      ? 4
      : symptomsSco.stiffnessSittingL === "1"
      ? 3
      : symptomsSco.stiffnessSittingL === "2"
      ? 2
      : symptomsSco.stiffnessSittingL === "3"
      ? 1
      : symptomsSco.stiffnessSittingL === "4"
      ? 0
      : 0;

  const FaosStiffnessSittingR =
    symptomsSco.stiffnessSittingR === "0"
      ? 4
      : symptomsSco.stiffnessSittingR === "1"
      ? 3
      : symptomsSco.stiffnessSittingR === "2"
      ? 2
      : symptomsSco.stiffnessSittingR === "3"
      ? 1
      : symptomsSco.stiffnessSittingR === "4"
      ? 0
      : 0;

  const faosFootBendingR =
    functionSco.footBendingR === "0"
      ? 4
      : functionSco.footBendingR === "1"
      ? 3
      : functionSco.footBendingR === "2"
      ? 2
      : functionSco.footBendingR === "3"
      ? 1
      : functionSco.footBendingR === "4"
      ? 0
      : 0;

  const faosFootBendingL =
    functionSco.footBendingL === "0"
      ? 4
      : functionSco.footBendingL === "1"
      ? 3
      : functionSco.footBendingL === "2"
      ? 2
      : functionSco.footBendingL === "3"
      ? 1
      : functionSco.footBendingL === "4"
      ? 0
      : 0;

  const faosStraighteningR =
    functionSco.footStraightR === "0"
      ? 4
      : functionSco.footStraightR === "1"
      ? 3
      : functionSco.footStraightR === "2"
      ? 2
      : functionSco.footStraightR === "3"
      ? 1
      : functionSco.footStraightR === "4"
      ? 0
      : 0;

  const faosStraighteningL =
    functionSco.footStraightL === "0"
      ? 4
      : functionSco.footStraightL === "1"
      ? 3
      : functionSco.footStraightL === "2"
      ? 2
      : functionSco.footStraightL === "3"
      ? 1
      : functionSco.footStraightL === "4"
      ? 0
      : 0;

  //FAOS Symptoms(end)

  // AOFAS Scale (American Orthopaedic Foot and Ankle Society)

  //Lesser toes ============

  // const AolesserdescripR =
  //   painSco.descripR === "0" ? 40 :
  //     painSco.descripR === "1" ? 30 :
  //       painSco.descripR === "2" ? 20 :
  //         painSco.descripR === "3" ? 0
  //           : 0
  // const AolesserdescripL =
  //   painSco.descripL === "0" ? 40 :
  //     painSco.descripL === "1" ? 30 :
  //       painSco.descripL === "2" ? 20 :
  //         painSco.descripL === "3" ? 0
  //           : 0
  // const AolesserfootActivityR =
  //   functionSco.footActivityR === "3" ? 10 :
  //     functionSco.footActivityR === "2" ? 7 :
  //       functionSco.footActivityR === "1" ? 4 :
  //         functionSco.footActivityR === "0" ? 0
  //           : 0
  // const AolesserfootActivityL =
  //   functionSco.footActivityL === "3" ? 10 :
  //     functionSco.footActivityL === "2" ? 7 :
  //       functionSco.footActivityL === "1" ? 4 :
  //         functionSco.footActivityL === "0" ? 0
  //           : 0
  // const AolesserfootwearR =
  //   functionSco.footwearR === "2" ? 10 :
  //     functionSco.footwearR === "1" ? 5 :
  //       functionSco.footwearR === "0" ? 0
  //         : 0
  // const AolesserfootwearL =
  //   functionSco.footwearL === "2" ? 10 :
  //     functionSco.footwearL === "1" ? 5 :
  //       functionSco.footwearL === "0" ? 0
  //         : 0

  //VAS
  const VasPainRating =
    painSco.painRating === "0"
      ? 0
      : painSco.painRating === "1"
      ? 1
      : painSco.painRating === "2"
      ? 2
      : painSco.painRating === "3"
      ? 3
      : painSco.painRating === "4"
      ? 4
      : painSco.painRating === "5"
      ? 5
      : painSco.painRating === "6"
      ? 6
      : painSco.painRating === "7"
      ? 7
      : painSco.painRating === "8"
      ? 8
      : painSco.painRating === "9"
      ? 9
      : painSco.painRating === "10"
      ? 10
      : 0;
  const VasPainRatingL =
    painSco.painRatingL === "0"
      ? 0
      : painSco.painRatingL === "1"
      ? 1
      : painSco.painRatingL === "2"
      ? 2
      : painSco.painRatingL === "3"
      ? 3
      : painSco.painRatingL === "4"
      ? 4
      : painSco.painRatingL === "5"
      ? 5
      : painSco.painRatingL === "6"
      ? 6
      : painSco.painRatingL === "7"
      ? 7
      : painSco.painRatingL === "8"
      ? 8
      : painSco.painRatingL === "9"
      ? 9
      : painSco.painRatingL === "10"
      ? 10
      : 0;

  // SATISFACTION
  const SatisfactionForgetR = parseFloat(satisfactionSco.forgetR);
  const SatisfactionForgetL = parseFloat(satisfactionSco.forgetL);

  const SatisfactionImproveR = parseFloat(satisfactionSco.improveR);
  const SatisfactionImproveL = parseFloat(satisfactionSco.improveL);

  const SatisfactionSymptomR = parseFloat(satisfactionSco.symptomR);
  const SatisfactionSymptomL = parseFloat(satisfactionSco.symptomL);

  //ANKLE ACTIVITY SCALE

  const EQDGenLevelL =
    painSco.genPainL === "0"
      ? 1
      : painSco.genPainL === "1"
      ? 2
      : painSco.genPainL === "2"
      ? 3
      : painSco.genPainL === "3"
      ? 4
      : painSco.genPainL === "4"
      ? 5
      : 0;

  const EQDGenLevelR =
    painSco.genPainR === "0"
      ? 1
      : painSco.genPainR === "1"
      ? 2
      : painSco.genPainR === "2"
      ? 3
      : painSco.genPainR === "3"
      ? 4
      : painSco.genPainR === "4"
      ? 5
      : 0;

  const EQPain = EQDGenLevelR;
  const EQPainL = EQDGenLevelL;

  const EQDWalkingR =
    functionSco.walkingFlatR === "0"
      ? 1
      : functionSco.walkingFlatR === "1"
      ? 2
      : functionSco.walkingFlatR === "2"
      ? 3
      : functionSco.walkingFlatR === "3"
      ? 4
      : functionSco.walkingFlatR === "4"
      ? 5
      : 0;

  const EQDWalkingL =
    functionSco.walkingFlatL === "0"
      ? 1
      : functionSco.walkingFlatL === "1"
      ? 2
      : functionSco.walkingFlatL === "2"
      ? 3
      : functionSco.walkingFlatL === "3"
      ? 4
      : functionSco.walkingFlatL === "4"
      ? 5
      : 0;

  const EqdPersonalCareR =
    functionSco.personalCareR === "0"
      ? 1
      : functionSco.personalCareR === "1"
      ? 2
      : functionSco.personalCareR === "2"
      ? 3
      : functionSco.personalCareR === "3"
      ? 4
      : functionSco.personalCareR === "4"
      ? 5
      : 0;

  const EqdPersonalCareL =
    functionSco.personalCareL === "0"
      ? 1
      : functionSco.personalCareL === "1"
      ? 2
      : functionSco.personalCareL === "2"
      ? 3
      : functionSco.personalCareL === "3"
      ? 4
      : functionSco.personalCareL === "4"
      ? 5
      : 0;

  const EqdActivitiesR =
    functionSco.activitiesR === "0"
      ? 1
      : functionSco.activitiesR === "1"
      ? 2
      : functionSco.activitiesR === "2"
      ? 3
      : functionSco.activitiesR === "3"
      ? 4
      : functionSco.activitiesR === "4"
      ? 5
      : 0;

  const EqdActivitiesL =
    functionSco.activitiesL === "0"
      ? 1
      : functionSco.activitiesL === "1"
      ? 2
      : functionSco.activitiesL === "2"
      ? 3
      : functionSco.activitiesL === "3"
      ? 4
      : functionSco.activitiesL === "4"
      ? 5
      : 0;

  const EqdsanxiousR =
    qolSco.anxiousR === "0"
      ? 5
      : qolSco.anxiousR === "1"
      ? 4
      : qolSco.anxiousR === "2"
      ? 3
      : qolSco.anxiousR === "3"
      ? 2
      : qolSco.anxiousR === "4"
      ? 1
      : 0;

  const EqdsanxiousL =
    qolSco.anxiousL === "0"
      ? 5
      : qolSco.anxiousL === "1"
      ? 4
      : qolSco.anxiousL === "2"
      ? 3
      : qolSco.anxiousL === "3"
      ? 2
      : qolSco.anxiousL === "4"
      ? 1
      : 0;

  //EQ VAS

  const HealthToday = parseFloat(qolSco.healthToday);

  //SANE (Single Assessment Numeric Evaluation)

  const FootPercent = parseFloat(functionSco.footPercent);
  const FootPercentL = parseFloat(functionSco.footPercentL);

  //DECISION TREE..............(start)//

  const findMax = (arrOfScores, scoreObj) => {
    let maxScore = -1;

    for (let i = 0; i < arrOfScores.length; i++) {
      let currVal = scoreObj[arrOfScores[i]] || 0;
      if (currVal > maxScore) {
        maxScore = currVal;
      }
    }

    return maxScore;
  };

  const topLevelScoreR = {
    0: 7,
    1: 4,
    2: 10,
    3: 7,
    4: 7,
    5: 10,
    6: 4,
    7: 8,
    8: 7,
    9: 3,
    10: 6,
    11: 5,
    12: 3,
    13: 6,
    14: 6,
    15: 8,
    16: 4,
    17: 10,
    18: 10,
    19: 9,
    20: 8,
    21: 9,
    22: 9,
    23: 7,
    24: 3,
    25: 6,
    26: 4,
    27: 6,
    28: 9,
    29: 6,
    30: 4,
    31: 9,
    32: 3,
    33: 10,
    34: 4,
    35: 5,
    36: 3,
    37: 5,
    38: 10,
    39: 6,
    40: 7,
    41: 7,
    42: 7,
    43: 8,
    44: 7,
    45: 5,
    46: 5,
    47: 9,
    48: 3,
    49: 7,
    50: 5,
    51: 8,
  };

  const topLevelArrR = functionSco.topLevelR;
  const TopLevelR = findMax(topLevelArrR, topLevelScoreR);

  const topLevelScoreL = {
    0: 7,
    1: 4,
    2: 10,
    3: 7,
    4: 7,
    5: 10,
    6: 4,
    7: 8,
    8: 7,
    9: 3,
    10: 6,
    11: 5,
    12: 3,
    13: 6,
    14: 6,
    15: 8,
    16: 4,
    17: 10,
    18: 10,
    19: 9,
    20: 8,
    21: 9,
    22: 9,
    23: 7,
    24: 3,
    25: 6,
    26: 4,
    27: 6,
    28: 9,
    29: 6,
    30: 4,
    31: 9,
    32: 3,
    33: 10,
    34: 4,
    35: 5,
    36: 3,
    37: 5,
    38: 10,
    39: 6,
    40: 7,
    41: 7,
    42: 7,
    43: 8,
    44: 7,
    45: 5,
    46: 5,
    47: 9,
    48: 3,
    49: 7,
    50: 5,
    51: 8,
  };

  const topLevelArrL = functionSco.topLevelL;
  const TopLevelL = findMax(topLevelArrL, topLevelScoreL);

  const lowLevelScoreR = {
    0: 6,
    1: 4,
    2: 9,
    3: 6,
    4: 6,
    5: 9,
    6: 4,
    7: 7,
    8: 6,
    9: 3,
    10: 5,
    11: 5,
    12: 3,
    13: 5,
    14: 5,
    15: 7,
    16: 4,
    17: 9,
    18: 9,
    19: 8,
    20: 7,
    21: 8,
    22: 8,
    23: 6,
    24: 5,
    25: 4,
    26: 5,
    27: 4,
    28: 8,
    29: 5,
    30: 4,
    31: 8,
    32: 3,
    33: 9,
    34: 4,
    35: 5,
    36: 3,
    37: 5,
    38: 9,
    39: 5,
    40: 6,
    41: 6,
    42: 6,
    43: 7,
    44: 6,
    45: 5,
    46: 5,
    47: 8,
    48: 3,
    49: 6,
    50: 5,
    51: 7,
  };

  const lowLevelArrR = functionSco.lowLevelR;
  const LowLevelR = findMax(lowLevelArrR, lowLevelScoreR);

  const lowLevelScoreL = {
    0: 6,
    1: 4,
    2: 9,
    3: 6,
    4: 6,
    5: 9,
    6: 4,
    7: 7,
    8: 6,
    9: 3,
    10: 5,
    11: 5,
    12: 3,
    13: 5,
    14: 5,
    15: 7,
    16: 4,
    17: 9,
    18: 9,
    19: 8,
    20: 7,
    21: 8,
    22: 8,
    23: 6,
    24: 5,
    25: 4,
    26: 5,
    27: 4,
    28: 8,
    29: 5,
    30: 4,
    31: 8,
    32: 3,
    33: 9,
    34: 4,
    35: 5,
    36: 3,
    37: 5,
    38: 9,
    39: 5,
    40: 6,
    41: 6,
    42: 6,
    43: 7,
    44: 6,
    45: 5,
    46: 5,
    47: 8,
    48: 3,
    49: 6,
    50: 5,
    51: 7,
  };

  const lowLevelArrL = functionSco.lowLevelL;
  const LowLevelL = findMax(lowLevelArrL, lowLevelScoreL);

  const recreatScoreR = {
    0: 5,
    1: 4,
    2: 8,
    3: 5,
    4: 5,
    5: 8,
    6: 4,
    7: 6,
    8: 5,
    9: 3,
    10: 4,
    11: 4,
    12: 3,
    13: 4,
    14: 4,
    15: 6,
    16: 4,
    17: 8,
    18: 8,
    19: 7,
    20: 6,
    21: 7,
    22: 7,
    23: 5,
    24: 3,
    25: 4,
    26: 4,
    27: 4,
    28: 7,
    29: 4,
    30: 4,
    31: 7,
    32: 3,
    33: 8,
    34: 4,
    35: 4,
    36: 3,
    37: 4,
    38: 8,
    39: 4,
    40: 5,
    41: 5,
    42: 5,
    43: 6,
    44: 5,
    45: 4,
    46: 4,
    47: 7,
    48: 3,
    49: 5,
    50: 4,
    51: 6,
  };

  const recreatArrR = functionSco.recreatR;
  const RecreatR = findMax(recreatArrR, recreatScoreR);

  const recreatScoreL = {
    0: 5,
    1: 4,
    2: 8,
    3: 5,
    4: 5,
    5: 8,
    6: 4,
    7: 6,
    8: 5,
    9: 3,
    10: 4,
    11: 4,
    12: 3,
    13: 4,
    14: 4,
    15: 6,
    16: 4,
    17: 8,
    18: 8,
    19: 7,
    20: 6,
    21: 7,
    22: 7,
    23: 5,
    24: 3,
    25: 4,
    26: 4,
    27: 4,
    28: 7,
    29: 4,
    30: 4,
    31: 7,
    32: 3,
    33: 8,
    34: 4,
    35: 4,
    36: 3,
    37: 4,
    38: 8,
    39: 4,
    40: 5,
    41: 5,
    42: 5,
    43: 6,
    44: 5,
    45: 4,
    46: 4,
    47: 7,
    48: 3,
    49: 5,
    50: 4,
    51: 6,
  };

  const recreatArrL = functionSco.recreatL;
  const RecreatL = findMax(recreatArrL, recreatScoreL);

  const IdoSpotScore = {
    1: 5,
    2: 4,
    3: 6,
    4: 3,
    5: 2,
    6: 1,
    7: 0,
  };

  const IdoSpotR = IdoSpotScore[functionSco.idoSpotR] || 0;

  const IdoSpotL = IdoSpotScore[functionSco.idoSpotL] || 0;

  const NoSpotR =
    functionSco.noSpotR === "0"
      ? 3
      : functionSco.noSpotR === "1"
      ? 1
      : functionSco.noSpotR === "2"
      ? 0
      : 0;

  const NoSpotL =
    functionSco.noSpotL === "0"
      ? 3
      : functionSco.noSpotL === "1"
      ? 1
      : functionSco.noSpotL === "2"
      ? 0
      : 0;

  //DECISION TREE..............(end)//

  const faamadlRsum = sumAll(
    faamDesStairR,
    faamAscStairR,
    faamStandingR,
    faamWalkingFlatR,
    faamWalkingWithoutShoesR,
    faamWalkingHillsR,
    faamWalkingDownHillsR,
    faamWalkingUnevenR,
    faamSteppingUpR,
    faamComingUpR,
    faamWalkFiveR,
    faamWalkIntiallyR,
    faamWalkTenR,
    faamWalkFifteenR,
    faamHomeR,
    faamActivitiesR,
    faamPersonalCareR,
    faamheavyWorkR,
    faammoderateWorkR,
    faamRecreationalR,
    faamsquattingR
  );

  const faamadlLsum = sumAll(
    faamDesStairL,
    faamAscStairL,
    faamStandingL,
    faamWalkingFlatL,
    faamWalkingWithoutShoesL,
    faamWalkingHillsL,
    faamWalkingDownHillsL,
    faamWalkingUnevenL,
    faamSteppingUpL,
    faamComingUpL,
    faamWalkFiveL,
    faamWalkIntiallyL,
    faamWalkTenL,
    faamWalkFifteenL,
    faamHomeL,
    faamActivitiesL,
    faamPersonalCareL,
    faamheavyWorkL,
    faammoderateWorkL,
    faamRecreationalL,
    faamsquattingL
  );

  const FAAMADRScrore = Math.round((faamadlRsum / (21 * 4)) * 100);
  const FAAMADLScrore = Math.round((faamadlLsum / (21 * 4)) * 100);

  const faamsportRsum = sumAll(
    faamRunningR,
    faamJumpingR,
    faamstoppingR,
    faamcuttingR,
    faamLandingR,
    faamabilityPerformR,
    faamabilityParticipateR
  );

  let runSelectL = functionSco.runSelectL ? 0 : 1;
  let jumpSelectL = functionSco.jumpSelectL ? 0 : 1;
  let landingSelectL = functionSco.landingSelectL ? 0 : 1;
  let startingSelectL = functionSco.startingSelectL ? 0 : 1;
  let cuttingSelectL = functionSco.cuttingSelectL ? 0 : 1;
  // let impactSelectL = functionSco.impactSelectL ? 0 : 1
  let performSelectL = functionSco.performSelectL ? 0 : 1;
  let participateSelectL = functionSco.participateSelectL ? 0 : 1;

  let value =
    (runSelectL +
      jumpSelectL +
      landingSelectL +
      startingSelectL +
      cuttingSelectL +
      // impactSelectL +
      performSelectL +
      participateSelectL) *
    4;

  // console.log(faamRunningR, faamJumpingR, faamstoppingR, faamcuttingR, faamLandingR, faamabilityPerformR,
  //   faamabilityParticipateR, "==========sportR", faamsportRsum,value);

  const faamsportLsum = sumAll(
    faamRunningL,
    faamJumpingL,
    faamstoppingL,
    faamcuttingL,
    faamLandingL,
    faamabilityPerformL,
    faamabilityParticipateL
  );

  // console.log(faamRunningL, faamJumpingL, faamstoppingL, faamcuttingL, faamLandingL, faamabilityPerformL,
  //   faamabilityParticipateL, "========sportL", faamsportLsum);

  const FAAMSRScrore = Math.round((faamsportRsum / value) * 100);
  const FAAMSLScrore = Math.round((faamsportLsum / value) * 100);

  // console.log(FAAMSRScrore, "sportR",faamsportRsum,value);
  // console.log(FAAMSLScrore, "sportL");

  const FADITLS = sumAll(
    AtRestL,
    NormalL,
    FirstThingL,
    fadiDesStairL,
    fadiAscStairL,
    fadiStandingL,
    fadiWalkingFlatL,
    fadiWalkingWithoutShoesL,
    fadiWalkingHillsL,
    fadiWalkingDownHillsL,
    fadiWalkingUnevenL,
    fadiSteppingUpL,
    fadiComingUpL,
    fadiWalkIntiallyL,
    fadiWalkFiveL,
    fadiWalkTenL,
    fadiWalkFifteenL,
    fadiSleepingL,
    fadiHomeL,
    fadiActivitiesL,
    fadiPersonalCareL,
    fadiheavyWorkL,
    fadiModerateWorkL,
    fadiRecreationalL,
    fadiSquattingL,
    genPainL
  );

  const FADIRTRS = sumAll(
    AtRestR,
    NormalR,
    FirstThingR,
    fadiDesStairR,
    fadiAscStairR,
    fadiStandingR,
    fadiWalkingFlatR,

    fadiWalkingWithoutShoesR,
    fadiWalkingHillsR,
    fadiWalkingDownHillsR,
    fadiWalkingUnevenR,
    fadiSteppingUpR,
    fadiComingUpR,
    fadiWalkIntiallyR,
    fadiWalkFiveR,
    fadiWalkTenR,
    fadiWalkFifteenR,
    fadiSleepingR,
    fadiHomeR,
    fadiActivitiesR,
    fadiPersonalCareR,
    fadiheavyWorkR,
    fadiModerateWorkR,
    fadiRecreationalR,
    fadiSquattingR,
    genPainR
  );

  const TotalFADILS = Math.round((FADITLS / 104) * 100);
  const TotalFADIRS = Math.round((FADIRTRS / 104) * 100);

  const FADISPORTR = sumAll(
    fadiJumpingR,
    fadiRunningR,
    fadilowActR,
    fadiLandingR,
    fadistoppingR,
    fadicuttingR,
    fadiabilityParticipateR,
    fadiabilityPerformR
  );

  const FADISPORTL = sumAll(
    fadiJumpingL,
    fadiRunningL,
    fadilowActL,
    fadiLandingL,
    fadistoppingL,
    fadicuttingL,
    fadiabilityParticipateL,
    fadiabilityPerformL
  );

  const FADISPORTRS = Math.round((FADISPORTR / 32) * 100);
  const FADISPORTLS = Math.round((FADISPORTL / 32) * 100);

  const FAOSPAINR = sumAll(
    FaosPainRatingR,
    faosTwistingR,
    StraighteningR,
    BendingR,
    WalkingR,
    GoingR,
    NightR,
    SittingR,
    StandingR
  );
  const FAOSPAINL = sumAll(
    FaosPainRatingL,
    faosTwistingL,
    StraighteningL,
    BendingL,
    WalkingL,
    GoingL,
    NightL,
    SittingL,
    StandingL
  );
  const FAOSTPAINR = Math.round((FAOSPAINR / 36) * 100);
  const FAOSTPAINL = Math.round((FAOSPAINL / 36) * 100);

  //console.log(FaosPainRatingR, faosTwistingR, StraighteningR, BendingR, WalkingR, GoingR, NightR, SittingR, StandingR, "right====", FAOSPAINR);
  //console.log(FaosPainRatingL, faosTwistingL, StraighteningL, BendingL, WalkingL, GoingL, NightL, SittingL, StandingL, "left =====", FAOSPAINL);
  // console.log(FAOSTPAINR, "Total====R")
  // console.log(FAOSTPAINL, "Total====L")

  const FAOSSYMPTOMSR = sumAll(
    FaosSwellingR,
    FaosCatchingR,
    FaosGrindingR,
    FaosStiffnessR,
    FaosStiffnessSittingR,
    faosFootBendingR,
    faosStraighteningR
  );

  const FAOSSYMPTOMSL = sumAll(
    FaosSwellingL,
    FaosCatchingL,
    FaosGrindingL,
    FaosStiffnessL,
    FaosStiffnessSittingL,
    faosFootBendingL,
    faosStraighteningL
  );

  const FAOSTSYMPTOMSR = Math.round((FAOSSYMPTOMSR / 28) * 100);
  const FAOSTSYMPTOMSL = Math.round((FAOSSYMPTOMSL / 28) * 100);

  const FAOSADLR = sumAll(
    faosDesStairR,
    faosAscStairR,
    faosRisingR,
    faosStandingR,
    faosBendingFloorR,
    faosWalkingFlatR,
    faosGettingCarR,
    faosGoingShoppingR,
    faosSlyingR,
    faosPuttingSocksR,
    faosSrisingBedR,
    faosTakingOffR,
    faosGetBathR,
    faosSittingR,
    faosGetToiletR,
    faosLightWorkR,
    faosheavyWorkR
  );

  const FAOSADLL = sumAll(
    faosDesStairL,
    faosAscStairL,
    faosRisingL,
    faosStandingL,
    faosBendingFloorL,
    faosWalkingFlatL,
    faosGettingCarL,
    faosGoingShoppingL,
    faosSlyingL,
    faosPuttingSocksL,
    faosSrisingBedL,
    faosTakingOffL,
    faosGetBathL,
    faosSittingL,
    faosGetToiletL,
    faosLightWorkL,
    faosheavyWorkL
  );

  const FAOSTADLR = Math.round((FAOSADLR / 68) * 100);
  const FAOSTADLL = Math.round((FAOSADLL / 68) * 100);

  const FAOSSPORTR = sumAll(
    faosSquattingR,
    faosJumpingR,
    faosKneelingR,
    faosRunningR,
    faostwistingR
  );
  const FAOSSPORTL = sumAll(
    faosSquattingL,
    faosJumpingL,
    faosKneelingL,
    faosRunningL,
    faostwistingL
  );

  const FAOSTSPORTR = Math.round((FAOSSPORTR / 20) * 100);
  const FAOSTSPORTL = Math.round((FAOSSPORTL / 20) * 100);

  const FAOSQOLR = sumAll(
    FaosdifficultyR,
    FaoslifestyleR,
    FaosoftenAnkleR,
    FaostroubledR
  );
  const FAOSQOLL = sumAll(
    FaosdifficultyL,
    FaoslifestyleL,
    FaosoftenAnkleL,
    FaostroubledL
  );

  // console.log(FaosdifficultyR, FaoslifestyleR, FaosoftenAnkleR, FaostroubledR, "======righqol", FAOSQOLR);
  // console.log(FaosdifficultyL, FaoslifestyleL, FaosoftenAnkleL, FaostroubledL, "=======leftqol", FAOSQOLL);

  const FAOSTQOLR = Math.round((FAOSQOLR / 16) * 100);
  const FAOSTQOLL = Math.round((FAOSQOLL / 16) * 100);

  // console.log(FAOSTQOLR, "=========R");
  // console.log(FAOSTQOLL, "=========L");

  // AOFAS Hallux   ==================================================

  const AohalluxdescripR =
    painSco.descripR === "0"
      ? 40
      : painSco.descripR === "1"
      ? 30
      : painSco.descripR === "2"
      ? 20
      : painSco.descripR === "3"
      ? 0
      : 0;
  const AohalluxdescripL =
    painSco.descripL === "0"
      ? 40
      : painSco.descripL === "1"
      ? 30
      : painSco.descripL === "2"
      ? 20
      : painSco.descripL === "3"
      ? 0
      : 0;
  const AohalluxfootActivityR =
    functionSco.footActivityR === "3"
      ? 0
      : functionSco.footActivityR === "2"
      ? 4
      : functionSco.footActivityR === "1"
      ? 7
      : functionSco.footActivityR === "0"
      ? 10
      : 0;

  const AohalluxfootActivityL =
    functionSco.footActivityL === "3"
      ? 0
      : functionSco.footActivityL === "2"
      ? 4
      : functionSco.footActivityL === "1"
      ? 7
      : functionSco.footActivityL === "0"
      ? 10
      : 0;
  const AohalluxfootwearR =
    functionSco.footwearR === "2"
      ? 0
      : functionSco.footwearR === "1"
      ? 5
      : functionSco.footwearR === "0"
      ? 10
      : 0;

  const AohalluxfootwearL =
    functionSco.footwearL === "2"
      ? 0
      : functionSco.footwearL === "1"
      ? 5
      : functionSco.footwearL === "0"
      ? 10
      : 0;

  const HalluxScaleR = sumAll(
    AohalluxdescripR,
    AohalluxfootActivityR,
    AohalluxfootwearR
  );

  const HalluxScaleL = sumAll(
    AohalluxdescripL,
    AohalluxfootActivityL,
    AohalluxfootwearL
  );

  ///  AOFAS LesserToe

  // AOFAS Ankle-Hindfoot

  const AofasHindfoot =
    functionSco.footActivityR === "3"
      ? 0
      : functionSco.footActivityR === "2"
      ? 4
      : functionSco.footActivityR === "1"
      ? 7
      : functionSco.footActivityR === "0"
      ? 10
      : 0;
  const AofasHindfootL =
    functionSco.footActivityL === "3"
      ? 0
      : functionSco.footActivityL === "2"
      ? 4
      : functionSco.footActivityL === "1"
      ? 7
      : functionSco.footActivityL === "0"
      ? 10
      : 0;

  const AofasHindfootb =
    painSco.descripR === "3"
      ? 0
      : painSco.descripR === "2"
      ? 20
      : painSco.descripR === "1"
      ? 30
      : painSco.descripR === "0"
      ? 40
      : 0;

  const AofasHindfootbL =
    painSco.descripL === "3"
      ? 0
      : painSco.descripL === "2"
      ? 20
      : painSco.descripL === "1"
      ? 30
      : painSco.descripL === "0"
      ? 40
      : 0;

  const AofasHindwalkingR =
    functionSco.walkingR === "2"
      ? 0
      : functionSco.walkingR === "1"
      ? 3
      : functionSco.walkingR === "0"
      ? 5
      : 0;
  const AofasHindwalkingL =
    functionSco.walkingL === "2"
      ? 0
      : functionSco.walkingL === "1"
      ? 3
      : functionSco.walkingL === "0"
      ? 5
      : 0;

  const AofasHindmaxwalkingR =
    functionSco.maxWalkingR === "0"
      ? 5
      : functionSco.maxWalkingR === "1"
      ? 4
      : functionSco.maxWalkingR === "2"
      ? 2
      : functionSco.maxWalkingR === "3"
      ? 0
      : 0;

  const AofasHindmaxwalkingL =
    functionSco.maxWalkingL === "0"
      ? 5
      : functionSco.maxWalkingL === "1"
      ? 4
      : functionSco.maxWalkingL === "2"
      ? 2
      : functionSco.maxWalkingL === "3"
      ? 0
      : 0;
  const TotalEvaluatedR =
    docFeedback.RightGAIT === "1"
      ? 8
      : docFeedback.RightGAIT === "2"
      ? 8
      : docFeedback.RightGAIT === "3"
      ? 4
      : docFeedback.RightGAIT === "4"
      ? 0
      : 0;

  const TotalEvaluatedL =
    docFeedback.LeftGAIT === "1"
      ? 8
      : docFeedback.LeftGAIT === "2"
      ? 8
      : docFeedback.LeftGAIT === "3"
      ? 4
      : docFeedback.LeftGAIT === "4"
      ? 0
      : 0;

  const TotalAnkleR =
    docFeedback.AnkleR === "1"
      ? 10
      : docFeedback.AnkleR === "2"
      ? 5
      : docFeedback.AnkleR === "3"
      ? 0
      : 0;

  const TotalAnkleL =
    docFeedback.AnkleL === "1"
      ? 10
      : docFeedback.AnkleL === "2"
      ? 5
      : docFeedback.AnkleL === "3"
      ? 0
      : 0;

  const TotalMotionRtext =
    docFeedback.MotionRtext < 15
      ? 0
      : docFeedback.MotionRtext >= 15 && docFeedback.MotionRtext <= 29
      ? 4
      : docFeedback.MotionRtext >= 30
      ? 8
      : 0;

  const TotalMotionLtext =
    docFeedback.MotionLRtext < 15
      ? 0
      : docFeedback.MotionLRtext >= 15 && docFeedback.MotionLRtext <= 29
      ? 4
      : docFeedback.MotionLRtext >= 30
      ? 8
      : 0;

  const TotalMotion3Rtext =
    docFeedback.Motion3Rtext < 12.5
      ? 0
      : docFeedback.Motion3Rtext >= 12.5 && docFeedback.Motion3Rtext <= 37.4
      ? 3
      : docFeedback.Motion3Rtext >= 37.5
      ? 6
      : 0;
  const TotalMotion4Rtext =
    docFeedback.Motion3RRtext < 12.5
      ? 0
      : docFeedback.Motion3RRtext >= 12.5 && docFeedback.Motion3RRtext <= 37.4
      ? 3
      : docFeedback.Motion3RRtext >= 37.5
      ? 6
      : 0;

  const TotalStabilityR =
    docFeedback.StabilityR === "0" ? 8 : docFeedback.StabilityR === "1" ? 0 : 0;
  const TotalStabilityL =
    docFeedback.StabilityL === "0" ? 8 : docFeedback.StabilityL === "1" ? 0 : 0;

  const HindfootScaleR = sumAll(
    AofasHindfoot,
    AofasHindfootb,
    AofasHindwalkingR,
    AofasHindmaxwalkingR,
    TotalEvaluatedR,
    TotalAnkleR,
    TotalMotionRtext,
    TotalMotion3Rtext,
    TotalStabilityR
  );

  const HindfootScaleL = sumAll(
    TotalEvaluatedL,
    TotalAnkleL,
    TotalMotionLtext,
    TotalMotion4Rtext,
    TotalStabilityL,
    AofasHindfootL,
    AofasHindfootbL,
    AofasHindwalkingL,
    AofasHindmaxwalkingL
  );

  // AOFAS Midfoot  =======================================================

  const AofasMidfootdescripR =
    painSco.descripR === "0"
      ? 40
      : painSco.descripR === "1"
      ? 30
      : painSco.descripR === "2"
      ? 20
      : painSco.descripR === "3"
      ? 0
      : 0;
  const AofasMidfootdescripL =
    painSco.descripL === "0"
      ? 40
      : painSco.descripL === "1"
      ? 30
      : painSco.descripL === "2"
      ? 20
      : painSco.descripL === "3"
      ? 0
      : 0;

  const AofasMidfootfootActivityR =
    functionSco.footActivityR === "3"
      ? 0
      : functionSco.footActivityR === "2"
      ? 4
      : functionSco.footActivityR === "1"
      ? 7
      : functionSco.footActivityR === "0"
      ? 10
      : 0;
  const AofasMidfootfootActivityL =
    functionSco.footActivityL === "3"
      ? 0
      : functionSco.footActivityL === "2"
      ? 4
      : functionSco.footActivityL === "1"
      ? 7
      : functionSco.footActivityL === "0"
      ? 10
      : 0;
  const AofasMidfootfootwearR =
    functionSco.footwearR === "0"
      ? 5
      : functionSco.footwearR === "1"
      ? 3
      : functionSco.footwearR === "2"
      ? 0
      : 0;
  const AofasMidfootfootwearL =
    functionSco.footwearL === "0"
      ? 5
      : functionSco.footwearL === "1"
      ? 3
      : functionSco.footwearL === "2"
      ? 0
      : 0;

  const AofasMidfootwalkingR =
    functionSco.walkingR === "2"
      ? 0
      : functionSco.walkingR === "1"
      ? 5
      : functionSco.walkingR === "0"
      ? 10
      : 0;
  const AofasMidfootwalkingL =
    functionSco.walkingL === "2"
      ? 0
      : functionSco.walkingL === "1"
      ? 5
      : functionSco.walkingL === "0"
      ? 10
      : 0;
  const AofasMidfootmaxwalkingR =
    functionSco.maxWalkingR === "3"
      ? 0
      : functionSco.maxWalkingR === "2"
      ? 4
      : functionSco.maxWalkingR === "1"
      ? 7
      : functionSco.maxWalkingR === "0"
      ? 10
      : 0;

  const AofasMidfootmaxwalkingL =
    functionSco.maxWalkingL === "3"
      ? 0
      : functionSco.maxWalkingL === "2"
      ? 4
      : functionSco.maxWalkingL === "1"
      ? 7
      : functionSco.maxWalkingL === "0"
      ? 10
      : 0;

  const AOFASSEvaluatedR =
    docFeedback.RightGAIT === "1"
      ? 10
      : docFeedback.RightGAIT === "2"
      ? 10
      : docFeedback.RightGAIT === "3"
      ? 5
      : docFeedback.RightGAIT === "4"
      ? 0
      : 0;
  const AOFASSEvaluatedL =
    docFeedback.LeftGAIT === "1"
      ? 10
      : docFeedback.LeftGAIT === "2"
      ? 10
      : docFeedback.LeftGAIT === "3"
      ? 5
      : docFeedback.LeftGAIT === "4"
      ? 0
      : 0;

  const midMidfootR =
    docFeedback.MidfootR === "1"
      ? 15
      : docFeedback.MidfootR === "2"
      ? 8
      : docFeedback.MidfootR === "0"
      ? 0
      : 0;
  const midMidfootL =
    docFeedback.MidfootL === "1"
      ? 15
      : docFeedback.MidfootL === "2"
      ? 8
      : docFeedback.MidfootL === "0"
      ? 0
      : 0;

  const MidfootScaleR = sumAll(
    AofasMidfootdescripR,
    AofasMidfootfootActivityR,
    AofasMidfootfootwearR,
    AofasMidfootwalkingR,
    AofasMidfootmaxwalkingR,
    // AOFASEvaluatedR,
    AOFASSEvaluatedR,
    midMidfootR
  );

  const MidfootScaleL = sumAll(
    AofasMidfootdescripL,
    AofasMidfootfootActivityL,
    AofasMidfootfootwearL,
    AofasMidfootwalkingL,
    AofasMidfootmaxwalkingL,
    // AOFASEvaluatedL,
    AOFASSEvaluatedL,
    midMidfootL
  );

  const AOFASAlignmentR =
    docFeedback.AlignmentR === "1"
      ? 15
      : docFeedback.AlignmentR === "2"
      ? 8
      : docFeedback.AlignmentR === "3"
      ? 0
      : 0;
  const AOFASAlignmentL =
    docFeedback.AlignmentL === "1"
      ? 15
      : docFeedback.AlignmentL === "2"
      ? 8
      : docFeedback.AlignmentL === "3"
      ? 0
      : 0;

  let AOFASAlignmentradioR;
  if (docFeedback.AlignmentradioL === "0") {
    AOFASAlignmentradioR = 5;
  } else {
    if (docFeedback.Alignmentdrop === "1") {
      AOFASAlignmentradioR = 5;
    } else {
      AOFASAlignmentradioR = 0;
    }
  }

  let AOFASAlignmentradioL;
  if (docFeedback.Alignmen1L === "0") {
    AOFASAlignmentradioL = 5;
  } else {
    if (docFeedback.Alignmen1drop === "1") {
      AOFASAlignmentradioL = 5;
    } else {
      AOFASAlignmentradioL = 0;
    }
  }

  const TotalAlignmeninputR =
    docFeedback.Motion2Rtext < 30
      ? 0
      : docFeedback.Motion2Rtext >= 30 && docFeedback.Motion2Rtext <= 74
      ? 5
      : docFeedback.Motion2Rtext >= 75
      ? 10
      : 0;

  const TotalAlignmeninputL =
    docFeedback.Motion2LRtext < 30
      ? 0
      : docFeedback.Motion2LRtext >= 30 && docFeedback.Motion2LRtext <= 74
      ? 5
      : docFeedback.Motion2LRtext >= 75
      ? 10
      : 0;

  const TotalAlignmeninputIPJOINTR = docFeedback.Motion23Rtext >= 10 ? 5 : 0;

  const TotalAlignmeninputIPJOINTL = docFeedback.Motion23RRtext >= 10 ? 5 : 0;

  const AOFASAlignmen2R = docFeedback.Alignmen2R === "0" ? 5 : 0;
  const AOFASAlignmen2L = docFeedback.Alignmen2L === "0" ? 5 : 0;

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ///  AOFAS LesserToe

  const AolesserdescripR =
    painSco.descripR === "0"
      ? 40
      : painSco.descripR === "1"
      ? 30
      : painSco.descripR === "2"
      ? 20
      : painSco.descripR === "3"
      ? 0
      : 0;
  const AolesserdescripL =
    painSco.descripL === "0"
      ? 40
      : painSco.descripL === "1"
      ? 30
      : painSco.descripL === "2"
      ? 20
      : painSco.descripL === "3"
      ? 0
      : 0;

  const AolesserfootActivityR =
    functionSco.footActivityR === "3"
      ? 0
      : functionSco.footActivityR === "2"
      ? 4
      : functionSco.footActivityR === "1"
      ? 7
      : functionSco.footActivityR === "0"
      ? 10
      : 0;
  const AolesserfootActivityL =
    functionSco.footActivityL === "3"
      ? 0
      : functionSco.footActivityL === "2"
      ? 4
      : functionSco.footActivityL === "1"
      ? 7
      : functionSco.footActivityL === "0"
      ? 10
      : 0;

  const AolesserfootwearR =
    functionSco.footwearR === "0"
      ? 10
      : functionSco.footwearR === "1"
      ? 5
      : functionSco.footwearR === "2"
      ? 0
      : 0;
  const AolesserfootwearL =
    functionSco.footwearL === "0"
      ? 10
      : functionSco.footwearL === "1"
      ? 5
      : functionSco.footwearL === "2"
      ? 0
      : 0;

  const Alignmentvalue = (data) => {
    const TotalAlignmen2R =
      data === "1" ? 15 : data === "2" ? 8 : data === "3" ? 0 : 0;
    return TotalAlignmen2R;
  };

  const Callusvalue = (data) => {
    const TotalAlignmen2R = data === "0" ? 5 : 0;
    return TotalAlignmen2R;
  };

  const CallusPresentvalue = (data) => {
    const TotalAlignmen2R =
      data === "Asymptomatic" ? 5 : data === "Symptomatic" ? 0 : 0;
    return TotalAlignmen2R;
  };

  const Motionvalue = (data) => {
    let TotalMotion2ndRtext =
      data >= 30 && data <= 74 ? 5 : data >= 75 ? 10 : 0;
    return TotalMotion2ndRtext;
  };

  const IpJoinvalue = (data) => {
    let TotalMotion2nRtext = data >= 10 ? 5 : data < 10 ? 0 : 0;
    return TotalMotion2nRtext;
  };

  const Stabilityvalue = (data) => {
    let TotalSpecifyRradio2 = data === "0" ? 5 : data === "1" ? 0 : 0;
    return TotalSpecifyRradio2;
  };

  //2nd toe
  let TotalAlignmen2R = Alignmentvalue(docFeedback.LesserR2);
  let TotalAlignmen2L = Alignmentvalue(docFeedback.LesserL2);
  let TotalLesserradioR2 = Callusvalue(docFeedback.LesserradioR2);
  let TotalLesserradioL2 = Callusvalue(docFeedback.LesserradioL2);
  let TotalLesserrRdrop2 = CallusPresentvalue(docFeedback.LesserrRdrop2);
  let TotalLesserrLdrop2 = CallusPresentvalue(docFeedback.LesserrLdrop2);

  let TotalMotion2ndRtext = Motionvalue(docFeedback.Motion2ndRtext);

  // let TotalMotion2ndLtext = Motionvalue(docFeedback.Motion2ndLtext)

  let TotalMotion2ndLRtext = Motionvalue(docFeedback.Motion2ndLRtext);

  let TotalMotion2ndLLtext = Motionvalue(docFeedback.Motion2ndLLtext);
  let TotalMotion2nRtext = IpJoinvalue(docFeedback.Motion2nRtext);
  // let TotalMotion2nlRtext = IpJoinvalue(docFeedback.Motion2nlRtext)
  let TotalMotion2ndRRtext = IpJoinvalue(docFeedback.Motion2ndRRtext);
  let TotalMotion2ndRLtext = IpJoinvalue(docFeedback.Motion2ndRLtext);
  let TotalSpecifyRradio2 = Stabilityvalue(docFeedback.SpecifyRradio2);
  let TotalSpecifyLradio2 = Stabilityvalue(docFeedback.SpecifyLradio2);

  const LesserToeScaleR2ndToe = sumAll(
    AolesserdescripR,
    AolesserfootActivityR,
    AolesserfootwearR,

    TotalAlignmen2R,
    TotalLesserradioR2,
    TotalLesserrRdrop2,
    TotalMotion2ndRtext,
    TotalMotion2nRtext,
    TotalSpecifyRradio2
  );

  const LesserToeScaleL2ndToe = sumAll(
    AolesserdescripL,
    AolesserfootActivityL,
    AolesserfootwearL,

    TotalAlignmen2L,
    TotalLesserradioL2,
    TotalLesserrLdrop2,
    TotalMotion2ndLRtext,
    TotalMotion2ndRRtext,
    TotalSpecifyLradio2
  );

  //3rd toe
  let TotalAlignmen3R = Alignmentvalue(docFeedback.LesserR23rd);
  let TotalAlignmen3L = Alignmentvalue(docFeedback.LesserL23rd);
  let TotalLesserradioR3 = Callusvalue(docFeedback.LesserradioR23rd);
  let TotalLesserradioL3 = Callusvalue(docFeedback.LesserradioL23rd);
  let TotalLesserrRdrop23rd = CallusPresentvalue(docFeedback.LesserrRdrop23rd);
  let TotalLesserrLdrop23rd = CallusPresentvalue(docFeedback.LesserrLdrop23rd);

  let TotalMotion3ndRtext = Motionvalue(docFeedback.Motion3ndRtext);
  let TotalMotion3ndLtext = Motionvalue(docFeedback.Motion3ndLtext);
  let TotalMotion3ndLRtext = Motionvalue(docFeedback.Motion3ndLRtext);
  let TotalMotion3ndLLtext = Motionvalue(docFeedback.Motion3ndLLtext);

  let TotalMotion3nRtext = IpJoinvalue(docFeedback.Motion3nRtext);
  let TotalMotion3nlRtext = IpJoinvalue(docFeedback.Motion3nlRtext);
  let TotalMotion3ndRRtext = IpJoinvalue(docFeedback.Motion3ndRRtext);
  let TotalMotion3ndRLtext = IpJoinvalue(docFeedback.Motion3ndRLtext);

  let TotalSpecifyRradio23rd = Stabilityvalue(docFeedback.SpecifyRradio23rd);

  let TotalSpecifyLradio23rd = Stabilityvalue(docFeedback.SpecifyLradio23rd);

  const LesserToeScaleR3rdToe = sumAll(
    AolesserdescripR,
    AolesserfootActivityR,
    AolesserfootwearR,

    TotalAlignmen3R,
    TotalLesserradioR3,
    TotalLesserrRdrop23rd,
    TotalMotion3ndRtext,
    TotalMotion3nRtext,
    TotalSpecifyRradio23rd
  );

  const LesserToeScaleL3rdToe = sumAll(
    AolesserdescripL,
    AolesserfootActivityL,
    AolesserfootwearL,

    TotalAlignmen3L,
    TotalLesserradioL3,
    TotalLesserrLdrop23rd,
    TotalMotion3ndLRtext,
    TotalMotion3ndRRtext,
    TotalSpecifyLradio23rd
  );

  //4th toe
  let TotalLesserR24th = Alignmentvalue(docFeedback.LesserR24th);
  let TotalLesserL24th = Alignmentvalue(docFeedback.LesserL24th);

  let TotalLesserradioR24th = Callusvalue(docFeedback.LesserradioR24th);
  let TotalLesserradioL24th = Callusvalue(docFeedback.LesserradioL24th);
  let TotalLesserrRdrop24th = CallusPresentvalue(docFeedback.LesserrRdrop24th);
  let TotalLesserrLdrop24th = CallusPresentvalue(docFeedback.LesserrLdrop24th);

  let TotalMotion4ndRtext = Motionvalue(docFeedback.Motion4ndRtext);
  let TotalMotion4ndLtext = Motionvalue(docFeedback.Motion4ndLtext);
  let TotalMotion4ndLRtext = Motionvalue(docFeedback.Motion4ndLRtext);
  let TotalMotion4ndLLtext = Motionvalue(docFeedback.Motion4ndLLtext);

  let TotalMotion4nRtext = IpJoinvalue(docFeedback.Motion4nRtext);
  let TotalMotion4nlRtext = IpJoinvalue(docFeedback.Motion4nlRtext);
  let TotalMotion4ndRRtext = IpJoinvalue(docFeedback.Motion4ndRRtext);
  let TotalMotion4ndRLtext = IpJoinvalue(docFeedback.Motion4ndRLtext);

  let TotalSpecifyRradio24th = Stabilityvalue(docFeedback.SpecifyRradio24th);

  let TotalSpecifyLradio24th = Stabilityvalue(docFeedback.SpecifyLradio24th);

  const LesserToeScaleR4thToe = sumAll(
    AolesserdescripR,
    AolesserfootActivityR,
    AolesserfootwearR,

    TotalLesserR24th,
    TotalLesserradioR24th,
    TotalLesserrRdrop24th,
    TotalMotion4ndRtext,
    TotalMotion4nRtext,
    TotalSpecifyRradio24th
  );

  const LesserToeScaleL4thToe = sumAll(
    AolesserdescripL,
    AolesserfootActivityL,
    AolesserfootwearL,

    TotalLesserL24th,
    TotalLesserradioL24th,
    TotalLesserrLdrop24th,
    TotalMotion4ndLRtext,
    TotalMotion4ndRRtext,
    TotalSpecifyLradio24th
  );
  //5th toe
  let TotalLesserR25th = Alignmentvalue(docFeedback.LesserR25th);
  let TotalLesserL25th = Alignmentvalue(docFeedback.LesserL25th);
  let TotalLesserradioR25th = Callusvalue(docFeedback.LesserradioR25th);
  let TotalLesserradioL25th = Callusvalue(docFeedback.LesserradioL25th);
  let TotalLesserrRdrop25th = CallusPresentvalue(docFeedback.LesserrRdrop25th);
  let TotalLesserrLdrop25th = CallusPresentvalue(docFeedback.LesserrLdrop25th);

  let TotalMotion5ndRtext = Motionvalue(docFeedback.Motion5ndRtext);
  let TotalMotion5ndLtext = Motionvalue(docFeedback.Motion5ndLtext);
  let TotalMotion5ndLRtext = Motionvalue(docFeedback.Motion5ndLRtext);
  let TotalMotion5ndLLtext = Motionvalue(docFeedback.Motion5ndLLtext);

  let TotalMotion5nRtext = IpJoinvalue(docFeedback.Motion5nRtext);
  let TotalMotion5nlRtext = IpJoinvalue(docFeedback.Motion5nlRtext);
  let TotalMotion5ndRRtext = IpJoinvalue(docFeedback.Motion5ndRRtext);
  let TotalMotion5ndRLtext = IpJoinvalue(docFeedback.Motion5ndRLtext);
  let TotalSpecifyRradio25th = Stabilityvalue(docFeedback.SpecifyRradio25th);

  let TotalSpecifyLradio25th = Stabilityvalue(docFeedback.SpecifyLradio25th);

  const LesserToeScaleR5thToe = sumAll(
    AolesserdescripR,
    AolesserfootActivityR,
    AolesserfootwearR,

    TotalLesserR25th,
    TotalLesserradioR25th,
    TotalLesserrRdrop25th,
    TotalMotion5ndRtext,
    TotalMotion5nRtext,
    TotalSpecifyRradio25th
  );
  const LesserToeScaleL5thToe = sumAll(
    AolesserdescripL,
    AolesserfootActivityL,
    AolesserfootwearL,

    TotalLesserL25th,
    TotalLesserradioL25th,
    TotalLesserrLdrop25th,
    TotalMotion5ndLRtext,
    TotalMotion5ndRRtext,
    TotalSpecifyLradio25th
  );

  const arrdataL = [];
  const arrdataR = [];

  if (docFeedback.Toe2nd === true) {
    arrdataR.push(Number(LesserToeScaleR2ndToe));
    arrdataL.push(Number(LesserToeScaleL2ndToe));
  }
  if (docFeedback.Toe3rd === true) {
    arrdataR.push(Number(LesserToeScaleR3rdToe));
    arrdataL.push(Number(LesserToeScaleL3rdToe));
  }
  if (docFeedback.Toe4th === true) {
    arrdataR.push(Number(LesserToeScaleR4thToe));
    arrdataL.push(Number(LesserToeScaleL4thToe));
  }
  if (docFeedback.Toe5th === true) {
    arrdataR.push(Number(LesserToeScaleR5thToe));
    arrdataL.push(Number(LesserToeScaleL5thToe));
  }

  const LesserToeScaleR = Math.min(...arrdataR);
  const LesserToeScaleL = Math.min(...arrdataL);

  // const LesserToeScaleL = sumAll(
  //   AolesserdescripL,
  //   AolesserfootActivityL,
  //   AolesserfootwearL,
  //   AOFASLesserL,
  //   AOFASLesserradioL,
  //   TotalLesserrSpecifyL,
  //   // TotalLesserrDegreeL,
  //   TotalAlignmen2L
  // );

  // const LesserToeScaleR = sumAll(AolesserdescripR,
  //   AolesserfootActivityR,
  //   AolesserfootwearR);

  // const LesserToeScaleL = sumAll(AolesserdescripL,
  //   AolesserfootActivityL,
  //   AolesserfootwearL);

  const SATISFACTION = sumAll(
    SatisfactionForgetR,
    SatisfactionImproveR,
    SatisfactionSymptomR
  );
  const SATISFACTIONL = sumAll(
    SatisfactionForgetL,
    SatisfactionImproveL,
    SatisfactionSymptomL
  );
  const TotalSTAISFACTION = Math.round((SATISFACTION / 12) * 100);
  const TotalSATISFACTIONL = Math.round((SATISFACTIONL / 12) * 100);

  //Cumberland
  const descripTR =
    cumberland.descripR === "0"
      ? 5
      : cumberland.descripR === "1"
      ? 4
      : cumberland.descripR === "2"
      ? 3
      : cumberland.descripR === "3"
      ? 2
      : cumberland.descripR === "4"
      ? 1
      : cumberland.descripR === "5"
      ? 0
      : 0;

  const descripTL =
    cumberland.descripL === "0"
      ? 5
      : cumberland.descripL === "1"
      ? 4
      : cumberland.descripL === "2"
      ? 3
      : cumberland.descripL === "3"
      ? 2
      : cumberland.descripL === "4"
      ? 1
      : cumberland.descripL === "5"
      ? 0
      : 0;

  const unstableTR =
    cumberland.unstableR === "0"
      ? 4
      : cumberland.unstableR === "1"
      ? 3
      : cumberland.unstableR === "2"
      ? 2
      : cumberland.unstableR === "3"
      ? 1
      : cumberland.unstableR === "4"
      ? 0
      : 0;

  const unstableTL =
    cumberland.unstableL === "0"
      ? 4
      : cumberland.unstableL === "1"
      ? 3
      : cumberland.unstableL === "2"
      ? 2
      : cumberland.unstableL === "3"
      ? 1
      : cumberland.unstableL === "4"
      ? 0
      : 0;

  const sharpTR =
    cumberland.sharpR === "0"
      ? 3
      : cumberland.sharpR === "1"
      ? 2
      : cumberland.sharpR === "2"
      ? 1
      : cumberland.sharpR === "3"
      ? 0
      : 0;

  const sharpTL =
    cumberland.sharpL === "0"
      ? 3
      : cumberland.sharpL === "1"
      ? 2
      : cumberland.sharpL === "2"
      ? 1
      : cumberland.sharpL === "3"
      ? 0
      : 0;

  const stairsTR =
    cumberland.stairsR === "0"
      ? 3
      : cumberland.stairsR === "1"
      ? 2
      : cumberland.stairsR === "2"
      ? 1
      : cumberland.stairsR === "3"
      ? 0
      : 0;

  const stairsTL =
    cumberland.stairsL === "0"
      ? 3
      : cumberland.stairsL === "1"
      ? 2
      : cumberland.stairsL === "2"
      ? 1
      : cumberland.stairsL === "3"
      ? 0
      : 0;

  const standTR =
    cumberland.standR === "0"
      ? 2
      : cumberland.standR === "1"
      ? 1
      : cumberland.standR === "2"
      ? 0
      : 0;

  const standTL =
    cumberland.standL === "0"
      ? 2
      : cumberland.standL === "1"
      ? 1
      : cumberland.standL === "2"
      ? 0
      : 0;

  const feelsTR =
    cumberland.feelsR === "0"
      ? 3
      : cumberland.feelsR === "1"
      ? 2
      : cumberland.feelsR === "2"
      ? 1
      : cumberland.feelsR === "3"
      ? 0
      : 0;

  const feelsTL =
    cumberland.feelsL === "0"
      ? 3
      : cumberland.feelsL === "1"
      ? 2
      : cumberland.feelsL === "2"
      ? 1
      : cumberland.feelsL === "3"
      ? 0
      : 0;

  const feelsankTR =
    cumberland.feelsankR === "0"
      ? 4
      : cumberland.feelsankR === "1"
      ? 3
      : cumberland.feelsankR === "2"
      ? 2
      : cumberland.feelsankR === "3"
      ? 1
      : cumberland.feelsankR === "4"
      ? 0
      : 0;

  const feelsankTL =
    cumberland.feelsankL === "0"
      ? 4
      : cumberland.feelsankL === "1"
      ? 3
      : cumberland.feelsankL === "2"
      ? 2
      : cumberland.feelsankL === "3"
      ? 1
      : cumberland.feelsankL === "4"
      ? 0
      : 0;

  const twistTR =
    cumberland.twistR === "0"
      ? 3
      : cumberland.twistR === "1"
      ? 2
      : cumberland.twistR === "2"
      ? 1
      : cumberland.twistR === "3"
      ? 0
      : cumberland.twistR === "4"
      ? 3
      : 0;

  const twistTL =
    cumberland.twistL === "0"
      ? 3
      : cumberland.twistL === "1"
      ? 2
      : cumberland.twistL === "2"
      ? 1
      : cumberland.twistL === "3"
      ? 0
      : cumberland.twistL === "4"
      ? 3
      : 0;

  const roleTR =
    cumberland.roleR === "0"
      ? 3
      : cumberland.roleR === "1"
      ? 2
      : cumberland.roleR === "2"
      ? 1
      : cumberland.roleR === "3"
      ? 0
      : cumberland.roleR === "4"
      ? 3
      : 0;

  const roleTL =
    cumberland.roleL === "0"
      ? 3
      : cumberland.roleL === "1"
      ? 2
      : cumberland.roleL === "2"
      ? 1
      : cumberland.roleL === "3"
      ? 0
      : cumberland.roleL === "4"
      ? 3
      : 0;

  const CumberlandRT = sumAll(
    descripTR,
    unstableTR,
    sharpTR,
    stairsTR,
    standTR,
    feelsTR,
    feelsankTR,
    twistTR,
    roleTR
  );

  const CumberlandLT = sumAll(
    descripTL,
    unstableTL,
    sharpTL,
    stairsTL,
    standTL,
    feelsTL,
    feelsankTL,
    twistTL,
    roleTL
  );

  const ankleActR =
    functionSco.highestActR === "1"
      ? TopLevelR
      : functionSco.highestActR === "2"
      ? LowLevelR
      : functionSco.highestActR === "3"
      ? RecreatR
      : functionSco.highestActR === "4"
      ? IdoSpotR
      : 0;

  const ankleActL =
    functionSco.highestActL === "1"
      ? TopLevelL
      : functionSco.highestActL === "2"
      ? LowLevelL
      : functionSco.highestActL === "3"
      ? RecreatL
      : functionSco.highestActL === "4"
      ? IdoSpotL
      : 0;

  // const ikdcRTotal = ((ikdcRight / 87) * 100).toFixed(2);
  // const ikdcLTotal = ((ikdcLeft / 87) * 100).toFixed(2);

  // const koosPainRTotal = ((koosPainRight / 36) * 100).toFixed(2);
  // const koosPainLTotal = ((koosPainLeft / 36) * 100).toFixed(2);

  // const koosSymptomsRTotal = ((koosSymptomsRight / 28) * 100).toFixed(2);
  // const koosSymptomsLTotal = ((koosSymptomsLeft / 28) * 100).toFixed(2);

  // const koosAdlRTotal = ((koosAdlRight / 68) * 100).toFixed(2);
  // const koosAdlLTotal = ((koosAdlLeft / 68) * 100).toFixed(2);

  // const koosSportsRTotal = ((koosSportsRight / 20) * 100).toFixed(2);
  // const koosSportsLTotal = ((koosSportsLeft / 20) * 100).toFixed(2);

  // const qolRTotal = ((qolRight / 16) * 100).toFixed(2);
  // const qolLTotal = ((qolLeft / 16) * 100).toFixed(2);

  // const ckrsRTotal = ckrsRight;
  // const ckrsLTotal = ckrsLeft;

  // const lysholmRTotal = lysholmRight;
  // const lysholmLTotal = lysholmLeft;

  // const oksPainRTotal = oksPainRight;
  // const oksPainLTotal = oksPainLeft;

  // const oksFuncRTotal = oksFuncRight;
  // const oksFuncLTotal = oksFuncLeft;

  // const womacPainRTotal = ((womacPainRight / 20) * 100).toFixed(2);
  // const womacPainLTotal = ((womacPainLeft / 20) * 100).toFixed(2);

  // const womacStiffRTotal = ((womacStiffRight / 8) * 100).toFixed(2);
  // const womacStiffLTotal = ((womacStiffLeft / 8) * 100).toFixed(2);

  // const womacFuncRTotal = ((womacFuncRight / 68) * 100).toFixed(2);
  // const womacFuncLTotal = ((womacFuncLeft / 68) * 100).toFixed(2);

  // const kssRTotal = kssRight;
  // const kssLTotal = kssLeft;
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=1`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (isdoctorform == 1) {
          setDisplayConfirmationModal(true);
          // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + window.btoa(patientID) + "/" + window.btoa(patientID);
        } else {
          navigate("/foot-ankle-result/" + rId);
        }
      });
  };
  const [submit, setSubmit] = useState(false);
  const continueRedirect = (e) => {
    dispatch(
      updateFootSatisTotalscrore([
        {
          FAAMADRScrore,
          FAAMADLScrore,
          TotalSTAISFACTION,
          TotalSATISFACTIONL,
          // SatisfactionForgetL,
          FAAMSRScrore,
          FAAMSLScrore,
          TotalFADILS,
          TotalFADIRS,
          FADISPORTRS,
          FADISPORTLS,
          FAOSTPAINR,
          FAOSTPAINL,
          FAOSTSYMPTOMSR,
          FAOSTSYMPTOMSL,
          FAOSTADLR,
          FAOSTADLL,
          FAOSTSPORTR,
          FAOSTSPORTL,
          FAOSTQOLR,
          FAOSTQOLL,
          HindfootScaleR,
          HindfootScaleL,
          MidfootScaleR,
          MidfootScaleL,
          HalluxScaleR,
          HalluxScaleL,
          LesserToeScaleR,
          LesserToeScaleL,
          // TotalSATISFACTION,
          // SatisfactionImproveL,
          // SatisfactionSymptomL,
          EQDWalkingR,
          EQDWalkingL,
          EqdPersonalCareR,
          EqdPersonalCareL,
          EqdActivitiesR,
          EqdActivitiesL,
          EqdsanxiousR,
          EqdsanxiousL,
          HealthToday,
          FootPercent,
          FootPercentL,
          VasPainRating,
          VasPainRatingL,
          EQPain,
          EQPainL,
          CumberlandRT,
          CumberlandLT,
          AASR: ankleActR,
          AASL: ankleActL,
        },
      ])
    );
    setSubmit(true);
    // );
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (submit) {
      updateDate();
    }
  }, [json]);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="complete_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              <NameCustom />
              <h4 className="ph_title f_40">{`${translation.patient_foot_ankle_complete}`}</h4>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_20 pt_20">
                  <p className="thanks_txt">
                    {translation.patient_foot_ankle_complete_text1}
                    <br /> {translation.patient_foot_ankle_complete_text2}
                  </p>
                  <Button
                    onClick={continueRedirect}
                    value={translation.submit}
                    buttonStyle="btn_fill cmp_submit_btn"
                  />
                  <span className="answer_txt">
                    {translation.patient_foot_ankle_complete_text3}
                  </span>
                </div>
              </div>

              <div className="row mb_30 button_box">
                <div className="col-md-6 col-sm-6">
                    <Link
                      to={"/foot-ankle-satisfaction/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <SubmitModel showModal={displayConfirmationModal} patientID={window.btoa(patientID)} />
    </>
  );
}

export default FootAnkleComplete;
