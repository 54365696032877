export const updateHandWristScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_DATA',
        data: scores,
    };
};

export const updateHandWristPainFieldScore = (score) => {
    return {
        type: "UPDATE_HAND_WRIST_PAIN_FIELD_DATA",
        data: score,
    };
};
export const updateHandWristPainScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_PAIN_DATA',
        data: scores,
    };
};
export const updateHandWristSymptomsFieldScore = (score) => {
    return {
        type: "UPDATE_HAND_WRIST_SYMPTOMS_FIELD_DATA",
        data: score,
    };
};
export const updateHandWristSymptomsScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_SYMPTOMS_DATA',
        data: scores,
    };
};
export const updateHandWristfunctionFieldScore = (score) => {
    return {
        type: "UPDATE_HAND_WRIST_FUNCTION_FIELD_DATA",
        data: score,
    };
};
export const updateHandWristfunctionScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_FUNCTION_DATA',
        data: scores,
    };
};
export const updateHandWristqolFieldScore = (score) => {
    return {
        type: "UPDATE_HAND_WRIST_QOL_FIELD_DATA",
        data: score,
    };
};
export const updateHandWristqolScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_QOL_DATA',
        data: scores,
    };
};
export const updateHandWristsatisfactionFieldScore = (score) => {
    return {
        type: "UPDATE_HAND_WRIST_SATISFACTION_FIELD_DATA",
        data: score,
    };
};
export const updateHandWristsatisfactionScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_SATISFACTION_DATA',
        data: scores,
    };
};
export const updateHandWristbctqFieldScore = (score) => {
    return {
        type: "UPDATE_HAND_WRIST_BCTQ_FIELD_DATA",
        data: score,
    };
};
export const updateHandWristbctqScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_BCTQ_DATA',
        data: scores,
    };
};
export const updateHandWristTotalScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_TOTAL_SCORE',
        data: scores,
    };
};
export const updateHandWristFeedbackScores = (scores) => {
    return {
        type: 'UPDATE_HAND_FEEDBACK_FIELD_DATA',
        data: scores,
    };
};
export const UPDATE_HAND_FEEDBACK_DATA = (scores) => {
    return {
        type: 'UPDATE_HAND_FEEDBACK_DATA',
        data: scores,
    };
};

export const updateHandWristUramFieldScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_URAM_FIELD_DATA',
        data: scores,
    };
};
export const updateHandWristUramScores = (scores) => {
    return {
        type: 'UPDATE_HAND_WRIST_URAM_DATA',
        data: scores,
    };
};
export const updateAllData = (state, dispatch) => {

    const [painSco] = state.painScore
    const [symptomsScore] = state.symptomsScore
    const [functionScore] = state.functionScore
    const [qolScore] = state.qolScore
    const [satisfactionScore] = state.satisfactionScore
    const [Bctq] = state.bctq
    const [URAM] = state.uramScore;

    function sumAll(...numbers) {
        let total = 0;
        for (const number of numbers) {
            total += number;
        }
        return total;
    }
    // QuickDASH Disability/Symptom Score
    const quickdisabilitypainTodayRating = painSco.painTodayRating == "0" ? 1
        : painSco.painTodayRating == "1" ? 2
            : painSco.painTodayRating == "2" ? 3
                : painSco.painTodayRating == "3" ? 4
                    : painSco.painTodayRating == "4" ? 5
                        : 0;
    const quickdisabilitypainTodayRatingL = painSco.painTodayRatingL == "0" ? 1
        : painSco.painTodayRatingL == "1" ? 2
            : painSco.painTodayRatingL == "2" ? 3
                : painSco.painTodayRatingL == "3" ? 4
                    : painSco.painTodayRatingL == "4" ? 5
                        : 0;

    const quickdisabilitysevereR = symptomsScore.severeR == "0" ? 1
        : symptomsScore.severeR == "1" ? 2
            : symptomsScore.severeR == "2" ? 3
                : symptomsScore.severeR == "3" ? 4
                    : symptomsScore.severeR == "4" ? 5
                        : 0;
    const quickdisabilitysevereL = symptomsScore.severeL == "0" ? 1
        : symptomsScore.severeL == "1" ? 2
            : symptomsScore.severeL == "2" ? 3
                : symptomsScore.severeL == "3" ? 4
                    : symptomsScore.severeL == "4" ? 5
                        : 0;
    const quickdisabilitytightR = functionScore.tightR == "0" ? 1
        : functionScore.tightR == "1" ? 2
            : functionScore.tightR == "2" ? 3
                : functionScore.tightR == "3" ? 4
                    : functionScore.tightR == "4" ? 5
                        : 0;
    const quickdisabilitytightL = functionScore.tightL == "0" ? 1
        : functionScore.tightL == "1" ? 2
            : functionScore.tightL == "2" ? 3
                : functionScore.tightL == "3" ? 4
                    : functionScore.tightL == "4" ? 5
                        : 0;

    const quickdisabilityhouseholdR = functionScore.householdR == "0" ? 1
        : functionScore.householdR == "1" ? 2
            : functionScore.householdR == "2" ? 3
                : functionScore.householdR == "3" ? 4
                    : functionScore.householdR == "4" ? 5
                        : 0;
    const quickdisabilityhouseholdL = functionScore.householdL == "0" ? 1
        : functionScore.householdL == "1" ? 2
            : functionScore.householdL == "2" ? 3
                : functionScore.householdL == "3" ? 4
                    : functionScore.householdL == "4" ? 5
                        : 0;

    const quickdisabilitycarryR = functionScore.carryR == "0" ? 1
        : functionScore.carryR == "1" ? 2
            : functionScore.carryR == "2" ? 3
                : functionScore.carryR == "3" ? 4
                    : functionScore.carryR == "4" ? 5
                        : 0;
    const quickdisabilitycarryL = functionScore.carryL == "0" ? 1
        : functionScore.carryL == "1" ? 2
            : functionScore.carryL == "2" ? 3
                : functionScore.carryL == "3" ? 4
                    : functionScore.carryL == "4" ? 5
                        : 0;

    const quickdisabilitywashR = functionScore.washR == "0" ? 1
        : functionScore.washR == "1" ? 2
            : functionScore.washR == "2" ? 3
                : functionScore.washR == "3" ? 4
                    : functionScore.washR == "4" ? 5
                        : 0;
    const quickdisabilitywashL = functionScore.washL == "0" ? 1
        : functionScore.washL == "1" ? 2
            : functionScore.washL == "2" ? 3
                : functionScore.washL == "3" ? 4
                    : functionScore.washL == "4" ? 5
                        : 0;

    const quickdisabilityknifeR = functionScore.knifeR == "0" ? 1
        : functionScore.knifeR == "1" ? 2
            : functionScore.knifeR == "2" ? 3
                : functionScore.knifeR == "3" ? 4
                    : functionScore.knifeR == "4" ? 5
                        : 0;
    const quickdisabilityknifeL = functionScore.knifeL == "0" ? 1
        : functionScore.knifeL == "1" ? 2
            : functionScore.knifeL == "2" ? 3
                : functionScore.knifeL == "3" ? 4
                    : functionScore.knifeL == "4" ? 5
                        : 0;

    const quickdisabilityactivitiesR = functionScore.activitiesR == "0" ? 1
        : functionScore.activitiesR == "1" ? 2
            : functionScore.activitiesR == "2" ? 3
                : functionScore.activitiesR == "3" ? 4
                    : functionScore.activitiesR == "4" ? 5
                        : 0;
    const quickdisabilityactivitiesL = functionScore.activitiesL == "0" ? 1
        : functionScore.activitiesL == "1" ? 2
            : functionScore.activitiesL == "2" ? 3
                : functionScore.activitiesL == "3" ? 4
                    : functionScore.activitiesL == "4" ? 5
                        : 0;

    const quickdisabilitysleepR = functionScore.sleepR == "0" ? 1
        : functionScore.sleepR == "1" ? 2
            : functionScore.sleepR == "2" ? 3
                : functionScore.sleepR == "3" ? 4
                    : functionScore.sleepR == "4" ? 5
                        : 0;
    const quickdisabilitysleepL = functionScore.sleepL == "0" ? 1
        : functionScore.sleepL == "1" ? 2
            : functionScore.sleepL == "2" ? 3
                : functionScore.sleepL == "3" ? 4
                    : functionScore.sleepL == "4" ? 5
                        : 0;

    const quickdisabilitysocialR = functionScore.socialR == "0" ? 1
        : functionScore.socialR == "1" ? 2
            : functionScore.socialR == "2" ? 3
                : functionScore.socialR == "3" ? 4
                    : functionScore.socialR == "4" ? 5
                        : 0;
    const quickdisabilitysocialL = functionScore.socialL == "0" ? 1
        : functionScore.socialL == "1" ? 2
            : functionScore.socialL == "2" ? 3
                : functionScore.socialL == "3" ? 4
                    : functionScore.socialL == "4" ? 5
                        : 0;

    const quickdisabilitylimitR = functionScore.limitR == "0" ? 1
        : functionScore.limitR == "1" ? 2
            : functionScore.limitR == "2" ? 3
                : functionScore.limitR == "3" ? 4
                    : functionScore.limitR == "4" ? 5
                        : 0;
    const quickdisabilitylimitL = functionScore.limitL == "0" ? 1
        : functionScore.limitL == "1" ? 2
            : functionScore.limitL == "2" ? 3
                : functionScore.limitL == "3" ? 4
                    : functionScore.limitL == "4" ? 5
                        : 0;

    // QuickDASH Work Score

    const quickworkspecifyR = functionScore.SpecifyR == "0" ? 1
        : functionScore.SpecifyR == "1" ? 2
            : functionScore.SpecifyR == "2" ? 3
                : functionScore.SpecifyR == "3" ? 4
                    : functionScore.SpecifyR == "4" ? 5
                        : 0;

    const quickworkspecifyL = functionScore.SpecifyL == "0" ? 1
        : functionScore.SpecifyL == "1" ? 2
            : functionScore.SpecifyL == "2" ? 3
                : functionScore.SpecifyL == "3" ? 4
                    : functionScore.SpecifyL == "4" ? 5
                        : 0;

    const quickworkusualR = functionScore.usualR == "0" ? 1
        : functionScore.usualR == "1" ? 2
            : functionScore.usualR == "2" ? 3
                : functionScore.usualR == "3" ? 4
                    : functionScore.usualR == "4" ? 5
                        : 0;
    const quickworkusualL = functionScore.usualL == "0" ? 1
        : functionScore.usualL == "1" ? 2
            : functionScore.usualL == "2" ? 3
                : functionScore.usualL == "3" ? 4
                    : functionScore.usualL == "4" ? 5
                        : 0;

    const quickworkworkR = functionScore.workR == "0" ? 1
        : functionScore.workR == "1" ? 2
            : functionScore.workR == "2" ? 3
                : functionScore.workR == "3" ? 4
                    : functionScore.workR == "4" ? 5
                        : 0;

    const quickworkworkL = functionScore.workL == "0" ? 1
        : functionScore.workL == "1" ? 2
            : functionScore.workL == "2" ? 3
                : functionScore.workL == "3" ? 4
                    : functionScore.workL == "4" ? 5
                        : 0;

    const quickworkspendingR = functionScore.spendingR == "0" ? 1
        : functionScore.spendingR == "1" ? 2
            : functionScore.spendingR == "2" ? 3
                : functionScore.spendingR == "3" ? 4
                    : functionScore.spendingR == "4" ? 5
                        : 0;
    const quickworkspendingL = functionScore.spendingL == "0" ? 1
        : functionScore.spendingL == "1" ? 2
            : functionScore.spendingL == "2" ? 3
                : functionScore.spendingL == "3" ? 4
                    : functionScore.spendingL == "4" ? 5
                        : 0;


    // QuickDASH Sport/Performing Arts Score

    const quicksportspecifysportR = functionScore.SpecifysportR == "0" ? 1
        : functionScore.SpecifysportR == "1" ? 2
            : functionScore.SpecifysportR == "2" ? 3
                : functionScore.SpecifysportR == "3" ? 4
                    : functionScore.SpecifysportR == "4" ? 5
                        : 0;
    const quicksportspecifysportL = functionScore.SpecifysportL == "0" ? 1
        : functionScore.SpecifysportL == "1" ? 2
            : functionScore.SpecifysportL == "2" ? 3
                : functionScore.SpecifysportL == "3" ? 4
                    : functionScore.SpecifysportL == "4" ? 5
                        : 0;

    const quicksportinstrumentR = functionScore.instrumentR == "0" ? 1
        : functionScore.instrumentR == "1" ? 2
            : functionScore.instrumentR == "2" ? 3
                : functionScore.instrumentR == "3" ? 4
                    : functionScore.instrumentR == "4" ? 5
                        : 0;
    const quicksportinstrumentL = functionScore.instrumentL == "0" ? 1
        : functionScore.instrumentL == "1" ? 2
            : functionScore.instrumentL == "2" ? 3
                : functionScore.instrumentL == "3" ? 4
                    : functionScore.instrumentL == "4" ? 5
                        : 0;

    const quicksportplayingR = functionScore.playingR == "0" ? 1
        : functionScore.playingR == "1" ? 2
            : functionScore.playingR == "2" ? 3
                : functionScore.playingR == "3" ? 4
                    : functionScore.playingR == "4" ? 5
                        : 0;
    const quicksportplayingL = functionScore.playingL == "0" ? 1
        : functionScore.playingL == "1" ? 2
            : functionScore.playingL == "2" ? 3
                : functionScore.playingL == "3" ? 4
                    : functionScore.playingL == "4" ? 5
                        : 0;

    const quicksportspendR = functionScore.spendR == "0" ? 1
        : functionScore.spendR == "1" ? 2
            : functionScore.spendR == "2" ? 3
                : functionScore.spendR == "3" ? 4
                    : functionScore.spendR == "4" ? 5
                        : 0;
    const quicksportspendL = functionScore.spendL == "0" ? 1
        : functionScore.spendL == "1" ? 2
            : functionScore.spendL == "2" ? 3
                : functionScore.spendL == "3" ? 4
                    : functionScore.spendL == "4" ? 5
                        : 0;


    // BMHQ (Brif MHQ) 

    const bmhqpainTodayRating = painSco.painTodayRating == "0" ? 5
        : painSco.painTodayRating == "1" ? 4
            : painSco.painTodayRating == "2" ? 3
                : painSco.painTodayRating == "3" ? 2
                    : painSco.painTodayRating == "4" ? 1
                        : 0;

    const bmhqpainTodayRatingL = painSco.painTodayRatingL == "0" ? 5
        : painSco.painTodayRatingL == "1" ? 4
            : painSco.painTodayRatingL == "2" ? 3
                : painSco.painTodayRatingL == "3" ? 2
                    : painSco.painTodayRatingL == "4" ? 1
                        : 0;

    const bmhqpainHandR = painSco.painHandR == "0" ? 5
        : painSco.painHandR == "1" ? 4
            : painSco.painHandR == "2" ? 3
                : painSco.painHandR == "3" ? 2
                    : painSco.painHandR == "4" ? 1
                        : 0;
    const bmhqpainHandL = painSco.painHandL == "0" ? 5
        : painSco.painHandL == "1" ? 4
            : painSco.painHandL == "2" ? 3
                : painSco.painHandL == "3" ? 2
                    : painSco.painHandL == "4" ? 1
                        : 0;

    const bmhqsensationR = symptomsScore.sensationR == "0" ? 5
        : symptomsScore.sensationR == "1" ? 4
            : symptomsScore.sensationR == "2" ? 3
                : symptomsScore.sensationR == "3" ? 2
                    : symptomsScore.sensationR == "4" ? 1
                        : 0;
    const bmhqsensationL = symptomsScore.sensationL == "0" ? 5
        : symptomsScore.sensationL == "1" ? 4
            : symptomsScore.sensationL == "2" ? 3
                : symptomsScore.sensationL == "3" ? 2
                    : symptomsScore.sensationL == "4" ? 1
                        : 0;

    const bmhqapperanceR = symptomsScore.apperanceR == "0" ? 5
        : symptomsScore.apperanceR == "1" ? 4
            : symptomsScore.apperanceR == "2" ? 3
                : symptomsScore.apperanceR == "3" ? 2
                    : symptomsScore.apperanceR == "4" ? 1
                        : 0;
    const bmhqapperanceL = symptomsScore.apperanceL == "0" ? 5
        : symptomsScore.apperanceL == "1" ? 4
            : symptomsScore.apperanceL == "2" ? 3
                : symptomsScore.apperanceL == "3" ? 2
                    : symptomsScore.apperanceL == "4" ? 1
                        : 0;

    const bmhqoverallR = functionScore.overallR == "0" ? 5
        : functionScore.overallR == "1" ? 4
            : functionScore.overallR == "2" ? 3
                : functionScore.overallR == "3" ? 2
                    : functionScore.overallR == "4" ? 1
                        : 0;
    const bmhqoverallL = functionScore.overallL == "0" ? 5
        : functionScore.overallL == "1" ? 4
            : functionScore.overallL == "2" ? 3
                : functionScore.overallL == "3" ? 2
                    : functionScore.overallL == "4" ? 1
                        : 0;

    const bmhqoftenR = functionScore.oftenR == "0" ? 5
        : functionScore.oftenR == "1" ? 4
            : functionScore.oftenR == "2" ? 3
                : functionScore.oftenR == "3" ? 2
                    : functionScore.oftenR == "4" ? 1
                        : 0;
    const bmhqoftenL = functionScore.oftenL == "0" ? 5
        : functionScore.oftenL == "1" ? 4
            : functionScore.oftenL == "2" ? 3
                : functionScore.oftenL == "3" ? 2
                    : functionScore.oftenL == "4" ? 1
                        : 0;

    const bmhqlongerR = functionScore.longerR == "0" ? 5
        : functionScore.longerR == "1" ? 4
            : functionScore.longerR == "2" ? 3
                : functionScore.longerR == "3" ? 2
                    : functionScore.longerR == "4" ? 1
                        : 0;
    const bmhqlongerL = functionScore.longerL == "0" ? 5
        : functionScore.longerL == "1" ? 4
            : functionScore.longerL == "2" ? 3
                : functionScore.longerL == "3" ? 2
                    : functionScore.longerL == "4" ? 1
                        : 0;

    const bmhqfryingR = functionScore.fryingR == "0" ? 5
        : functionScore.fryingR == "1" ? 4
            : functionScore.fryingR == "2" ? 3
                : functionScore.fryingR == "3" ? 2
                    : functionScore.fryingR == "4" ? 1
                        : 0;
    const bmhqfryingL = functionScore.fryingL == "0" ? 5
        : functionScore.fryingL == "1" ? 4
            : functionScore.fryingL == "2" ? 3
                : functionScore.fryingL == "3" ? 2
                    : functionScore.fryingL == "4" ? 1
                        : 0;

    const bmhqbuttonR = functionScore.buttonR == "0" ? 5
        : functionScore.buttonR == "1" ? 4
            : functionScore.buttonR == "2" ? 3
                : functionScore.buttonR == "3" ? 2
                    : functionScore.buttonR == "4" ? 1
                        : 0;
    const bmhqbuttonL = functionScore.buttonL == "0" ? 5
        : functionScore.buttonL == "1" ? 4
            : functionScore.buttonL == "2" ? 3
                : functionScore.buttonL == "3" ? 2
                    : functionScore.buttonL == "4" ? 1
                        : 0;

    const bmhqmotionR = satisfactionScore.motionR == "0" ? 1
        : satisfactionScore.motionR == "1" ? 2
            : satisfactionScore.motionR == "2" ? 3
                : satisfactionScore.motionR == "3" ? 4
                    : satisfactionScore.motionR == "4" ? 5
                        : 0;
    const bmhqmotionL = satisfactionScore.motionL == "0" ? 1
        : satisfactionScore.motionL == "1" ? 2
            : satisfactionScore.motionL == "2" ? 3
                : satisfactionScore.motionL == "3" ? 4
                    : satisfactionScore.motionL == "4" ? 5
                        : 0;

    const bmhqwristR = satisfactionScore.wristR == "0" ? 1
        : satisfactionScore.wristR == "1" ? 2
            : satisfactionScore.wristR == "2" ? 3
                : satisfactionScore.wristR == "3" ? 4
                    : satisfactionScore.wristR == "4" ? 5
                        : 0;
    const bmhqwristL = satisfactionScore.wristL == "0" ? 1
        : satisfactionScore.wristL == "1" ? 2
            : satisfactionScore.wristL == "2" ? 3
                : satisfactionScore.wristL == "3" ? 4
                    : satisfactionScore.wristL == "4" ? 5
                        : 0;

    const bmhqhandR = satisfactionScore.handR == "0" ? 1
        : satisfactionScore.handR == "1" ? 2
            : satisfactionScore.handR == "2" ? 3
                : satisfactionScore.handR == "3" ? 4
                    : satisfactionScore.handR == "4" ? 5
                        : 0;
    const bmhqhandL = satisfactionScore.handL == "0" ? 1
        : satisfactionScore.handL == "1" ? 2
            : satisfactionScore.handL == "2" ? 3
                : satisfactionScore.handL == "3" ? 4
                    : satisfactionScore.handL == "4" ? 5
                        : 0;

    // VAS
    const vaspainTodayRating = parseFloat(painSco.howBadThisMomentR);
    const vaspainTodayRatingL = parseFloat(painSco.howBadThisMomentL);


    // SANE (Single Assessment Numeric Evaluation) 
    const sanepercent = parseFloat(symptomsScore.percent);
    const sanepercentL = parseFloat(symptomsScore.percentL);

    // EQ-5D-5L

    const eq5dproblem = qolScore.problem == "0" ? 1
        : qolScore.problem == "1" ? 2
            : qolScore.problem == "2" ? 3
                : qolScore.problem == "3" ? 4
                    : qolScore.problem == "4" ? 5
                        : 0;
    // const eq5dproblemL = qolScore.problemL == "0" ? 1
    //     : qolScore.problemL == "1" ? 2
    //         : qolScore.problemL == "2" ? 3
    //             : qolScore.problemL == "3" ? 4
    //                 : qolScore.problemL == "4" ? 5
    //                     : 0;

    const eq5dwashing = qolScore.washing == "0" ? 1
        : qolScore.washing == "1" ? 2
            : qolScore.washing == "2" ? 3
                : qolScore.washing == "3" ? 4
                    : qolScore.washing == "4" ? 5
                        : 0;
    // const eq5dwashingL = qolScore.washingL == "0" ? 1
    //     : qolScore.washingL == "1" ? 2
    //         : qolScore.washingL == "2" ? 3
    //             : qolScore.washingL == "3" ? 4
    //                 : qolScore.washingL == "4" ? 5
    //                     : 0;

    const eq5dact = qolScore.act == "0" ? 1
        : qolScore.act == "1" ? 2
            : qolScore.act == "2" ? 3
                : qolScore.act == "3" ? 4
                    : qolScore.act == "4" ? 5
                        : 0;
    // const eq5dactL = qolScore.actL == "0" ? 1
    //     : qolScore.actL == "1" ? 2
    //         : qolScore.actL == "2" ? 3
    //             : qolScore.actL == "3" ? 4
    //                 : qolScore.actL == "4" ? 5
    //                     : 0;
    const eq5dpain = qolScore.pain == "0" ? 1
        : qolScore.pain == "1" ? 2
            : qolScore.pain == "2" ? 3
                : qolScore.pain == "3" ? 4
                    : qolScore.pain == "4" ? 5
                        : 0;
    // const eq5dpainL = qolScore.painL == "0" ? 1
    //     : qolScore.painL == "1" ? 2
    //         : qolScore.painL == "2" ? 3
    //             : qolScore.painL == "3" ? 4
    //                 : qolScore.painL == "4" ? 5
    //                     : 0;
    const eq5ddepression = qolScore.depression == "0" ? 1
        : qolScore.depression == "1" ? 2
            : qolScore.depression == "2" ? 3
                : qolScore.depression == "3" ? 4
                    : qolScore.depression == "4" ? 5
                        : 0;
    // const eq5ddepressionL = qolScore.depressionL == "0" ? 1
    //     : qolScore.depressionL == "1" ? 2
    //         : qolScore.depressionL == "2" ? 3
    //             : qolScore.depressionL == "3" ? 4
    //                 : qolScore.depressionL == "4" ? 5
    //                     : 0;

    // EQ VAS

    const eqvashandpercent = parseFloat(qolScore.handpercent);
    // const eqvashandpercentL = parseFloat(qolScore.handpercentL);

    // Calculation Part 


    //QuickDASH Disability/Symptom Score

    let quickdisabilityR = sumAll(quickdisabilitypainTodayRating, quickdisabilitysevereR, quickdisabilitytightR, quickdisabilityhouseholdR, quickdisabilitycarryR, quickdisabilitywashR, quickdisabilityknifeR, quickdisabilityactivitiesR, quickdisabilitysleepR, quickdisabilitysocialR, quickdisabilitylimitR)

    let quickdisabilityL = sumAll(quickdisabilitypainTodayRatingL, quickdisabilitysevereL, quickdisabilitytightL, quickdisabilityhouseholdL, quickdisabilitycarryL, quickdisabilitywashL, quickdisabilityknifeL, quickdisabilityactivitiesL, quickdisabilitysleepL, quickdisabilitysocialL, quickdisabilitylimitL)


    const quickdisabilityTR = ((((quickdisabilityR / 11) - 1) * 25)).toFixed()
    const quickdisabilityTL = ((((quickdisabilityL / 11) - 1) * 25)).toFixed()

    // QuickDASH Work Score

    let quickworkR = sumAll(quickworkspecifyR, quickworkusualR, quickworkworkR, quickworkspendingR,)
    let quickworkL = sumAll(quickworkspecifyL, quickworkusualL, quickworkworkL, quickworkspendingL,)




    let totalNumR = 0;
    if (quickworkspecifyR !== 0) {
        totalNumR = totalNumR + 1
    } if (quickworkusualR !== 0) {
        totalNumR = totalNumR + 1
    } if (quickworkworkR !== 0) {
        totalNumR = totalNumR + 1
    } if (quickworkspendingR !== 0) {
        totalNumR = totalNumR + 1
    } else {
        totalNumR = totalNumR
    }


    let totalNumL = 0;
    if (quickworkspecifyR !== 0) {
        totalNumL = totalNumL + 1
    } if (quickworkusualR !== 0) {
        totalNumL = totalNumL + 1
    } if (quickworkworkR !== 0) {
        totalNumL = totalNumL + 1
    } if (quickworkspendingR !== 0) {
        totalNumL = totalNumL + 1
    } else {
        totalNumL = totalNumL
    }


    const quickworkTR = ((((quickworkR / totalNumR) - 1) * 25)).toFixed()
    const quickworkTL = ((((quickworkL / totalNumL) - 1) * 25)).toFixed()

    // QuickDASH Sport/Performing Arts Score

    let quicksportR = sumAll(quicksportspecifysportR, quicksportinstrumentR, quicksportplayingR, quicksportspendR,)
    let quicksportL = sumAll(quicksportspecifysportL, quicksportinstrumentL, quicksportplayingL, quicksportspendL,)

    let totalNumsportR = 0;
    if (quickworkspecifyR !== 0) {
        totalNumsportR = totalNumsportR + 1
    } if (quickworkusualR !== 0) {
        totalNumsportR = totalNumsportR + 1
    } if (quickworkworkR !== 0) {
        totalNumsportR = totalNumsportR + 1
    } if (quickworkspendingR !== 0) {
        totalNumsportR = totalNumsportR + 1
    } else {
        totalNumsportR = totalNumsportR
    }

    let totalNumsportL = 0;
    if (quickworkspecifyR !== 0) {
        totalNumsportL = totalNumsportL + 1
    } if (quickworkusualR !== 0) {
        totalNumsportL = totalNumsportL + 1
    } if (quickworkworkR !== 0) {
        totalNumsportL = totalNumsportL + 1
    } if (quickworkspendingR !== 0) {
        totalNumsportL = totalNumsportL + 1
    } else {
        totalNumsportL = totalNumsportL
    }


    const quicksportTR = (((quicksportR / totalNumsportR) - 1) * 25).toFixed()
    const quicksportTL = (((quicksportL / totalNumsportL) - 1) * 25).toFixed()

    // BMHQ (Brif MHQ)

    // Number of not aplicable check
    const bmhqNotSatisR = [satisfactionScore.applicable1R, satisfactionScore.applicable2R, satisfactionScore.applicable3R, satisfactionScore.applicableR];
    const bmhqNotSatisCountR = (12 - (bmhqNotSatisR.filter(variable => variable === true).length));

    const bmhqNotSatisL = [satisfactionScore.applicable1L, satisfactionScore.applicable2L, satisfactionScore.applicable3L, satisfactionScore.applicableL];
    const bmhqNotSatisCountL = (12 - (bmhqNotSatisL.filter(variable => variable === true).length));


    let bmhqR = sumAll(bmhqpainTodayRating, bmhqpainHandR, bmhqsensationR, bmhqapperanceR, bmhqoverallR, bmhqoftenR, bmhqlongerR, bmhqfryingR, bmhqbuttonR, bmhqmotionR, bmhqwristR, bmhqhandR)

    // console.log(bmhqpainTodayRating, bmhqpainHandR, bmhqsensationR, bmhqapperanceR, bmhqoverallR, bmhqoftenR, bmhqlongerR, bmhqfryingR, bmhqbuttonR, bmhqmotionR, bmhqwristR, bmhqhandR,"======",bmhqR);
    let AveragebmhqR = (bmhqR / bmhqNotSatisCountR)


    let bmhqL = sumAll(bmhqpainTodayRatingL, bmhqpainHandL, bmhqsensationL, bmhqapperanceL, bmhqoverallL, bmhqoftenL, bmhqlongerL, bmhqfryingL, bmhqbuttonL, bmhqmotionL, bmhqwristL, bmhqhandL)

    let AveragebmhqL = (bmhqL / bmhqNotSatisCountL)

    const bmhqTR = ((((AveragebmhqR - 1) / 4) * 100)).toFixed()
    const bmhqTL = ((((AveragebmhqL - 1) / 4) * 100)).toFixed()

    // console.log(bmhqTR,"BMHQ right");
    // console.log(bmhqTL,"BMHQ left");

    //VAS 

    let vas = sumAll(vaspainTodayRating)
    let vasL = sumAll(vaspainTodayRatingL)

    //SANE

    let sane = sumAll(sanepercent)
    let saneL = sumAll(sanepercentL)

    // EQ-5D-5L

    // let eq5dR = sumAll(eq5dproblemR, eq5dwashingR, eq5dactR, eq5dpainR, eq5ddepressionR,)
    // let eq5dL = sumAll(eq5dproblemL, eq5dwashingL, eq5dactL, eq5dpainL, eq5ddepressionL,)

    // EQ VAS

    let eqvas = sumAll(eqvashandpercent)
    // let eqvasL = sumAll(eqvashandpercentL)

    //BCTQ Symptom
    let SevereTR = Bctq.severeR == "0" ? 1
        : Bctq.severeR == "1" ? 2
            : Bctq.severeR == "2" ? 3
                : Bctq.severeR == "3" ? 4
                    : Bctq.severeR == "4" ? 5
                        : 0;
    let SevereTL = Bctq.severeL == "0" ? 1
        : Bctq.severeL == "1" ? 2
            : Bctq.severeL == "2" ? 3
                : Bctq.severeL == "3" ? 4
                    : Bctq.severeL == "4" ? 5
                        : 0;

    let OftenTR = Bctq.oftenR == "0" ? 1
        : Bctq.oftenR == "1" ? 2
            : Bctq.oftenR == "2" ? 3
                : Bctq.oftenR == "3" ? 4
                    : Bctq.oftenR == "4" ? 5
                        : 0;
    let OftenTL = Bctq.oftenL == "0" ? 1
        : Bctq.oftenL == "1" ? 2
            : Bctq.oftenL == "2" ? 3
                : Bctq.oftenL == "3" ? 4
                    : Bctq.oftenL == "4" ? 5
                        : 0;

    let typicallyTR = Bctq.typicallyR == "0" ? 1
        : Bctq.typicallyR == "1" ? 2
            : Bctq.typicallyR == "2" ? 3
                : Bctq.typicallyR == "3" ? 4
                    : Bctq.typicallyR == "4" ? 5
                        : 0;
    let typicallyTL = Bctq.typicallyL == "0" ? 1
        : Bctq.typicallyL == "1" ? 2
            : Bctq.typicallyL == "2" ? 3
                : Bctq.typicallyL == "3" ? 4
                    : Bctq.typicallyL == "4" ? 5
                        : 0;

    let daytimeRTR = Bctq.daytimeR == "0" ? 1
        : Bctq.daytimeR == "1" ? 2
            : Bctq.daytimeR == "2" ? 3
                : Bctq.daytimeR == "3" ? 4
                    : Bctq.daytimeR == "4" ? 5
                        : 0;
    let daytimeRTL = Bctq.daytimeL == "0" ? 1
        : Bctq.daytimeL == "1" ? 2
            : Bctq.daytimeL == "2" ? 3
                : Bctq.daytimeL == "3" ? 4
                    : Bctq.daytimeL == "4" ? 5
                        : 0;


    let averageRTR = Bctq.averageR == "0" ? 1
        : Bctq.averageR == "1" ? 2
            : Bctq.averageR == "2" ? 3
                : Bctq.averageR == "3" ? 4
                    : Bctq.averageR == "4" ? 5
                        : 0;
    let averageRTL = Bctq.averageL == "0" ? 1
        : Bctq.averageL == "1" ? 2
            : Bctq.averageL == "2" ? 3
                : Bctq.averageL == "3" ? 4
                    : Bctq.averageL == "4" ? 5
                        : 0;

    let numbnessRTR = Bctq.numbnessR == "0" ? 1
        : Bctq.numbnessR == "1" ? 2
            : Bctq.numbnessR == "2" ? 3
                : Bctq.numbnessR == "3" ? 4
                    : Bctq.numbnessR == "4" ? 5
                        : 0;
    let numbnessRTL = Bctq.numbnessL == "0" ? 1
        : Bctq.numbnessL == "1" ? 2
            : Bctq.numbnessL == "2" ? 3
                : Bctq.numbnessL == "3" ? 4
                    : Bctq.numbnessL == "4" ? 5
                        : 0;

    let weaknessRTR = Bctq.weaknessR == "0" ? 1
        : Bctq.weaknessR == "1" ? 2
            : Bctq.weaknessR == "2" ? 3
                : Bctq.weaknessR == "3" ? 4
                    : Bctq.weaknessR == "4" ? 5
                        : 0;
    let weaknessRTL = Bctq.weaknessL == "0" ? 1
        : Bctq.weaknessL == "1" ? 2
            : Bctq.weaknessL == "2" ? 3
                : Bctq.weaknessL == "3" ? 4
                    : Bctq.weaknessL == "4" ? 5
                        : 0;

    let tinglingRTR = Bctq.tinglingR == "0" ? 1
        : Bctq.tinglingR == "1" ? 2
            : Bctq.tinglingR == "2" ? 3
                : Bctq.tinglingR == "3" ? 4
                    : Bctq.tinglingR == "4" ? 5
                        : 0;
    let tinglingRTL = Bctq.tinglingL == "0" ? 1
        : Bctq.tinglingL == "1" ? 2
            : Bctq.tinglingL == "2" ? 3
                : Bctq.tinglingL == "3" ? 4
                    : Bctq.tinglingL == "4" ? 5
                        : 0;

    let nightRTR = Bctq.nightR == "0" ? 1
        : Bctq.nightR == "1" ? 2
            : Bctq.nightR == "2" ? 3
                : Bctq.nightR == "3" ? 4
                    : Bctq.nightR == "4" ? 5
                        : 0;
    let nightRTL = Bctq.nightL == "0" ? 1
        : Bctq.nightL == "1" ? 2
            : Bctq.nightL == "2" ? 3
                : Bctq.nightL == "3" ? 4
                    : Bctq.nightL == "4" ? 5
                        : 0;

    let duringRTR = Bctq.duringR == "0" ? 1
        : Bctq.duringR == "1" ? 2
            : Bctq.duringR == "2" ? 3
                : Bctq.duringR == "3" ? 4
                    : Bctq.duringR == "4" ? 5
                        : 0;
    let duringRTL = Bctq.duringL == "0" ? 1
        : Bctq.duringL == "1" ? 2
            : Bctq.duringL == "2" ? 3
                : Bctq.duringL == "3" ? 4
                    : Bctq.duringL == "4" ? 5
                        : 0;

    let DifficultyRTR = Bctq.DifficultyR == "0" ? 1
        : Bctq.DifficultyR == "1" ? 2
            : Bctq.DifficultyR == "2" ? 3
                : Bctq.DifficultyR == "3" ? 4
                    : Bctq.DifficultyR == "4" ? 5
                        : 0;

    let DifficultyRTL = Bctq.DifficultyL == "0" ? 1
        : Bctq.DifficultyL == "1" ? 2
            : Bctq.DifficultyL == "2" ? 3
                : Bctq.DifficultyL == "3" ? 4
                    : Bctq.DifficultyL == "4" ? 5
                        : 0;



    let BctqSymptomR = sumAll(SevereTR, OftenTR, typicallyTR, daytimeRTR, averageRTR, numbnessRTR, weaknessRTR, tinglingRTR, nightRTR, duringRTR, DifficultyRTR)
    let BctqSymptomL = sumAll(SevereTL, OftenTL, typicallyTL, daytimeRTL, averageRTL, numbnessRTL, weaknessRTL, tinglingRTL, nightRTL, duringRTL, DifficultyRTL)


    let BctqSymptomTR = Math.round(BctqSymptomR / 11);
    let BctqSymptomTL = Math.round(BctqSymptomL / 11);

    //BCTQ Function
    let ButtoningRTR = Bctq.ButtoningR == "0" ? 1
        : Bctq.ButtoningR == "1" ? 2
            : Bctq.ButtoningR == "2" ? 3
                : Bctq.ButtoningR == "3" ? 4
                    : Bctq.ButtoningR == "4" ? 5
                        : 0;
    let ButtoningRTL = Bctq.ButtoningL == "0" ? 1
        : Bctq.ButtoningL == "1" ? 2
            : Bctq.ButtoningL == "2" ? 3
                : Bctq.ButtoningL == "3" ? 4
                    : Bctq.ButtoningL == "4" ? 5
                        : 0;

    let WritingRTR = Bctq.WritingR == "0" ? 1
        : Bctq.WritingR == "1" ? 2
            : Bctq.WritingR == "2" ? 3
                : Bctq.WritingR == "3" ? 4
                    : Bctq.WritingR == "4" ? 5
                        : 0;

    let WritingRTL = Bctq.WritingL == "0" ? 1
        : Bctq.WritingL == "1" ? 2
            : Bctq.WritingL == "2" ? 3
                : Bctq.WritingL == "3" ? 4
                    : Bctq.WritingL == "4" ? 5
                        : 0;


    let HoldingRTR = Bctq.HoldingR == "0" ? 1
        : Bctq.HoldingR == "1" ? 2
            : Bctq.HoldingR == "2" ? 3
                : Bctq.HoldingR == "3" ? 4
                    : Bctq.HoldingR == "4" ? 5
                        : 0;
    let HoldingRTL = Bctq.HoldingL == "0" ? 1
        : Bctq.HoldingL == "1" ? 2
            : Bctq.HoldingL == "2" ? 3
                : Bctq.HoldingL == "3" ? 4
                    : Bctq.HoldingL == "4" ? 5
                        : 0;
    let GrippingRTR = Bctq.GrippingR == "0" ? 1
        : Bctq.GrippingR == "1" ? 2
            : Bctq.GrippingR == "2" ? 3
                : Bctq.GrippingR == "3" ? 4
                    : Bctq.GrippingR == "4" ? 5
                        : 0;
    let GrippingTL = Bctq.GrippingL == "0" ? 1
        : Bctq.GrippingL == "1" ? 2
            : Bctq.GrippingL == "2" ? 3
                : Bctq.GrippingL == "3" ? 4
                    : Bctq.GrippingL == "4" ? 5
                        : 0;
    let OpeningRTR = Bctq.OpeningR == "0" ? 1
        : Bctq.OpeningR == "1" ? 2
            : Bctq.OpeningR == "2" ? 3
                : Bctq.OpeningR == "3" ? 4
                    : Bctq.OpeningR == "4" ? 5
                        : 0;
    let OpeningLTL = Bctq.OpeningL == "0" ? 1
        : Bctq.OpeningL == "1" ? 2
            : Bctq.OpeningL == "2" ? 3
                : Bctq.OpeningL == "3" ? 4
                    : Bctq.OpeningL == "4" ? 5
                        : 0;
    let HouseholdRTR = Bctq.HouseholdR == "0" ? 1
        : Bctq.HouseholdR == "1" ? 2
            : Bctq.HouseholdR == "2" ? 3
                : Bctq.HouseholdR == "3" ? 4
                    : Bctq.HouseholdR == "4" ? 5
                        : 0;
    let HouseholdLTL = Bctq.HouseholdL == "0" ? 1
        : Bctq.HouseholdL == "1" ? 2
            : Bctq.HouseholdL == "2" ? 3
                : Bctq.HouseholdL == "3" ? 4
                    : Bctq.HouseholdL == "4" ? 5
                        : 0;

    let CarryingRTR = Bctq.CarryingR == "0" ? 1
        : Bctq.CarryingR == "1" ? 2
            : Bctq.CarryingR == "2" ? 3
                : Bctq.CarryingR == "3" ? 4
                    : Bctq.CarryingR == "4" ? 5
                        : 0;

    let CarryingRTL = Bctq.CarryingL == "0" ? 1
        : Bctq.CarryingL == "1" ? 2
            : Bctq.CarryingL == "2" ? 3
                : Bctq.CarryingL == "3" ? 4
                    : Bctq.CarryingL == "4" ? 5
                        : 0;
    let BathingRTR = Bctq.BathingR == "0" ? 1
        : Bctq.BathingR == "1" ? 2
            : Bctq.BathingR == "2" ? 3
                : Bctq.BathingR == "3" ? 4
                    : Bctq.BathingR == "4" ? 5
                        : 0;
    let BathingLTL = Bctq.BathingL == "0" ? 1
        : Bctq.BathingL == "1" ? 2
            : Bctq.BathingL == "2" ? 3
                : Bctq.BathingL == "3" ? 4
                    : Bctq.BathingL == "4" ? 5
                        : 0;
    let BctqFunctionR = sumAll(WritingRTR,
        ButtoningRTR,
        HoldingRTR,
        GrippingRTR,
        OpeningRTR,
        HouseholdRTR,
        CarryingRTR,
        BathingRTR)

    let BctqFunctionL = sumAll(WritingRTL,
        ButtoningRTL,
        HoldingRTL,
        GrippingTL,
        OpeningLTL,
        HouseholdLTL,
        CarryingRTL,
        BathingLTL);

    let BctqFunctionTR = ((BctqFunctionR / 8)).toFixed()
    let BctqFunctionTL = ((BctqFunctionL / 8)).toFixed()

    console.log(WritingRTR,
        ButtoningRTR,
        HoldingRTR,
        GrippingRTR,
        OpeningRTR,
        HouseholdRTR,
        CarryingRTR,
        BathingRTR, "=======right", BctqFunctionR);

    console.log(WritingRTL,
        ButtoningRTL,
        HoldingRTL,
        GrippingTL,
        OpeningLTL,
        HouseholdLTL,
        CarryingRTL,
        BathingLTL, "========left", BctqFunctionL);



    // URAM

    let flanneTlR = URAM.flannelR == "0" ? 0
        : URAM.flannelR == "1" ? 1
            : URAM.flannelR == "2" ? 2
                : URAM.flannelR == "3" ? 3
                    : URAM.flannelR == "4" ? 4
                        : URAM.flannelR == "5" ? 5
                            : 0;

    let flanneTlL = URAM.flannelL == "0" ? 0
        : URAM.flannelL == "1" ? 1
            : URAM.flannelL == "2" ? 2
                : URAM.flannelL == "3" ? 3
                    : URAM.flannelL == "4" ? 4
                        : URAM.flannelL == "5" ? 5
                            : 0;

    let faceTR = URAM.faceR == "0" ? 0
        : URAM.faceR == "1" ? 1
            : URAM.faceR == "2" ? 2
                : URAM.faceR == "3" ? 3
                    : URAM.faceR == "4" ? 4
                        : URAM.faceR == "5" ? 5
                            : 0;

    let faceTL = URAM.faceL == "0" ? 0
        : URAM.faceL == "1" ? 1
            : URAM.faceL == "2" ? 2
                : URAM.faceL == "3" ? 3
                    : URAM.faceL == "4" ? 4
                        : URAM.faceL == "5" ? 5
                            : 0;

    let bottleTR = URAM.bottleR == "0" ? 0
        : URAM.bottleR == "1" ? 1
            : URAM.bottleR == "2" ? 2
                : URAM.bottleR == "3" ? 3
                    : URAM.bottleR == "4" ? 4
                        : URAM.bottleR == "5" ? 5
                            : 0;

    let bottleTL = URAM.bottleL == "0" ? 0
        : URAM.bottleL == "1" ? 1
            : URAM.bottleL == "2" ? 2
                : URAM.bottleL == "3" ? 3
                    : URAM.bottleL == "4" ? 4
                        : URAM.bottleL == "5" ? 5
                            : 0;

    let shakeTR = URAM.shakeR == "0" ? 0
        : URAM.shakeR == "1" ? 1
            : URAM.shakeR == "2" ? 2
                : URAM.shakeR == "3" ? 3
                    : URAM.shakeR == "4" ? 4
                        : URAM.shakeR == "5" ? 5
                            : 0;

    let shakeTL = URAM.shakeL == "0" ? 0
        : URAM.shakeL == "1" ? 1
            : URAM.shakeL == "2" ? 2
                : URAM.shakeL == "3" ? 3
                    : URAM.shakeL == "4" ? 4
                        : URAM.shakeL == "5" ? 5
                            : 0;

    let strokeTR = URAM.strokeR == "0" ? 0
        : URAM.strokeR == "1" ? 1
            : URAM.strokeR == "2" ? 2
                : URAM.strokeR == "3" ? 3
                    : URAM.strokeR == "4" ? 4
                        : URAM.strokeR == "5" ? 5
                            : 0;

    let strokeTL = URAM.strokeL == "0" ? 0
        : URAM.strokeL == "1" ? 1
            : URAM.strokeL == "2" ? 2
                : URAM.strokeL == "3" ? 3
                    : URAM.strokeL == "4" ? 4
                        : URAM.strokeL == "5" ? 5
                            : 0;

    let clapTR = URAM.clapR == "0" ? 0
        : URAM.clapR == "1" ? 1
            : URAM.clapR == "2" ? 2
                : URAM.clapR == "3" ? 3
                    : URAM.clapR == "4" ? 4
                        : URAM.clapR == "5" ? 5
                            : 0;

    let clapTL = URAM.clapL == "0" ? 0
        : URAM.clapL == "1" ? 1
            : URAM.clapL == "2" ? 2
                : URAM.clapL == "3" ? 3
                    : URAM.clapL == "4" ? 4
                        : URAM.clapL == "5" ? 5
                            : 0;

    let spreadTR = URAM.spreadR == "0" ? 0
        : URAM.spreadR == "1" ? 1
            : URAM.spreadR == "2" ? 2
                : URAM.spreadR == "3" ? 3
                    : URAM.spreadR == "4" ? 4
                        : URAM.spreadR == "5" ? 5
                            : 0;

    let spreadTL = URAM.spreadL == "0" ? 0
        : URAM.spreadL == "1" ? 1
            : URAM.spreadL == "2" ? 2
                : URAM.spreadL == "3" ? 3
                    : URAM.spreadL == "4" ? 4
                        : URAM.spreadL == "5" ? 5
                            : 0;

    let leeanTR = URAM.leeanR == "0" ? 0
        : URAM.leeanR == "1" ? 1
            : URAM.leeanR == "2" ? 2
                : URAM.leeanR == "3" ? 3
                    : URAM.leeanR == "4" ? 4
                        : URAM.leeanR == "5" ? 5
                            : 0;

    let leeanTL = URAM.leeanL == "0" ? 0
        : URAM.leeanL == "1" ? 1
            : URAM.leeanL == "2" ? 2
                : URAM.leeanL == "3" ? 3
                    : URAM.leeanL == "4" ? 4
                        : URAM.leeanL == "5" ? 5
                            : 0;

    let smallTR = URAM.smallR == "0" ? 0
        : URAM.smallR == "1" ? 1
            : URAM.smallR == "2" ? 2
                : URAM.smallR == "3" ? 3
                    : URAM.smallR == "4" ? 4
                        : URAM.smallR == "5" ? 5
                            : 0;

    let smallTL = URAM.smallL == "0" ? 0
        : URAM.smallL == "1" ? 1
            : URAM.smallL == "2" ? 2
                : URAM.smallL == "3" ? 3
                    : URAM.smallL == "4" ? 4
                        : URAM.smallL == "5" ? 5
                            : 0;

    let uramR = sumAll(flanneTlR,
        faceTR,
        bottleTR,
        shakeTR,
        strokeTR,
        clapTR,
        spreadTR,
        leeanTR,
        smallTR)

    let uramL = sumAll(flanneTlL,
        faceTL,
        bottleTL,
        shakeTL,
        strokeTL,
        clapTL,
        spreadTL,
        leeanTL,
        smallTL)





    dispatch(
        updateHandWristTotalScores([
            {
                quickdisabilityTR,
                quickdisabilityTL,
                quickworkTR,
                quickworkTL,
                quicksportTR,
                quicksportTL,
                bmhqTR,
                bmhqTL,
                vas,
                vasL,
                sane,
                saneL,
                eqvas,
                // eqvasL,
                BctqSymptomTR,
                BctqSymptomTL,
                BctqFunctionTR,
                BctqFunctionTL,
                eq5dproblem,
                // eq5dproblemL,
                eq5dwashing,
                // eq5dwashingL,
                eq5dact,
                // eq5dactL,
                eq5dpain,
                // eq5dpainL,
                eq5ddepression,
                // eq5ddepressionL,
                uramR,
                uramL,
                wristsurR: satisfactionScore.wristsurR,
                wristsurL: satisfactionScore.wristsurL,

            },
        ])
    );
};