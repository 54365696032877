import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import Button from "../../../../custom/button/Button";
import {
  updateAdultKneeTotalScores,
  updateAdultKneeQolScore,
  updateAdultKneeSymptomsScore,
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneeSatisfactionScore,
  updateAdultKneeFunctionScore,
  updateAdultKneeNorwichInstaScore,
  updateAdultKneeFeedback,
} from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from "../../../../customHooks/translations";
import NameCustom from "../../../../custom/name/NameCustom";
import SubmitModel from "../../../SubmissionModel/SubmitModel";
function AdultKneeComplete() {
  const translation = useTransition();
  const { rId } = useParams();
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [initialAssesment, setIitialAssesment] = useState(0);
  const [painSco] = useSelector((state) => state.adultKneeScore.painScore);
  const [sympScore] = useSelector(
    (state) => state.adultKneeScore.symptomsScore
  );
  const [funcScore] = useSelector(
    (state) => state.adultKneeScore.functionScore
  );
  const [qualityScore] = useSelector((state) => state.adultKneeScore.qolScore);
  const [satisScore] = useSelector(
    (state) => state.adultKneeScore.satisfactionScore
  );
  const [Norwich] = useSelector(
    (state) => state.adultKneeScore.norwichInstability
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }

  function selectedQuestion(...numbers) {
    let count = 0;
    for (const number of numbers) {
      if (number !== "") {
        count++;
      }
    }
    return count;
  }
  const [isdoctorform, setisdoctorform] = useState(localStorage.getItem('isdoctorForm')==='true'?1:0)

  const [patientID, setpatientID] = useState("");

  const [chk, setChk] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {
        setIitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(
          updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        setpatientID(res.data.data2[0].patient_id);
        // setisdoctorform(res.data.data2[0].is_doctor_form);

        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  const mapValueToPercentage = (value = 0) => {
    return value * 10;
  };

  useEffect(() => {
    save();
  }, []);

  const json = useSelector((state) => state.adultKneeScore);

  // Koos IKDC calculation starting
  const ikdcDescribePainR = parseFloat(painSco.describePainR);

  const ikdcPainTodayRatingR =
    painSco.painTodayRatingR === "0"
      ? 10
      : painSco.painTodayRatingR === "1"
      ? 9
      : painSco.painTodayRatingR === "2"
      ? 8
      : painSco.painTodayRatingR === "3"
      ? 7
      : painSco.painTodayRatingR === "4"
      ? 6
      : painSco.painTodayRatingR === "5"
      ? 5
      : painSco.painTodayRatingR === "6"
      ? 4
      : painSco.painTodayRatingR === "7"
      ? 3
      : painSco.painTodayRatingR === "8"
      ? 2
      : painSco.painTodayRatingR === "9"
      ? 1
      : painSco.painTodayRatingR === "10"
      ? 0
      : 0;

  const ikdcHowSevereR =
    painSco.howSevereR === "0"
      ? 10
      : painSco.howSevereR === "1"
      ? 9
      : painSco.howSevereR === "2"
      ? 8
      : painSco.howSevereR === "3"
      ? 7
      : painSco.howSevereR === "4"
      ? 6
      : painSco.howSevereR === "5"
      ? 5
      : painSco.howSevereR === "6"
      ? 4
      : painSco.howSevereR === "7"
      ? 3
      : painSco.howSevereR === "8"
      ? 2
      : painSco.howSevereR === "9"
      ? 1
      : painSco.howSevereR === "10"
      ? 0
      : 0;

  const ikdcSwellingR =
    sympScore.swellingR === "0"
      ? 4
      : sympScore.swellingR === "1"
      ? 3
      : sympScore.swellingR === "2"
      ? 2
      : sympScore.swellingR === "3"
      ? 1
      : sympScore.swellingR === "4"
      ? 0
      : 0;

  const ikdcCatchingR =
    sympScore.catchingR === "0"
      ? 1
      : sympScore.catchingR === "1"
      ? 0
      : sympScore.catchingR === "2"
      ? 0
      : sympScore.catchingR === "3"
      ? 0
      : sympScore.catchingR === "4"
      ? 0
      : 0;

  const ikdcSignificantSwellingR = parseFloat(sympScore.significantSwellingR);

  const ikdcInstabilityR =
    sympScore.instabilityR === "0"
      ? 0
      : sympScore.instabilityR === "1"
      ? 1
      : sympScore.instabilityR === "2"
      ? 2
      : sympScore.instabilityR === "3"
      ? 2
      : sympScore.instabilityR === "4"
      ? 3
      : sympScore.instabilityR === "5"
      ? 4
      : 0;

  const ikdcGoUpStairsR =
    funcScore.goUpStairsR === "0"
      ? 4
      : funcScore.goUpStairsR === "1"
      ? 3
      : funcScore.goUpStairsR === "2"
      ? 2
      : funcScore.goUpStairsR === "3"
      ? 1
      : funcScore.goUpStairsR === "4"
      ? 0
      : 0;

  const ikdcGoDownStairsR =
    funcScore.goDownStairsR === "0"
      ? 4
      : funcScore.goDownStairsR === "1"
      ? 3
      : funcScore.goDownStairsR === "2"
      ? 2
      : funcScore.goDownStairsR === "3"
      ? 1
      : funcScore.goDownStairsR === "4"
      ? 0
      : 0;

  const ikdcKneelFrontR =
    funcScore.kneelFrontR === "0"
      ? 4
      : funcScore.kneelFrontR === "1"
      ? 3
      : funcScore.kneelFrontR === "2"
      ? 2
      : funcScore.kneelFrontR === "3"
      ? 1
      : funcScore.kneelFrontR === "4"
      ? 0
      : 0;

  const ikdcSquatR =
    funcScore.squatR === "0"
      ? 4
      : funcScore.squatR === "1"
      ? 3
      : funcScore.squatR === "2"
      ? 2
      : funcScore.squatR === "3"
      ? 1
      : funcScore.squatR === "4"
      ? 0
      : 0;

  const ikdcSitKneeBentR =
    funcScore.sitKneeBentR === "0"
      ? 4
      : funcScore.sitKneeBentR === "1"
      ? 3
      : funcScore.sitKneeBentR === "2"
      ? 2
      : funcScore.sitKneeBentR === "3"
      ? 1
      : funcScore.sitKneeBentR === "4"
      ? 0
      : 0;

  const ikdcRiseFromChairR =
    funcScore.riseFromChairR === "0"
      ? 4
      : funcScore.riseFromChairR === "1"
      ? 3
      : funcScore.riseFromChairR === "2"
      ? 2
      : funcScore.riseFromChairR === "3"
      ? 1
      : funcScore.riseFromChairR === "4"
      ? 0
      : 0;

  const ikdcRunStraightAheadR =
    funcScore.runStraightAheadR === "0"
      ? 4
      : funcScore.runStraightAheadR === "1"
      ? 3
      : funcScore.runStraightAheadR === "2"
      ? 2
      : funcScore.runStraightAheadR === "3"
      ? 1
      : funcScore.runStraightAheadR === "4"
      ? 0
      : 0;

  const ikdcJumpAndLandR =
    funcScore.jumpAndLandR === "0"
      ? 4
      : funcScore.jumpAndLandR === "1"
      ? 3
      : funcScore.jumpAndLandR === "2"
      ? 2
      : funcScore.jumpAndLandR === "3"
      ? 1
      : funcScore.jumpAndLandR === "4"
      ? 0
      : 0;

  const ikdcStopAndStartR =
    funcScore.stopAndStartR === "0"
      ? 4
      : funcScore.stopAndStartR === "1"
      ? 3
      : funcScore.stopAndStartR === "2"
      ? 2
      : funcScore.stopAndStartR === "3"
      ? 1
      : funcScore.stopAndStartR === "4"
      ? 0
      : 0;

  const ikdcSwollenR =
    sympScore.swollenR === "0"
      ? 4
      : sympScore.swollenR === "1"
      ? 3
      : sympScore.swollenR === "2"
      ? 2
      : sympScore.swollenR === "3"
      ? 1
      : sympScore.swollenR === "4"
      ? 0
      : 0;

  const ikdcSwollenL =
    sympScore.swollenL === "0"
      ? 4
      : sympScore.swollenL === "1"
      ? 3
      : sympScore.swollenL === "2"
      ? 2
      : sympScore.swollenL === "3"
      ? 1
      : sympScore.swollenL === "4"
      ? 0
      : 0;

  const ikdcRateKneeFunctionR = Math.floor(funcScore.rateKneeFunctionR);

  const ikdcActivityLevelR = parseFloat(funcScore.activityLevelR);

  const ikdcRight = sumAll(
    ikdcDescribePainR,
    ikdcPainTodayRatingR,
    ikdcHowSevereR,
    ikdcCatchingR,
    ikdcSwollenR,
    ikdcSignificantSwellingR,
    ikdcInstabilityR,
    ikdcGoUpStairsR,
    ikdcGoDownStairsR,
    ikdcKneelFrontR,
    ikdcSquatR,
    ikdcSitKneeBentR,
    ikdcRiseFromChairR,
    ikdcRunStraightAheadR,
    ikdcJumpAndLandR,
    ikdcStopAndStartR,
    ikdcRateKneeFunctionR,
    ikdcActivityLevelR
  );

  const ikdcDescribePainL = parseFloat(painSco.describePainL);

  const ikdcPainTodayRatingL =
    painSco.painTodayRatingL === "0"
      ? 10
      : painSco.painTodayRatingL === "1"
      ? 9
      : painSco.painTodayRatingL === "2"
      ? 8
      : painSco.painTodayRatingL === "3"
      ? 7
      : painSco.painTodayRatingL === "4"
      ? 6
      : painSco.painTodayRatingL === "5"
      ? 5
      : painSco.painTodayRatingL === "6"
      ? 4
      : painSco.painTodayRatingL === "7"
      ? 3
      : painSco.painTodayRatingL === "8"
      ? 2
      : painSco.painTodayRatingL === "9"
      ? 1
      : painSco.painTodayRatingL === "10"
      ? 0
      : 0;

  const ikdcHowSevereL =
    painSco.howSevereL === "0"
      ? 10
      : painSco.howSevereL === "1"
      ? 9
      : painSco.howSevereL === "2"
      ? 8
      : painSco.howSevereL === "3"
      ? 7
      : painSco.howSevereL === "4"
      ? 6
      : painSco.howSevereL === "5"
      ? 5
      : painSco.howSevereL === "6"
      ? 4
      : painSco.howSevereL === "7"
      ? 3
      : painSco.howSevereL === "8"
      ? 2
      : painSco.howSevereL === "9"
      ? 1
      : painSco.howSevereL === "10"
      ? 0
      : 0;

  const ikdcSwellingL =
    sympScore.swellingL === "0"
      ? 4
      : sympScore.swellingL === "1"
      ? 3
      : sympScore.swellingL === "2"
      ? 2
      : sympScore.swellingL === "3"
      ? 1
      : sympScore.swellingL === "4"
      ? 0
      : 0;

  const ikdcCatchingL =
    sympScore.catchingL === "0"
      ? 1
      : sympScore.catchingL === "1"
      ? 0
      : sympScore.catchingL === "2"
      ? 0
      : sympScore.catchingL === "3"
      ? 0
      : sympScore.catchingL === "4"
      ? 0
      : 0;

  const ikdcSignificantSwellingL = parseFloat(sympScore.significantSwellingL);

  const ikdcInstabilityL =
    sympScore.instabilityL === "0"
      ? 0
      : sympScore.instabilityL === "1"
      ? 1
      : sympScore.instabilityL === "2"
      ? 2
      : sympScore.instabilityL === "3"
      ? 2
      : sympScore.instabilityL === "4"
      ? 3
      : sympScore.instabilityL === "5"
      ? 4
      : 0;

  const ikdcGoUpStairsL =
    funcScore.goUpStairsL === "0"
      ? 4
      : funcScore.goUpStairsL === "1"
      ? 3
      : funcScore.goUpStairsL === "2"
      ? 2
      : funcScore.goUpStairsL === "3"
      ? 1
      : funcScore.goUpStairsL === "4"
      ? 0
      : 0;

  const ikdcGoDownStairsL =
    funcScore.goDownStairsL === "0"
      ? 4
      : funcScore.goDownStairsL === "1"
      ? 3
      : funcScore.goDownStairsL === "2"
      ? 2
      : funcScore.goDownStairsL === "3"
      ? 1
      : funcScore.goDownStairsL === "4"
      ? 0
      : 0;

  const ikdcKneelFrontL =
    funcScore.kneelFrontL === "0"
      ? 4
      : funcScore.kneelFrontL === "1"
      ? 3
      : funcScore.kneelFrontL === "2"
      ? 2
      : funcScore.kneelFrontL === "3"
      ? 1
      : funcScore.kneelFrontL === "4"
      ? 0
      : 0;

  const ikdcSquatL =
    funcScore.squatL === "0"
      ? 4
      : funcScore.squatL === "1"
      ? 3
      : funcScore.squatL === "2"
      ? 2
      : funcScore.squatL === "3"
      ? 1
      : funcScore.squatL === "4"
      ? 0
      : 0;

  const ikdcSitKneeBentL =
    funcScore.sitKneeBentL === "0"
      ? 4
      : funcScore.sitKneeBentL === "1"
      ? 3
      : funcScore.sitKneeBentL === "2"
      ? 2
      : funcScore.sitKneeBentL === "3"
      ? 1
      : funcScore.sitKneeBentL === "4"
      ? 0
      : 0;

  const ikdcRiseFromChairL =
    funcScore.riseFromChairL === "0"
      ? 4
      : funcScore.riseFromChairL === "1"
      ? 3
      : funcScore.riseFromChairL === "2"
      ? 2
      : funcScore.riseFromChairL === "3"
      ? 1
      : funcScore.riseFromChairL === "4"
      ? 0
      : 0;

  const ikdcRunStraightAheadL =
    funcScore.runStraightAheadL === "0"
      ? 4
      : funcScore.runStraightAheadL === "1"
      ? 3
      : funcScore.runStraightAheadL === "2"
      ? 2
      : funcScore.runStraightAheadL === "3"
      ? 1
      : funcScore.runStraightAheadL === "4"
      ? 0
      : 0;

  const ikdcJumpAndLandL =
    funcScore.jumpAndLandL === "0"
      ? 4
      : funcScore.jumpAndLandL === "1"
      ? 3
      : funcScore.jumpAndLandL === "2"
      ? 2
      : funcScore.jumpAndLandL === "3"
      ? 1
      : funcScore.jumpAndLandL === "4"
      ? 0
      : 0;

  const ikdcStopAndStartL =
    funcScore.stopAndStartL === "0"
      ? 4
      : funcScore.stopAndStartL === "1"
      ? 3
      : funcScore.stopAndStartL === "2"
      ? 2
      : funcScore.stopAndStartL === "3"
      ? 1
      : funcScore.stopAndStartL === "4"
      ? 0
      : 0;

  const ikdcRateKneeFunctionL = Math.floor(funcScore.rateKneeFunctionL);

  const ikdcActivityLevelL =
    funcScore.activityLevelL === "0"
      ? 0
      : funcScore.activityLevelL === "1"
      ? 1
      : funcScore.activityLevelL === "2"
      ? 2
      : funcScore.activityLevelL === "3"
      ? 3
      : funcScore.activityLevelL === "4"
      ? 4
      : 0;

  const ikdcLeft = sumAll(
    ikdcDescribePainL,
    ikdcPainTodayRatingL,
    ikdcHowSevereL,
    ikdcCatchingL,
    ikdcSwollenL,
    ikdcSignificantSwellingL,
    ikdcInstabilityL,
    ikdcGoUpStairsL,
    ikdcGoDownStairsL,
    ikdcKneelFrontL,
    ikdcSquatL,
    ikdcSitKneeBentL,
    ikdcRiseFromChairL,
    ikdcRunStraightAheadL,
    ikdcJumpAndLandL,
    ikdcStopAndStartL,
    ikdcRateKneeFunctionL,
    ikdcActivityLevelL
  );

  // Koos IKDC calculation End

  // Koos koosPain calculation starting
  const koosPainTodayR =
    painSco.painTodayRatingR === "0"
      ? 4
      : painSco.painTodayRatingR === "1"
      ? 3
      : painSco.painTodayRatingR === "2"
      ? 3
      : painSco.painTodayRatingR === "3"
      ? 3
      : painSco.painTodayRatingR === "4"
      ? 2
      : painSco.painTodayRatingR === "5"
      ? 2
      : painSco.painTodayRatingR === "6"
      ? 2
      : painSco.painTodayRatingR === "7"
      ? 1
      : painSco.painTodayRatingR === "8"
      ? 1
      : painSco.painTodayRatingR === "9"
      ? 1
      : painSco.painTodayRatingR === "10"
      ? 0
      : 0;
  const koosPainTwistingR =
    painSco.twistingR === "0"
      ? 4
      : painSco.twistingR === "1"
      ? 3
      : painSco.twistingR === "2"
      ? 2
      : painSco.twistingR === "3"
      ? 1
      : painSco.twistingR === "4"
      ? 0
      : 0;

  const koossPaintraighteningR =
    painSco.straighteningR === "0"
      ? 4
      : painSco.straighteningR === "1"
      ? 3
      : painSco.straighteningR === "2"
      ? 2
      : painSco.straighteningR === "3"
      ? 1
      : painSco.straighteningR === "4"
      ? 0
      : 0;

  const koossPainBendingR =
    painSco.bendingR === "0"
      ? 4
      : painSco.bendingR === "1"
      ? 3
      : painSco.bendingR === "2"
      ? 2
      : painSco.bendingR === "3"
      ? 1
      : painSco.bendingR === "4"
      ? 0
      : 0;

  const koossPainWalkingSurfaceR =
    painSco.walkingSurfaceR === "0"
      ? 4
      : painSco.walkingSurfaceR === "1"
      ? 3
      : painSco.walkingSurfaceR === "2"
      ? 2
      : painSco.walkingSurfaceR === "3"
      ? 1
      : painSco.walkingSurfaceR === "4"
      ? 0
      : 0;

  const koossPainUseStepsR =
    painSco.useStepsR === "0"
      ? 4
      : painSco.useStepsR === "1"
      ? 3
      : painSco.useStepsR === "2"
      ? 2
      : painSco.useStepsR === "3"
      ? 1
      : painSco.useStepsR === "4"
      ? 0
      : 0;

  const koossPainWhileBedR =
    painSco.whileBedR === "0"
      ? 4
      : painSco.whileBedR === "1"
      ? 3
      : painSco.whileBedR === "2"
      ? 2
      : painSco.whileBedR === "3"
      ? 1
      : painSco.whileBedR === "4"
      ? 0
      : 0;

  const koossPainSittingR =
    painSco.sittingR === "0"
      ? 4
      : painSco.sittingR === "1"
      ? 3
      : painSco.sittingR === "2"
      ? 2
      : painSco.sittingR === "3"
      ? 1
      : painSco.sittingR === "4"
      ? 0
      : 0;

  const koossPainStandingR =
    painSco.standingR === "0"
      ? 4
      : painSco.standingR === "1"
      ? 3
      : painSco.standingR === "2"
      ? 2
      : painSco.standingR === "3"
      ? 1
      : painSco.standingR === "4"
      ? 0
      : 0;

  const koosPainRight = sumAll(
    koosPainTodayR,
    koosPainTwistingR,
    koossPaintraighteningR,
    koossPainBendingR,
    koossPainWalkingSurfaceR,
    koossPainUseStepsR,
    koossPainWhileBedR,
    koossPainSittingR,
    koossPainStandingR
  );
  const koosPainTodayL =
    painSco.painTodayRatingL === "0"
      ? 4
      : painSco.painTodayRatingL === "1"
      ? 3
      : painSco.painTodayRatingL === "2"
      ? 3
      : painSco.painTodayRatingL === "3"
      ? 3
      : painSco.painTodayRatingL === "4"
      ? 2
      : painSco.painTodayRatingL === "5"
      ? 2
      : painSco.painTodayRatingL === "6"
      ? 2
      : painSco.painTodayRatingL === "7"
      ? 1
      : painSco.painTodayRatingL === "8"
      ? 1
      : painSco.painTodayRatingL === "9"
      ? 1
      : painSco.painTodayRatingL === "10"
      ? 0
      : 0;
  const koosPainTwistingL =
    painSco.twistingL === "0"
      ? 4
      : painSco.twistingL === "1"
      ? 3
      : painSco.twistingL === "2"
      ? 2
      : painSco.twistingL === "3"
      ? 1
      : painSco.twistingL === "4"
      ? 0
      : 0;

  const koossPaintraighteningL =
    painSco.straighteningL === "0"
      ? 4
      : painSco.straighteningL === "1"
      ? 3
      : painSco.straighteningL === "2"
      ? 2
      : painSco.straighteningL === "3"
      ? 1
      : painSco.straighteningL === "4"
      ? 0
      : 0;

  const koossPainBendingL =
    painSco.bendingL === "0"
      ? 4
      : painSco.bendingL === "1"
      ? 3
      : painSco.bendingL === "2"
      ? 2
      : painSco.bendingL === "3"
      ? 1
      : painSco.bendingL === "4"
      ? 0
      : 0;

  const koossPainWalkingSurfaceL =
    painSco.walkingSurfaceL === "0"
      ? 4
      : painSco.walkingSurfaceL === "1"
      ? 3
      : painSco.walkingSurfaceL === "2"
      ? 2
      : painSco.walkingSurfaceL === "3"
      ? 1
      : painSco.walkingSurfaceL === "4"
      ? 0
      : 0;

  const koossPainUseStepsL =
    painSco.useStepsL === "0"
      ? 4
      : painSco.useStepsL === "1"
      ? 3
      : painSco.useStepsL === "2"
      ? 2
      : painSco.useStepsL === "3"
      ? 1
      : painSco.useStepsL === "4"
      ? 0
      : 0;

  const koossPainWhileBedL =
    painSco.whileBedL === "0"
      ? 4
      : painSco.whileBedL === "1"
      ? 3
      : painSco.whileBedL === "2"
      ? 2
      : painSco.whileBedL === "3"
      ? 1
      : painSco.whileBedL === "4"
      ? 0
      : 0;

  const koossPainSittingL =
    painSco.sittingL === "0"
      ? 4
      : painSco.sittingL === "1"
      ? 3
      : painSco.sittingL === "2"
      ? 2
      : painSco.sittingL === "3"
      ? 1
      : painSco.sittingL === "4"
      ? 0
      : 0;

  const koossPainStandingL =
    painSco.standingL === "0"
      ? 4
      : painSco.standingL === "1"
      ? 3
      : painSco.standingL === "2"
      ? 2
      : painSco.standingL === "3"
      ? 1
      : painSco.standingL === "4"
      ? 0
      : 0;

  const koosPainLeft = sumAll(
    koosPainTodayL,
    koosPainTwistingL,
    koossPaintraighteningL,
    koossPainBendingL,
    koossPainWalkingSurfaceL,
    koossPainUseStepsL,
    koossPainWhileBedL,
    koossPainSittingL,
    koossPainStandingL
  );

  // Koos koosPain calculation End

  // Koos Symptoms calculation Start
  const koossSymSwellingR =
    sympScore.swellingR === "0"
      ? 4
      : sympScore.swellingR === "1"
      ? 3
      : sympScore.swellingR === "2"
      ? 2
      : sympScore.swellingR === "3"
      ? 1
      : sympScore.swellingR === "4"
      ? 0
      : 0;

  const koossSymGrindingR =
    sympScore.grindingR === "0"
      ? 4
      : sympScore.grindingR === "1"
      ? 3
      : sympScore.grindingR === "2"
      ? 2
      : sympScore.grindingR === "3"
      ? 1
      : sympScore.grindingR === "4"
      ? 0
      : 0;

  const koossSymCatchingR =
    sympScore.catchingR === "0"
      ? 4
      : sympScore.catchingR === "1"
      ? 3
      : sympScore.catchingR === "2"
      ? 2
      : sympScore.catchingR === "3"
      ? 1
      : sympScore.catchingR === "4"
      ? 0
      : 0;

  const koossSymAwakeningR =
    sympScore.awakeningR === "0"
      ? 4
      : sympScore.awakeningR === "1"
      ? 3
      : sympScore.awakeningR === "2"
      ? 2
      : sympScore.awakeningR === "3"
      ? 1
      : sympScore.awakeningR === "4"
      ? 0
      : 0;

  const koossSymRestingLaterR =
    sympScore.restingLaterR === "0"
      ? 4
      : sympScore.restingLaterR === "1"
      ? 3
      : sympScore.restingLaterR === "2"
      ? 2
      : sympScore.restingLaterR === "3"
      ? 1
      : sympScore.restingLaterR === "4"
      ? 0
      : 0;

  const koossSymStraighteningR =
    funcScore.straighteningR === "0"
      ? 4
      : funcScore.straighteningR === "1"
      ? 3
      : funcScore.straighteningR === "2"
      ? 2
      : funcScore.straighteningR === "3"
      ? 1
      : funcScore.straighteningR === "4"
      ? 0
      : 0;

  const koossSymBendingKneeR =
    funcScore.bendingKneeR === "0"
      ? 4
      : funcScore.bendingKneeR === "1"
      ? 3
      : funcScore.bendingKneeR === "2"
      ? 2
      : funcScore.bendingKneeR === "3"
      ? 1
      : funcScore.bendingKneeR === "4"
      ? 0
      : 0;

  const koosSymptomsRight = sumAll(
    koossSymSwellingR,
    koossSymGrindingR,
    koossSymCatchingR,
    koossSymAwakeningR,
    koossSymRestingLaterR,
    koossSymStraighteningR,
    koossSymBendingKneeR
  );

  const koossSymSwellingL =
    sympScore.swellingL === "0"
      ? 4
      : sympScore.swellingL === "1"
      ? 3
      : sympScore.swellingL === "2"
      ? 2
      : sympScore.swellingL === "3"
      ? 1
      : sympScore.swellingL === "4"
      ? 0
      : 0;

  const koossSymGrindingL =
    sympScore.grindingL === "0"
      ? 4
      : sympScore.grindingL === "1"
      ? 3
      : sympScore.grindingL === "2"
      ? 2
      : sympScore.grindingL === "3"
      ? 1
      : sympScore.grindingL === "4"
      ? 0
      : 0;

  const koossSymCatchingL =
    sympScore.catchingL === "0"
      ? 4
      : sympScore.catchingL === "1"
      ? 3
      : sympScore.catchingL === "2"
      ? 2
      : sympScore.catchingL === "3"
      ? 1
      : sympScore.catchingL === "4"
      ? 0
      : 0;

  const koossSymAwakeningL =
    sympScore.awakeningL === "0"
      ? 4
      : sympScore.awakeningL === "1"
      ? 3
      : sympScore.awakeningL === "2"
      ? 2
      : sympScore.awakeningL === "3"
      ? 1
      : sympScore.awakeningL === "4"
      ? 0
      : 0;

  const koossSymRestingLaterL =
    sympScore.restingLaterL === "0"
      ? 4
      : sympScore.restingLaterL === "1"
      ? 3
      : sympScore.restingLaterL === "2"
      ? 2
      : sympScore.restingLaterL === "3"
      ? 1
      : sympScore.restingLaterL === "4"
      ? 0
      : 0;

  const koossSymStraighteningL =
    funcScore.straighteningL === "0"
      ? 4
      : funcScore.straighteningL === "1"
      ? 3
      : funcScore.straighteningL === "2"
      ? 2
      : funcScore.straighteningL === "3"
      ? 1
      : funcScore.straighteningL === "4"
      ? 0
      : 0;

  const koossSymBendingKneeL =
    funcScore.bendingKneeL === "0"
      ? 4
      : funcScore.bendingKneeL === "1"
      ? 3
      : funcScore.bendingKneeL === "2"
      ? 2
      : funcScore.bendingKneeL === "3"
      ? 1
      : funcScore.bendingKneeL === "4"
      ? 0
      : 0;

  const koosSymptomsLeft = sumAll(
    koossSymSwellingL,
    koossSymGrindingL,
    koossSymCatchingL,
    koossSymAwakeningL,
    koossSymRestingLaterL,
    koossSymStraighteningL,
    koossSymBendingKneeL
  );
  // Koos Symptoms calculation End

  // Koos Adl calculation starting
  const adlGoUpStairsR =
    funcScore.goUpStairsR === "0"
      ? 4
      : funcScore.goUpStairsR === "1"
      ? 3
      : funcScore.goUpStairsR === "2"
      ? 2
      : funcScore.goUpStairsR === "3"
      ? 1
      : funcScore.goUpStairsR === "4"
      ? 0
      : 0;

  const adlDownStairsR =
    funcScore.goDownStairsR === "0"
      ? 4
      : funcScore.goDownStairsR === "1"
      ? 3
      : funcScore.goDownStairsR === "2"
      ? 2
      : funcScore.goDownStairsR === "3"
      ? 1
      : funcScore.goDownStairsR === "4"
      ? 0
      : 0;

  const adlSitKneeBentR =
    funcScore.sitKneeBentR === "0"
      ? 4
      : funcScore.sitKneeBentR === "1"
      ? 3
      : funcScore.sitKneeBentR === "2"
      ? 2
      : funcScore.sitKneeBentR === "3"
      ? 1
      : funcScore.sitKneeBentR === "4"
      ? 0
      : 0;

  const adlRiseFromChairR =
    funcScore.riseFromChairR === "0"
      ? 4
      : funcScore.riseFromChairR === "1"
      ? 3
      : funcScore.riseFromChairR === "2"
      ? 2
      : funcScore.riseFromChairR === "3"
      ? 1
      : funcScore.riseFromChairR === "4"
      ? 0
      : 0;

  const adlRiseFromBedR =
    funcScore.riseFromBedR === "0"
      ? 4
      : funcScore.riseFromBedR === "1"
      ? 3
      : funcScore.riseFromBedR === "2"
      ? 2
      : funcScore.riseFromBedR === "3"
      ? 1
      : funcScore.riseFromBedR === "4"
      ? 0
      : 0;

  const adlLyingInBedR =
    funcScore.lyingInBedR === "0"
      ? 4
      : funcScore.lyingInBedR === "1"
      ? 3
      : funcScore.lyingInBedR === "2"
      ? 2
      : funcScore.lyingInBedR === "3"
      ? 1
      : funcScore.lyingInBedR === "4"
      ? 0
      : 0;

  const adlBendingToFloorR =
    funcScore.bendingToFloorR === "0"
      ? 4
      : funcScore.bendingToFloorR === "1"
      ? 3
      : funcScore.bendingToFloorR === "2"
      ? 2
      : funcScore.bendingToFloorR === "3"
      ? 1
      : funcScore.bendingToFloorR === "4"
      ? 0
      : 0;

  const adlPuttingOnSocksR =
    funcScore.puttingOnSocksR === "0"
      ? 4
      : funcScore.puttingOnSocksR === "1"
      ? 3
      : funcScore.puttingOnSocksR === "2"
      ? 2
      : funcScore.puttingOnSocksR === "3"
      ? 1
      : funcScore.puttingOnSocksR === "4"
      ? 0
      : 0;

  const adlTakingOffSocksR =
    funcScore.takingOffSocksR === "0"
      ? 4
      : funcScore.takingOffSocksR === "1"
      ? 3
      : funcScore.takingOffSocksR === "2"
      ? 2
      : funcScore.takingOffSocksR === "3"
      ? 1
      : funcScore.takingOffSocksR === "4"
      ? 0
      : 0;

  const adlStandingR =
    funcScore.standingR === "0"
      ? 4
      : funcScore.standingR === "1"
      ? 3
      : funcScore.standingR === "2"
      ? 2
      : funcScore.standingR === "3"
      ? 1
      : funcScore.standingR === "4"
      ? 0
      : 0;

  const adlWalkingR =
    funcScore.walkingR === "0"
      ? 4
      : funcScore.walkingR === "1"
      ? 3
      : funcScore.walkingR === "2"
      ? 2
      : funcScore.walkingR === "3"
      ? 1
      : funcScore.walkingR === "4"
      ? 0
      : 0;

  const adlPublicTransportR =
    funcScore.publicTransportR === "0"
      ? 4
      : funcScore.publicTransportR === "1"
      ? 3
      : funcScore.publicTransportR === "2"
      ? 2
      : funcScore.publicTransportR === "3"
      ? 1
      : funcScore.publicTransportR === "4"
      ? 0
      : 0;

  const adlGettingBathR =
    funcScore.gettingBathR === "0"
      ? 4
      : funcScore.gettingBathR === "1"
      ? 3
      : funcScore.gettingBathR === "2"
      ? 2
      : funcScore.gettingBathR === "3"
      ? 1
      : funcScore.gettingBathR === "4"
      ? 0
      : 0;

  const adlGettingToiletR =
    funcScore.gettingToiletR === "0"
      ? 4
      : funcScore.gettingToiletR === "1"
      ? 3
      : funcScore.gettingToiletR === "2"
      ? 2
      : funcScore.gettingToiletR === "3"
      ? 1
      : funcScore.gettingToiletR === "4"
      ? 0
      : 0;

  const adlShoppingR =
    funcScore.shoppingR === "0"
      ? 4
      : funcScore.shoppingR === "1"
      ? 3
      : funcScore.shoppingR === "2"
      ? 2
      : funcScore.shoppingR === "3"
      ? 1
      : funcScore.shoppingR === "4"
      ? 0
      : 0;

  const adlHeavyDutiesR =
    funcScore.heavyDutiesR === "0"
      ? 4
      : funcScore.heavyDutiesR === "1"
      ? 3
      : funcScore.heavyDutiesR === "2"
      ? 2
      : funcScore.heavyDutiesR === "3"
      ? 1
      : funcScore.heavyDutiesR === "4"
      ? 0
      : 0;

  const adlLightDutiesR =
    funcScore.lightDutiesR === "0"
      ? 4
      : funcScore.lightDutiesR === "1"
      ? 3
      : funcScore.lightDutiesR === "2"
      ? 2
      : funcScore.lightDutiesR === "3"
      ? 1
      : funcScore.lightDutiesR === "4"
      ? 0
      : 0;

  const koosAdlRight = sumAll(
    adlGoUpStairsR,
    adlDownStairsR,
    adlSitKneeBentR,
    adlRiseFromChairR,
    adlRiseFromBedR,
    adlLyingInBedR,
    adlBendingToFloorR,
    adlPuttingOnSocksR,
    adlTakingOffSocksR,
    adlStandingR,
    adlWalkingR,
    adlPublicTransportR,
    adlGettingBathR,
    adlGettingToiletR,
    adlShoppingR,
    adlHeavyDutiesR,
    adlLightDutiesR
  );

  const adlGoUpStairsL =
    funcScore.goUpStairsL === "0"
      ? 4
      : funcScore.goUpStairsL === "1"
      ? 3
      : funcScore.goUpStairsL === "2"
      ? 2
      : funcScore.goUpStairsL === "3"
      ? 1
      : funcScore.goUpStairsL === "4"
      ? 0
      : 0;

  const adlDownStairsL =
    funcScore.goDownStairsL === "0"
      ? 4
      : funcScore.goDownStairsL === "1"
      ? 3
      : funcScore.goDownStairsL === "2"
      ? 2
      : funcScore.goDownStairsL === "3"
      ? 1
      : funcScore.goDownStairsL === "4"
      ? 0
      : 0;

  const adlSitKneeBentL =
    funcScore.sitKneeBentL === "0"
      ? 4
      : funcScore.sitKneeBentL === "1"
      ? 3
      : funcScore.sitKneeBentL === "2"
      ? 2
      : funcScore.sitKneeBentL === "3"
      ? 1
      : funcScore.sitKneeBentL === "4"
      ? 0
      : 0;

  const adlRiseFromChairL =
    funcScore.riseFromChairL === "0"
      ? 4
      : funcScore.riseFromChairL === "1"
      ? 3
      : funcScore.riseFromChairL === "2"
      ? 2
      : funcScore.riseFromChairL === "3"
      ? 1
      : funcScore.riseFromChairL === "4"
      ? 0
      : 0;

  const adlRiseFromBedL =
    funcScore.riseFromBedL === "0"
      ? 4
      : funcScore.riseFromBedL === "1"
      ? 3
      : funcScore.riseFromBedL === "2"
      ? 2
      : funcScore.riseFromBedL === "3"
      ? 1
      : funcScore.riseFromBedL === "4"
      ? 0
      : 0;

  const adlLyingInBedL =
    funcScore.lyingInBedL === "0"
      ? 4
      : funcScore.lyingInBedL === "1"
      ? 3
      : funcScore.lyingInBedL === "2"
      ? 2
      : funcScore.lyingInBedL === "3"
      ? 1
      : funcScore.lyingInBedL === "4"
      ? 0
      : 0;

  const adlBendingToFloorL =
    funcScore.bendingToFloorL === "0"
      ? 4
      : funcScore.bendingToFloorL === "1"
      ? 3
      : funcScore.bendingToFloorL === "2"
      ? 2
      : funcScore.bendingToFloorL === "3"
      ? 1
      : funcScore.bendingToFloorL === "4"
      ? 0
      : 0;

  const adlPuttingOnSocksL =
    funcScore.puttingOnSocksL === "0"
      ? 4
      : funcScore.puttingOnSocksL === "1"
      ? 3
      : funcScore.puttingOnSocksL === "2"
      ? 2
      : funcScore.puttingOnSocksL === "3"
      ? 1
      : funcScore.puttingOnSocksL === "4"
      ? 0
      : 0;

  const adlTakingOffSocksL =
    funcScore.takingOffSocksL === "0"
      ? 4
      : funcScore.takingOffSocksL === "1"
      ? 3
      : funcScore.takingOffSocksL === "2"
      ? 2
      : funcScore.takingOffSocksL === "3"
      ? 1
      : funcScore.takingOffSocksL === "4"
      ? 0
      : 0;

  const adlStandingL =
    funcScore.standingL === "0"
      ? 4
      : funcScore.standingL === "1"
      ? 3
      : funcScore.standingL === "2"
      ? 2
      : funcScore.standingL === "3"
      ? 1
      : funcScore.standingL === "4"
      ? 0
      : 0;

  const adlWalkingL =
    funcScore.walkingL === "0"
      ? 4
      : funcScore.walkingL === "1"
      ? 3
      : funcScore.walkingL === "2"
      ? 2
      : funcScore.walkingL === "3"
      ? 1
      : funcScore.walkingL === "4"
      ? 0
      : 0;

  const adlPublicTransportL =
    funcScore.publicTransportL === "0"
      ? 4
      : funcScore.publicTransportL === "1"
      ? 3
      : funcScore.publicTransportL === "2"
      ? 2
      : funcScore.publicTransportL === "3"
      ? 1
      : funcScore.publicTransportL === "4"
      ? 0
      : 0;

  const adlGettingBathL =
    funcScore.gettingBathL === "0"
      ? 4
      : funcScore.gettingBathL === "1"
      ? 3
      : funcScore.gettingBathL === "2"
      ? 2
      : funcScore.gettingBathL === "3"
      ? 1
      : funcScore.gettingBathL === "4"
      ? 0
      : 0;

  const adlGettingToiletL =
    funcScore.gettingToiletL === "0"
      ? 4
      : funcScore.gettingToiletL === "1"
      ? 3
      : funcScore.gettingToiletL === "2"
      ? 2
      : funcScore.gettingToiletL === "3"
      ? 1
      : funcScore.gettingToiletL === "4"
      ? 0
      : 0;

  const adlShoppingL =
    funcScore.shoppingL === "0"
      ? 4
      : funcScore.shoppingL === "1"
      ? 3
      : funcScore.shoppingL === "2"
      ? 2
      : funcScore.shoppingL === "3"
      ? 1
      : funcScore.shoppingL === "4"
      ? 0
      : 0;

  const adlHeavyDutiesL =
    funcScore.heavyDutiesL === "0"
      ? 4
      : funcScore.heavyDutiesL === "1"
      ? 3
      : funcScore.heavyDutiesL === "2"
      ? 2
      : funcScore.heavyDutiesL === "3"
      ? 1
      : funcScore.heavyDutiesL === "4"
      ? 0
      : 0;

  const adlLightDutiesL =
    funcScore.lightDutiesL === "0"
      ? 4
      : funcScore.lightDutiesL === "1"
      ? 3
      : funcScore.lightDutiesL === "2"
      ? 2
      : funcScore.lightDutiesL === "3"
      ? 1
      : funcScore.lightDutiesL === "4"
      ? 0
      : 0;

  const koosAdlLeft = sumAll(
    adlGoUpStairsL,
    adlDownStairsL,
    adlSitKneeBentL,
    adlRiseFromChairL,
    adlRiseFromBedL,
    adlLyingInBedL,
    adlBendingToFloorL,
    adlPuttingOnSocksL,
    adlTakingOffSocksL,
    adlStandingL,
    adlWalkingL,
    adlPublicTransportL,
    adlGettingBathL,
    adlGettingToiletL,
    adlShoppingL,
    adlHeavyDutiesL,
    adlLightDutiesL
  );

  // Koos Adl calculation Ending

  // Koos sports calculation starting
  const sportKneelFrontR =
    funcScore.kneelFrontR === "0"
      ? 4
      : funcScore.kneelFrontR === "1"
      ? 3
      : funcScore.kneelFrontR === "2"
      ? 2
      : funcScore.kneelFrontR === "3"
      ? 1
      : 0;

  const sportSquatR =
    funcScore.squatR === "0"
      ? 4
      : funcScore.squatR === "1"
      ? 3
      : funcScore.squatR === "2"
      ? 2
      : funcScore.squatR === "3"
      ? 1
      : 0;

  const sportRunStraightAheadR =
    funcScore.runStraightAheadR === "0"
      ? 4
      : funcScore.runStraightAheadR === "1"
      ? 3
      : funcScore.runStraightAheadR === "2"
      ? 2
      : funcScore.runStraightAheadR === "3"
      ? 1
      : 0;

  const sportJumpAndLandR =
    funcScore.jumpAndLandR === "0"
      ? 4
      : funcScore.jumpAndLandR === "1"
      ? 3
      : funcScore.jumpAndLandR === "2"
      ? 2
      : funcScore.jumpAndLandR === "3"
      ? 1
      : 0;

  const sportTwistingR =
    funcScore.twistingR === "0"
      ? 4
      : funcScore.twistingR === "1"
      ? 3
      : funcScore.twistingR === "2"
      ? 2
      : funcScore.twistingR === "3"
      ? 1
      : 0;

  const koosSportsRight = sumAll(
    sportKneelFrontR,
    sportSquatR,
    sportRunStraightAheadR,
    sportJumpAndLandR,
    sportTwistingR
  );

  const sportKneelFrontL =
    funcScore.kneelFrontL === "0"
      ? 4
      : funcScore.kneelFrontL === "1"
      ? 3
      : funcScore.kneelFrontL === "2"
      ? 2
      : funcScore.kneelFrontL === "3"
      ? 1
      : 0;

  const sportSquatL =
    funcScore.squatL === "0"
      ? 4
      : funcScore.squatL === "1"
      ? 3
      : funcScore.squatL === "2"
      ? 2
      : funcScore.squatL === "3"
      ? 1
      : 0;

  const sportRunStraightAheadL =
    funcScore.runStraightAheadL === "0"
      ? 4
      : funcScore.runStraightAheadL === "1"
      ? 3
      : funcScore.runStraightAheadL === "2"
      ? 2
      : funcScore.runStraightAheadL === "3"
      ? 1
      : 0;

  const sportJumpAndLandL =
    funcScore.jumpAndLandL === "0"
      ? 4
      : funcScore.jumpAndLandL === "1"
      ? 3
      : funcScore.jumpAndLandL === "2"
      ? 2
      : funcScore.jumpAndLandL === "3"
      ? 1
      : 0;

  const sportTwistingL =
    funcScore.twistingL === "0"
      ? 4
      : funcScore.twistingL === "1"
      ? 3
      : funcScore.twistingL === "2"
      ? 2
      : funcScore.twistingL === "3"
      ? 1
      : 0;

  const koosSportsLeft = sumAll(
    sportKneelFrontL,
    sportSquatL,
    sportRunStraightAheadL,
    sportJumpAndLandL,
    sportTwistingL
  );
  // Koos sports calculation End

  // Quality of Life calculation Start
  const QOLhowOftenR =
    qualityScore.howOftenR === "0"
      ? 4
      : qualityScore.howOftenR === "1"
      ? 3
      : qualityScore.howOftenR === "2"
      ? 2
      : qualityScore.howOftenR === "3"
      ? 1
      : qualityScore.howOftenR === "4"
      ? 0
      : 0;

  const QOLhowOftenL =
    qualityScore.howOftenL === "0"
      ? 4
      : qualityScore.howOftenL === "1"
      ? 3
      : qualityScore.howOftenL === "2"
      ? 2
      : qualityScore.howOftenL === "3"
      ? 1
      : qualityScore.howOftenL === "4"
      ? 0
      : 0;

  const QOLlifestyleR =
    qualityScore.lifestyleR === "0"
      ? 4
      : qualityScore.lifestyleR === "1"
      ? 3
      : qualityScore.lifestyleR === "2"
      ? 2
      : qualityScore.lifestyleR === "3"
      ? 1
      : qualityScore.lifestyleR === "4"
      ? 0
      : 0;

  const QOLlifestyleL =
    qualityScore.lifestyleL === "0"
      ? 4
      : qualityScore.lifestyleL === "1"
      ? 3
      : qualityScore.lifestyleL === "2"
      ? 2
      : qualityScore.lifestyleL === "3"
      ? 1
      : qualityScore.lifestyleL === "4"
      ? 0
      : 0;

  const QOLconfidenceR =
    qualityScore.confidenceR === "0"
      ? 4
      : qualityScore.confidenceR === "1"
      ? 3
      : qualityScore.confidenceR === "2"
      ? 2
      : qualityScore.confidenceR === "3"
      ? 1
      : qualityScore.confidenceR === "4"
      ? 0
      : 0;

  const QOLconfidenceL =
    qualityScore.confidenceL === "0"
      ? 4
      : qualityScore.confidenceL === "1"
      ? 3
      : qualityScore.confidenceL === "2"
      ? 2
      : qualityScore.confidenceL === "3"
      ? 1
      : qualityScore.confidenceL === "4"
      ? 0
      : 0;

  const QOLdifficultyR =
    qualityScore.difficultyR === "0"
      ? 4
      : qualityScore.difficultyR === "1"
      ? 3
      : qualityScore.difficultyR === "2"
      ? 2
      : qualityScore.difficultyR === "3"
      ? 1
      : qualityScore.difficultyR === "4"
      ? 0
      : 0;

  const QOLdifficultyL =
    qualityScore.difficultyL === "0"
      ? 4
      : qualityScore.difficultyL === "1"
      ? 3
      : qualityScore.difficultyL === "2"
      ? 2
      : qualityScore.difficultyL === "3"
      ? 1
      : qualityScore.difficultyL === "4"
      ? 0
      : 0;

  const qolRight = sumAll(
    QOLhowOftenR,
    QOLlifestyleR,
    QOLconfidenceR,
    QOLdifficultyR
  );

  const qolLeft = sumAll(
    QOLhowOftenL,
    QOLlifestyleL,
    QOLconfidenceL,
    QOLdifficultyL
  );

  // Quality of Life calculation End

  // CKRS calculation Start
  const ckrsBestStatmentForPainR =
    painSco.bestStatmentForPainR === "0"
      ? 0
      : painSco.bestStatmentForPainR === "1"
      ? 4
      : painSco.bestStatmentForPainR === "2"
      ? 8
      : painSco.bestStatmentForPainR === "3"
      ? 12
      : painSco.bestStatmentForPainR === "4"
      ? 16
      : painSco.bestStatmentForPainR === "5"
      ? 20
      : 0;

  const ckrsSignificantSwellingR =
    sympScore.significantSwellingR === "0"
      ? 0
      : sympScore.significantSwellingR === "1"
      ? 2
      : sympScore.significantSwellingR === "2"
      ? 6
      : sympScore.significantSwellingR === "3"
      ? 8
      : sympScore.significantSwellingR === "4"
      ? 10
      : 0;

  const ckrsInstabilityR =
    sympScore.instabilityR === "0"
      ? 0
      : sympScore.instabilityR === "1"
      ? 4
      : sympScore.instabilityR === "2"
      ? 8
      : sympScore.instabilityR === "3"
      ? 12
      : sympScore.instabilityR === "4"
      ? 16
      : sympScore.instabilityR === "5"
      ? 20
      : 0;

  const ckrsGoUpStairsR =
    funcScore.goUpStairsR === "0"
      ? 10
      : funcScore.goUpStairsR === "1"
      ? 8
      : funcScore.goUpStairsR === "2"
      ? 6
      : funcScore.goUpStairsR === "3"
      ? 4
      : funcScore.goUpStairsR === "4"
      ? 2
      : 0;

  const ckrsWalkingR =
    funcScore.walkingR === "0"
      ? 10
      : funcScore.walkingR === "1"
      ? 8
      : funcScore.walkingR === "2"
      ? 6
      : funcScore.walkingR === "3"
      ? 4
      : funcScore.walkingR === "4"
      ? 2
      : 0;

  const ckrsRunStraightAheadR =
    funcScore.runStraightAheadR === "0"
      ? 5
      : funcScore.runStraightAheadR === "1"
      ? 4
      : funcScore.runStraightAheadR === "2"
      ? 3
      : funcScore.runStraightAheadR === "3"
      ? 2
      : funcScore.runStraightAheadR === "4"
      ? 1
      : 0;

  const ckrsJumpAndLandR =
    funcScore.jumpAndLandR === "0"
      ? 5
      : funcScore.jumpAndLandR === "1"
      ? 4
      : funcScore.jumpAndLandR === "2"
      ? 3
      : funcScore.jumpAndLandR === "3"
      ? 2
      : funcScore.jumpAndLandR === "4"
      ? 1
      : 0;

  // const ckrsRateKneeFunctionR = parseFloat(funcScore.rateKneeFunctionR);

  const ckrsActivityLevelR =
    funcScore.activityLevelR === "0"
      ? 0
      : funcScore.activityLevelR === "1"
      ? 8
      : funcScore.activityLevelR === "2"
      ? 12
      : funcScore.activityLevelR === "3"
      ? 16
      : funcScore.activityLevelR === "4"
      ? 20
      : 0;

  const ckrsActivityTwistingR =
    funcScore.twistingR === "0"
      ? 5
      : funcScore.twistingR === "1"
      ? 4
      : funcScore.twistingR === "2"
      ? 3
      : funcScore.twistingR === "3"
      ? 2
      : funcScore.twistingR === "4"
      ? 1
      : 0;

  let maxVAlCkrsJumpTwist = Math.min(
    Number(ckrsJumpAndLandR),
    Number(ckrsActivityTwistingR)
  );
  console.log(funcScore);
  console.log(
    maxVAlCkrsJumpTwist,
    "===========",
    funcScore.jumpAndLandR,
    funcScore.twistingR
  );

  const ckrsRight = sumAll(
    ckrsBestStatmentForPainR,
    ckrsSignificantSwellingR,
    ckrsInstabilityR,
    ckrsGoUpStairsR,
    ckrsWalkingR,
    ckrsRunStraightAheadR,
    maxVAlCkrsJumpTwist,
    ckrsActivityLevelR
  );

  console.log(
    ckrsBestStatmentForPainR,
    ckrsSignificantSwellingR,
    ckrsInstabilityR,
    ckrsGoUpStairsR,
    ckrsWalkingR,
    ckrsRunStraightAheadR,
    maxVAlCkrsJumpTwist,
    ckrsActivityLevelR,
    "ckrsRightRight",
    ckrsRight
  );

  const ckrsBestStatmentForPainL =
    painSco.bestStatmentForPainL === "0"
      ? 0
      : painSco.bestStatmentForPainL === "1"
      ? 4
      : painSco.bestStatmentForPainL === "2"
      ? 8
      : painSco.bestStatmentForPainL === "3"
      ? 12
      : painSco.bestStatmentForPainL === "4"
      ? 16
      : painSco.bestStatmentForPainL === "5"
      ? 20
      : 0;

  const ckrsSignificantSwellingL =
    sympScore.significantSwellingL === "0"
      ? 0
      : sympScore.significantSwellingL === "1"
      ? 2
      : sympScore.significantSwellingL === "2"
      ? 6
      : sympScore.significantSwellingL === "3"
      ? 8
      : sympScore.significantSwellingL === "4"
      ? 10
      : 0;

  const ckrsInstabilityL =
    sympScore.instabilityL === "0"
      ? 0
      : sympScore.instabilityL === "1"
      ? 4
      : sympScore.instabilityL === "2"
      ? 8
      : sympScore.instabilityL === "3"
      ? 12
      : sympScore.instabilityL === "4"
      ? 16
      : sympScore.instabilityL === "5"
      ? 20
      : 0;

  const ckrsGoUpStairsL =
    funcScore.goUpStairsL === "0"
      ? 10
      : funcScore.goUpStairsL === "1"
      ? 8
      : funcScore.goUpStairsL === "2"
      ? 6
      : funcScore.goUpStairsL === "3"
      ? 4
      : funcScore.goUpStairsL === "4"
      ? 2
      : 0;

  const ckrsWalkingL =
    funcScore.walkingL === "0"
      ? 10
      : funcScore.walkingL === "1"
      ? 8
      : funcScore.walkingL === "2"
      ? 6
      : funcScore.walkingL === "3"
      ? 4
      : funcScore.walkingL === "4"
      ? 2
      : 0;

  const ckrsRunStraightAheadL =
    funcScore.runStraightAheadL === "0"
      ? 5
      : funcScore.runStraightAheadL === "1"
      ? 4
      : funcScore.runStraightAheadL === "2"
      ? 3
      : funcScore.runStraightAheadL === "3"
      ? 2
      : funcScore.runStraightAheadL === "4"
      ? 1
      : 0;

  const ckrsJumpAndLandL =
    funcScore.jumpAndLandL === "0"
      ? 5
      : funcScore.jumpAndLandL === "1"
      ? 4
      : funcScore.jumpAndLandL === "2"
      ? 3
      : funcScore.jumpAndLandL === "3"
      ? 2
      : funcScore.jumpAndLandL === "4"
      ? 1
      : 0;

  const ckrsActivityTwistingL =
    funcScore.twistingL === "0"
      ? 5
      : funcScore.twistingL === "1"
      ? 4
      : funcScore.twistingL === "2"
      ? 3
      : funcScore.twistingL === "3"
      ? 2
      : funcScore.twistingL === "4"
      ? 1
      : 0;
  // const ckrsRateKneeFunctionL = parseFloat(funcScore.rateKneeFunctionL);

  const ckrsActivityLevelL =
    funcScore.activityLevelL === "0"
      ? 0
      : funcScore.activityLevelL === "1"
      ? 8
      : funcScore.activityLevelL === "2"
      ? 12
      : funcScore.activityLevelL === "3"
      ? 16
      : funcScore.activityLevelL === "4"
      ? 20
      : 0;

  let maxVAlCkrsJumpTwistL = Math.min(
    Number(ckrsJumpAndLandL),
    Number(ckrsActivityTwistingL)
  );

  console.log(
    ckrsJumpAndLandL,
    ckrsActivityTwistingL,
    "maxL",
    maxVAlCkrsJumpTwistL
  );

  const ckrsLeft = sumAll(
    ckrsBestStatmentForPainL,
    ckrsSignificantSwellingL,
    ckrsInstabilityL,
    ckrsGoUpStairsL,
    ckrsWalkingL,
    ckrsRunStraightAheadL,
    maxVAlCkrsJumpTwistL,
    ckrsActivityLevelL
  );

  console.log(
    ckrsBestStatmentForPainL,
    ckrsSignificantSwellingL,
    ckrsInstabilityL,
    ckrsGoUpStairsL,
    ckrsWalkingL,
    ckrsRunStraightAheadL,
    maxVAlCkrsJumpTwistL,
    ckrsActivityLevelL,
    "ckrsLeftLeft",
    ckrsLeft
  );

  // CKRS calculation End

  //VAS
  const VasScoreR = parseInt(painSco.howSevereR);
  const VasScoreL = parseInt(painSco.howSevereL);

  //EQ-5D-5L DISCOMFORTR
  const EQDISCOMFORTL =
    painSco.howSevereL === "0"
      ? 1
      : painSco.howSevereL === "1"
      ? 2
      : painSco.howSevereL === "2"
      ? 2
      : painSco.howSevereL === "3"
      ? 2
      : painSco.howSevereL === "4"
      ? 3
      : painSco.howSevereL === "5"
      ? 3
      : painSco.howSevereL === "6"
      ? 3
      : painSco.howSevereL === "7"
      ? 4
      : painSco.howSevereL === "8"
      ? 4
      : painSco.howSevereL === "9"
      ? 4
      : painSco.howSevereL === "10"
      ? 5
      : 0;

  const EQDISCOMFORTR =
    painSco.howSevereR === "0"
      ? 1
      : painSco.howSevereR === "1"
      ? 2
      : painSco.howSevereR === "2"
      ? 2
      : painSco.howSevereR === "3"
      ? 2
      : painSco.howSevereR === "4"
      ? 3
      : painSco.howSevereR === "5"
      ? 3
      : painSco.howSevereR === "6"
      ? 3
      : painSco.howSevereR === "7"
      ? 4
      : painSco.howSevereR === "8"
      ? 4
      : painSco.howSevereR === "9"
      ? 4
      : painSco.howSevereR === "10"
      ? 5
      : 0;

  //EQ-5D-5L MOBILITY
  const EQMOBILITYR =
    funcScore.walkingR === "0"
      ? 1
      : funcScore.walkingR === "1"
      ? 2
      : funcScore.walkingR === "2"
      ? 3
      : funcScore.walkingR === "3"
      ? 4
      : funcScore.walkingR === "4"
      ? 5
      : 0;

  const EQMOBILITYL =
    funcScore.walkingL === "0"
      ? 1
      : funcScore.walkingL === "1"
      ? 2
      : funcScore.walkingL === "2"
      ? 3
      : funcScore.walkingL === "3"
      ? 4
      : funcScore.walkingL === "4"
      ? 5
      : 0;

  //EQ-5D-5L SELF-CARE
  const EQCARER =
    funcScore.washingR === "0"
      ? 1
      : funcScore.washingR === "1"
      ? 2
      : funcScore.washingR === "2"
      ? 3
      : funcScore.washingR === "3"
      ? 4
      : funcScore.washingR === "4"
      ? 5
      : 0;

  const EQCAREL =
    funcScore.washingL === "0"
      ? 1
      : funcScore.washingL === "1"
      ? 2
      : funcScore.washingL === "2"
      ? 3
      : funcScore.washingL === "3"
      ? 4
      : funcScore.washingL === "4"
      ? 5
      : 0;

  //EQ-5D-5L USUAL ACTIVITIES
  const EQACTIVITIESR =
    qualityScore.difficultyR === "0"
      ? 1
      : qualityScore.difficultyR === "1"
      ? 2
      : qualityScore.difficultyR === "2"
      ? 3
      : qualityScore.difficultyR === "3"
      ? 4
      : qualityScore.difficultyR === "4"
      ? 5
      : 0;

  const EQACTIVITIESL =
    qualityScore.difficultyL === "0"
      ? 1
      : qualityScore.difficultyL === "1"
      ? 2
      : qualityScore.difficultyL === "2"
      ? 3
      : qualityScore.difficultyL === "3"
      ? 4
      : qualityScore.difficultyL === "4"
      ? 5
      : 0;

  //EQ-5D-5L ANXIETY / DEPRESSION
  const EQANXIETYR =
    qualityScore.anxious === "0"
      ? 1
      : qualityScore.anxious === "1"
      ? 2
      : qualityScore.anxious === "2"
      ? 3
      : qualityScore.anxious === "3"
      ? 4
      : qualityScore.anxious === "4"
      ? 5
      : 0;

  //EQ VAS
  const EQ_VAS = parseInt(qualityScore.howGood);
  // const EQ_VASL = parseInt(qualityScore.howGoodL)

  //SAPS_OVERALL
  const SAPS_OVERALLR =
    satisScore.overallR === "1"
      ? 25
      : satisScore.overallR === "2"
      ? 50
      : satisScore.overallR === "3"
      ? 75
      : satisScore.overallR === "4"
      ? 100
      : 0;

  const SAPS_OVERALLL =
    satisScore.overallL === "1"
      ? 25
      : satisScore.overallL === "2"
      ? 50
      : satisScore.overallL === "3"
      ? 75
      : satisScore.overallL === "4"
      ? 100
      : 0;

  //SAPS_Pain
  const SAPS_PAINR =
    satisScore.improvingPainR === "1"
      ? 25
      : satisScore.improvingPainR === "2"
      ? 50
      : satisScore.improvingPainR === "3"
      ? 75
      : satisScore.improvingPainR === "4"
      ? 100
      : 0;

  const SAPS_PAINL =
    satisScore.improvingPainL === "1"
      ? 25
      : satisScore.improvingPainL === "2"
      ? 50
      : satisScore.improvingPainL === "3"
      ? 75
      : satisScore.improvingPainL === "4"
      ? 100
      : 0;

  //SAPS_Work

  const SAPS_WORKR =
    satisScore.improvingHomeR === "1"
      ? 25
      : satisScore.improvingHomeR === "2"
      ? 50
      : satisScore.improvingHomeR === "3"
      ? 75
      : satisScore.improvingHomeR === "4"
      ? 100
      : 0;

  const SAPS_WORKL =
    satisScore.improvingHomeL === "1"
      ? 25
      : satisScore.improvingHomeL === "2"
      ? 50
      : satisScore.improvingHomeL === "3"
      ? 75
      : satisScore.improvingHomeL === "4"
      ? 100
      : 0;

  //SAPS_Recreation
  const SAPS_RECREATIONR =
    satisScore.improvingActivitiesR === "1"
      ? 25
      : satisScore.improvingActivitiesR === "2"
      ? 50
      : satisScore.improvingActivitiesR === "3"
      ? 75
      : satisScore.improvingActivitiesR === "4"
      ? 100
      : 0;

  const SAPS_RECREATIONL =
    satisScore.improvingActivitiesL === "1"
      ? 25
      : satisScore.improvingActivitiesL === "2"
      ? 50
      : satisScore.improvingActivitiesL === "3"
      ? 75
      : satisScore.improvingActivitiesL === "4"
      ? 100
      : 0;

  //SANE
  const SANE_R = mapValueToPercentage(funcScore.rateKneeFunctionR);
  const SANE_L = mapValueToPercentage(funcScore.rateKneeFunctionL);

  // LYSHOLM calculation Start
  const lysholmBestStatmentForPainR =
    painSco.bestStatmentForPainR === "0"
      ? 0
      : painSco.bestStatmentForPainR === "1"
      ? 5
      : painSco.bestStatmentForPainR === "2"
      ? 10
      : painSco.bestStatmentForPainR === "3"
      ? 15
      : painSco.bestStatmentForPainR === "4"
      ? 20
      : painSco.bestStatmentForPainR === "5"
      ? 25
      : 0;

  const lysholmSwellingR =
    sympScore.swellingR === "0"
      ? 10
      : sympScore.swellingR === "1"
      ? 6
      : sympScore.swellingR === "2"
      ? 2
      : sympScore.swellingR === "3"
      ? 0
      : sympScore.swellingR === "4"
      ? 0
      : 0;

  const lysholmCatchingR =
    sympScore.catchingR === "0"
      ? 15
      : sympScore.catchingR === "1"
      ? 10
      : sympScore.catchingR === "2"
      ? 6
      : sympScore.catchingR === "3"
      ? 2
      : sympScore.catchingR === "4"
      ? 0
      : 0;

  const lysholmLimpingR =
    sympScore.limpingR === "0"
      ? 5
      : sympScore.limpingR === "1"
      ? 3
      : sympScore.limpingR === "2"
      ? 3
      : sympScore.limpingR === "3"
      ? 0
      : sympScore.limpingR === "4"
      ? 0
      : 0;

  const lysholmInstabilityR =
    sympScore.instabilityR === "0"
      ? 0
      : sympScore.instabilityR === "1"
      ? 5
      : sympScore.instabilityR === "2"
      ? 10
      : sympScore.instabilityR === "3"
      ? 15
      : sympScore.instabilityR === "4"
      ? 20
      : sympScore.instabilityR === "5"
      ? 25
      : 0;

  const lysholmSupportR =
    sympScore.supportR === "0"
      ? 5
      : sympScore.supportR === "1"
      ? 2
      : sympScore.supportR === "2"
      ? 0
      : 0;

  const lysholmGoUpStairsR =
    funcScore.goUpStairsR === "0"
      ? 10
      : funcScore.goUpStairsR === "1"
      ? 6
      : funcScore.goUpStairsR === "2"
      ? 2
      : funcScore.goUpStairsR === "3"
      ? 2
      : funcScore.goUpStairsR === "4"
      ? 0
      : 0;

  const lysholmSquatR =
    funcScore.squatR === "0"
      ? 5
      : funcScore.squatR === "1"
      ? 4
      : funcScore.squatR === "2"
      ? 2
      : funcScore.squatR === "3"
      ? 2
      : funcScore.squatR === "4"
      ? 0
      : 0;

  const lysholmRight = sumAll(
    lysholmBestStatmentForPainR,
    lysholmSwellingR,
    lysholmCatchingR,
    lysholmLimpingR,
    lysholmInstabilityR,
    lysholmSupportR,
    lysholmGoUpStairsR,
    lysholmSquatR
  );

  const lysholmBestStatmentForPainL =
    painSco.bestStatmentForPainL === "0"
      ? 0
      : painSco.bestStatmentForPainL === "1"
      ? 5
      : painSco.bestStatmentForPainL === "2"
      ? 10
      : painSco.bestStatmentForPainL === "3"
      ? 15
      : painSco.bestStatmentForPainL === "4"
      ? 20
      : painSco.bestStatmentForPainL === "5"
      ? 25
      : 0;

  const lysholmSwellingL =
    sympScore.swellingL === "0"
      ? 10
      : sympScore.swellingL === "1"
      ? 6
      : sympScore.swellingL === "2"
      ? 2
      : sympScore.swellingL === "3"
      ? 0
      : sympScore.swellingL === "4"
      ? 0
      : 0;

  const lysholmCatchingL =
    sympScore.catchingL === "0"
      ? 15
      : sympScore.catchingL === "1"
      ? 10
      : sympScore.catchingL === "2"
      ? 6
      : sympScore.catchingL === "3"
      ? 2
      : sympScore.catchingL === "4"
      ? 0
      : 0;

  const lysholmLimpingL =
    sympScore.limpingL === "0"
      ? 5
      : sympScore.limpingL === "1"
      ? 3
      : sympScore.limpingL === "2"
      ? 3
      : sympScore.limpingL === "3"
      ? 0
      : sympScore.limpingL === "4"
      ? 0
      : 0;

  const lysholmInstabilityL =
    sympScore.instabilityL === "0"
      ? 0
      : sympScore.instabilityL === "1"
      ? 5
      : sympScore.instabilityL === "2"
      ? 10
      : sympScore.instabilityL === "3"
      ? 15
      : sympScore.instabilityL === "4"
      ? 20
      : sympScore.instabilityL === "5"
      ? 25
      : 0;

  const lysholmSupportL =
    sympScore.supportL === "0"
      ? 5
      : sympScore.supportL === "1"
      ? 2
      : sympScore.supportL === "2"
      ? 0
      : 0;

  const lysholmGoUpStairsL =
    funcScore.goUpStairsL === "0"
      ? 10
      : funcScore.goUpStairsL === "1"
      ? 6
      : funcScore.goUpStairsL === "2"
      ? 2
      : funcScore.goUpStairsL === "3"
      ? 2
      : funcScore.goUpStairsL === "4"
      ? 0
      : 0;

  const lysholmSquatL =
    funcScore.squatL === "0"
      ? 5
      : funcScore.squatL === "1"
      ? 4
      : funcScore.squatL === "2"
      ? 2
      : funcScore.squatL === "3"
      ? 2
      : funcScore.squatL === "4"
      ? 0
      : 0;

  const lysholmLeft = sumAll(
    lysholmBestStatmentForPainL,
    lysholmSwellingL,
    lysholmCatchingL,
    lysholmLimpingL,
    lysholmInstabilityL,
    lysholmSupportL,
    lysholmGoUpStairsL,
    lysholmSquatL
  );

  // LYSHOLM calculation End

  // OKS PAIN calculation Start
  const oksPainHowSevereR =
    painSco.howSevereR === "0"
      ? 4
      : painSco.howSevereR === "1"
      ? 3
      : painSco.howSevereR === "2"
      ? 3
      : painSco.howSevereR === "3"
      ? 3
      : painSco.howSevereR === "4"
      ? 2
      : painSco.howSevereR === "5"
      ? 2
      : painSco.howSevereR === "6"
      ? 2
      : painSco.howSevereR === "7"
      ? 1
      : painSco.howSevereR === "8"
      ? 1
      : painSco.howSevereR === "9"
      ? 1
      : painSco.howSevereR === "10"
      ? 0
      : 0;

  const oksPainKneeInterferedR =
    painSco.kneeInterferedR === "0"
      ? 4
      : painSco.kneeInterferedR === "1"
      ? 3
      : painSco.kneeInterferedR === "2"
      ? 2
      : painSco.kneeInterferedR === "3"
      ? 1
      : painSco.kneeInterferedR === "4"
      ? 0
      : 0;

  const oksPainPainAtNightR =
    painSco.painAtNightR === "0"
      ? 0
      : painSco.painAtNightR === "1"
      ? 1
      : painSco.painAtNightR === "2"
      ? 2
      : painSco.painAtNightR === "3"
      ? 3
      : painSco.painAtNightR === "4"
      ? 4
      : 0;

  const oksPainHowLongWalkR = parseFloat(painSco.howLongWalkR);

  const oksPainStandingFromChairR =
    painSco.standingFromChairR === "0"
      ? 4
      : painSco.standingFromChairR === "1"
      ? 3
      : painSco.standingFromChairR === "2"
      ? 2
      : painSco.standingFromChairR === "3"
      ? 1
      : painSco.standingFromChairR === "4"
      ? 0
      : 0;

  const oksPainLimpingR =
    sympScore.limpingR === "0"
      ? 4
      : sympScore.limpingR === "1"
      ? 3
      : sympScore.limpingR === "2"
      ? 2
      : sympScore.limpingR === "3"
      ? 1
      : sympScore.limpingR === "4"
      ? 0
      : 0;

  const oksPainInstabilityR =
    sympScore.instabilityR === "0"
      ? 0
      : sympScore.instabilityR === "1"
      ? 1
      : sympScore.instabilityR === "2"
      ? 2
      : sympScore.instabilityR === "3"
      ? 2
      : sympScore.instabilityR === "4"
      ? 3
      : sympScore.instabilityR === "5"
      ? 4
      : 0;

  const oksPainRight = sumAll(
    oksPainHowSevereR,
    oksPainKneeInterferedR,
    oksPainHowLongWalkR,
    oksPainPainAtNightR,
    oksPainStandingFromChairR,
    oksPainLimpingR,
    oksPainInstabilityR
  );

  const oksPainHowSevereL =
    painSco.howSevereL === "0"
      ? 4
      : painSco.howSevereL === "1"
      ? 3
      : painSco.howSevereL === "2"
      ? 3
      : painSco.howSevereL === "3"
      ? 3
      : painSco.howSevereL === "4"
      ? 2
      : painSco.howSevereL === "5"
      ? 2
      : painSco.howSevereL === "6"
      ? 2
      : painSco.howSevereL === "7"
      ? 1
      : painSco.howSevereL === "8"
      ? 1
      : painSco.howSevereL === "9"
      ? 1
      : painSco.howSevereL === "10"
      ? 0
      : 0;

  const oksPainKneeInterferedL =
    painSco.kneeInterferedL === "0"
      ? 4
      : painSco.kneeInterferedL === "1"
      ? 3
      : painSco.kneeInterferedL === "2"
      ? 2
      : painSco.kneeInterferedL === "3"
      ? 1
      : painSco.kneeInterferedL === "4"
      ? 0
      : 0;

  const oksPainPainAtNightL =
    painSco.painAtNightL === "0"
      ? 0
      : painSco.painAtNightL === "1"
      ? 1
      : painSco.painAtNightL === "2"
      ? 2
      : painSco.painAtNightL === "3"
      ? 3
      : painSco.painAtNightL === "4"
      ? 4
      : 0;

  const oksPainHowLongWalkL = parseFloat(painSco.howLongWalkL);

  const oksPainStandingFromChairL =
    painSco.standingFromChairL === "0"
      ? 4
      : painSco.standingFromChairL === "1"
      ? 3
      : painSco.standingFromChairL === "2"
      ? 2
      : painSco.standingFromChairL === "3"
      ? 1
      : painSco.standingFromChairL === "4"
      ? 0
      : 0;

  const oksPainLimpingL =
    sympScore.limpingL === "0"
      ? 4
      : sympScore.limpingL === "1"
      ? 3
      : sympScore.limpingL === "2"
      ? 2
      : sympScore.limpingL === "3"
      ? 1
      : sympScore.limpingL === "4"
      ? 0
      : 0;

  const oksPainInstabilityL =
    sympScore.instabilityL === "0"
      ? 0
      : sympScore.instabilityL === "1"
      ? 1
      : sympScore.instabilityL === "2"
      ? 2
      : sympScore.instabilityL === "3"
      ? 2
      : sympScore.instabilityL === "4"
      ? 3
      : sympScore.instabilityL === "5"
      ? 4
      : 0;

  const oksPainLeft = sumAll(
    oksPainHowSevereL,
    oksPainKneeInterferedL,
    oksPainHowLongWalkL,
    oksPainPainAtNightL,
    oksPainStandingFromChairL,
    oksPainLimpingL,
    oksPainInstabilityL
  );

  // OKS PAIN calculation End

  // OKS Function calculation Start
  const oksFuncGoDownStairsR =
    funcScore.goDownStairsR === "0"
      ? 4
      : funcScore.goDownStairsR === "1"
      ? 3
      : funcScore.goDownStairsR === "2"
      ? 2
      : funcScore.goDownStairsR === "3"
      ? 1
      : funcScore.goDownStairsR === "4"
      ? 0
      : 0;

  const oksFuncKneelFrontR =
    funcScore.kneelFrontR === "0"
      ? 4
      : funcScore.kneelFrontR === "1"
      ? 3
      : funcScore.kneelFrontR === "2"
      ? 2
      : funcScore.kneelFrontR === "3"
      ? 1
      : funcScore.kneelFrontR === "4"
      ? 0
      : 0;

  const oksFuncPublicTransportR =
    funcScore.publicTransportR === "0"
      ? 4
      : funcScore.publicTransportR === "1"
      ? 3
      : funcScore.publicTransportR === "2"
      ? 2
      : funcScore.publicTransportR === "3"
      ? 1
      : funcScore.publicTransportR === "4"
      ? 0
      : 0;

  const oksFuncWashingR =
    funcScore.washingR === "0"
      ? 4
      : funcScore.washingR === "1"
      ? 3
      : funcScore.washingR === "2"
      ? 2
      : funcScore.washingR === "3"
      ? 1
      : funcScore.washingR === "4"
      ? 0
      : 0;

  const oksFuncShoppingR =
    funcScore.shoppingR === "0"
      ? 4
      : funcScore.shoppingR === "1"
      ? 3
      : funcScore.shoppingR === "2"
      ? 2
      : funcScore.shoppingR === "3"
      ? 1
      : funcScore.shoppingR === "4"
      ? 0
      : 0;

  const oksFuncRight = sumAll(
    oksFuncGoDownStairsR,
    oksFuncKneelFrontR,
    oksFuncPublicTransportR,
    oksFuncWashingR,
    oksFuncShoppingR
  );

  const oksFuncGoDownStairsL =
    funcScore.goDownStairsL === "0"
      ? 4
      : funcScore.goDownStairsL === "1"
      ? 3
      : funcScore.goDownStairsL === "2"
      ? 2
      : funcScore.goDownStairsL === "3"
      ? 1
      : funcScore.goDownStairsL === "4"
      ? 0
      : 0;

  const oksFuncKneelFrontL =
    funcScore.kneelFrontL === "0"
      ? 4
      : funcScore.kneelFrontL === "1"
      ? 3
      : funcScore.kneelFrontL === "2"
      ? 2
      : funcScore.kneelFrontL === "3"
      ? 1
      : funcScore.kneelFrontL === "4"
      ? 0
      : 0;

  const oksFuncPublicTransportL =
    funcScore.publicTransportL === "0"
      ? 4
      : funcScore.publicTransportL === "1"
      ? 3
      : funcScore.publicTransportL === "2"
      ? 2
      : funcScore.publicTransportL === "3"
      ? 1
      : funcScore.publicTransportL === "4"
      ? 0
      : 0;

  const oksFuncWashingL =
    funcScore.washingL === "0"
      ? 4
      : funcScore.washingL === "1"
      ? 3
      : funcScore.washingL === "2"
      ? 2
      : funcScore.washingL === "3"
      ? 1
      : funcScore.washingL === "4"
      ? 0
      : 0;

  const oksFuncShoppingL =
    funcScore.shoppingL === "0"
      ? 4
      : funcScore.shoppingL === "1"
      ? 3
      : funcScore.shoppingL === "2"
      ? 2
      : funcScore.shoppingL === "3"
      ? 1
      : funcScore.shoppingL === "4"
      ? 0
      : 0;

  const oksFuncLeft = sumAll(
    oksFuncGoDownStairsL,
    oksFuncKneelFrontL,
    oksFuncPublicTransportL,
    oksFuncWashingL,
    oksFuncShoppingL
  );

  // OKS Function calculation End

  // OKS Overall

  const oksOverallR = sumAll(oksFuncRight, oksPainRight);
  const oksOverallL = sumAll(oksFuncLeft, oksPainLeft);

  // WOMAC PAIN calculation Start

  const womacWalkingSurfaceR =
    painSco.walkingSurfaceR === "0"
      ? 4
      : painSco.walkingSurfaceR === "1"
      ? 3
      : painSco.walkingSurfaceR === "2"
      ? 2
      : painSco.walkingSurfaceR === "3"
      ? 1
      : painSco.walkingSurfaceR === "4"
      ? 0
      : 0;

  const womacUseStepsR =
    painSco.useStepsR === "0"
      ? 4
      : painSco.useStepsR === "1"
      ? 3
      : painSco.useStepsR === "2"
      ? 2
      : painSco.useStepsR === "3"
      ? 1
      : painSco.useStepsR === "4"
      ? 0
      : 0;

  const womacWhileBedR =
    painSco.whileBedR === "0"
      ? 4
      : painSco.whileBedR === "1"
      ? 3
      : painSco.whileBedR === "2"
      ? 2
      : painSco.whileBedR === "3"
      ? 1
      : painSco.whileBedR === "4"
      ? 0
      : 0;

  const womacSittingR =
    painSco.sittingR === "0"
      ? 4
      : painSco.sittingR === "1"
      ? 3
      : painSco.sittingR === "2"
      ? 2
      : painSco.sittingR === "3"
      ? 1
      : painSco.sittingR === "4"
      ? 0
      : 0;

  const womacStandingR =
    painSco.standingR === "0"
      ? 4
      : painSco.standingR === "1"
      ? 3
      : painSco.standingR === "2"
      ? 2
      : painSco.standingR === "3"
      ? 1
      : painSco.standingR === "4"
      ? 0
      : 0;
  const womacPainRight = sumAll(
    womacWalkingSurfaceR,
    womacUseStepsR,
    womacWhileBedR,
    womacSittingR,
    womacStandingR
  );

  const womacWalkingSurfaceL =
    painSco.walkingSurfaceL === "0"
      ? 4
      : painSco.walkingSurfaceL === "1"
      ? 3
      : painSco.walkingSurfaceL === "2"
      ? 2
      : painSco.walkingSurfaceL === "3"
      ? 1
      : painSco.walkingSurfaceL === "4"
      ? 0
      : 0;

  const womacUseStepsL =
    painSco.useStepsL === "0"
      ? 4
      : painSco.useStepsL === "1"
      ? 3
      : painSco.useStepsL === "2"
      ? 2
      : painSco.useStepsL === "3"
      ? 1
      : painSco.useStepsL === "4"
      ? 0
      : 0;

  const womacWhileBedL =
    painSco.whileBedL === "0"
      ? 4
      : painSco.whileBedL === "1"
      ? 3
      : painSco.whileBedL === "2"
      ? 2
      : painSco.whileBedL === "3"
      ? 1
      : painSco.whileBedL === "4"
      ? 0
      : 0;

  const womacSittingL =
    painSco.sittingL === "0"
      ? 4
      : painSco.sittingL === "1"
      ? 3
      : painSco.sittingL === "2"
      ? 2
      : painSco.sittingL === "3"
      ? 1
      : painSco.sittingL === "4"
      ? 0
      : 0;

  const womacStandingL =
    painSco.standingL === "0"
      ? 4
      : painSco.standingL === "1"
      ? 3
      : painSco.standingL === "2"
      ? 2
      : painSco.standingL === "3"
      ? 1
      : painSco.standingL === "4"
      ? 0
      : 0;
  const womacPainLeft = sumAll(
    womacWalkingSurfaceL,
    womacUseStepsL,
    womacWhileBedL,
    womacSittingL,
    womacStandingL
  );

  // WOMAC PAIN calculation End

  // WOMAC Stiffness calculation Start

  const womacStiffAwakeningR =
    sympScore.awakeningR === "0"
      ? 4
      : sympScore.awakeningR === "1"
      ? 3
      : sympScore.awakeningR === "2"
      ? 2
      : sympScore.awakeningR === "3"
      ? 1
      : sympScore.awakeningR === "4"
      ? 0
      : 0;

  const womacStiffRestingLaterR =
    sympScore.restingLaterR === "0"
      ? 4
      : sympScore.restingLaterR === "1"
      ? 3
      : sympScore.restingLaterR === "2"
      ? 2
      : sympScore.restingLaterR === "3"
      ? 1
      : sympScore.restingLaterR === "4"
      ? 0
      : 0;

  const womacStiffRight = sumAll(womacStiffAwakeningR, womacStiffRestingLaterR);

  const womacStiffAwakeningL =
    sympScore.awakeningL === "0"
      ? 4
      : sympScore.awakeningL === "1"
      ? 3
      : sympScore.awakeningL === "2"
      ? 2
      : sympScore.awakeningL === "3"
      ? 1
      : sympScore.awakeningL === "4"
      ? 0
      : 0;

  const womacStiffRestingLaterL =
    sympScore.restingLaterL === "0"
      ? 4
      : sympScore.restingLaterL === "1"
      ? 3
      : sympScore.restingLaterL === "2"
      ? 2
      : sympScore.restingLaterL === "3"
      ? 1
      : sympScore.restingLaterL === "4"
      ? 0
      : 0;

  const womacStiffLeft = sumAll(womacStiffAwakeningL, womacStiffRestingLaterL);

  // WOMAC Stiffness calculation End

  // WOMAC Function calculation Start
  const womacFuncGoUpStairsR =
    funcScore.goUpStairsR === "0"
      ? 4
      : funcScore.goUpStairsR === "1"
      ? 3
      : funcScore.goUpStairsR === "2"
      ? 2
      : funcScore.goUpStairsR === "3"
      ? 1
      : funcScore.goUpStairsR === "4"
      ? 0
      : 0;
  const womacFuncGoDownStairsR =
    funcScore.goDownStairsR === "0"
      ? 4
      : funcScore.goDownStairsR === "1"
      ? 3
      : funcScore.goDownStairsR === "2"
      ? 2
      : funcScore.goDownStairsR === "3"
      ? 1
      : funcScore.goDownStairsR === "4"
      ? 0
      : 0;
  const womacFuncSitKneeBentR =
    funcScore.sitKneeBentR === "0"
      ? 4
      : funcScore.sitKneeBentR === "1"
      ? 3
      : funcScore.sitKneeBentR === "2"
      ? 2
      : funcScore.sitKneeBentR === "3"
      ? 1
      : funcScore.sitKneeBentR === "4"
      ? 0
      : 0;
  const womacFuncRiseFromChairR =
    funcScore.riseFromChairR === "0"
      ? 4
      : funcScore.riseFromChairR === "1"
      ? 3
      : funcScore.riseFromChairR === "2"
      ? 2
      : funcScore.riseFromChairR === "3"
      ? 1
      : funcScore.riseFromChairR === "4"
      ? 0
      : 0;
  const womacFuncRiseFromBedR =
    funcScore.riseFromBedR === "0"
      ? 4
      : funcScore.riseFromBedR === "1"
      ? 3
      : funcScore.riseFromBedR === "2"
      ? 2
      : funcScore.riseFromBedR === "3"
      ? 1
      : funcScore.riseFromBedR === "4"
      ? 0
      : 0;
  const womacFuncLyingInBedR =
    funcScore.lyingInBedR === "0"
      ? 4
      : funcScore.lyingInBedR === "1"
      ? 3
      : funcScore.lyingInBedR === "2"
      ? 2
      : funcScore.lyingInBedR === "3"
      ? 1
      : funcScore.lyingInBedR === "4"
      ? 0
      : 0;
  const womacFuncBendingToFloorR =
    funcScore.bendingToFloorR === "0"
      ? 4
      : funcScore.bendingToFloorR === "1"
      ? 3
      : funcScore.bendingToFloorR === "2"
      ? 2
      : funcScore.bendingToFloorR === "3"
      ? 1
      : funcScore.bendingToFloorR === "4"
      ? 0
      : 0;
  const womacFuncPuttingOnSocksR =
    funcScore.puttingOnSocksR === "0"
      ? 4
      : funcScore.puttingOnSocksR === "1"
      ? 3
      : funcScore.puttingOnSocksR === "2"
      ? 2
      : funcScore.puttingOnSocksR === "3"
      ? 1
      : funcScore.puttingOnSocksR === "4"
      ? 0
      : 0;
  const womacFuncTakingOffSocksR =
    funcScore.takingOffSocksR === "0"
      ? 4
      : funcScore.takingOffSocksR === "1"
      ? 3
      : funcScore.takingOffSocksR === "2"
      ? 2
      : funcScore.takingOffSocksR === "3"
      ? 1
      : funcScore.takingOffSocksR === "4"
      ? 0
      : 0;
  const womacFuncStandingR =
    funcScore.standingR === "0"
      ? 4
      : funcScore.standingR === "1"
      ? 3
      : funcScore.standingR === "2"
      ? 2
      : funcScore.standingR === "3"
      ? 1
      : funcScore.standingR === "4"
      ? 0
      : 0;
  const womacFuncWalkingR =
    funcScore.walkingR === "0"
      ? 4
      : funcScore.walkingR === "1"
      ? 3
      : funcScore.walkingR === "2"
      ? 2
      : funcScore.walkingR === "3"
      ? 1
      : funcScore.walkingR === "4"
      ? 0
      : 0;
  const womacFuncPublicTransportR =
    funcScore.publicTransportR === "0"
      ? 4
      : funcScore.publicTransportR === "1"
      ? 3
      : funcScore.publicTransportR === "2"
      ? 2
      : funcScore.publicTransportR === "3"
      ? 1
      : funcScore.publicTransportR === "4"
      ? 0
      : 0;
  const womacFuncGettingBathR =
    funcScore.gettingBathR === "0"
      ? 4
      : funcScore.gettingBathR === "1"
      ? 3
      : funcScore.gettingBathR === "2"
      ? 2
      : funcScore.gettingBathR === "3"
      ? 1
      : funcScore.gettingBathR === "4"
      ? 0
      : 0;
  const womacFuncGettingToiletR =
    funcScore.gettingToiletR === "0"
      ? 4
      : funcScore.gettingToiletR === "1"
      ? 3
      : funcScore.gettingToiletR === "2"
      ? 2
      : funcScore.gettingToiletR === "3"
      ? 1
      : funcScore.gettingToiletR === "4"
      ? 0
      : 0;
  const womacFuncShoppingR =
    funcScore.shoppingR === "0"
      ? 4
      : funcScore.shoppingR === "1"
      ? 3
      : funcScore.shoppingR === "2"
      ? 2
      : funcScore.shoppingR === "3"
      ? 1
      : funcScore.shoppingR === "4"
      ? 0
      : 0;
  const womacFuncHeavyDutiesR =
    funcScore.heavyDutiesR === "0"
      ? 4
      : funcScore.heavyDutiesR === "1"
      ? 3
      : funcScore.heavyDutiesR === "2"
      ? 2
      : funcScore.heavyDutiesR === "3"
      ? 1
      : funcScore.heavyDutiesR === "4"
      ? 0
      : 0;
  const womacFuncLightDutiesR =
    funcScore.lightDutiesR === "0"
      ? 4
      : funcScore.lightDutiesR === "1"
      ? 3
      : funcScore.lightDutiesR === "2"
      ? 2
      : funcScore.lightDutiesR === "3"
      ? 1
      : funcScore.lightDutiesR === "4"
      ? 0
      : 0;

  const womacFuncRight = sumAll(
    womacFuncGoUpStairsR,
    womacFuncGoDownStairsR,
    womacFuncSitKneeBentR,
    womacFuncRiseFromChairR,
    womacFuncRiseFromBedR,
    womacFuncLyingInBedR,
    womacFuncBendingToFloorR,
    womacFuncPuttingOnSocksR,
    womacFuncTakingOffSocksR,
    womacFuncStandingR,
    womacFuncWalkingR,
    womacFuncPublicTransportR,
    womacFuncGettingBathR,
    womacFuncGettingToiletR,
    womacFuncShoppingR,
    womacFuncHeavyDutiesR,
    womacFuncLightDutiesR
  );

  const womacFuncGoUpStairsL =
    funcScore.goUpStairsL === "0"
      ? 4
      : funcScore.goUpStairsL === "1"
      ? 3
      : funcScore.goUpStairsL === "2"
      ? 2
      : funcScore.goUpStairsL === "3"
      ? 1
      : funcScore.goUpStairsL === "4"
      ? 0
      : 0;
  const womacFuncGoDownStairsL =
    funcScore.goDownStairsL === "0"
      ? 4
      : funcScore.goDownStairsL === "1"
      ? 3
      : funcScore.goDownStairsL === "2"
      ? 2
      : funcScore.goDownStairsL === "3"
      ? 1
      : funcScore.goDownStairsL === "4"
      ? 0
      : 0;
  const womacFuncSitKneeBentL =
    funcScore.sitKneeBentL === "0"
      ? 4
      : funcScore.sitKneeBentL === "1"
      ? 3
      : funcScore.sitKneeBentL === "2"
      ? 2
      : funcScore.sitKneeBentL === "3"
      ? 1
      : funcScore.sitKneeBentL === "4"
      ? 0
      : 0;
  const womacFuncRiseFromChairL =
    funcScore.riseFromChairL === "0"
      ? 4
      : funcScore.riseFromChairL === "1"
      ? 3
      : funcScore.riseFromChairL === "2"
      ? 2
      : funcScore.riseFromChairL === "3"
      ? 1
      : funcScore.riseFromChairL === "4"
      ? 0
      : 0;
  const womacFuncRiseFromBedL =
    funcScore.riseFromBedL === "0"
      ? 4
      : funcScore.riseFromBedL === "1"
      ? 3
      : funcScore.riseFromBedL === "2"
      ? 2
      : funcScore.riseFromBedL === "3"
      ? 1
      : funcScore.riseFromBedL === "4"
      ? 0
      : 0;
  const womacFuncLyingInBedL =
    funcScore.lyingInBedL === "0"
      ? 4
      : funcScore.lyingInBedL === "1"
      ? 3
      : funcScore.lyingInBedL === "2"
      ? 2
      : funcScore.lyingInBedL === "3"
      ? 1
      : funcScore.lyingInBedL === "4"
      ? 0
      : 0;
  const womacFuncBendingToFloorL =
    funcScore.bendingToFloorL === "0"
      ? 4
      : funcScore.bendingToFloorL === "1"
      ? 3
      : funcScore.bendingToFloorL === "2"
      ? 2
      : funcScore.bendingToFloorL === "3"
      ? 1
      : funcScore.bendingToFloorL === "4"
      ? 0
      : 0;
  const womacFuncPuttingOnSocksL =
    funcScore.puttingOnSocksL === "0"
      ? 4
      : funcScore.puttingOnSocksL === "1"
      ? 3
      : funcScore.puttingOnSocksL === "2"
      ? 2
      : funcScore.puttingOnSocksL === "3"
      ? 1
      : funcScore.puttingOnSocksL === "4"
      ? 0
      : 0;
  const womacFuncTakingOffSocksL =
    funcScore.takingOffSocksL === "0"
      ? 4
      : funcScore.takingOffSocksL === "1"
      ? 3
      : funcScore.takingOffSocksL === "2"
      ? 2
      : funcScore.takingOffSocksL === "3"
      ? 1
      : funcScore.takingOffSocksL === "4"
      ? 0
      : 0;
  const womacFuncStandingL =
    funcScore.standingL === "0"
      ? 4
      : funcScore.standingL === "1"
      ? 3
      : funcScore.standingL === "2"
      ? 2
      : funcScore.standingL === "3"
      ? 1
      : funcScore.standingL === "4"
      ? 0
      : 0;
  const womacFuncWalkingL =
    funcScore.walkingL === "0"
      ? 4
      : funcScore.walkingL === "1"
      ? 3
      : funcScore.walkingL === "2"
      ? 2
      : funcScore.walkingL === "3"
      ? 1
      : funcScore.walkingL === "4"
      ? 0
      : 0;
  const womacFuncPublicTransportL =
    funcScore.publicTransportL === "0"
      ? 4
      : funcScore.publicTransportL === "1"
      ? 3
      : funcScore.publicTransportL === "2"
      ? 2
      : funcScore.publicTransportL === "3"
      ? 1
      : funcScore.publicTransportL === "4"
      ? 0
      : 0;
  const womacFuncGettingBathL =
    funcScore.gettingBathL === "0"
      ? 4
      : funcScore.gettingBathL === "1"
      ? 3
      : funcScore.gettingBathL === "2"
      ? 2
      : funcScore.gettingBathL === "3"
      ? 1
      : funcScore.gettingBathL === "4"
      ? 0
      : 0;
  const womacFuncGettingToiletL =
    funcScore.gettingToiletL === "0"
      ? 4
      : funcScore.gettingToiletL === "1"
      ? 3
      : funcScore.gettingToiletL === "2"
      ? 2
      : funcScore.gettingToiletL === "3"
      ? 1
      : funcScore.gettingToiletL === "4"
      ? 0
      : 0;
  const womacFuncShoppingL =
    funcScore.shoppingL === "0"
      ? 4
      : funcScore.shoppingL === "1"
      ? 3
      : funcScore.shoppingL === "2"
      ? 2
      : funcScore.shoppingL === "3"
      ? 1
      : funcScore.shoppingL === "4"
      ? 0
      : 0;
  const womacFuncHeavyDutiesL =
    funcScore.heavyDutiesL === "0"
      ? 4
      : funcScore.heavyDutiesL === "1"
      ? 3
      : funcScore.heavyDutiesL === "2"
      ? 2
      : funcScore.heavyDutiesL === "3"
      ? 1
      : funcScore.heavyDutiesL === "4"
      ? 0
      : 0;
  const womacFuncLightDutiesL =
    funcScore.lightDutiesL === "0"
      ? 4
      : funcScore.lightDutiesL === "1"
      ? 3
      : funcScore.lightDutiesL === "2"
      ? 2
      : funcScore.lightDutiesL === "3"
      ? 1
      : funcScore.lightDutiesL === "4"
      ? 0
      : 0;

  const womacFuncLeft = sumAll(
    womacFuncGoUpStairsL,
    womacFuncGoDownStairsL,
    womacFuncSitKneeBentL,
    womacFuncRiseFromChairL,
    womacFuncRiseFromBedL,
    womacFuncLyingInBedL,
    womacFuncBendingToFloorL,
    womacFuncPuttingOnSocksL,
    womacFuncTakingOffSocksL,
    womacFuncStandingL,
    womacFuncWalkingL,
    womacFuncPublicTransportL,
    womacFuncGettingBathL,
    womacFuncGettingToiletL,
    womacFuncShoppingL,
    womacFuncHeavyDutiesL,
    womacFuncLightDutiesL
  );

  // WOMAC Function calculation End

  // KSS calculation Start
  const kssWalkingSurfaceR =
    painSco.walkingSurfaceR === "0"
      ? 10
      : painSco.walkingSurfaceR === "1"
      ? 8
      : painSco.walkingSurfaceR === "2"
      ? 5
      : painSco.walkingSurfaceR === "3"
      ? 2
      : painSco.walkingSurfaceR === "4"
      ? 0
      : 0;

  const kssUseStepsR =
    painSco.useStepsR === "0"
      ? 10
      : painSco.useStepsR === "1"
      ? 8
      : painSco.useStepsR === "2"
      ? 5
      : painSco.useStepsR === "3"
      ? 2
      : painSco.useStepsR === "4"
      ? 0
      : 0;

  const kssRateKneeFunctionR = (() => {
    const value = Math.floor(funcScore.rateKneeFunctionR);
    const mapping = {
      0: 0,
      1: 3,
      2: 3,
      3: 3,
      4: 3,
      5: 3,
      6: 3,
      7: 3,
      8: 3,
      9: 3,
      10: 5,
    };
    return mapping[value] || 0;
  })();

  const kssRight = sumAll(
    kssWalkingSurfaceR,
    kssUseStepsR,
    kssRateKneeFunctionR
  );

  const kssWalkingSurfaceL =
    painSco.walkingSurfaceL === "0"
      ? 10
      : painSco.walkingSurfaceL === "1"
      ? 8
      : painSco.walkingSurfaceL === "2"
      ? 5
      : painSco.walkingSurfaceL === "3"
      ? 2
      : painSco.walkingSurfaceL === "4"
      ? 0
      : 0;

  const kssUseStepsL =
    painSco.useStepsL === "0"
      ? 10
      : painSco.useStepsL === "1"
      ? 8
      : painSco.useStepsL === "2"
      ? 5
      : painSco.useStepsL === "3"
      ? 2
      : painSco.useStepsL === "4"
      ? 0
      : 0;

  const kssRateKneeFunctionL = (() => {
    const value = Math.floor(funcScore.rateKneeFunctionL);
    const mapping = {
      0: 0,
      1: 3,
      2: 3,
      3: 3,
      4: 3,
      5: 3,
      6: 3,
      7: 3,
      8: 3,
      9: 3,
      10: 5,
    };
    return mapping[value] || 0;
  })();

  const kssLeft = sumAll(
    kssWalkingSurfaceL,
    kssUseStepsL,
    kssRateKneeFunctionL
  );

  // KSS calculation End

  // const ikdcRTotal = Math.round(((ikdcRight / 87) * 100));
  // const ikdcLTotal = Math.round(((ikdcLeft / 87) * 100));

  // const koosPainRTotal = Math.round(((koosPainRight / 36) * 100));
  // const koosPainLTotal = Math.round(((koosPainLeft / 36) * 100));

  // const koosSymptomsRTotal = Math.round(((koosSymptomsRight / 28) * 100));
  // const koosSymptomsLTotal = Math.round(((koosSymptomsLeft / 28) * 100));

  // const koosAdlRTotal = Math.round(((koosAdlRight / 68) * 100));
  // const koosAdlLTotal = Math.round(((koosAdlLeft / 68) * 100));

  // const koosSportsRTotal = Math.round(((koosSportsRight / 20) * 100));
  // const koosSportsLTotal = Math.round(((koosSportsLeft / 20) * 100));

  // Norwich Score
  const MaxvalueNorwich = (norwichtitle) => {
    let array = [
      { title: "twisting", max: 5 },
      { title: "changeDirection", max: 7 },
      { title: "runningStraightline", max: 7 },
      { title: "walkingSlippery", max: 7 },
      { title: "runningSideways", max: 10 },
      { title: "hopping", max: 10 },
      { title: "jumping", max: 10 },
      { title: "runningOnEvenSurface", max: 10 },
      { title: "kneeling", max: 10 },
      { title: "squatting", max: 10 },
      { title: "walkingUnevenSurfaces", max: 15 },
      { title: "goingDownStairs", max: 10 },
      { title: "climbingStairs", max: 15 },
      { title: "steppingOverHigh", max: 15 },
      { title: "crossingWhenSitting", max: 15 },
      { title: "walkingEvenSurface", max: 22 },
      { title: "gettingIntoCar", max: 22 },
      { title: "supermarketAisle", max: 25 },
      { title: "turningYourShoulder", max: 25 },
    ];
    let value = array.filter((item) => item.title === norwichtitle)[0].max;
    return value;
  };

  let maxtwistingvalue = MaxvalueNorwich("twisting");
  let maxchangeDirectionvalue = MaxvalueNorwich("changeDirection");
  let maxrunningStraightlinevalue = MaxvalueNorwich("runningStraightline");
  let maxwalkingSlipperyvalue = MaxvalueNorwich("walkingSlippery");
  let maxrunningSidewaysvalue = MaxvalueNorwich("runningSideways");
  let maxhoppingvalue = MaxvalueNorwich("hopping");
  let maxjumpingvalue = MaxvalueNorwich("jumping");
  let maxrunningOnEvenSurfacevalue = MaxvalueNorwich("runningOnEvenSurface");
  let maxkneelingvalue = MaxvalueNorwich("kneeling");
  let maxsquattingvalue = MaxvalueNorwich("squatting");
  let maxwalkingUnevenSurfacesvalue = MaxvalueNorwich("walkingUnevenSurfaces");
  let maxgoingDownStairsvalue = MaxvalueNorwich("goingDownStairs");
  let maxclimbingStairsvalue = MaxvalueNorwich("climbingStairs");
  let maxsteppingOverHighvalue = MaxvalueNorwich("steppingOverHigh");
  let maxcrossingWhenSittingvalue = MaxvalueNorwich("crossingWhenSitting");
  let maxwalkingEvenSurfacevalue = MaxvalueNorwich("walkingEvenSurface");
  let maxgettingIntoCarvalue = MaxvalueNorwich("gettingIntoCar");
  let maxsupermarketAislevalue = MaxvalueNorwich("supermarketAisle");
  let maxturningYourShouldervalue = MaxvalueNorwich("turningYourShoulder");

  let twcheck = Norwich.twcheck ? 0 : maxtwistingvalue;
  let directioncheck = Norwich.directioncheck ? 0 : maxchangeDirectionvalue;
  let straightcheck = Norwich.straightcheck ? 0 : maxrunningStraightlinevalue;
  let slipperycheck = Norwich.slipperycheck ? 0 : maxwalkingSlipperyvalue;
  let sidewayscheck = Norwich.sidewayscheck ? 0 : maxrunningSidewaysvalue;
  let Hoppingcheck = Norwich.Hoppingcheck ? 0 : maxhoppingvalue;
  let Jumpingcheck = Norwich.Jumpingcheck ? 0 : maxjumpingvalue;
  let scaleLabelcheck = Norwich.scaleLabelcheck
    ? 0
    : maxrunningOnEvenSurfacevalue;
  let downcheck = Norwich.downcheck ? 0 : maxgoingDownStairsvalue;
  let Squattingcheck = Norwich.Squattingcheck ? 0 : maxsquattingvalue;
  let Kneelingcheck = Norwich.Kneelingcheck ? 0 : maxkneelingvalue;
  let Surfacescheck = Norwich.Surfacescheck ? 0 : maxwalkingUnevenSurfacesvalue;
  let Climbingcheck = Norwich.Climbingcheck ? 0 : maxclimbingStairsvalue;
  let Steppingcheck = Norwich.Steppingcheck ? 0 : maxsteppingOverHighvalue;
  let Crossingcheck = Norwich.Crossingcheck ? 0 : maxcrossingWhenSittingvalue;
  let walkingEvencheck = Norwich.walkingEvencheck
    ? 0
    : maxwalkingEvenSurfacevalue;
  let IntoCarcheck = Norwich.IntoCarcheck ? 0 : maxgettingIntoCarvalue;
  let heavycheck = Norwich.heavycheck ? 0 : maxsupermarketAislevalue;
  let turningcheck = Norwich.turningcheck ? 0 : maxturningYourShouldervalue;

  let ConstvalueNorwich = 0;

  const norwichTwistR =
    Norwich.twistingR === "0"
      ? 0
      : Norwich.twistingR === "1"
      ? 2
      : Norwich.twistingR === "2"
      ? 3
      : Norwich.twistingR === "3"
      ? 4
      : Norwich.twistingR === "4"
      ? 5
      : 0;

  const norwichTwistL =
    Norwich.twistingL === "0"
      ? 0
      : Norwich.twistingL === "1"
      ? 2
      : Norwich.twistingL === "2"
      ? 3
      : Norwich.twistingL === "3"
      ? 4
      : Norwich.twistingL === "4"
      ? 5
      : 0;

  const norwichChangeDirectionR =
    Norwich.changeDirectionR === "0"
      ? 0
      : Norwich.changeDirectionR === "1"
      ? 2
      : Norwich.changeDirectionR === "2"
      ? 3
      : Norwich.changeDirectionR === "3"
      ? 5
      : Norwich.changeDirectionR === "4"
      ? 7
      : 0;

  const norwichChangeDirectionL =
    Norwich.changeDirectionL === "0"
      ? 0
      : Norwich.changeDirectionL === "1"
      ? 2
      : Norwich.changeDirectionL === "2"
      ? 3
      : Norwich.changeDirectionL === "3"
      ? 5
      : Norwich.changeDirectionL === "4"
      ? 7
      : 0;

  const norwichRunningR =
    Norwich.runningStraightlineR === "0"
      ? 0
      : Norwich.runningStraightlineR === "1"
      ? 2
      : Norwich.runningStraightlineR === "2"
      ? 3
      : Norwich.runningStraightlineR === "3"
      ? 5
      : Norwich.runningStraightlineR === "4"
      ? 7
      : 0;

  const norwichRunningL =
    Norwich.runningStraightlineL === "0"
      ? 0
      : Norwich.runningStraightlineL === "1"
      ? 2
      : Norwich.runningStraightlineL === "2"
      ? 3
      : Norwich.runningStraightlineL === "3"
      ? 5
      : Norwich.runningStraightlineL === "4"
      ? 7
      : 0;

  const norwichWalkingR =
    Norwich.walkingSlipperyR === "0"
      ? 0
      : Norwich.walkingSlipperyR === "1"
      ? 2
      : Norwich.walkingSlipperyR === "2"
      ? 3
      : Norwich.walkingSlipperyR === "3"
      ? 5
      : Norwich.walkingSlipperyR === "4"
      ? 7
      : 0;

  const norwichWalkingL =
    Norwich.walkingSlipperyL === "0"
      ? 0
      : Norwich.walkingSlipperyL === "1"
      ? 2
      : Norwich.walkingSlipperyL === "2"
      ? 3
      : Norwich.walkingSlipperyL === "3"
      ? 5
      : Norwich.walkingSlipperyL === "4"
      ? 7
      : 0;

  const norwichRunningSideR =
    Norwich.runningSidewaysR === "0"
      ? 0
      : Norwich.runningSidewaysR === "1"
      ? 3
      : Norwich.runningSidewaysR === "2"
      ? 5
      : Norwich.runningSidewaysR === "3"
      ? 7
      : Norwich.runningSidewaysR === "4"
      ? 10
      : 0;

  const norwichRunningSideL =
    Norwich.runningSidewaysL === "0"
      ? 0
      : Norwich.runningSidewaysL === "1"
      ? 3
      : Norwich.runningSidewaysL === "2"
      ? 5
      : Norwich.runningSidewaysL === "3"
      ? 7
      : Norwich.runningSidewaysL === "4"
      ? 10
      : 0;

  const norwichHopingR =
    Norwich.hoppingR === "0"
      ? 0
      : Norwich.hoppingR === "1"
      ? 3
      : Norwich.hoppingR === "2"
      ? 5
      : Norwich.hoppingR === "3"
      ? 7
      : Norwich.hoppingR === "4"
      ? 10
      : 0;

  const norwichHopingL =
    Norwich.hoppingL === "0"
      ? 0
      : Norwich.hoppingL === "1"
      ? 3
      : Norwich.hoppingL === "2"
      ? 5
      : Norwich.hoppingL === "3"
      ? 7
      : Norwich.hoppingL === "4"
      ? 10
      : 0;

  const norwichJumpingR =
    Norwich.jumpingR === "0"
      ? 0
      : Norwich.jumpingR === "1"
      ? 3
      : Norwich.jumpingR === "2"
      ? 5
      : Norwich.jumpingR === "3"
      ? 7
      : Norwich.jumpingR === "4"
      ? 10
      : 0;

  const norwichJumpingL =
    Norwich.jumpingL === "0"
      ? 0
      : Norwich.jumpingL === "1"
      ? 3
      : Norwich.jumpingL === "2"
      ? 5
      : Norwich.jumpingL === "3"
      ? 7
      : Norwich.jumpingL === "4"
      ? 10
      : 0;

  const norwichRunningStraightR =
    Norwich.runningOnEvenSurfaceR === "0"
      ? 0
      : Norwich.runningOnEvenSurfaceR === "1"
      ? 3
      : Norwich.runningOnEvenSurfaceR === "2"
      ? 5
      : Norwich.runningOnEvenSurfaceR === "3"
      ? 7
      : Norwich.runningOnEvenSurfaceR === "4"
      ? 10
      : 0;

  const norwichRunningStraightL =
    Norwich.runningOnEvenSurfaceL === "0"
      ? 0
      : Norwich.runningOnEvenSurfaceL === "1"
      ? 3
      : Norwich.runningOnEvenSurfaceL === "2"
      ? 5
      : Norwich.runningOnEvenSurfaceL === "3"
      ? 7
      : Norwich.runningOnEvenSurfaceL === "4"
      ? 10
      : 0;

  const norwichGoingDownR =
    Norwich.goingDownStairsR === "0"
      ? 0
      : Norwich.goingDownStairsR === "1"
      ? 3
      : Norwich.goingDownStairsR === "2"
      ? 5
      : Norwich.goingDownStairsR === "3"
      ? 7
      : Norwich.goingDownStairsR === "4"
      ? 10
      : 0;

  const norwichGoingDownL =
    Norwich.goingDownStairsL === "0"
      ? 0
      : Norwich.goingDownStairsL === "1"
      ? 3
      : Norwich.goingDownStairsL === "2"
      ? 5
      : Norwich.goingDownStairsL === "3"
      ? 7
      : Norwich.goingDownStairsL === "4"
      ? 10
      : 0;

  const norwichSquattingR =
    Norwich.squattingR === "0"
      ? 0
      : Norwich.squattingR === "1"
      ? 3
      : Norwich.squattingR === "2"
      ? 5
      : Norwich.squattingR === "3"
      ? 7
      : Norwich.squattingR === "4"
      ? 10
      : 0;

  const norwichSquattingL =
    Norwich.squattingL === "0"
      ? 0
      : Norwich.squattingL === "1"
      ? 3
      : Norwich.squattingL === "2"
      ? 5
      : Norwich.squattingL === "3"
      ? 7
      : Norwich.squattingL === "4"
      ? 10
      : 0;

  const norwichKneelingR =
    Norwich.kneelingR === "0"
      ? 0
      : Norwich.kneelingR === "1"
      ? 3
      : Norwich.kneelingR === "2"
      ? 5
      : Norwich.kneelingR === "3"
      ? 7
      : Norwich.kneelingR === "4"
      ? 10
      : 0;

  const norwichKneelingL =
    Norwich.kneelingL === "0"
      ? 0
      : Norwich.kneelingL === "1"
      ? 3
      : Norwich.kneelingL === "2"
      ? 5
      : Norwich.kneelingL === "3"
      ? 7
      : Norwich.kneelingL === "4"
      ? 10
      : 0;

  const norwichWalkingUnevenR =
    Norwich.walkingUnevenSurfacesR === "0"
      ? 0
      : Norwich.walkingUnevenSurfacesR === "1"
      ? 5
      : Norwich.walkingUnevenSurfacesR === "2"
      ? 7
      : Norwich.walkingUnevenSurfacesR === "3"
      ? 10
      : Norwich.walkingUnevenSurfacesR === "4"
      ? 15
      : 0;

  const norwichWalkingUnevenL =
    Norwich.walkingUnevenSurfacesL === "0"
      ? 0
      : Norwich.walkingUnevenSurfacesL === "1"
      ? 5
      : Norwich.walkingUnevenSurfacesL === "2"
      ? 7
      : Norwich.walkingUnevenSurfacesL === "3"
      ? 10
      : Norwich.walkingUnevenSurfacesL === "4"
      ? 15
      : 0;

  const norwichClimbingR =
    Norwich.climbingStairsR === "0"
      ? 0
      : Norwich.climbingStairsR === "1"
      ? 5
      : Norwich.climbingStairsR === "2"
      ? 7
      : Norwich.climbingStairsR === "3"
      ? 10
      : Norwich.climbingStairsR === "4"
      ? 15
      : 0;

  const norwichClimbingL =
    Norwich.climbingStairsL === "0"
      ? 0
      : Norwich.climbingStairsL === "1"
      ? 5
      : Norwich.climbingStairsL === "2"
      ? 7
      : Norwich.climbingStairsL === "3"
      ? 10
      : Norwich.climbingStairsL === "4"
      ? 15
      : 0;

  const norwichSteppingR =
    Norwich.steppingOverHighR === "0"
      ? 0
      : Norwich.steppingOverHighR === "1"
      ? 5
      : Norwich.steppingOverHighR === "2"
      ? 7
      : Norwich.steppingOverHighR === "3"
      ? 10
      : Norwich.steppingOverHighR === "4"
      ? 15
      : 0;

  const norwichSteppingL =
    Norwich.steppingOverHighL === "0"
      ? 0
      : Norwich.steppingOverHighL === "1"
      ? 5
      : Norwich.steppingOverHighL === "2"
      ? 7
      : Norwich.steppingOverHighL === "3"
      ? 10
      : Norwich.steppingOverHighL === "4"
      ? 15
      : 0;

  const norwichCrossingR =
    Norwich.crossingWhenSittingR === "0"
      ? 0
      : Norwich.crossingWhenSittingR === "1"
      ? 5
      : Norwich.crossingWhenSittingR === "2"
      ? 7
      : Norwich.crossingWhenSittingR === "3"
      ? 10
      : Norwich.crossingWhenSittingR === "4"
      ? 15
      : 0;

  const norwichCrossingL =
    Norwich.crossingWhenSittingL === "0"
      ? 0
      : Norwich.crossingWhenSittingL === "1"
      ? 5
      : Norwich.crossingWhenSittingL === "2"
      ? 7
      : Norwich.crossingWhenSittingL === "3"
      ? 10
      : Norwich.crossingWhenSittingL === "4"
      ? 15
      : 0;

  const norwichEvenSurfaceR =
    Norwich.walkingEvenSurfaceR === "0"
      ? 0
      : Norwich.walkingEvenSurfaceR === "1"
      ? 7
      : Norwich.walkingEvenSurfaceR === "2"
      ? 10
      : Norwich.walkingEvenSurfaceR === "3"
      ? 15
      : Norwich.walkingEvenSurfaceR === "4"
      ? 22
      : 0;

  const norwichEvenSurfaceL =
    Norwich.walkingEvenSurfaceL === "0"
      ? 0
      : Norwich.walkingEvenSurfaceL === "1"
      ? 7
      : Norwich.walkingEvenSurfaceL === "2"
      ? 10
      : Norwich.walkingEvenSurfaceL === "3"
      ? 15
      : Norwich.walkingEvenSurfaceL === "4"
      ? 22
      : 0;

  const norwichGetinOutR =
    Norwich.gettingIntoCarR === "0"
      ? 0
      : Norwich.gettingIntoCarR === "1"
      ? 7
      : Norwich.gettingIntoCarR === "2"
      ? 10
      : Norwich.gettingIntoCarR === "3"
      ? 15
      : Norwich.gettingIntoCarR === "4"
      ? 22
      : 0;

  const norwichGetinOutL =
    Norwich.gettingIntoCarL === "0"
      ? 0
      : Norwich.gettingIntoCarL === "1"
      ? 7
      : Norwich.gettingIntoCarL === "2"
      ? 10
      : Norwich.gettingIntoCarL === "3"
      ? 15
      : Norwich.gettingIntoCarL === "4"
      ? 22
      : 0;

  const norwichTurningHeavyR =
    Norwich.supermarketAisleR === "0"
      ? 0
      : Norwich.supermarketAisleR === "1"
      ? 10
      : Norwich.supermarketAisleR === "2"
      ? 15
      : Norwich.supermarketAisleR === "3"
      ? 22
      : Norwich.supermarketAisleR === "4"
      ? 25
      : 0;

  const norwichTurningHeavyL =
    Norwich.supermarketAisleL === "0"
      ? 0
      : Norwich.supermarketAisleL === "1"
      ? 10
      : Norwich.supermarketAisleL === "2"
      ? 15
      : Norwich.supermarketAisleL === "3"
      ? 22
      : Norwich.supermarketAisleL === "4"
      ? 25
      : 0;

  const norwichTurningLookR =
    Norwich.turningYourShoulderR === "0"
      ? 0
      : Norwich.turningYourShoulderR === "1"
      ? 10
      : Norwich.turningYourShoulderR === "2"
      ? 15
      : Norwich.turningYourShoulderR === "3"
      ? 22
      : Norwich.turningYourShoulderR === "4"
      ? 25
      : 0;

  const norwichTurningLookL =
    Norwich.turningYourShoulderL === "0"
      ? 0
      : Norwich.turningYourShoulderL === "1"
      ? 10
      : Norwich.turningYourShoulderL === "2"
      ? 15
      : Norwich.turningYourShoulderL === "3"
      ? 22
      : Norwich.turningYourShoulderL === "4"
      ? 25
      : 0;

  const NorwichSumR = sumAll(
    norwichTwistR,
    norwichChangeDirectionR,
    norwichRunningR,
    norwichWalkingR,
    norwichRunningSideR,
    norwichHopingR,
    norwichJumpingR,
    norwichRunningStraightR,
    norwichGoingDownR,
    norwichSquattingR,
    norwichKneelingR,
    norwichWalkingUnevenR,
    norwichClimbingR,
    norwichSteppingR,
    norwichCrossingR,
    norwichEvenSurfaceR,
    norwichGetinOutR,
    norwichTurningHeavyR,
    norwichTurningLookR
  );
  const NorwichSumL = sumAll(
    norwichTwistL,
    norwichChangeDirectionL,
    norwichRunningL,
    norwichWalkingL,
    norwichRunningSideL,
    norwichHopingL,
    norwichJumpingL,
    norwichRunningStraightL,
    norwichGoingDownL,
    norwichSquattingL,
    norwichKneelingL,
    norwichWalkingUnevenL,
    norwichClimbingL,
    norwichSteppingL,
    norwichCrossingL,
    norwichEvenSurfaceL,
    norwichGetinOutL,
    norwichTurningHeavyL,
    norwichTurningLookL
  );
  const numberOfSelectionR = selectedQuestion(
    Norwich.twistingR,
    Norwich.changeDirectionR,
    Norwich.runningStraightlineR,
    Norwich.walkingSlipperyR,
    Norwich.runningSidewaysR,
    Norwich.hoppingR,
    Norwich.jumpingR,
    Norwich.runningOnEvenSurfaceR,
    Norwich.goingDownStairsR,
    Norwich.squattingR,
    Norwich.kneelingR,
    Norwich.walkingUnevenSurfacesR,
    Norwich.climbingStairsR,
    Norwich.steppingOverHighR,
    Norwich.crossingWhenSittingR,
    Norwich.walkingEvenSurfaceR,
    Norwich.gettingIntoCarR,
    Norwich.supermarketAisleR,
    Norwich.turningYourShoulderR
  );

  const numberOfSelectionL = selectedQuestion(
    Norwich.twistingL,
    Norwich.changeDirectionL,
    Norwich.runningStraightlineL,
    Norwich.walkingSlipperyL,
    Norwich.runningSidewaysL,
    Norwich.hoppingL,
    Norwich.jumpingL,
    Norwich.runningOnEvenSurfaceL,
    Norwich.goingDownStairsL,
    Norwich.squattingL,
    Norwich.kneelingL,
    Norwich.walkingUnevenSurfacesL,
    Norwich.climbingStairsL,
    Norwich.steppingOverHighL,
    Norwich.crossingWhenSittingL,
    Norwich.walkingEvenSurfaceL,
    Norwich.gettingIntoCarL,
    Norwich.supermarketAisleL,
    Norwich.turningYourShoulderL
  );

  // Norwich End

  // Tigner Start
  const tignerRight =
    funcScore.sports === "1"
      ? 10
      : funcScore.sports === "2"
      ? 9
      : funcScore.sports === "3"
      ? 8
      : funcScore.sports === "4"
      ? 7
      : funcScore.sports === "5"
      ? 6
      : funcScore.sports === "6"
      ? 5
      : funcScore.sports === "7"
      ? 4
      : funcScore.sports === "8"
      ? 3
      : funcScore.sports === "9"
      ? 5
      : funcScore.sports === "10"
      ? 4
      : funcScore.sports === "11"
      ? 3
      : funcScore.sports === "12"
      ? 1
      : funcScore.sports === "13"
      ? 3
      : funcScore.sports === "14"
      ? 2
      : funcScore.sports === "15"
      ? 1
      : 0;

  const tignerLeft =
    funcScore.currsports === "16"
      ? 10
      : funcScore.currsports === "17"
      ? 9
      : funcScore.currsports === "18"
      ? 8
      : funcScore.currsports === "19"
      ? 7
      : funcScore.currsports === "20"
      ? 6
      : funcScore.currsports === "21"
      ? 5
      : funcScore.currsports === "22"
      ? 4
      : funcScore.currsports === "23"
      ? 3
      : funcScore.currsports === "24"
      ? 5
      : funcScore.currsports === "25"
      ? 4
      : funcScore.currsports === "26"
      ? 3
      : funcScore.currsports === "27"
      ? 1
      : funcScore.currsports === "28"
      ? 3
      : funcScore.currsports === "29"
      ? 2
      : funcScore.currsports === "30"
      ? 1
      : 0;

  const TignerRight = funcScore.highestActivity > "0" ? tignerRight : 0;
  const TignerLeft = funcScore.highestActivityL > "0" ? tignerLeft : 0;

  // Tigner End

  const ikdcRTotal = Math.round((ikdcRight / 87) * 100);
  const ikdcLTotal = Math.round((ikdcLeft / 87) * 100);

  const koosPainRTotal = Math.round((koosPainRight / 36) * 100);
  const koosPainLTotal = Math.round((koosPainLeft / 36) * 100);

  const koosSymptomsRTotal = Math.round((koosSymptomsRight / 28) * 100);
  const koosSymptomsLTotal = Math.round((koosSymptomsLeft / 28) * 100);

  const koosAdlRTotal = Math.round((koosAdlRight / 68) * 100);
  const koosAdlLTotal = Math.round((koosAdlLeft / 68) * 100);

  const koosSportsRTotal = Math.round((koosSportsRight / 20) * 100);
  const koosSportsLTotal = Math.round((koosSportsLeft / 20) * 100);

  const qolRTotal = Math.round((qolRight / 16) * 100);
  const qolLTotal = Math.round((qolLeft / 16) * 100);

  const ckrsRTotal = ckrsRight;
  const ckrsLTotal = ckrsLeft;

  const lysholmRTotal = lysholmRight;
  const lysholmLTotal = lysholmLeft;

  const oksPainRTotal = ((oksPainRight / 28) * 100).toFixed();
  const oksPainLTotal = ((oksPainLeft / 28) * 100).toFixed();

  const oksFuncRTotal = ((oksFuncRight / 20) * 100).toFixed();
  const oksFuncLTotal = ((oksFuncLeft / 20) * 100).toFixed();

  const womacPainRTotal = Math.round((womacPainRight / 20) * 100);
  const womacPainLTotal = Math.round((womacPainLeft / 20) * 100);

  const womacStiffRTotal = Math.round((womacStiffRight / 8) * 100);
  const womacStiffLTotal = Math.round((womacStiffLeft / 8) * 100);

  const womacFuncRTotal = Math.round((womacFuncRight / 68) * 100);
  const womacFuncLTotal = Math.round((womacFuncLeft / 68) * 100);

  const kssRTotal = kssRight;
  const kssLTotal = kssLeft;

  const norwichScoreR = Math.round(
    (NorwichSumR /
      (ConstvalueNorwich +
        twcheck +
        directioncheck +
        straightcheck +
        slipperycheck +
        sidewayscheck +
        Hoppingcheck +
        Jumpingcheck +
        scaleLabelcheck +
        downcheck +
        Squattingcheck +
        Kneelingcheck +
        Surfacescheck +
        Climbingcheck +
        Steppingcheck +
        Crossingcheck +
        walkingEvencheck +
        IntoCarcheck +
        heavycheck +
        turningcheck)) *
      100
  );

  const norwichScoreL = Math.round(
    (NorwichSumL /
      (ConstvalueNorwich +
        twcheck +
        directioncheck +
        straightcheck +
        slipperycheck +
        sidewayscheck +
        Hoppingcheck +
        Jumpingcheck +
        scaleLabelcheck +
        downcheck +
        Squattingcheck +
        Kneelingcheck +
        Surfacescheck +
        Climbingcheck +
        Steppingcheck +
        Crossingcheck +
        walkingEvencheck +
        IntoCarcheck +
        heavycheck +
        turningcheck)) *
      100
  );

  // console.log(NorwichSumR, "NPI Right SUM", (ConstvalueNorwich + twcheck + directioncheck + straightcheck + slipperycheck + sidewayscheck + Hoppingcheck + Jumpingcheck + scaleLabelcheck +
  //   downcheck + Squattingcheck + Kneelingcheck + Surfacescheck + Climbingcheck + Steppingcheck + Crossingcheck +
  //   walkingEvencheck + IntoCarcheck + heavycheck + turningcheck));
  // console.log(ConstvalueNorwich, twcheck, directioncheck, straightcheck, slipperycheck, sidewayscheck, Hoppingcheck, Jumpingcheck, scaleLabelcheck,
  //   downcheck, Squattingcheck, Kneelingcheck, Surfacescheck, Climbingcheck, Steppingcheck, Crossingcheck,
  //   walkingEvencheck, IntoCarcheck, heavycheck, turningcheck,"Right=========",Kneelingcheck);

  // console.log(NorwichSumL, "NPI Left SUM", (ConstvalueNorwich + twcheck + directioncheck + straightcheck + slipperycheck + sidewayscheck + Hoppingcheck + Jumpingcheck + scaleLabelcheck + downcheck + Squattingcheck + Kneelingcheck + Surfacescheck + Climbingcheck + Steppingcheck + Crossingcheck + walkingEvencheck +
  //   IntoCarcheck + heavycheck + turningcheck));

  // console.log(norwichScoreR, "right", norwichScoreL, "left");
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=1`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (isdoctorform == 1) {
          setDisplayConfirmationModal(true);
          // window.location.href =
          //   process.env.REACT_APP_DOCTOR_URL +
          //   "patient-view/" +
          //   window.btoa(patientID);
        } else {
          navigate("/adult-knee-result/" + rId);
        }
      });
  };
  const [submit, setSubmit] = useState(false);
  const continueRedirect = (e) => {
    // navigate("/adult-knee-result");
    dispatch(
      updateAdultKneeTotalScores([
        {
          ikdcScoreR: ikdcRTotal,
          ikdcScoreL: ikdcLTotal,
          koosPainScoreR: koosPainRTotal,
          koosPainScoreL: koosPainLTotal,
          koosSymptomsScoreR: koosSymptomsRTotal,
          koosSymptomsScoreL: koosSymptomsLTotal,
          koosAdlScoreR: koosAdlRTotal,
          koosAdlScoreL: koosAdlLTotal,
          koosSportsScoreR: koosSportsRTotal,
          koosSportsScoreL: koosSportsLTotal,
          qolScoreR: qolRTotal,
          qolScoreL: qolLTotal,
          ckrsScoreR: ckrsRTotal,
          ckrsScoreL: ckrsLTotal,
          lysholmScoreR: lysholmRTotal,
          lysholmScoreL: lysholmLTotal,
          oksPainScoreR: oksPainRTotal,
          oksPainScoreL: oksPainLTotal,
          oksFuncScoreR: oksFuncRTotal,
          oksFuncScoreL: oksFuncLTotal,
          oksOverallR: oksOverallR,
          oksOverallL: oksOverallL,
          womacPainScoreR: womacPainRTotal,
          womacPainScoreL: womacPainLTotal,
          womacStiffScoreR: womacStiffRTotal,
          womacStiffScoreL: womacStiffLTotal,
          womacFuncScoreR: womacFuncRTotal,
          womacFuncScoreL: womacFuncLTotal,
          kssScoreR: kssRTotal,
          kssScoreL: kssLTotal,
          VasScoreR: VasScoreR,
          VasScoreL: VasScoreL,
          EQDISCOMFORTR: EQDISCOMFORTR,
          EQDISCOMFORTL: EQDISCOMFORTL,
          EQMOBILITYR: EQMOBILITYR,
          EQMOBILITYL: EQMOBILITYL,
          EQCARER: EQCARER,
          EQCAREL: EQCAREL,
          EQACTIVITIESR: EQACTIVITIESR,
          EQACTIVITIESL: EQACTIVITIESL,
          EQANXIETYR: EQANXIETYR,
          EQ_VAS: EQ_VAS,
          // EQ_VASL: EQ_VASL,
          SAPS_OVERALLR: SAPS_OVERALLR,
          SAPS_OVERALLL: SAPS_OVERALLL,
          SAPS_PAINR: SAPS_PAINR,
          SAPS_PAINL: SAPS_PAINL,
          SAPS_WORKR: SAPS_WORKR,
          SAPS_WORKL: SAPS_WORKL,
          SAPS_RECREATIONR: SAPS_RECREATIONR,
          SAPS_RECREATIONL: SAPS_RECREATIONL,
          SANE_R: SANE_R,
          SANE_L: SANE_L,
          NorwichR: norwichScoreR,
          NorwichL: norwichScoreL,
          TignerRight: TignerRight,
          TignerLeft: TignerLeft,
        },
      ])
    );
    setSubmit(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (submit) {
      updateDate();
    }
  }, [json]);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="complete_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              <NameCustom />
              <h4 className="ph_title f_40 ">{`${translation.patient_adult_knee_complete}`}</h4>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_20 pt_20">
                  <p className="thanks_txt">
                    {translation.patient_adult_knee_complete_text1}
                    <br /> {translation.patient_adult_knee_complete_text2}
                  </p>
                  <Button
                    onClick={continueRedirect}
                    value={translation.submit}
                    buttonStyle="btn_fill cmp_submit_btn"
                  />
                  <span className="answer_txt">
                    {translation.patient_adult_knee_complete_text3}
                  </span>
                </div>
              </div>

              <div className="row mb_30 button_box">
                <div className="col-md-6 col-sm-6">
              
                    <Link
                      to={"/adult-knee-satisfaction/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <SubmitModel showModal={displayConfirmationModal}  patientID={window.btoa(patientID)}/>
    </>
  );
}

export default AdultKneeComplete;
