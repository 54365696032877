import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import ResultCircleProgress from "../../../custom/result-circle-progress/ResultCircleProgress";
import Button from "../../../custom/button/Button";
import useTranslation from "../../../customHooks/translations";
import useAxios from "../../../../axiosinstance";
import Share from "../../Share";
import {
  updateHandWristTotalScores,
  updateHandWristfunctionScores,
  updateHandWristPainScores,
  updateHandWristSymptomsScores,
  updateHandWristqolScores,
  updateHandWristsatisfactionScores,
  updateHandWristFeedbackScores,
  updateHandWristScores,
  updateHandWristbctqScores,
  updateHandWristUramScores,
} from "../../../../redux/actions/handWrist";
import NameCustom from "../../../custom/name/NameCustom";
import SubmitModel from "../../SubmissionModel/SubmitModel"
function HandWristResult() {
  const { rId } = useParams();
  const axiosinstance = useAxios();
  const translation = useTranslation();
  let type = 23;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const evaluated = useSelector((state) => state.handWrist.evaluated);
  const [totalScore] = useSelector((state) => state.handWrist.TotalScores);
  const [dominant, setDominant] = useState("");
  const navigate = useNavigate();
  const [isSydrome, setIsSydrome] = useState("1");
  const [isDisease, setIsDisease] = useState("1");
  const [isdoctorform, setisdoctorform] = useState(Number());
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [status, setStatus] = useState("");
  const continueRedirect2 = (e) => {
    setDisplayConfirmationModal(true)
    // if (isdoctorform === 1) {
    //   window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
    // } else {
    //   window.location.href = process.env.REACT_APP_PATIENT_URL;
    // }
  };

  // const continueRedirect2 = (e) => {
  //   window.open(process.env.REACT_APP_PATIENT_URL, "_blank");

  // };

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        console.log(res.data.data2);
        setDominant(res.data.data2[0].dominant);
        setIsDisease(res.data.data2[0].isDisease);
        setIsSydrome(res.data.data2[0].isSyndrome);
        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));
        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateHandWristFeedbackScores(res.data.data2[0].doctorFeedback)
        );
        dispatch(updateHandWristTotalScores(res.data.data2[0].TotalScores));
        setisdoctorform(res.data.data2[0].is_doctor_form);
        setStatus(res.data.data2[0].status);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="result_screen mb_100">
          <div className="row justify-content-center">
            <div className="col-xxl-8 col-lg-10 col-md-12 col-sm-12">
              <div className="panel">
                <div className="panel-body p_25">
                  <div className="row">
                    <div className="col-xxl-8 col-lg-7 col-md-6 col-sm-6">
                      <h3 className="text-start f_22 ">
                        {translation.patient_hand_result}
                      </h3>
                      <p className="answer_txt">
                        {translation.patient_hand_result_text1}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-lg-5 col-md-6 col-sm-6">
                      <NameCustom />
                    </div>
                  </div>
                  <div className="row">
                    {status === "1" ? (
                      <div className="pb_50 text-center">
                        <Button
                          onClick={continueRedirect2}
                          value={translation.Sign_out}
                          buttonStyle="btn_fill result_submit_btn"
                        />

                      </div>
                    ) : status === "2" ? (
                      <div className="pb_50 text-center">
                        <Share
                          type={type}
                          rId={rId}
                        />
                      </div>
                    ) : null}
                  </div>


                  <div className="row result_scorebox">
                    {dominant === "1" && (
                      <>
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.patient_hand_result_function}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quickdisabilityTL}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.patient_hand_result_function}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quickdisabilityTR}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.QuickDASH_Work_Score}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quickworkTR}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.QuickDASH_Work_Score}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quickworkTL}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={
                                translation.QuickDASH_Sport_Performing_Arts_Score
                              }
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quicksportTR}
                              total="100"
                              date="22/09/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={
                                translation.QuickDASH_Sport_Performing_Arts_Score
                              }
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quicksportTL}
                              total="100"
                              date="22/09/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.BMHQ}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.bmhqTR}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.BMHQ}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.bmhqTL}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_hand_result_pain}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              persent={totalScore.vasL * 10}
                              value={totalScore.vasL}
                              total="10"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_hand_result_pain}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              persent={totalScore.vas * 10}
                              value={totalScore.vas}
                              total="10"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}


                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.sane}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.saneL}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {/* {evaluated === "1" || evaluated === "3" ? (
                  <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_5D_5L} type="Hand and Wrist" arm={translation.Right_hand_and_wrist} value={totalScore.eq5dR} total="100" date="22/09/2022" co{translation.Hand_And_Wrist}
                  </div>
                ) : ""}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_5D_5L} type="Hand and Wrist" arm={translation.Left_hand_and_wrist} value={totalScore.eq5dL} total="100" date="22/09/2022" co{translation.Hand_And_Wrist}
                  </div>
                ) : ""} */}

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_DISCOMFORT}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dpain * 20}
                            value={`Level ${totalScore.eq5dpain}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_MOBILITY}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dproblem * 20}
                            value={`Level ${totalScore.eq5dproblem}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_CARE}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dwashing * 20}
                            value={`Level ${totalScore.eq5dwashing}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_ACTIVITIES}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dact * 20}
                            value={`Level ${totalScore.eq5dact}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_ANXIETY}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5ddepression * 20}
                            value={`Level ${totalScore.eq5ddepression}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                      <ResultCircleProgress
                        min_score={0}
                        reverse={false}
                        title={translation.patient_hand_result_health_perception}
                        type={translation.patient_hand_result_self_evaluation}
                        // arm={translation.Hand_And_Wrist}
                        value={totalScore.eqvas}
                        total="100"
                        date="22/09/2022"
                        color="#78b776"
                      />
                    </div> */}

                        {evaluated === "1" || evaluated === "3" ? (
                          isDisease === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={true}
                                title={translation.uram}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.uramR}
                                total="45"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {evaluated === "2" || evaluated === "3"
                          ? isDisease === "2" && (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={true}
                                title={translation.uram}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.uramL}
                                total="45"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          )
                          : ""}

                        {evaluated === "1" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Symptom}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.BctqSymptomTR}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {evaluated === "2" || evaluated === "3"
                          ? isSydrome === "2" && (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Symptom}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.BctqSymptomTL}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          )
                          : ""}
                        {evaluated === "1" || evaluated === "3"
                          ? isSydrome === "2" && (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Function}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.BctqFunctionTR}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          )
                          : ""}
                        {evaluated === "2" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Function}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.BctqFunctionTL}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>

                  <div className="row result_scorebox">
                    {dominant === "2" && (
                      <>
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.patient_hand_result_function}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quickdisabilityTL}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.patient_hand_result_function}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quickdisabilityTR}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.QuickDASH_Work_Score}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quickworkTR}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.QuickDASH_Work_Score}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quickworkTL}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={
                                translation.QuickDASH_Sport_Performing_Arts_Score
                              }
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quicksportTR}
                              total="100"
                              date="22/09/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={
                                translation.QuickDASH_Sport_Performing_Arts_Score
                              }
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quicksportTL}
                              total="100"
                              date="22/09/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.BMHQ}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.bmhqTR}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.BMHQ}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.bmhqTL}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_hand_result_pain}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              persent={totalScore.vasL * 10}
                              value={totalScore.vasL}
                              total="10"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_hand_result_pain}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              persent={totalScore.vas * 10}
                              value={totalScore.vas}
                              total="10"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}


                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE}
                              type={translation.Right_hand_and_wrist}
                              arm={translation.Hand_And_Wrist}
                              value={totalScore.sane}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE}
                              type={translation.Left_hand_and_wrist}
                              arm={translation.Hand_And_Wrist}
                              value={totalScore.saneL}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {evaluated === "1" || evaluated === "3" ? (
                  <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_5D_5L} type="Hand and Wrist" arm={translation.Right_hand_and_wrist} value={totalScore.eq5dR} total="100" date="22/09/2022" co{translation.Hand_And_Wrist}
                  </div>
                ) : ""}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_5D_5L} type="Hand and Wrist" arm={translation.Left_hand_and_wrist} value={totalScore.eq5dL} total="100" date="22/09/2022" co{translation.Hand_And_Wrist}
                  </div>
                ) : ""} */}

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_DISCOMFORT}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dpain * 20}
                            value={`${translation.Level} ${totalScore.eq5dpain}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_MOBILITY}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dproblem * 20}
                            value={`${translation.Level} ${totalScore.eq5dproblem}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_CARE}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dwashing * 20}
                            value={`${translation.Level} ${totalScore.eq5dwashing}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_ACTIVITIES}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dact * 20}
                            value={`${translation.Level} ${totalScore.eq5dact}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_ANXIETY}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5ddepression * 20}
                            value={`${translation.Level} ${totalScore.eq5ddepression}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                      <ResultCircleProgress
                        min_score={0}
                        reverse={false}
                        title={translation.patient_hand_result_health_perception}
                        type={translation.patient_hand_result_self_evaluation}
                        // arm={translation.Hand_And_Wrist}
                        value={totalScore.eqvas}
                        total="100"
                        date="22/09/2022"
                        color="#78b776"
                      />
                    </div> */}

                        {evaluated === "1" || evaluated === "3" ? (
                          isDisease === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={true}
                                title={translation.uram}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.uramR}
                                total="45"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          isDisease === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={true}
                                title={translation.uram}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.uramL}
                                total="45"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {evaluated === "1" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Symptom}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.BctqSymptomTR}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {evaluated === "2" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Symptom}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.BctqSymptomTL}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Function}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.BctqFunctionTR}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Function}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.BctqFunctionTL}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>

                  <div className="row result_scorebox">
                    {dominant === "3" && (
                      <>
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.patient_hand_result_function}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quickdisabilityTL}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.patient_hand_result_function}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quickdisabilityTR}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.QuickDASH_Work_Score}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quickworkTR}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={translation.QuickDASH_Work_Score}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quickworkTL}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={
                                translation.QuickDASH_Sport_Performing_Arts_Score
                              }
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.quicksportTR}
                              total="100"
                              date="22/09/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={true}
                              title={
                                translation.QuickDASH_Sport_Performing_Arts_Score
                              }
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.quicksportTL}
                              total="100"
                              date="22/09/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.BMHQ}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              value={totalScore.bmhqTR}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.BMHQ}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              value={totalScore.bmhqTL}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <div className="col-md-4 col-sm-6">
                      <ResultCircleProgress min_score={0}  reverse={false} title={translation.patient_hand_result_pain} type="Hand and Wrist" arm={translation.Hand_And_Wrist} value={totalScore.vas} total="10" date="22/09/2022" co{translation.Hand_And_Wrist}
                    </div> */}

                        {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                      <ResultCircleProgress min_score={0}  reverse={false} title={translation.SANE} type="Hand and Wrist" arm={translation.Hand_And_Wrist} value={totalScore.sane} total="100" date="22/09/2022" co{translation.Hand_And_Wrist}
                    </div> */}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_hand_result_pain}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Left_hand_and_wrist}
                              persent={totalScore.vasL * 10}
                              value={totalScore.vasL}
                              total="10"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">

                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_hand_result_pain}
                              type={translation.patient_hand_result_self_evaluation}
                              arm={translation.Right_hand_and_wrist}
                              persent={totalScore.vas * 10}
                              value={totalScore.vas}
                              total="10"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}




                        {evaluated === "1" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE}
                              type={translation.Right_hand_and_wrist}
                              arm={translation.Hand_And_Wrist}
                              value={totalScore.sane}
                              total="100"
                              date="22/09/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE}
                              type={translation.Left_hand_and_wrist}
                              arm={translation.Hand_And_Wrist}
                              value={totalScore.saneL}
                              total="100"
                              date="22/09/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {/* {evaluated === "1" || evaluated === "3" ? (
                  <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_5D_5L} type="Hand and Wrist" arm={translation.Right_hand_and_wrist} value={totalScore.eq5dR} total="100" date="22/09/2022" co{translation.Hand_And_Wrist}
                  </div>
                ) : ""}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_5D_5L} type="Hand and Wrist" arm={translation.Left_hand_and_wrist} value={totalScore.eq5dL} total="100" date="22/09/2022" co{translation.Hand_And_Wrist}
                  </div>
                ) : ""} */}

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_DISCOMFORT}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dpain * 20}
                            value={`Level ${totalScore.eq5dpain}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_MOBILITY}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dproblem * 20}
                            value={`Level ${totalScore.eq5dproblem}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_CARE}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dwashing * 20}
                            value={`Level ${totalScore.eq5dwashing}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_ACTIVITIES}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5dact * 20}
                            value={`Level ${totalScore.eq5dact}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_ANXIETY}
                            type={translation.patient_hand_result_self_evaluation}
                            arm={translation.Hand_And_Wrist}
                            hide={"1"}
                            persent={totalScore.eq5ddepression * 20}
                            value={`Level ${totalScore.eq5ddepression}`}
                            total="5"
                            date="22/09/2022"
                            color="#006DFF"
                          />
                        </div>

                        {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 ">
                      <ResultCircleProgress
                        min_score={0}
                        reverse={false}
                        title={translation.patient_hand_result_health_perception}
                        type={translation.patient_hand_result_self_evaluation}
                        // arm={translation.Hand_And_Wrist}
                        value={totalScore.eqvas}
                        total="100"
                        date="22/09/2022"
                        color="#78b776"
                      />
                    </div> */}

                        {evaluated === "1" || evaluated === "3" ? (
                          isDisease === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={true}
                                title={translation.uram}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.uramR}
                                total="45"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          isDisease === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={true}
                                title={translation.uram}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.uramL}
                                total="45"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {evaluated === "1" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Symptom}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.BctqSymptomTR}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {evaluated === "2" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Symptom}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.BctqSymptomTL}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {evaluated === "1" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Function}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Right_hand_and_wrist}
                                value={totalScore.BctqFunctionTR}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {evaluated === "2" || evaluated === "3" ? (
                          isSydrome === "2" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                              <ResultCircleProgress
                                min_score={1}
                                reverse={true}
                                title={translation.BCTQ_Function}
                                type={translation.patient_hand_result_self_evaluation}
                                arm={translation.Left_hand_and_wrist}
                                value={totalScore.BctqFunctionTL}
                                total="5"
                                date="22/09/2022"
                                color="#78b776"
                              />
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>



                </div>
              </div>
            </div>
          </div>


        </div>
      )}
      <SubmitModel
        showModal={displayConfirmationModal} patientID={window.btoa(sessionStorage.getItem("patient_akid"))}

      />
    </>
  );
}

export default HandWristResult;
