import React, { useState, useEffect } from "react";
import "../../../css/ViewTreatmentForm.css";
import Button from "../../custom/button/Button";
import PersonalInformation from "./PersonalInformation";
import ShoulderScoring from "./ShoulderScoring";
import WesternOntarioShoulder from "./WesternOntarioShoulder";
import SacsScore from "./SacsScore";
import Satisfaction from "./Satisfaction";
import ClinicalExamination from "./ClinicalExamination";
import ClinicalExaminationBottom from "./ClinicalExaminationBottom";
import { useParams, useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import useAxios from "../../../axiosinstance";
import useTranslation from "../../customHooks/translations";
function ViewTreatmentForm() {
  const translation = useTranslation();
  const { rId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const pId = queryParams.get("patient");
  const patientEdit = queryParams.get("patientEdit");
  const management = queryParams.get("management");
  const pScore = queryParams.get("form");
  const action = queryParams.get("action");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  let isdoctorForm = queryParams.get("is_doctor_form");
  useEffect(() => {
    localStorage.setItem("isdoctorForm", isdoctorForm);
  }, [isdoctorForm]);

  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [shoulderPain, setShoulderPain] = useState({});
  const [functionData, setShoulderFunction] = useState({});
  const [satisfactionData, setShoulderSatisfaction] = useState({});
  const [sacsScore, setsacsScore] = useState({});
  const [wosiScore, setwosiScore] = useState({});
  const [doctorefeedback, setShoulderDoctorFeedback] = useState({});
  const [editShow, seteditShow] = useState(false);
  const [viewDoctor, setViewDoctor] = useState("0");
  const [viewPatient, setViewPatient] = useState("0");
  const [doctorView, setdoctorView] = useState(false);
  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
    const [editShow1, setEditShow1] = useState(false);
  const axiosInstance = useAxios();
  const doctor_id = queryParams.get("doctor")
  let orgid=sessionStorage.getItem("orgid")
  const save = () => {
    axiosInstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${rId}&type=4&akpt=${window.atob(
          pId
        )}`
      )
      .then((res) => {
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);

        setShoulderPain(res.data.data2[0].painScore[0]);
        setShoulderFunction(res.data.data2[0].functionScore[0]);
        setsacsScore(res.data.data2[0].sacsScore[0]);
        setShoulderSatisfaction(res.data.data2[0].safisScore[0]);
        setwosiScore(res.data.data2[0].wosiScore[0]);
        setShoulderDoctorFeedback(res.data.data2[0].doctorFeedback[0]);
        seteditShow(res.data.status);
        setdoctorView(res.data.doctorStatus);
        setViewDoctor(res.data.data2[0].doctor_scoring_date);
        setViewPatient(res.data.data2[0].patient_scoring_date);
        setEditShow1(res.data.doctorStatus);
        setIsLoading(false);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);
  const handleEdit = () => {
    navigate(
      `/personal-data-edit/${
        pId
      }/${window.btoa(doctor_id)}/${window.btoa(orgid)}/${window.btoa(
        4
      )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
    );
    // navigate("/pain/" + rId + "?d=true&akpt=" + pId + "&is_doctor_form=true");
  };
  const handlePatientEdit = () => {
  
    navigate("/pain/" + rId + "?is_doctor_form=null&edit=true");
  };
  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const submitDelete = (type, e) => {
    handleDelByrID();
  };

  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rId}`
      );
      if (result.status === 200) {
        window.open(
          process.env.REACT_APP_DOCTOR_URL + "patient-list",
          "_blank"
        );
        window.close();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };

  const handleDelete = (e, te) => {
    setDisplayConfirmationModalDelete(true);
  };

  const handleBack = () => {
    window.open(process.env.REACT_APP_DOCTOR_URL + 'patient-view' + "/" + pId);
    window.close();
  };
  const handleEdit1 = () => {
    window.open(
      
      "/shoulder-treatment-form/step-1/" +
      rId +
      `?doctor=${doctor_id}&patient=${pId}&is_doctor_form=true`
    );
  };
  const profileAllData = React.useMemo(() => profileData, [profileData]);

let ak_dc=  sessionStorage.getItem("ak_dc")
  return (
    <div className="view_treatment_form">
      <div className="row page_header">
        <div className="col-sm-6 col-md-6">
          <h4>{translation.Shoulder_Treatment_Form}</h4>
        </div>
        {management === "true" && (
              <>
              {ak_dc===doctor_id &&(
          <div className={`col-sm-6 col-md-6 text-end`}>
            <Button
              onClick={() => handleDelete()}
              value="Delete Form"
              buttonStyle="btn_fill"
            />
            {editShow || doctorView ? (
              <Button
                onClick={() => handleEdit()}
                value="Edit Form"
                buttonStyle="btn_fill ml_15 "
              />
            ) : (
              <Button
                onClick={() => console.log("")}
                value="Edit Form"
                buttonStyle=" ml_15 button_disabled "
              />
            )}
          </div>
              )}
          </>
        )}
        {isdoctorForm && management !== "true"&& (
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleBack()}
                  value="Back"
                  buttonStyle="btn_fill ml_15 "
                />

                {editShow === true &&ak_dc===doctor_id && (
                  <Button
                    onClick={() => handleEdit1()}
                    value="Edit Form"
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}

        {patientEdit === "true" && (
          <div className={`col-sm-6 col-md-6 text-end`}>
            {editShow  && (
              <Button
                onClick={() => handlePatientEdit()}
                value="Edit Form"
                buttonStyle="btn_fill ml_15 "
              />
            )}
          </div>
        )}
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModalDelete}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        message={"Form"}
        type="task_id"
        id={rId}
      />
      {pScore === "true" && action === "1" && (
        <>
          <PersonalInformation
            profileAllData={profileAllData}
            AllData={AllData}
          />
          {viewPatient === "0" ? (
            <div className="no_data">Patient form is not yet filled </div>
          ) : (
            <>
              <ShoulderScoring
                shoulderPain={shoulderPain}
                AllData={AllData}
                functionData={functionData}
                profileAllData={profileAllData}
              />
              <WesternOntarioShoulder
                wosiScore={wosiScore}
                AllData={AllData}
                functionData={functionData}
                profileAllData={profileAllData}
              />
              <SacsScore
                sacsScore={sacsScore}
                AllData={AllData}
                functionData={functionData}
                profileAllData={profileAllData}
              />
              <Satisfaction
                satisfactionData={satisfactionData}
                AllData={AllData}
                profileAllData={profileAllData}
              />
            </>
          )}
        </>
      )}
      {pScore === "true" && action === "2" && (
        <>
          <ClinicalExamination
            doctorefeedback={doctorefeedback}
            AllData={AllData}
            profileAllData={profileAllData}
          />
          <ClinicalExaminationBottom
            doctorefeedback={doctorefeedback}
            AllData={AllData}
            profileAllData={profileAllData}
          />
        </>
      )}

      {management === "true" && (
        <>
          <PersonalInformation
            profileAllData={profileAllData}
            AllData={AllData}
          />

          {viewPatient === "0" ? (
            <div className="no_data">Patient form is not filled yet </div>
          ) : (
            <>
              <ShoulderScoring
                shoulderPain={shoulderPain}
                AllData={AllData}
                functionData={functionData}
                profileAllData={profileAllData}
              />

              <WesternOntarioShoulder
                wosiScore={wosiScore}
                AllData={AllData}
                functionData={functionData}
                profileAllData={profileAllData}
              />

              <SacsScore
                sacsScore={sacsScore}
                AllData={AllData}
                functionData={functionData}
                profileAllData={profileAllData}
              />

              <Satisfaction
                satisfactionData={satisfactionData}
                AllData={AllData}
                profileAllData={profileAllData}
              />
            </>
          )}
        </>
      )}

      {viewDoctor !== "0" && management === "true" && (
        <>
          <ClinicalExamination
            doctorefeedback={doctorefeedback}
            AllData={AllData}
            profileAllData={profileAllData}
          />
          <ClinicalExaminationBottom
            doctorefeedback={doctorefeedback}
            AllData={AllData}
            profileAllData={profileAllData}
          />
        </>
      )}
    </div>
  );
}

export default ViewTreatmentForm;
