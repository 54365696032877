import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import {
  updateHandWristsatisfactionFieldScore,
  updateHandWristsatisfactionScores,
  updateHandWristPainScores,
  updateHandWristSymptomsScores,
  updateHandWristfunctionScores,
  updateHandWristqolScores,
  updateHandWristFeedbackScores,
  updateHandWristScores,
  updateHandWristUramScores,
  updateHandWristbctqScores,
  updateAllData,
} from "../../../../redux/actions/handWrist";
import useAxios from "../../../../axiosinstance";
import Checkbox from "../../../custom/checkbox/Checkbox";
import useTranslation from "../../../customHooks/translations";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import HipScoreRangeSlider from "../../../custom/hip-score-range-slider/HipScoreRangeSlider";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function HandWristQQL() {
  const dispatch = useDispatch();
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const evaluated = useSelector((state) => state.handWrist.evaluated);
  const [isLoading, setIsLoading] = useState(true);
  const [motionR, setMotionR] = useState("0");
  const [motionL, setMotionL] = useState("0");
  const [wristR, setWristR] = useState("0");
  const [wristL, setWristL] = useState("0");
  const [handR, setHandR] = useState("0");
  const [handL, setHandL] = useState("0");
  const [wristsurR, setWristsurR] = useState("0");
  const [wristsurL, setWristsurL] = useState("0");

  const [applicableR, setapplicableR] = useState(false);
  const [applicableL, setapplicableL] = useState(false);

  const [applicable1R, setapplicable1R] = useState(false);
  const [applicable1L, setapplicable1L] = useState(false);

  const [applicable2R, setapplicable2R] = useState(false);
  const [applicable2L, setapplicable2L] = useState(false);

  const [applicable3R, setapplicable3R] = useState(false);
  const [applicable3L, setapplicable3L] = useState(false);

  const [checkR, setcheckR] = useState(false);
  const [checkL, setcheckL] = useState(false);
  const [chk, setChk] = useState(false);
  const [initialasssment, setInitialAssesment] = useState("");
  const [doctorStatus, setDoctorStatus] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);


  const [syndrome, setSyndrome] = useState("0");
  const [disease, setDisease] = useState("0");
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateHandWristFeedbackScores(res.data.data2[0].doctorFeedback)
        );
        setDoctorStatus(res.data.doctorStatus);
        setChk(true);
        setSyndrome(res.data.data2[0].isSyndrome);
        setDisease(res.data.data2[0].isDisease);
        // setIsSyndrome(res.data.data[2].functionScore.isSyndrome)
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };



  useEffect(() => {
    save();
  }, []);
  const HandSatisfaction = useSelector(
    (state) => state.handWrist.satisfactionScore[0]
  );
  const json = useSelector((state) => state.handWrist);

  useEffect(() => {
    setMotionR(HandSatisfaction.motionR);
    setMotionL(HandSatisfaction.motionL);
    setWristR(HandSatisfaction.wristR);
    setWristL(HandSatisfaction.wristL);
    setHandR(HandSatisfaction.handR);
    setHandL(HandSatisfaction.handL);
    setWristsurR(HandSatisfaction.wristsurR);
    setWristsurL(HandSatisfaction.wristsurL);
    setcheckR(HandSatisfaction.checkR);
    setcheckL(HandSatisfaction.checkL);

    setapplicableR(HandSatisfaction.applicableR);
    setapplicableL(HandSatisfaction.applicableL);
    setapplicable1R(HandSatisfaction.applicable1R);
    setapplicable1L(HandSatisfaction.applicable1L);
    setapplicable2R(HandSatisfaction.applicable2R);
    setapplicable2L(HandSatisfaction.applicable2L);
    setapplicable3R(HandSatisfaction.applicable3R);
    setapplicable3L(HandSatisfaction.applicable3L);
  }, [HandSatisfaction]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${
          doctorEnable === "true" ? "1" : "0"
        }`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [HandSatisfaction, updateTotal]);

  const navigate = useNavigate();
  const handleMotionR = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "motionR",
        value: e,
      })
    );
    setMotionR(e);
  };
  const handleMotionL = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "motionL",
        value: e,
      })
    );
    setMotionL(e);
  };
  const handleWristR = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "wristR",
        value: e,
      })
    );
    setWristR(e);
  };
  const handleWristL = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "wristL",
        value: e,
      })
    );
    setWristL(e);
  };
  const handleHandR = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "handR",
        value: e,
      })
    );
    setHandR(e);
  };
  const handleHandL = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "handL",
        value: e,
      })
    );
    setHandL(e);
  };
  const handleWristsurtR = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "wristsurR",
        value: e,
      })
    );
    setWristsurR(e);
  };
  const handleWristsurtL = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "wristsurL",
        value: e,
      })
    );
    setWristsurL(e);
  };
  const handleCheckR = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "checkR",
        value: e,
      })
    );
    setcheckR(e);
  };
  const handleCheckL = (e) => {
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "checkL",
        value: e,
      })
    );
    setcheckL(e);
  };

  const handleapplicableR = (e) => {
    setapplicableR(e);
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "applicableR",
        value: e,
      })
    );
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "wristsurR",
        value: "",
      })
    );
  };

  const handleapplicableL = (e) => {
    setapplicableL(e);
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "applicableL",
        value: e,
      })
    );
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "wristsurL",
        value: "",
      })
    );
  };

  const handleapplicable1R = (e) => {
    setapplicable1R(e);
    // setImprovePainR("0")
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "applicable1R",
        value: e,
      })
    );
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "motionR",
        value: "",
      })
    );
  };

  const handleapplicable1L = (e) => {
    setapplicable1L(e);
    // setImprovePainL("0")
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "applicable1L",
        value: e,
      })
    );
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "motionL",
        value: "",
      })
    );
  };

  const handleapplicable2R = (e) => {
    setapplicable2R(e);
    // setImproveHomeR("0")
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "applicable2R",
        value: e,
      })
    );
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "wristR",
        value: "",
      })
    );
  };

  const handleapplicable2L = (e) => {
    setapplicable2L(e);
    // setImproveHomeL("0")
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "applicable2L",
        value: e,
      })
    );
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "wristL",
        value: "",
      })
    );
  };

  const handleapplicable3R = (e) => {
    setapplicable3R(e);
    // setImproveRecrR("0")
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "applicable3R",
        value: e,
      })
    );
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "handR",
        value: "",
      })
    );
  };

  const handleapplicable3L = (e) => {
    setapplicable3L(e);
    // setImproveRecrL("0")
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "applicable3L",
        value: e,
      })
    );
    dispatch(
      updateHandWristsatisfactionFieldScore({
        key: "handL",
        value: "",
      })
    );
  };


  const continueBack =(e) =>{
    if (syndrome === "2") {
      navigate("/hand-wrist-bctq/" + rId);
    } else if (disease === "2") {
      navigate("/hand-wrist-uram/" + rId);
    } else navigate("/hand-wrist-qol/" + rId);
  }

  const continueRedirect = (e) => {
    dispatch(
      updateHandWristsatisfactionScores([
        {
          motionR: motionR,
          motionL: motionL,
          wristR: wristR,
          wristL: wristL,
          handR: handR,
          handL: handL,
          wristsurR: wristsurR,
          wristsurL: wristsurL,
          applicableR: applicableR,
          applicableL: applicableL,
          applicable1R: applicable1R,
          applicable1L: applicable1L,
          applicable2R: applicable2R,
          applicable2L: applicable2L,
          applicable3R: applicable3R,
          applicable3L: applicable3L,
        },
      ])
    );

    navigate("/hand-wrist-complete/" + rId);
  };
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const SubmitForm = (id) => {
    axiosinstance.current.patch(
      `extapp/forms/update_scoring?resource_id=${id}`,
      {
        patient_scoring_date: new Date(),
        status: 1,
      }
    );
  };

  let akpt = searchParams.get("akpt");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    SubmitForm(rId);
    setmsgType("success");
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('23') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=23`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };

  const handleNext=()=>{
    navigate(`/hand-wrist-feedback/${rId}?d=true&akpt=${akpt}`);

  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HandWristTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_hand_satisfaction}
              </h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_hand_satisfaction_instrucion}
                    </label>{" "}
                    <br />
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>0</strong> ={" "}
                      {translation.patient_hand_satisfaction_instrucion_0},{" "}
                      <strong>1</strong> ={" "}
                      {translation.patient_hand_satisfaction_instrucion_1},{" "}
                      <strong>2</strong> ={" "}
                      {translation.patient_hand_satisfaction_instrucion_2},{" "}
                      <strong>3</strong> =
                      {translation.patient_hand_satisfaction_instrucion_3},{" "}
                      <strong>4</strong> ={" "}
                      {translation.patient_hand_satisfaction_instrucion_4})
                    </span>
                  </div>
                </div>
                <div className="row">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}
                    >
                      {/* <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio3" name="radio3" value={applicable1R} checked={applicable1R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable1R(e.target.checked)}></input>
                                                {translation.patient_hand_satisfaction_q1_na}
                                            </label> */}
                      <HipScoreRangeSlider
                        maximun="0"
                        minimum="4"
                        label={
                          translation.patient_hand_satisfaction_q1 +
                          RigthTrans(evaluated, translation)
                        }
                        value={motionR}
                        onChange={handleMotionR}
                        // onClick={handleMotionR}
                        classStyle="pb-0"
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_satisfaction_q1_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_satisfaction_q1_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_satisfaction_q1_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_satisfaction_q1_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_satisfaction_q1_a5,
                          },
                        ]}
                        view={applicable1R === true ? true : false}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}
                    >
                      {/* <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio4" name="radio4" value={applicable1L} checked={applicable1L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable1L(e.target.checked)}></input>

                                                {translation.patient_hand_satisfaction_q1_na}
                                            </label> */}
                      <HipScoreRangeSlider
                        maximun="0"
                        minimum="4"
                        label={
                          translation.patient_hand_satisfaction_q1 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={motionL}
                        onChange={handleMotionL}
                        // onClick={handleMotionL}
                        classStyle="pb-0"
                        view={applicable1L === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_satisfaction_q1_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_satisfaction_q1_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_satisfaction_q1_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_satisfaction_q1_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_satisfaction_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row mt-3">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}
                    >
                      {/* <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio5" name="radio5" value={applicable2R} checked={applicable2R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable2R(e.target.checked)}></input>

                                                {translation.patient_hand_satisfaction_q2_na}
                                            </label> */}
                      <HipScoreRangeSlider
                        maximun="0"
                        minimum="4"
                        label={
                          translation.patient_hand_satisfaction_q2 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={wristR}
                        classStyle="pb-0"
                        view={applicable2R === true ? true : false}
                        onChange={handleWristR}
                        // onClick={handleWristR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_satisfaction_q2_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_satisfaction_q2_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_satisfaction_q2_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_satisfaction_q2_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_satisfaction_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}
                    >
                      {/* <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio6" name="radio6" value={applicable2L} checked={applicable2L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable2L(e.target.checked)}></input>
                                                {translation.patient_hand_satisfaction_q2_na}
                                            </label> */}
                      <HipScoreRangeSlider
                        maximun="0"
                        minimum="4"
                        label={
                          translation.patient_hand_satisfaction_q2 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        classStyle="pb-0"
                        value={wristL}
                        onChange={handleWristL}
                        // onClick={handleWristL}
                        view={applicable2L === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_satisfaction_q2_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_satisfaction_q2_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_satisfaction_q2_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_satisfaction_q2_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_satisfaction_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row mt-3">
                  {evaluated === "1" || evaluated === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}
                    >
                      {/* <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio7" name="radio7" value={applicable3R} checked={applicable3R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable3R(e.target.checked)}></input>

                                                {translation.patient_hand_satisfaction_q3_na}
                                            </label> */}
                      <HipScoreRangeSlider
                        maximun="0"
                        minimum="4"
                        label={
                          translation.patient_hand_satisfaction_q3 +
                          RigthTrans(evaluated, translation)
                        }
                        classes=""
                        value={handR}
                        classStyle="pb-0"
                        onChange={handleHandR}
                        // onClick={handleHandR}
                        view={applicable3R === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_satisfaction_q3_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_satisfaction_q3_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_satisfaction_q3_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_satisfaction_q3_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_satisfaction_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "2" || evaluated === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}
                    >
                      {/* <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio8" name="radio8" value={applicable3L} checked={applicable3L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable3L(e.target.checked)}></input>

                                                {translation.patient_hand_satisfaction_q3_na}
                                            </label> */}
                      <HipScoreRangeSlider
                        maximun="0"
                        minimum="4"
                        label={
                          translation.patient_hand_satisfaction_q3 +
                          LeftTrans(evaluated, translation)
                        }
                        classes=""
                        value={handL}
                        classStyle="pb-0"
                        onChange={handleHandL}
                        // onClick={handleHandL}
                        view={applicable3L === true ? true : false}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_hand_satisfaction_q3_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_hand_satisfaction_q3_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hand_satisfaction_q3_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_hand_satisfaction_q3_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_hand_satisfaction_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row mt-3">
                  {
                    <>
                      {evaluated === "1" || evaluated === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + RightSide()
                          }
                        >
                          <label class="form-check-label not-applicable">
                            <input
                              className="check_ mx-3"
                              type="checkbox"
                              id="radio1"
                              name="radio1"
                              value={applicableR}
                              checked={applicableR}
                              style={{ marginRight: 10 }}
                              onChange={(e) =>
                                handleapplicableR(e.target.checked)
                              }
                            ></input>

                            {translation.patient_hand_satisfaction_q4_na}
                          </label>
                          <HipScoreRangeSlider
                            maximun="0"
                            minimum="4"
                            label={
                              translation.patient_hand_satisfaction_q4 +
                              RigthTrans(evaluated, translation)
                            }
                            classes=""
                            value={wristsurR}
                            onChange={handleWristsurtR}
                            // onClick={handleWristsurtR}
                            view={applicableR === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_hand_satisfaction_q4_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_hand_satisfaction_q4_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_hand_satisfaction_q4_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_hand_satisfaction_q4_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_hand_satisfaction_q4_a5,
                              },
                            ]}
                          />
                          {/* <Checkbox
                                            value={checkR}
                                            selected={checkR}
                                            onChange={handleCheckR}
                                            label="Not Applicable"
                                            /> */}
                        </div>
                      ) : (
                        ""
                      )}
                      {evaluated === "2" || evaluated === "3" ? (
                        <div
                          className={
                            "col-md-6 col-sm-6 mb_10 satis" + LeftSide()
                          }
                        >
                          <label class="form-check-label not-applicable">
                            <input
                              className="check_ mx-3"
                              type="checkbox"
                              id="radio2"
                              name="radio2"
                              value={applicableL}
                              checked={applicableL}
                              style={{ marginRight: 10 }}
                              onChange={(e) =>
                                handleapplicableL(e.target.checked)
                              }
                            ></input>

                            {translation.patient_hand_satisfaction_q4_na}
                          </label>
                          <HipScoreRangeSlider
                            maximun="0"
                            minimum="4"
                            label={
                              translation.patient_hand_satisfaction_q4 +
                              LeftTrans(evaluated, translation)
                            }
                            classes=""
                            value={wristsurL}
                            onChange={handleWristsurtL}
                            // onClick={handleWristsurtL}
                            view={applicableL === true ? true : false}
                            sliderData={[
                              {
                                score: "0",
                                label:
                                  translation.patient_hand_satisfaction_q4_a1,
                              },
                              {
                                score: "1",
                                label:
                                  translation.patient_hand_satisfaction_q4_a2,
                              },
                              {
                                score: "2",
                                label:
                                  translation.patient_hand_satisfaction_q4_a3,
                              },
                              {
                                score: "3",
                                label:
                                  translation.patient_hand_satisfaction_q4_a4,
                              },
                              {
                                score: "4",
                                label:
                                  translation.patient_hand_satisfaction_q4_a5,
                              },
                            ]}
                          />

                          {/* <Checkbox
                                            value={checkL}
                                            selected={checkL}
                                            onChange={handleCheckL}
                                            label="Not Applicable"
                                            /> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  }
                </div>

                <br />
              </div>

              {doctorEnable === "true" && (
                <>
                  {doctorStatus ? (
                    <>
                      <div className="row mb_30 button_box">
                        <div className="col-md-6 col-sm-6">

                        </div>
                        <div className="col-md-6 col-sm-6 text-end">
                          <button
                            className="next_btn btn_fill me-3"
                            onClick={() => handleNext()}
                          >
                            {translation.next_button}
                            <span>
                              <img src={nextIcon} alt="" />
                              {/* <img src={nextWhiteIcon} alt="" /> */}
                            </span>
                          </button>
                          <button
                            className="next_btn btn_fill"
                            onClick={() => handleSubmit()}
                          >
                            {translation.submit}
                            <span>
                              <img src={nextIcon} alt="" />
                              {/* <img src={nextWhiteIcon} alt="" /> */}
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row mb_30 button_box">
                      <div className="col-md-6 col-sm-6">
                      </div>
                      <div className="col-md-6 col-sm-6 text-end">
                        <button
                          className="next_btn btn_fill"
                          onClick={() => handleSubmit()}
                        >
                          {"Submit"}
                          <span>
                            <img src={nextIcon} alt="" />
                            {/* <img src={nextWhiteIcon} alt="" /> */}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}

                </>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">

                    {/* <Link to={"/hand-wrist-function/" + rId} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> */}
                    <button
                      className="next_btn btn_fill"
                      onClick={continueBack}
                    >
                      {translation.back_button}
                      <span>
                        <img src={backIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>

                    {/* <span onClick={()=>Back()} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HandWristQQL;
