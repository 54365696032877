import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab, Badge } from "react-bootstrap";
import "./Tab.css";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import useAxios from "../../../axiosinstance";

export default function ShoulderTab({ rId }) {
  const instabilityACjoint = useSelector(
    (state) => state.shoulderScore.functionScore[0].instability_acjoint
  );
  const feel_unstable = useSelector(
    (state) => state.shoulderScore.functionScore[0].feel_unstable
  );

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const pId = queryParams.get("akpt");
  const [patientStatus, setPatientStatus] = useState(false);
  const [doctorStatus, setDoctorStatus] = useState(false);
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [key, setKey] = useState(splitLocation[1]);
  const [ptid, setPtId] = useState("");

  const is_doctor_form = queryParams.get("is_doctor_form");
  // useEffect(()=>{
  //     localStorage.setItem("isdoctorForm",is_doctor_form)
  // },[is_doctor_form])
  const axiosinstance = useAxios();
  const getTab = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {

        setPatientStatus(res.data.status);
        setDoctorStatus(res.data.doctorStatus);
        setPtId(res.data.data2[0].ak_pt);
        if (res.data.status) {
          // handleTabSelect("pain");
        } else if (res.data.doctorStatus) {
          if (splitLocation[2] !== "step-2") {
            handleTabSelect("shoulder-treatment-form/step-1");
          }
        } else {
          navigate("/un-authorized-access");
        }
      })
      .catch((err) => {
        // navigate('/un-authorized-access');
      });
  };
  useEffect(() => {
    // alert(1);
    getTab();
  }, []);
  let doctordata=localStorage.getItem("isdoctorForm")
  let akDc = sessionStorage.getItem("akDc");
  let akOu = sessionStorage.getItem("akOu");
  const handleTabSelect = (selectedTab) => {
    setKey(selectedTab);
    switch (selectedTab) {
      case "personal":
        navigate(
          `/personal-data-edit/${window.btoa(
            ptid
          )}/${akDc}/${akOu}/${window.btoa(
            4
          )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
        );
        break;
      case "pain":
        navigate(`/pain/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
      case "function":
        navigate(`/function/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
        break;
      case "feeling-insta":
        navigate(`/feeling-insta/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
      case "ac-insta":
        navigate(`/ac-insta/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
      case "wosi":
        navigate(`/wosi/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
      case "sacs":
        navigate(`/sacs/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
      case "satisfaction":
        navigate(`/satisfaction/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;

      case "shoulder-treatment-form/step-1":
        navigate(`/shoulder-treatment-form/step-1/${rId}?d=true&akpt=${pId}&is_doctor_form=${doctordata}`);
        break;

      case "shoulder-treatment-form/step-2":
        navigate(`/shoulder-treatment-form/step-2/${rId}?d=true&akpt=${pId}&is_doctor_form=${doctordata}`);
        break;

      default:
        break;
    }
  };
  return (
    <>
      {}
      <div className="col-md-12">
        <Tabs
          id="doctor-reg-tabs"
          activeKey={key}
          // onSelect={handleSelect}
          className="doctor_reg_tabs mb-1"
          onSelect={(e) => handleTabSelect(e)}
        >
        {patientStatus && (
            <Tab
              eventKey="personal"
              title={<React.Fragment>Personal Information</React.Fragment>}
            ></Tab>
          )}
          {patientStatus && (
            <Tab
              eventKey="pain"
              title={<React.Fragment>Pain</React.Fragment>}
            ></Tab>
          )}
          {patientStatus && (
            <Tab
              eventKey="function"
              title={<React.Fragment>Function</React.Fragment>}
            ></Tab>
          )}
          {patientStatus && (
            <Tab
              eventKey="feeling-insta"
              title={<React.Fragment>Feeling of instability</React.Fragment>}
            ></Tab>
          )}
          {patientStatus && feel_unstable === "1" ? (
           
                <Tab
                  eventKey="wosi"
                  title={<React.Fragment>WOSI</React.Fragment>}
                ></Tab>
            
          ):""}
          {patientStatus && (
            <Tab
              eventKey="ac-insta"
              title={<React.Fragment>AC joint instability</React.Fragment>}
            ></Tab>
          )}
          {patientStatus &&instabilityACjoint === "1"? (
         
                <Tab
                  eventKey="sacs"
                  title={<React.Fragment>SACS</React.Fragment>}
                ></Tab>
             
          ):""}
          {patientStatus && (
            <Tab
              eventKey="satisfaction"
              title={<React.Fragment>Satisfaction</React.Fragment>}
            ></Tab>
          )}
          {doctorStatus && (
            <Tab
              eventKey="shoulder-treatment-form/step-1"
              title={<React.Fragment>Patient-Assessment-Step 1</React.Fragment>}
            ></Tab>
          )}
          {doctorStatus && (
            <Tab
              eventKey="shoulder-treatment-form/step-2"
              title={<React.Fragment>Patient-Assessment-Step 2</React.Fragment>}
            ></Tab>
          )}
        </Tabs>
      </div>
    </>
  );
}
