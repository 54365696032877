import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from "react-redux";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";

import {
  updateHandWristScores,
  updateHandWristPainFieldScore,
  updateHandWristPainScores,
  updateHandWristqolScores,
  updateHandWristsatisfactionScores,
  updateHandWristSymptomsScores,
  updateHandWristfunctionScores,
  updateHandWristFeedbackScores,
  updateHandWristUramScores,
  updateHandWristbctqScores,
  updateAllData,
} from "../../../../redux/actions/handWrist";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";
import PersonalData from "../../shoulder-forms/personaldata/PersonalData";

// import Button from "../../custom/button/Button";
export default function HandWristpain() {
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [painTodayRating, setPainTodayRating] = useState("0");
  const [painTodayRatingL, setPainTodayRatingL] = useState("0");
  const [howBadThisMomentR, sethowBadThisMomentR] = useState("0");
  const [howBadThisMomentL, sethowBadThisMomentL] = useState("0");
  const [painHandR, setPainHandR] = useState("0");
  const [painHandL, setPainHandL] = useState("0");
  const navigate = useNavigate();
  const evaluated = useSelector((state) => state.handWrist.evaluated);
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [backD, setbackD] = useState({});
  const [backDoc, setBackDoc] = useState("");
  let isdoctorForm = searchParams.get("is_doctor_form");
 let viewicon= searchParams.get("edit")
  localStorage.setItem("viewicon",viewicon)
  useEffect(() => {
    if (doctorEnable === "true") {
      let doctordata = localStorage.getItem("isdoctorForm")
      localStorage.setItem("isdoctorForm", doctordata)
    } else {
      localStorage.setItem("isdoctorForm", isdoctorForm)
    }
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }, [isdoctorForm])
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        setBackDoc(res.data.data2[0].is_doctor_form);
        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateHandWristFeedbackScores(res.data.data2[0].doctorFeedback)
        );
        localStorage.setItem("handandwristsyndromdata", res.data.data2[0].isSyndrome)
        localStorage.setItem("handandwristdiseasedata", res.data.data2[0].isDisease)

        // console.log(res.data.data2[0].isSyndrome,"data");
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
    backData();
  }, []);
  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
        sessionStorage.setItem("orgId", res.data.data.org_id);
        sessionStorage.setItem("akdc", res.data.data.ak_dc);
      });
  };
  const HandPain = useSelector((state) => state.handWrist.painScore[0]);
  const json = useSelector((state) => state.handWrist);
  useEffect(() => {
    setPainTodayRating(HandPain.painTodayRating);
    setPainTodayRatingL(HandPain.painTodayRatingL);
    setPainHandR(HandPain.painHandR);
    setPainHandL(HandPain.painHandL);
    sethowBadThisMomentL(HandPain.howBadThisMomentL);
    sethowBadThisMomentR(HandPain.howBadThisMomentR);
  }, [HandPain]);
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
       
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [HandPain, updateTotal]);
  const handlePainTodayRating = (e) => {
    setPainTodayRating(e);
    dispatch(
      updateHandWristPainFieldScore({
        key: "painTodayRating",
        value: e,
      })
    );
  };
  const handlePainTodayRatingL = (e) => {
    setPainTodayRatingL(e);
    dispatch(
      updateHandWristPainFieldScore({
        key: "painTodayRatingL",
        value: e,
      })
    );
  };

  const handlehowBadThisMomentR = (e) => {
    sethowBadThisMomentR(e);
    dispatch(
      updateHandWristPainFieldScore({
        key: "howBadThisMomentR",
        value: e,
      })
    );
  };
  const handlehowBadThisMomentL = (e) => {
    sethowBadThisMomentL(e);
    dispatch(
      updateHandWristPainFieldScore({
        key: "howBadThisMomentL",
        value: e,
      })
    );
  };

  const handlepainHandR = (e) => {
    setPainHandR(e);
    dispatch(
      updateHandWristPainFieldScore({
        key: "painHandR",
        value: e,
      })
    );
  };
  const handlepainHandL = (e) => {
    setPainHandL(e);
    dispatch(
      updateHandWristPainFieldScore({
        key: "painHandL",
        value: e,
      })
    );
  };
  const handleEvoluted = (e) => {
    dispatch(updateHandWristScores(e));
  };
  const continueRedirect = (e) => {
    dispatch(
      updateHandWristPainScores([
        {
          painTodayRating: painTodayRating,
          painTodayRatingL: painTodayRatingL,
          howBadThisMomentR: howBadThisMomentR,
          howBadThisMomentL: howBadThisMomentL,
          painHandR: painHandR,
          painHandL: painHandL,
        },
      ])
    );
    navigate("/hand-wrist-symptoms/" + rId+`?is_doctor_form=${isdoctorForm}`);
  };


  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('23') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=23`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext=()=>{
    navigate(`/hand-wrist-symptoms/${rId}?d=true&akpt=${akpt}&is_doctor_form=${isdoctorForm}`);

  }


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HandWristTab rId={rId} />}
              {/* {doctorEnable === "true" && <PersonalDataEdit />} */}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">{translation.patient_hand_pain}</h4>
              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  <div className="row mt-4">
                    {evaluated === "1" || evaluated === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hand_pain_q1 +
                            RigthTrans(evaluated, translation)
                          }
                          styleClass=""
                          value={painTodayRating}
                          onChange={handlePainTodayRating}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hand_pain_q1_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hand_pain_q1_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hand_pain_q1_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hand_pain_q1_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hand_pain_q1_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evaluated === "2" || evaluated === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hand_pain_q1 +
                            LeftTrans(evaluated, translation)
                          }
                          styleClass=""
                          value={painTodayRatingL}
                          onChange={handlePainTodayRatingL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hand_pain_q1_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hand_pain_q1_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hand_pain_q1_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hand_pain_q1_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hand_pain_q1_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {evaluated === "2" || evaluated === "3" ? (
                    <div className="row mt-4">
                      <div className="mb_20">
                        <RangeSlider
                          id="howBadThisMomentL"
                          min="0"
                          max="10"
                          step="1"
                          type="both"
                          leftTitle={translation.patient_hand_pain_q2_a1}
                          rightTitle={translation.patient_hand_pain_q2_a2}
                          label={
                            translation.patient_hand_pain_q2 +
                            LeftTrans(evaluated, translation)
                          }
                          // label={translation.Describe_the_pain_hand_wrist}
                          value={howBadThisMomentL}
                          onChange={handlehowBadThisMomentL}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {evaluated === "1" || evaluated === "3" ? (
                    <div className="row mt-5">
                      <div className="mb_20">
                        <RangeSlider
                          id="howBadThisMomentR"
                          min="0"
                          max="10"
                          step="1"
                          type="both"
                          leftTitle={translation.patient_hand_pain_q2_a1}
                          rightTitle={translation.patient_hand_pain_q2_a2}
                          label={
                            translation.patient_hand_pain_q2 +
                            RigthTrans(evaluated, translation)
                          }
                          value={howBadThisMomentR}
                          onChange={handlehowBadThisMomentR}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row mt-5">
                    {evaluated === "1" || evaluated === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hand_pain_q3 +
                            RigthTrans(evaluated, translation)
                          }
                          styleClass=""
                          value={painHandR}
                          onChange={handlepainHandR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hand_pain_q3_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hand_pain_q3_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hand_pain_q3_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hand_pain_q3_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hand_pain_q3_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evaluated === "2" || evaluated === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_hand_pain_q3 +
                            LeftTrans(evaluated, translation)
                          }
                          styleClass=""
                          value={painHandL}
                          onChange={handlepainHandL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_hand_pain_q3_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_hand_pain_q3_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_hand_pain_q3_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_hand_pain_q3_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_hand_pain_q3_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {doctorEnable === "true" && (
                    <div className="row mb_30 button_box">
                      <div className="col-md-6 col-sm-6"></div>
                      <div className="col-md-6 col-sm-6 text-end">
                      <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                        <button
                          className="next_btn btn_fill"
                          onClick={() => handleSubmit()}
                        >
                          {translation.submit}
                          <span>
                            <img src={nextIcon} alt="" />
                            {/* <img src={nextWhiteIcon} alt="" /> */}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                {doctorEnable !== "true" && viewicon!=="true" &&   (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {backDoc != 1 ? <Link
                      to={`/patient-personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.type)}?r=${window.btoa(rId)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> : <Link
                      to={`/personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                        backD.org_id
                      )}/${window.btoa(backD.type)}?is_doctor_form=${isdoctorForm}&doctorvalue=0`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>}


                    {/* <span onClick={()=>Back()} className="assm_back_btn c_pointer">
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
              { viewicon==="true"  &&(
                <div className="row mb_30 button_box">
                <div className="col-md-12 col-sm-12 col-12 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                  </div>
              )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
