import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";
import degreeWidgetStyles from "./AssessmentDegree.module.css";

function AssessmentNagetiveWidget({
  title,
  value,
  validators,
  labelR,
  labelL,
  nameR,
  nameL,
  onChange,
  onChangeR,
  onChangeL,
  valueR,
  valueL,
  validator,
  validatorL,
}) {
  const [countR, setCountR] = useState(valueR);
  const [countL, setCountL] = useState(valueL);
  const [errorValR, setErrorValR] = useState(false);
  const [errorValL, setErrorValL] = useState(false);
  const [errorR, setErrorR] = useState(false);
  const [errorL, setErrorL] = useState(false);
  const [errorMsgR, setErrorMsgR] = useState("Number Should be -50 to 100");
  const [errorMsgL, setErrorMsgL] = useState("Number Should be -50 to 100");

  useEffect(() => {
    setCountR(valueR);
    setCountL(valueL);
  }, [valueR, valueL]);

  // regex for avoiding negative values
  const regex = /^\-?[1-9]\d{0,2}(\.\d*)?$/;

  const handleChangeR = (event) => {
    const { value } = event.target;
    let newValue = value;
    setErrorValR(validateInput(validators, value));
    if (newValue >= -50 && newValue <= 100.0) {
      setCountR(newValue);
      onChangeR(newValue);
      setErrorR(false);
      console.log("✅ num is between values");
    } else {
      console.log("⛔️ num is NOT between values");
      setCountR(-50);
      setErrorR(true);
    }
  };


  // Create handleIncrement event handler
  const handleIncrementR = () => {
    if (isNaN(countR) && countR === "") {
      setCountR(-50);
    } else {
      setCountR(countR >= 100 ? 100 :Number(countR) + 1);
      onChangeR(countR >= 100 ? 100 : Number(countR )+ 1);
    }
  };

  //Create handleDecrement event handler
  const handleDecrementR = () => {
 
      setCountR(countR <= -50 ? -50 : countR - 1);
      onChangeR(countR <= -50 ? -50 : countR - 1);

  };

  const handleChangeL = (event) => {
    const { value } = event.target;
    let newValue = value;
    setErrorValL(validateInput(validators, value));

    if (newValue >= -50 && newValue <= 100.0) {
      setCountL(newValue);
      onChangeL(newValue);
      setErrorL(false);
      console.log("✅ num is between values");
    } else {
      console.log("⛔️ num is NOT between values");
      setCountL(-50);
      setErrorL(true);
    }
  };

  // Create handleIncrement event handler
  const handleIncrementL = () => {
    if (isNaN(countR) && countR === "") {
      setCountR(-50);
    } else {
    setCountL(countL >= 100 ? 100 :Number(countL) + 1);
    onChangeL(countL >= 100 ? 100 :Number(countL) + 1);
    }
  };

  //Create handleDecrement event handler
  const handleDecrementL = () => {
    
      setCountL(countL <= -50 ? -50 : countL - 1);
      onChangeL(countL <= -50 ? -50 : countL - 1);
  
  };

  return (
    <>
      <div className={`${degreeWidgetStyles.input_box_main}`}>
        <h4 className={`${degreeWidgetStyles.title_txt}`}>{title}</h4>
        <div className={`${degreeWidgetStyles.number_box_row}`}>
          <div className={`${degreeWidgetStyles.number_box_col}`}>
            <label className={`${degreeWidgetStyles.requiredFieldRightF}`}>
              {labelR}
            </label>
            <div className={`${degreeWidgetStyles.degree_box}`}>
              <span
                className={`${degreeWidgetStyles.qty_minus}`}
                onClick={handleDecrementR}
              >
                -
              </span>
              <input
                type="number"
                max={100}
                min={-50}
                step={0.01}
                className="qty trim"
                name={nameR}
                value={countR}
                placeholder="000"
                onChange={handleChangeR}
                onBlur={handleChangeR}
              />
              <span
                className={`${degreeWidgetStyles.qty_plus}`}
                onClick={handleIncrementR}
              >
                +
              </span>
            </div>
            <span className={`${degreeWidgetStyles.degree_txt}`}>Degrees</span>
            {/* {valueR ==0 || valueR==null  &&(
              <> */}
            {errorR && (
              <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                {errorMsgR}
              </span>
            )}
            {/* </>
            )} */}

            {valueR == "" || valueR == null ? (
              <>
                {validator === true && (
                  <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                    This field is required
                  </span>
                )}
              </>
            ) : null}

            {errorValR && (
              <span className="text-danger">{errorValR.message}</span>
            )}
          </div>
          <div className={`${degreeWidgetStyles.number_box_col}`}>
            <label className={`${degreeWidgetStyles.requiredFieldLeft}`}>
              {labelL}
            </label>
            <div className={`${degreeWidgetStyles.degree_box}`}>
              <span
                className={`${degreeWidgetStyles.qty_minus}`}
                onClick={handleDecrementL}
              >
                -
              </span>
              <input
                type="number"
                max={100}
                min={-50}
                step={0.01}
                className="qty trim"
                name={nameL}
                value={countL}
                placeholder="000"
                onChange={handleChangeL}
                onBlur={handleChangeL}
              />
              <span
                className={`${degreeWidgetStyles.qty_plus}`}
                onClick={handleIncrementL}
              >
                +
              </span>
            </div>
            <span className={`${degreeWidgetStyles.degree_txt}`}>Degrees</span>
            {valueL !== "" && (
              <>
                {errorL && (
                  <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                    {errorMsgL}
                  </span>
                )}
              </>
            )}

            {valueL == "" || valueL == null ? (
              <>
                {validatorL === true && (
                  <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                    This field is required
                  </span>
                )}
              </>
            ) : null}
            {errorValL && (
              <span className="text-danger">{errorValL.message}</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

AssessmentNagetiveWidget.propTypes = {
  validators: PropTypes.array,
};

AssessmentNagetiveWidget.defaultProps = {
  value: "",
  title: "",
  labelR: "",
  labelL: "",
  nameR: "",
  nameL: "",
  validators: [],
};

export default AssessmentNagetiveWidget;
