export const updateElbowScores = (scores) => {
  return {
    type: 'UPDATE_ELBOW_DATA',
    data: scores,
  };
};
export const updateElbowPainFieldScore = (score) => {
  return {
    type: "UPDATE_ELBOW_PAIN_FIELD_DATA",
    data: score,
  };

};
export const updateElbowPainData = (score) => {
  return {
    type: "UPDATE_ELBOW_PAIN_DATA",
    data: score,
  };
};

export const updateElbowFunctionFieldScore = (score) => {
  return {
    type: "UPDATE_ELBOW_FUNCTION_FIELD_DATA",
    data: score,
  };

};
export const updateElbowFunctionData = (score) => {
  return {
    type: "UPDATE_ELBOW_FUNCTION_DATA",
    data: score,
  };
};

export const updateElbowSymptomsFieldScore = (score) => {
  return {
    type: "UPDATE_ELBOW_SYMPTOMS_FIELD_DATA",
    data: score,
  };

};
export const updateElbowSymptomsData = (score) => {
  return {
    type: "UPDATE_ELBOW_SYMPTOMS_DATA",
    data: score,
  };
};

export const updateElbowQOLFieldScore = (score) => {
  return {
    type: "UPDATE_ELBOW_QOL_FIELD_DATA",
    data: score,
  };

};
export const updateElbowQOLData = (score) => {
  return {
    type: "UPDATE_ELBOW_QOL_DATA",
    data: score,
  };
};

export const updateElbowSatisficationFieldScore = (score) => {
  return {
    type: "UPDATE_ELBOW_SATISFACTION_FIELD_DATA",
    data: score,
  };

};
export const updateElbowSatisficationData = (score) => {
  return {
    type: "UPDATE_ELBOW_SATISFACTION_DATA",
    data: score,
  };
};

export const updateElbowTotalScores = (scores) => {
  return {
    type: 'UPDATE_ELBOW_TOTAL_SCORE',
    data: scores,
  };
};
export const updateElbowFeedbackScores = (scores) => {
  return {
    type: 'UPDATE_ELBOW_FEEDBACK_FIELD_DATA',
    data: scores,
  };
};
export const updateElbowFeedback = (scores) => {
  return {
    type: 'UPDATE_ELBOW_FEEDBACK_SCORE',
    data: scores,
  };
};
export const updateAllData = (state, dispatch) => {
  const [painSco] =  state.painScore
  const [functionScore] =  state.functionScore
  const [symptomsScore] =  state.symptomsScore
  const [qolScore] =  state.qolScore
  const [satisfactionScore] =  state.satisfactionScore

  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }

  //HSS
  const HHSElbowPainR = painSco.decElbowPainR === "4" ? 50
    : painSco.decElbowPainR === "3" ? 45
      : painSco.decElbowPainR === "2" ? 35
        : painSco.decElbowPainR === "1" ? 15
          : painSco.decElbowPainR === "0" ? 0
            : 0;

  const HHSElbowPainL = painSco.decElbowPainL === "4" ? 50
    : painSco.decElbowPainL === "3" ? 45
      : painSco.decElbowPainL === "2" ? 35
        : painSco.decElbowPainL === "1" ? 15
          : painSco.decElbowPainL === "0" ? 0
            : 0;
  // MEPS
  const MEPSElbowPainR = painSco.decElbowPainR === "4" ? 45
    : painSco.decElbowPainR === "3" ? 30
      : painSco.decElbowPainR === "2" ? 15
        : painSco.decElbowPainR === "1" ? 15
          : painSco.decElbowPainR === "0" ? 0
            : 0;

  const MEPSElbowPainL = painSco.decElbowPainL === "4" ? 45
    : painSco.decElbowPainL === "3" ? 30
      : painSco.decElbowPainL === "2" ? 15
        : painSco.decElbowPainL === "1" ? 15
          : painSco.decElbowPainL === "0" ? 0
            : 0;

  const MEPSfunHygienegR = functionScore.funHygienegR === "4" ? 0
    : functionScore.funHygienegR === "3" ? 5
      : functionScore.funHygienegR === "2" ? 5
        : functionScore.funHygienegR === "1" ? 5
          : functionScore.funHygienegR === "0" ? 5
            : 0;
  const MEPSfunHygienegL = functionScore.funHygienegL === "4" ? 0
    : functionScore.funHygienegL === "3" ? 5
      : functionScore.funHygienegL === "2" ? 5
        : functionScore.funHygienegL === "1" ? 5
          : functionScore.funHygienegL === "0" ? 5
            : 0;

  const MEPSfunUtensilR = functionScore.funUtensilR === "4" ? 0
    : functionScore.funUtensilR === "3" ? 5
      : functionScore.funUtensilR === "2" ? 5
        : functionScore.funUtensilR === "1" ? 5
          : functionScore.funUtensilR === "0" ? 5
            : 0;

  const MEPSFfunUtensilL = functionScore.funUtensilL === "4" ? 0
    : functionScore.funUtensilL === "3" ? 5
      : functionScore.funUtensilL === "2" ? 5
        : functionScore.funUtensilL === "1" ? 5
          : functionScore.funUtensilL === "0" ? 5
            : 0;

  const MEPSfunShirtR = functionScore.funShirtR === "4" ? 0
    : functionScore.funShirtR === "3" ? 5
      : functionScore.funShirtR === "2" ? 5
        : functionScore.funShirtR === "1" ? 5
          : functionScore.funShirtR === "0" ? 5
            : 0;
  const MEPSfunShirtL = functionScore.funShirtL === "4" ? 0
    : functionScore.funShirtL === "3" ? 5
      : functionScore.funShirtL === "2" ? 5
        : functionScore.funShirtL === "1" ? 5
          : functionScore.funShirtL === "0" ? 5
            : 0;

  const MEPSfunHairR = functionScore.funHairR === "4" ? 0
    : functionScore.funHairR === "3" ? 5
      : functionScore.funHairR === "2" ? 5
        : functionScore.funHairR === "1" ? 5
          : functionScore.funHairR === "0" ? 5
            : 0;
  const MEPSfunHairL = functionScore.funHairL === "4" ? 0
    : functionScore.funHairL === "3" ? 5
      : functionScore.funHairL === "2" ? 5
        : functionScore.funHairL === "1" ? 5
          : functionScore.funHairL === "0" ? 5
            : 0;
  const MEPSfunPutShoesR = functionScore.funPutShoesR === "4" ? 0
    : functionScore.funPutShoesR === "3" ? 5
      : functionScore.funPutShoesR === "2" ? 5
        : functionScore.funPutShoesR === "1" ? 5
          : functionScore.funPutShoesR === "0" ? 5
            : 0;

  const MEPSfunPutShoesL = functionScore.funPutShoesL === "4" ? 0
    : functionScore.funPutShoesL === "3" ? 5
      : functionScore.funPutShoesL === "2" ? 5
        : functionScore.funPutShoesL === "1" ? 5
          : functionScore.funPutShoesL === "0" ? 5
            : 0;

  //   VAS     

  const VASRateElbow = painSco.moment === "0" ? 0
    : painSco.moment === "1" ? 1
      : painSco.moment === "2" ? 2
        : painSco.moment === "3" ? 3
          : painSco.moment === "4" ? 4
            : painSco.moment === "5" ? 5
              : painSco.moment === "6" ? 6
                : painSco.moment === "7" ? 7
                  : painSco.moment === "8" ? 8
                    : painSco.moment === "9" ? 9
                      : painSco.moment === "10" ? 10
                        : 0;

  const VASRateElbowL = painSco.momentL === "0" ? 0
    : painSco.momentL === "1" ? 1
      : painSco.momentL === "2" ? 2
        : painSco.momentL === "3" ? 3
          : painSco.momentL === "4" ? 4
            : painSco.momentL === "5" ? 5
              : painSco.momentL === "6" ? 6
                : painSco.momentL === "7" ? 7
                  : painSco.momentL === "8" ? 8
                    : painSco.momentL === "9" ? 9
                      : painSco.momentL === "10" ? 10
                        : 0;



  //  OES P12
  const OESRateElbow = painSco.rateElbow === "0" ? 4
    : painSco.rateElbow === "1" ? 3
      : painSco.rateElbow === "2" ? 3
        : painSco.rateElbow === "3" ? 3
          : painSco.rateElbow === "4" ? 2
            : painSco.rateElbow === "5" ? 2
              : painSco.rateElbow === "6" ? 2
                : painSco.rateElbow === "7" ? 1
                  : painSco.rateElbow === "8" ? 1
                    : painSco.rateElbow === "9" ? 1
                      : painSco.rateElbow === "10" ? 0
                        : 0;
  const OESRateElbowL = painSco.rateElbowL === "0" ? 4
    : painSco.rateElbowL === "1" ? 3
      : painSco.rateElbowL === "2" ? 3
        : painSco.rateElbowL === "3" ? 3
          : painSco.rateElbowL === "4" ? 2
            : painSco.rateElbowL === "5" ? 2
              : painSco.rateElbowL === "6" ? 2
                : painSco.rateElbowL === "7" ? 1
                  : painSco.rateElbowL === "8" ? 1
                    : painSco.rateElbowL === "9" ? 1
                      : painSco.rateElbowL === "10" ? 0
                        : 0;


  //ASES-E
  const ASESRateWorst = painSco.rateWorst === "0" ? 10
    : painSco.rateWorst === "1" ? 9
      : painSco.rateWorst === "2" ? 8
        : painSco.rateWorst === "3" ? 7
          : painSco.rateWorst === "4" ? 6
            : painSco.rateWorst === "5" ? 5
              : painSco.rateWorst === "6" ? 4
                : painSco.rateWorst === "7" ? 3
                  : painSco.rateWorst === "8" ? 2
                    : painSco.rateWorst === "9" ? 1
                      : painSco.rateWorst === "10" ? 0
                        : 0;
  const ASESRateWorstL = painSco.rateWorstL === "0" ? 10
    : painSco.rateWorstL === "1" ? 9
      : painSco.rateWorstL === "2" ? 8
        : painSco.rateWorstL === "3" ? 7
          : painSco.rateWorstL === "4" ? 6
            : painSco.rateWorstL === "5" ? 5
              : painSco.rateWorstL === "6" ? 4
                : painSco.rateWorstL === "7" ? 3
                  : painSco.rateWorstL === "8" ? 2
                    : painSco.rateWorstL === "9" ? 1
                      : painSco.rateWorstL === "10" ? 0
                        : 0;


  // OES P11
  const OESPRateWorst = painSco.rateWorst === "0" ? 4
    : painSco.rateWorst === "1" ? 3
      : painSco.rateWorst === "2" ? 3
        : painSco.rateWorst === "3" ? 3
          : painSco.rateWorst === "4" ? 2
            : painSco.rateWorst === "5" ? 2
              : painSco.rateWorst === "6" ? 2
                : painSco.rateWorst === "7" ? 1
                  : painSco.rateWorst === "8" ? 1
                    : painSco.rateWorst === "9" ? 1
                      : painSco.rateWorst === "10" ? 0
                        : 0;
  const OESPRateWorstL = painSco.rateWorstL === "0" ? 4
    : painSco.rateWorstL === "1" ? 3
      : painSco.rateWorstL === "2" ? 3
        : painSco.rateWorstL === "3" ? 3
          : painSco.rateWorstL === "4" ? 2
            : painSco.rateWorstL === "5" ? 2
              : painSco.rateWorstL === "6" ? 2
                : painSco.rateWorstL === "7" ? 1
                  : painSco.rateWorstL === "8" ? 1
                    : painSco.rateWorstL === "9" ? 1
                      : painSco.rateWorstL === "10" ? 0
                        : 0;

  //ASES-E Pain

  const ASESPRateRest = painSco.rateRest === "0" ? 10
    : painSco.rateRest === "1" ? 9
      : painSco.rateRest === "2" ? 8
        : painSco.rateRest === "3" ? 7
          : painSco.rateRest === "4" ? 6
            : painSco.rateRest === "5" ? 5
              : painSco.rateRest === "6" ? 4
                : painSco.rateRest === "7" ? 3
                  : painSco.rateRest === "8" ? 2
                    : painSco.rateRest === "9" ? 1
                      : painSco.rateRest === "10" ? 0
                        : 0;
  const ASESPRateRestL = painSco.rateRestL === "0" ? 10
    : painSco.rateRestL === "1" ? 9
      : painSco.rateRestL === "2" ? 8
        : painSco.rateRestL === "3" ? 7
          : painSco.rateRestL === "4" ? 6
            : painSco.rateRestL === "5" ? 5
              : painSco.rateRestL === "6" ? 4
                : painSco.rateRestL === "7" ? 3
                  : painSco.rateRestL === "8" ? 2
                    : painSco.rateRestL === "9" ? 1
                      : painSco.rateRestL === "10" ? 0
                        : 0;


  const ASESPRateObj = painSco.rateObj === "0" ? 10
    : painSco.rateObj === "1" ? 9
      : painSco.rateObj === "2" ? 8
        : painSco.rateObj === "3" ? 7
          : painSco.rateObj === "4" ? 6
            : painSco.rateObj === "5" ? 5
              : painSco.rateObj === "6" ? 4
                : painSco.rateObj === "7" ? 3
                  : painSco.rateObj === "8" ? 2
                    : painSco.rateObj === "9" ? 1
                      : painSco.rateObj === "10" ? 0
                        : 0;
  const ASESPRateObjL = painSco.rateObjL === "0" ? 10
    : painSco.rateObjL === "1" ? 9
      : painSco.rateObjL === "2" ? 8
        : painSco.rateObjL === "3" ? 7
          : painSco.rateObjL === "4" ? 6
            : painSco.rateObjL === "5" ? 5
              : painSco.rateObjL === "6" ? 4
                : painSco.rateObjL === "7" ? 3
                  : painSco.rateObjL === "8" ? 2
                    : painSco.rateObjL === "9" ? 1
                      : painSco.rateObjL === "10" ? 0
                        : 0;

  const ASESPrateMovements = painSco.rateMovements === "0" ? 10
    : painSco.rateMovements === "1" ? 9
      : painSco.rateMovements === "2" ? 8
        : painSco.rateMovements === "3" ? 7
          : painSco.rateMovements === "4" ? 6
            : painSco.rateMovements === "5" ? 5
              : painSco.rateMovements === "6" ? 4
                : painSco.rateMovements === "7" ? 3
                  : painSco.rateMovements === "8" ? 2
                    : painSco.rateMovements === "9" ? 1
                      : painSco.rateMovements === "10" ? 0
                        : 0;
  const ASESPrateMovementsL = painSco.rateMovementsL === "0" ? 10
    : painSco.rateMovementsL === "1" ? 9
      : painSco.rateMovementsL === "2" ? 8
        : painSco.rateMovementsL === "3" ? 7
          : painSco.rateMovementsL === "4" ? 6
            : painSco.rateMovementsL === "5" ? 5
              : painSco.rateMovementsL === "6" ? 4
                : painSco.rateMovementsL === "7" ? 3
                  : painSco.rateMovementsL === "8" ? 2
                    : painSco.rateMovementsL === "9" ? 1
                      : painSco.rateMovementsL === "10" ? 0
                        : 0;




  const ASESPrateNight = painSco.rateNight === "0" ? 10
    : painSco.rateNight === "1" ? 9
      : painSco.rateNight === "2" ? 8
        : painSco.rateNight === "3" ? 7
          : painSco.rateNight === "4" ? 6
            : painSco.rateNight === "5" ? 5
              : painSco.rateNight === "6" ? 4
                : painSco.rateNight === "7" ? 3
                  : painSco.rateNight === "8" ? 2
                    : painSco.rateNight === "9" ? 1
                      : painSco.rateNight === "10" ? 0
                        : 0;
  const ASESPrateNightL = painSco.rateNightL === "0" ? 10
    : painSco.rateNightL === "1" ? 9
      : painSco.rateNightL === "2" ? 8
        : painSco.rateNightL === "3" ? 7
          : painSco.rateNightL === "4" ? 6
            : painSco.rateNightL === "5" ? 5
              : painSco.rateNightL === "6" ? 4
                : painSco.rateNightL === "7" ? 3
                  : painSco.rateNightL === "8" ? 2
                    : painSco.rateNightL === "9" ? 1
                      : painSco.rateNightL === "10" ? 0
                        : 0;

  //OES P7
  const OESTroubledL = painSco.troubledL === "4" ? 0
    : painSco.troubledL === "3" ? 1
      : painSco.troubledL === "2" ? 2
        : painSco.troubledL === "1" ? 3
          : painSco.troubledL === "0" ? 4
            : 0;

  const OESTroubledR = painSco.troubledR === "4" ? 0
    : painSco.troubledR === "3" ? 1
      : painSco.troubledR === "2" ? 2
        : painSco.troubledR === "1" ? 3
          : painSco.troubledR === "0" ? 4
            : 0;

  //OES P8
  const OESOftenR = painSco.oftenR === "4" ? 0
    : painSco.oftenR === "3" ? 1
      : painSco.oftenR === "2" ? 2
        : painSco.oftenR === "1" ? 3
          : painSco.oftenR === "0" ? 4
            : 0;
  const OESOftenL = painSco.oftenL === "4" ? 0
    : painSco.oftenL === "3" ? 1
      : painSco.oftenL === "2" ? 2
        : painSco.oftenL === "1" ? 3
          : painSco.oftenL === "0" ? 4
            : 0;

  //HSS
  const HSSFunDescR = functionScore.funDescR === "5" ? 30
    : functionScore.funDescR === "4" ? 25
      : functionScore.funDescR === "3" ? 20
        : functionScore.funDescR === "2" ? 10
          : functionScore.funDescR === "1" ? 5
            : functionScore.funDescR === "0" ? 0
              : 0;

  const HSSFunDescL = functionScore.funDescL === "5" ? 30
    : functionScore.funDescL === "4" ? 25
      : functionScore.funDescL === "3" ? 20
        : functionScore.funDescL === "2" ? 10
          : functionScore.funDescL === "1" ? 5
            : functionScore.funDescL === "0" ? 0
              : 0;

  const HSSFunActivityR = functionScore.funActivityR === "4" ? 12
    : functionScore.funActivityR === "3" ? 10
      : functionScore.funActivityR === "2" ? 8
        : functionScore.funActivityR === "1" ? 6
          : functionScore.funActivityR === "0" ? 0
            : 0;

  const HSSFunActivityL = functionScore.funActivityL === "4" ? 12
    : functionScore.funActivityL === "3" ? 10
      : functionScore.funActivityL === "2" ? 8
        : functionScore.funActivityL === "1" ? 6
          : functionScore.funActivityL === "0" ? 0
            : 0;

  const HSSFunUselongR = functionScore.funUselongR === "3" ? 8
    : functionScore.funUselongR === "2" ? 6
      : functionScore.funUselongR === "1" ? 4
        : functionScore.funUselongR === "0" ? 0
          : 0;

  const HSSFunUselongL = functionScore.funUselongL === "3" ? 8
    : functionScore.funUselongL === "2" ? 6
      : functionScore.funUselongL === "1" ? 4
        : functionScore.funUselongL === "0" ? 0
          : 0;

  //Qdash Disability/Symptom
  const OdashtightR = functionScore.tightR === "4" ? 5
    : functionScore.tightR === "3" ? 4
      : functionScore.tightR === "2" ? 3
        : functionScore.tightR === "1" ? 2
          : functionScore.tightR === "0" ? 1
            : 0;

  const OdashtightL = functionScore.tightL === "4" ? 5
    : functionScore.tightL === "3" ? 4
      : functionScore.tightL === "2" ? 3
        : functionScore.tightL === "1" ? 2
          : functionScore.tightL === "0" ? 1
            : 0;

  const OdashfunHouseholdR = functionScore.householdR === "4" ? 5
    : functionScore.householdR === "3" ? 4
      : functionScore.householdR === "2" ? 3
        : functionScore.householdR === "1" ? 2
          : functionScore.householdR === "0" ? 1
            : 0;

  const OdashfunHouseholdL = functionScore.householdL === "4" ? 5
    : functionScore.householdL === "3" ? 4
      : functionScore.householdL === "2" ? 3
        : functionScore.householdL === "1" ? 2
          : functionScore.householdL === "0" ? 1
            : 0;

  const OdashfunshoppingR = functionScore.funshoppingR === "4" ? 5
    : functionScore.funshoppingR === "3" ? 4
      : functionScore.funshoppingR === "2" ? 3
        : functionScore.funshoppingR === "1" ? 2
          : functionScore.funshoppingR === "0" ? 1
            : 0;

  const OdashfunshoppingL = functionScore.funshoppingL === "4" ? 5
    : functionScore.funshoppingL === "3" ? 4
      : functionScore.funshoppingL === "2" ? 3
        : functionScore.funshoppingL === "1" ? 2
          : functionScore.funshoppingL === "0" ? 1
            : 0;

  const OdashfunWashR = functionScore.funWashR === "4" ? 5
    : functionScore.funWashR === "3" ? 4
      : functionScore.funWashR === "2" ? 3
        : functionScore.funWashR === "1" ? 2
          : functionScore.funWashR === "0" ? 1
            : 0;
  const OdashfunWashL = functionScore.funWashL === "4" ? 5
    : functionScore.funWashL === "3" ? 4
      : functionScore.funWashL === "2" ? 3
        : functionScore.funWashL === "1" ? 2
          : functionScore.funWashL === "0" ? 1
            : 0;

  const OdashfunKnifeR = functionScore.funKnifeR === "4" ? 5
    : functionScore.funKnifeR === "3" ? 4
      : functionScore.funKnifeR === "2" ? 3
        : functionScore.funKnifeR === "1" ? 2
          : functionScore.funKnifeR === "0" ? 1
            : 0;

  const OdashfunKnifeL = functionScore.funKnifeL === "4" ? 5
    : functionScore.funKnifeL === "3" ? 4
      : functionScore.funKnifeL === "2" ? 3
        : functionScore.funKnifeL === "1" ? 2
          : functionScore.funKnifeL === "0" ? 1
            : 0;

  const OdashfunRecreationalR = functionScore.funRecreationalR === "4" ? 5
    : functionScore.funRecreationalR === "3" ? 4
      : functionScore.funRecreationalR === "2" ? 3
        : functionScore.funRecreationalR === "1" ? 2
          : functionScore.funRecreationalR === "0" ? 1
            : 0;

  const OdashfunRecreationalL = functionScore.funRecreationalL === "4" ? 5
    : functionScore.funRecreationalL === "3" ? 4
      : functionScore.funRecreationalL === "2" ? 3
        : functionScore.funRecreationalL === "1" ? 2
          : functionScore.funRecreationalL === "0" ? 1
            : 0;

  const OdashfunSleepingR = functionScore.funSleepingR === "4" ? 5
    : functionScore.funSleepingR === "3" ? 4
      : functionScore.funSleepingR === "2" ? 3
        : functionScore.funSleepingR === "1" ? 2
          : functionScore.funSleepingR === "0" ? 1
            : 0;

  const OdashfunSleepingL = functionScore.funSleepingL === "4" ? 5
    : functionScore.funSleepingL === "3" ? 4
      : functionScore.funSleepingL === "2" ? 3
        : functionScore.funSleepingL === "1" ? 2
          : functionScore.funSleepingL === "0" ? 1
            : 0;

  const OdasharmR = symptomsScore.armR === "4" ? 5
    : symptomsScore.armR === "3" ? 4
      : symptomsScore.armR === "2" ? 3
        : symptomsScore.armR === "1" ? 2
          : symptomsScore.armR === "0" ? 1
            : 0;

  const OdasharmL = symptomsScore.armL === "4" ? 5
    : symptomsScore.armL === "3" ? 4
      : symptomsScore.armL === "2" ? 3
        : symptomsScore.armL === "1" ? 2
          : symptomsScore.armL === "0" ? 1
            : 0;
  const OdashTinglingR = symptomsScore.TinglingR === "4" ? 5
    : symptomsScore.TinglingR === "3" ? 4
      : symptomsScore.TinglingR === "2" ? 3
        : symptomsScore.TinglingR === "1" ? 2
          : symptomsScore.TinglingR === "0" ? 1
            : 0;

  const OdashTinglingL = symptomsScore.TinglingL === "4" ? 5
    : symptomsScore.TinglingL === "3" ? 4
      : symptomsScore.TinglingL === "2" ? 3
        : symptomsScore.TinglingL === "1" ? 2
          : symptomsScore.TinglingL === "0" ? 1
            : 0;

  const OdashLeisureR = functionScore.funLeisureR === "4" ? 5
    : functionScore.funLeisureR === "3" ? 4
      : functionScore.funLeisureR === "2" ? 3
        : functionScore.funLeisureR === "1" ? 2
          : functionScore.funLeisureR === "0" ? 1
            : 0;
  const OdashLeisureL = functionScore.funLeisureL === "4" ? 5
    : functionScore.funLeisureL === "3" ? 4
      : functionScore.funLeisureL === "2" ? 3
        : functionScore.funLeisureL === "1" ? 2
          : functionScore.funLeisureL === "0" ? 1
            : 0;

  const OdashRegularR = functionScore.funRegularR === "4" ? 5
    : functionScore.funRegularR === "3" ? 4
      : functionScore.funRegularR === "2" ? 3
        : functionScore.funRegularR === "1" ? 2
          : functionScore.funRegularR === "0" ? 1
            : 0;
  const OdashRegularL = functionScore.funRegularL === "4" ? 5
    : functionScore.funRegularL === "3" ? 4
      : functionScore.funRegularL === "2" ? 3
        : functionScore.funRegularL === "1" ? 2
          : functionScore.funRegularL === "0" ? 1
            : 0;
  //OES F2
  const OESfunshoppingR = functionScore.funshoppingR === "4" ? 0
    : functionScore.funshoppingR === "3" ? 1
      : functionScore.funshoppingR === "2" ? 2
        : functionScore.funshoppingR === "1" ? 3
          : functionScore.funshoppingR === "0" ? 4
            : 0;

  const OESfunshoppingL = functionScore.funshoppingL === "4" ? 0
    : functionScore.funshoppingL === "3" ? 1
      : functionScore.funshoppingL === "2" ? 2
        : functionScore.funshoppingL === "1" ? 3
          : functionScore.funshoppingL === "0" ? 4
            : 0;

  //OES F1
  const OESFfunshoppingL = functionScore.funrubbishL === "4" ? 0
    : functionScore.funrubbishL === "3" ? 1
      : functionScore.funrubbishL === "2" ? 2
        : functionScore.funrubbishL === "1" ? 3
          : functionScore.funrubbishL === "0" ? 4
            : 0;

  const OESFfunshoppingR = functionScore.funrubbishR === "4" ? 0
    : functionScore.funrubbishR === "3" ? 1
      : functionScore.funrubbishR === "2" ? 2
        : functionScore.funrubbishR === "1" ? 3
          : functionScore.funrubbishR === "0" ? 4
            : 0;

  //OES F4
  const OESF4funshoppingR = functionScore.funDressingR === "4" ? 0
    : functionScore.funDressingR === "3" ? 1
      : functionScore.funDressingR === "2" ? 2
        : functionScore.funDressingR === "1" ? 3
          : functionScore.funDressingR === "0" ? 4
            : 0;

  const OESF4funshoppingL = functionScore.funDressingL === "4" ? 0
    : functionScore.funDressingL === "3" ? 1
      : functionScore.funDressingL === "2" ? 2
        : functionScore.funDressingL === "1" ? 3
          : functionScore.funDressingL === "0" ? 4
            : 0;
  //ASES-E Function
  const ASESFfunToiletinghR = functionScore.funToiletinghR === "4" ? 0
    : functionScore.funToiletinghR === "3" ? 1
      : functionScore.funToiletinghR === "2" ? 2
        : functionScore.funToiletinghR === "1" ? 2
          : functionScore.funToiletinghR === "0" ? 3
            : 0;

  const ASESFfunToiletinghL = functionScore.funToiletinghL === "4" ? 0
    : functionScore.funToiletinghL === "3" ? 1
      : functionScore.funToiletinghL === "2" ? 2
        : functionScore.funToiletinghL === "1" ? 2
          : functionScore.funToiletinghL === "0" ? 3
            : 0;

  const ASESFfunUtensilR = functionScore.funUtensilR === "4" ? 0
    : functionScore.funUtensilR === "3" ? 1
      : functionScore.funUtensilR === "2" ? 2
        : functionScore.funUtensilR === "1" ? 2
          : functionScore.funUtensilR === "0" ? 3
            : 0;

  const ASESFfunUtensilL = functionScore.funUtensilL === "4" ? 0
    : functionScore.funUtensilL === "3" ? 1
      : functionScore.funUtensilL === "2" ? 2
        : functionScore.funUtensilL === "1" ? 2
          : functionScore.funUtensilL === "0" ? 3
            : 0;

  const ASESFfunTopShirtR = functionScore.funTopShirtR === "4" ? 0
    : functionScore.funTopShirtR === "3" ? 1
      : functionScore.funTopShirtR === "2" ? 2
        : functionScore.funTopShirtR === "1" ? 2
          : functionScore.funTopShirtR === "0" ? 3
            : 0;

  const ASESFfunTopShirtL = functionScore.funTopShirtL === "4" ? 0
    : functionScore.funTopShirtL === "3" ? 1
      : functionScore.funTopShirtL === "2" ? 2
        : functionScore.funTopShirtL === "1" ? 2
          : functionScore.funTopShirtL === "0" ? 3
            : 0;

  const ASESFfunHairR = functionScore.funHairR === "4" ? 0
    : functionScore.funHairR === "3" ? 1
      : functionScore.funHairR === "2" ? 2
        : functionScore.funHairR === "1" ? 2
          : functionScore.funHairR === "0" ? 3
            : 0;
  const ASESFfunHairL = functionScore.funHairL === "4" ? 0
    : functionScore.funHairL === "3" ? 1
      : functionScore.funHairL === "2" ? 2
        : functionScore.funHairL === "1" ? 2
          : functionScore.funHairL === "0" ? 3
            : 0;

  const ASESFfunTieShoesR = functionScore.funTieShoesR === "4" ? 0
    : functionScore.funTieShoesR === "3" ? 1
      : functionScore.funTieShoesR === "2" ? 2
        : functionScore.funTieShoesR === "1" ? 2
          : functionScore.funTieShoesR === "0" ? 3
            : 0;

  const ASESFfunTieShoesL = functionScore.funTieShoesL === "4" ? 0
    : functionScore.funTieShoesL === "3" ? 1
      : functionScore.funTieShoesL === "2" ? 2
        : functionScore.funTieShoesL === "1" ? 2
          : functionScore.funTieShoesL === "0" ? 3
            : 0;

  const ASESFfunHeavyR = functionScore.funHeavyR === "4" ? 0
    : functionScore.funHeavyR === "3" ? 1
      : functionScore.funHeavyR === "2" ? 2
        : functionScore.funHeavyR === "1" ? 2
          : functionScore.funHeavyR === "0" ? 3
            : 0;

  const ASESFfunHeavyL = functionScore.funHeavyL === "4" ? 0
    : functionScore.funHeavyL === "3" ? 1
      : functionScore.funHeavyL === "2" ? 2
        : functionScore.funHeavyL === "1" ? 2
          : functionScore.funHeavyL === "0" ? 3
            : 0;

  const ASESFfunChairR = functionScore.funChairR === "4" ? 0
    : functionScore.funChairR === "3" ? 1
      : functionScore.funChairR === "2" ? 2
        : functionScore.funChairR === "1" ? 2
          : functionScore.funChairR === "0" ? 3
            : 0;

  const ASESFfunChairL = functionScore.funChairL === "4" ? 0
    : functionScore.funChairL === "3" ? 1
      : functionScore.funChairL === "2" ? 2
        : functionScore.funChairL === "1" ? 2
          : functionScore.funChairL === "0" ? 3
            : 0;

  const ASESFhouseholdR = functionScore.householdR === "4" ? 0
    : functionScore.householdR === "3" ? 1
      : functionScore.householdR === "2" ? 2
        : functionScore.householdR === "1" ? 2
          : functionScore.householdR === "0" ? 3
            : 0;


  const ASESFhouseholdL = functionScore.householdL === "4" ? 0
    : functionScore.householdL === "3" ? 1
      : functionScore.householdL === "2" ? 2
        : functionScore.householdL === "1" ? 2
          : functionScore.householdL === "0" ? 3
            : 0;

  const ASESFfunTurnR = functionScore.funTurnR === "4" ? 0
    : functionScore.funTurnR === "3" ? 1
      : functionScore.funTurnR === "2" ? 2
        : functionScore.funTurnR === "1" ? 2
          : functionScore.funTurnR === "0" ? 3
            : 0;


  const ASESFfunTurnL = functionScore.funTurnL === "4" ? 0
    : functionScore.funTurnL === "3" ? 1
      : functionScore.funTurnL === "2" ? 2
        : functionScore.funTurnL === "1" ? 2
          : functionScore.funTurnL === "0" ? 3
            : 0;

  const ASESFfunBallR = functionScore.funBallR === "4" ? 0
    : functionScore.funBallR === "3" ? 1
      : functionScore.funBallR === "2" ? 2
        : functionScore.funBallR === "1" ? 2
          : functionScore.funBallR === "0" ? 3
            : 0;

  const ASESFfunBallL = functionScore.funBallL === "4" ? 0
    : functionScore.funBallL === "3" ? 1
      : functionScore.funBallL === "2" ? 2
        : functionScore.funBallL === "1" ? 2
          : functionScore.funBallL === "0" ? 3
            : 0;
  const ASESFfunWorkR = functionScore.funWorkR === "4" ? 0
    : functionScore.funWorkR === "3" ? 1
      : functionScore.funWorkR === "2" ? 2
        : functionScore.funWorkR === "1" ? 2
          : functionScore.funWorkR === "0" ? 3
            : 0;
  const ASESFfunWorkL = functionScore.funWorkL === "4" ? 0
    : functionScore.funWorkL === "3" ? 1
      : functionScore.funWorkL === "2" ? 2
        : functionScore.funWorkL === "1" ? 2
          : functionScore.funWorkL === "0" ? 3
            : 0;

  const ASESFfunMusicalR = functionScore.funMusicalR === "4" ? 0
    : functionScore.funMusicalR === "3" ? 1
      : functionScore.funMusicalR === "2" ? 2
        : functionScore.funMusicalR === "1" ? 2
          : functionScore.funMusicalR === "0" ? 3
            : 0;
  const ASESFfunMusicalL = functionScore.funMusicalL === "4" ? 0
    : functionScore.funMusicalL === "3" ? 1
      : functionScore.funMusicalL === "2" ? 2
        : functionScore.funMusicalL === "1" ? 2
          : functionScore.funMusicalL === "0" ? 3
            : 0;

  //OES F3
  const OESF3funWashingR = functionScore.funWashingR === "4" ? 0
    : functionScore.funWashingR === "3" ? 1
      : functionScore.funWashingR === "2" ? 2
        : functionScore.funWashingR === "1" ? 3
          : functionScore.funWashingR === "0" ? 4
            : 0;

  const OESF3funWashingL = functionScore.funWashingL === "4" ? 0
    : functionScore.funWashingL === "3" ? 1
      : functionScore.funWashingL === "2" ? 2
        : functionScore.funWashingL === "1" ? 3
          : functionScore.funWashingL === "0" ? 4
            : 0;
  //Qdash Sport/Performance
  const QSportfunMusicalR = functionScore.funMusicalR === "4" ? 5
    : functionScore.funMusicalR === "3" ? 4
      : functionScore.funMusicalR === "2" ? 3
        : functionScore.funMusicalR === "1" ? 2
          : functionScore.funMusicalR === "0" ? 1
            : 0;
  const QSportfunMusicalL = functionScore.funMusicalL === "4" ? 5
    : functionScore.funMusicalL === "3" ? 4
      : functionScore.funMusicalL === "2" ? 3
        : functionScore.funMusicalL === "1" ? 2
          : functionScore.funMusicalL === "0" ? 1
            : 0;

  const QdashfunInstrumentR = functionScore.funInstrumentR === "4" ? 5
    : functionScore.funInstrumentR === "3" ? 4
      : functionScore.funInstrumentR === "2" ? 3
        : functionScore.funInstrumentR === "1" ? 2
          : functionScore.funInstrumentR === "0" ? 1
            : 0;
  const QdashfunInstrumentL = functionScore.funInstrumentL === "4" ? 5
    : functionScore.funInstrumentL === "3" ? 4
      : functionScore.funInstrumentL === "2" ? 3
        : functionScore.funInstrumentL === "1" ? 2
          : functionScore.funInstrumentL === "0" ? 1
            : 0;

  const QdashfunPlayingR = functionScore.funPlayingR === "4" ? 5
    : functionScore.funPlayingR === "3" ? 4
      : functionScore.funPlayingR === "2" ? 3
        : functionScore.funPlayingR === "1" ? 2
          : functionScore.funPlayingR === "0" ? 1
            : 0;

  const QdashfunPlayingL = functionScore.funPlayingL === "4" ? 5
    : functionScore.funPlayingL === "3" ? 4
      : functionScore.funPlayingL === "2" ? 3
        : functionScore.funPlayingL === "1" ? 2
          : functionScore.funPlayingL === "0" ? 1
            : 0;

  const QdashfunPractisingR = functionScore.funPractisingR === "4" ? 5
    : functionScore.funPractisingR === "3" ? 4
      : functionScore.funPractisingR === "2" ? 3
        : functionScore.funPractisingR === "1" ? 2
          : functionScore.funPractisingR === "0" ? 1
            : 0;

  const QdashfunPractisingL = functionScore.funPractisingL === "4" ? 5
    : functionScore.funPractisingL === "3" ? 4
      : functionScore.funPractisingL === "2" ? 3
        : functionScore.funPractisingL === "1" ? 2
          : functionScore.funPractisingL === "0" ? 1
            : 0;

  const QdashfunLeisureR = functionScore.funLeisureR === "4" ? 1
    : functionScore.funLeisureR === "3" ? 2
      : functionScore.funLeisureR === "2" ? 3
        : functionScore.funLeisureR === "1" ? 4
          : functionScore.funLeisureR === "0" ? 5
            : 0;
  const QdashfunLeisureL = functionScore.funLeisureL === "4" ? 1
    : functionScore.funLeisureL === "3" ? 2
      : functionScore.funLeisureL === "2" ? 3
        : functionScore.funLeisureL === "1" ? 4
          : functionScore.funLeisureL === "0" ? 5
            : 0;

  const QdashfunRegularR = functionScore.funRegularR === "4" ? 1
    : functionScore.funRegularR === "3" ? 2
      : functionScore.funRegularR === "2" ? 3
        : functionScore.funRegularR === "1" ? 4
          : functionScore.funRegularR === "0" ? 5
            : 0;
  const QdashfunRegularL = functionScore.funRegularL === "4" ? 1
    : functionScore.funRegularL === "3" ? 2
      : functionScore.funRegularL === "2" ? 3
        : functionScore.funRegularL === "1" ? 4
          : functionScore.funRegularL === "0" ? 5
            : 0;

  //OES S/P9
  const OES9funLeisureR = functionScore.funRegularR === "4" ? 0
    : functionScore.funRegularR === "3" ? 1
      : functionScore.funRegularR === "2" ? 2
        : functionScore.funRegularR === "1" ? 3
          : functionScore.funRegularR === "0" ? 4
            : 0;
  const OES9funLeisureL = functionScore.funRegularL === "4" ? 0
    : functionScore.funRegularL === "3" ? 1
      : functionScore.funRegularL === "2" ? 2
        : functionScore.funRegularL === "1" ? 3
          : functionScore.funRegularL === "0" ? 4
            : 0;

  //OES S/P10
  const OES10funLeisureR = functionScore.funLeisureR === "0" ? 4
    : functionScore.funLeisureR === "1" ? 3
      : functionScore.funLeisureR === "2" ? 2
        : functionScore.funLeisureR === "3" ? 1
          : functionScore.funLeisureR === "4" ? 0
            : 0;
  const OES10funLeisureL = functionScore.funLeisureL === "0" ? 4
    : functionScore.funLeisureL === "1" ? 3
      : functionScore.funLeisureL === "2" ? 2
        : functionScore.funLeisureL === "3" ? 1
          : functionScore.funLeisureL === "4" ? 0
            : 0;

  //OES S/P5
  const OES5funLeisureR = qolScore.elbowDuringR === "4" ? 0
    : qolScore.elbowDuringR === "3" ? 1
      : qolScore.elbowDuringR === "2" ? 2
        : qolScore.elbowDuringR === "1" ? 3
          : qolScore.elbowDuringR === "0" ? 4
            : 0;

  const OES5funLeisureL = qolScore.elbowDuringL === "4" ? 0
    : qolScore.elbowDuringL === "3" ? 1
      : qolScore.elbowDuringL === "2" ? 2
        : qolScore.elbowDuringL === "1" ? 3
          : qolScore.elbowDuringL === "0" ? 4
            : 0;

  //OES S/P6
  const OES6elbowlifeR = qolScore.elbowlifeR === "4" ? 0
    : qolScore.elbowlifeR === "3" ? 1
      : qolScore.elbowlifeR === "2" ? 2
        : qolScore.elbowlifeR === "1" ? 3
          : qolScore.elbowlifeR === "0" ? 4
            : 0;

  const OES6elbowlifeL = qolScore.elbowlifeL === "4" ? 0
    : qolScore.elbowlifeL === "3" ? 1
      : qolScore.elbowlifeL === "2" ? 2
        : qolScore.elbowlifeL === "1" ? 3
          : qolScore.elbowlifeL === "0" ? 4
            : 0;

  //SANE    
  const HipperT = parseFloat(symptomsScore.HipPer);
  const HipperTL = parseFloat(symptomsScore.HipPerL);

  //EQ-5D-5L Mobility
  const EQproblem = qolScore.problemR === "4" ? 5
    : qolScore.problemR === "3" ? 4
      : qolScore.problemR === "2" ? 3
        : qolScore.problemR === "1" ? 2
          : qolScore.problemR === "0" ? 1
            : 0;

  //EQ-5D-5L Self-Care
  const EQwashing = qolScore.washingR === "4" ? 5
    : qolScore.washingR === "3" ? 4
      : qolScore.washingR === "2" ? 3
        : qolScore.washingR === "1" ? 2
          : qolScore.washingR === "0" ? 1
            : 0;


  //EQ-5D-5L Usual Activities
  const EQactivities = qolScore.activitiesR === "4" ? 5
    : qolScore.activitiesR === "3" ? 4
      : qolScore.activitiesR === "2" ? 3
        : qolScore.activitiesR === "1" ? 2
          : qolScore.activitiesR === "0" ? 1
            : 0;


  //EQ-5D-5L Pain/Discomfort
  const EQdiscomfort = qolScore.discomfortR === "4" ? 5
    : qolScore.discomfortR === "3" ? 4
      : qolScore.discomfortR === "2" ? 3
        : qolScore.discomfortR === "1" ? 2
          : qolScore.discomfortR === "0" ? 1
            : 0;


  //EQ-5D-5L Anxiety/Depression
  const EQanxiety = qolScore.anxietyR === "4" ? 5
    : qolScore.anxietyR === "3" ? 4
      : qolScore.anxietyR === "2" ? 3
        : qolScore.anxietyR === "1" ? 2
          : qolScore.anxietyR === "0" ? 1
            : 0;


  //EQ-VAS        
  const EQpercentage = qolScore.percentage;

  //ASES-E Satisfaction
  const ASESrateElbowSatisfactionR = satisfactionScore.rateElbowSatisfactionR;
  const ASESrateElbowSatisfactionL = satisfactionScore.rateElbowSatisfactionL;
  //Qdash Work
  const QdashfunTechniqueR = functionScore.funTechniqueR === "4" ? 5
    : functionScore.funTechniqueR === "3" ? 4
      : functionScore.funTechniqueR === "2" ? 3
        : functionScore.funTechniqueR === "1" ? 2
          : functionScore.funTechniqueR === "0" ? 1
            : 0;

  const QdashfunTechniqueL = functionScore.funTechniqueL === "4" ? 5
    : functionScore.funTechniqueL === "3" ? 4
      : functionScore.funTechniqueL === "2" ? 3
        : functionScore.funTechniqueL === "1" ? 2
          : functionScore.funTechniqueL === "0" ? 1
            : 0;

  const QdashfunWorkAsWallR = functionScore.funWorkAsWallR === "4" ? 5
    : functionScore.funWorkAsWallR === "3" ? 4
      : functionScore.funWorkAsWallR === "2" ? 3
        : functionScore.funWorkAsWallR === "1" ? 2
          : functionScore.funWorkAsWallR === "0" ? 1
            : 0;
  const QdashfunWorkAsWallL = functionScore.funWorkAsWallL === "4" ? 5
    : functionScore.funWorkAsWallL === "3" ? 4
      : functionScore.funWorkAsWallL === "2" ? 3
        : functionScore.funWorkAsWallL === "1" ? 2
          : functionScore.funWorkAsWallL === "0" ? 1
            : 0;

  const QdashfunSpendingR = functionScore.funSpendingR === "4" ? 5
    : functionScore.funSpendingR === "3" ? 4
      : functionScore.funSpendingR === "2" ? 3
        : functionScore.funSpendingR === "1" ? 2
          : functionScore.funSpendingR === "0" ? 1
            : 0;

  const QdashfunSpendingL = functionScore.funSpendingL === "4" ? 5
    : functionScore.funSpendingL === "3" ? 4
      : functionScore.funSpendingL === "2" ? 3
        : functionScore.funSpendingL === "1" ? 2
          : functionScore.funSpendingL === "0" ? 1
            : 0;

  const QdashWorkR = functionScore.funWorkR === "4" ? 5
    : functionScore.funWorkR === "3" ? 4
      : functionScore.funWorkR === "2" ? 3
        : functionScore.funWorkR === "1" ? 2
          : functionScore.funWorkR === "0" ? 1
            : 0;
  const QdashWorkL = functionScore.funWorkL === "4" ? 5
    : functionScore.funWorkL === "3" ? 4
      : functionScore.funWorkL === "2" ? 3
        : functionScore.funWorkL === "1" ? 2
          : functionScore.funWorkL === "0" ? 1
            : 0;

  //QuickDASH
  //QuickDASH Disability/Symptom Score 
  const QuickDASHDisabilityRSum = sumAll(OdashtightR, OdashfunshoppingR, OdashfunWashR, OdashfunKnifeR, OdashfunRecreationalR, OdashfunSleepingR, OdashfunHouseholdR, OdashLeisureR, OdashRegularR, OdasharmR, OdashTinglingR);
  const QuickDASHDisabilityLSum = sumAll(OdashtightL, OdashfunshoppingL, OdashfunWashL, OdashfunKnifeL, OdashfunRecreationalL, OdashfunSleepingL, OdashfunHouseholdL, OdashLeisureL, OdashRegularL, OdasharmL, OdashTinglingL);
  const QuickDASHDisabilityRFSum = Math.round((((QuickDASHDisabilityRSum / 11) - 1) * 25));
  const QuickDASHDisabilityLFSum = Math.round((((QuickDASHDisabilityLSum / 11) - 1) * 25));

  //QuickDASH Work Score 
  const QuickDashWorkRSum = sumAll(QdashfunTechniqueR, QdashfunWorkAsWallR, QdashfunSpendingR, QdashWorkR);
  const QuickDashWorkLSum = sumAll(QdashfunTechniqueL, QdashfunWorkAsWallL, QdashfunSpendingL, QdashWorkL)
  //  const QuickDashWorkRFSum=(((QuickDashWorkRSum/4)-1)*25).toFixed(2);
  const QuickDashWorkRFSum = Math.round((((QuickDashWorkRSum / 4) - 1) * 25));

  const QuickDashWorkLFSum = Math.round((((QuickDashWorkLSum / 4) - 1) * 25));

  //QuickDASH Sport/Performing Arts Score Formula
  const QuickDashSportRSum = sumAll(QSportfunMusicalR, QdashfunInstrumentR, QdashfunPlayingR, QdashfunPractisingR);

  const QuickDashSportLSum = sumAll(QSportfunMusicalL, QdashfunInstrumentL, QdashfunPlayingL, QdashfunPractisingL);

  const QuickDashSportRFSum = Math.round((((QuickDashSportRSum / 4) - 1) * 25));
  const QuickDashSportLFSum = Math.round((((QuickDashSportLSum / 4) - 1) * 25));

  //ASES-E (ASES Elbow Outcome Score)
  //ASES-E Pain Formula
  const AsesPainSum = sumAll(ASESRateWorst, ASESPRateRest, ASESPRateObj, ASESPrateMovements, ASESPrateNight);
  const AsesPainSumL = sumAll(ASESRateWorstL, ASESPRateRestL, ASESPRateObjL, ASESPrateMovementsL, ASESPrateNightL);
  const AsesPainSumF = Math.round(((AsesPainSum / 50) * 100));
  const AsesPainSumFL = Math.round(((AsesPainSumL / 50) * 100));

  //ASES-E Function Formula
  const AsesFunctionRSum = sumAll(ASESFfunToiletinghR, ASESFfunUtensilR, ASESFfunTopShirtR, ASESFfunHairR,
    ASESFfunTieShoesR, ASESFfunHeavyR, ASESFfunChairR, ASESFhouseholdR, ASESFfunTurnR, ASESFfunBallR, ASESFfunWorkR, ASESFfunMusicalR);
  const AsesFunctionLSum = sumAll(ASESFfunToiletinghL, ASESFfunUtensilL, ASESFfunTopShirtL, ASESFfunHairL,
    ASESFfunTieShoesL, ASESFfunHeavyL, ASESFfunChairL, ASESFhouseholdL, ASESFfunTurnL, ASESFfunBallL, ASESFfunWorkL, ASESFfunMusicalL);

  const AsesFunctionRFSum = Math.round(((AsesFunctionRSum / 36) * 100));
  const AsesFunctionLFSum = Math.round(((AsesFunctionLSum / 36) * 100));

  //ASES-E Satisfaction  Formula  
  const AsesRFSum = ASESrateElbowSatisfactionR * 10;
  const AsesLFSum = ASESrateElbowSatisfactionL * 10;

  //MEPS Formula
  const MepsRFSum = sumAll(MEPSElbowPainR, MEPSfunHygienegR, MEPSfunUtensilR, MEPSfunShirtR, MEPSfunHairR, MEPSfunPutShoesR);



  const MepsLFSum = sumAll(MEPSElbowPainL, MEPSfunHygienegL, MEPSFfunUtensilL, MEPSfunShirtL, MEPSfunHairL, MEPSfunPutShoesL);


  //HSS Formula  
  const HssElbowPainRSum = sumAll(HHSElbowPainR, HSSFunDescR, HSSFunActivityR, HSSFunUselongR);
  const HssElbowPainLSum = sumAll(HHSElbowPainL, HSSFunDescL, HSSFunActivityL, HSSFunUselongL);

  //OES Pain Formula
  const OesPainRSum = sumAll(OESRateElbow, OESPRateWorst, OESTroubledR, OESOftenR);
  const OesPainLSum = sumAll(OESRateElbowL, OESPRateWorstL, OESTroubledL, OESOftenL);

  const OesPainRFSum = Math.round(((OesPainRSum / 16) * 100));
  const OesPainLFSum = Math.round(((OesPainLSum / 16) * 100));

  //OES Function 
  const OesFunRSum = sumAll(OESfunshoppingR, OESFfunshoppingR, OESF4funshoppingR, OESF3funWashingR);
  const OesFunLSum = sumAll(OESfunshoppingL, OESFfunshoppingL, OESF4funshoppingL, OESF3funWashingL);

  const OesFunRFSum = Math.round(((OesFunRSum / 16) * 100));
  const OesFunLFSum = Math.round(((OesFunLSum / 16) * 100));

  //OES Social/Psychological  Formula
  const OesSocialRSum = sumAll(OES9funLeisureR, OES10funLeisureR, OES5funLeisureR, OES6elbowlifeR);
  const OesSocialLSum = sumAll(OES9funLeisureL, OES10funLeisureL, OES5funLeisureL, OES6elbowlifeL);

  const OesSocialRFSum = Math.round(((OesSocialRSum / 16) * 100));
  const OesSocialLFSum = Math.round(((OesSocialLSum / 16) * 100));

  //VAS Formula
  const VASRateElbowF = VASRateElbow;
  const VASRateElbowFL = VASRateElbowL;


    dispatch(
      updateElbowTotalScores([
        {
          QuickDASHDisabilityRFSum,
          QuickDASHDisabilityLFSum,
          QuickDashWorkRFSum,
          QuickDashWorkLFSum,
          QuickDashSportRFSum,
          QuickDashSportLFSum,
          AsesPainSumF,
          AsesPainSumFL,
          AsesFunctionRFSum,
          AsesFunctionLFSum,
          AsesRFSum,
          AsesLFSum,
          MepsRFSum,
          MepsLFSum,
          HssElbowPainRSum,
          HssElbowPainLSum,
          OesPainRFSum,
          OesPainLFSum,
          OesFunRFSum,
          OesFunLFSum,
          OesSocialRFSum,
          OesSocialLFSum,
          VASRateElbowF,
          VASRateElbowFL,
          HipperT,
          HipperTL,
          EQproblem,
          EQwashing,
          EQactivities,
          EQdiscomfort,
          EQanxiety,
          EQpercentage,

        },
      ])
    );

}


