import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";
import degreeWidgetStyles from "./AssessmentDegree.module.css";
import RadioSwitch from "../radio-switch/RadioSwitch";
export default function KneeRadioDegree({ title, value, validators, labelR,
    labelL, nameR, nameL, onChange, onChangeR, onChangeL, valueR, valueL, validator, validatorL, RadioChange, radioname,radioSwitchR,radioSwitchL ,RadioDegree,RadioValue}) {
    const [countR, setCountR] = useState(valueR);
    const [countL, setCountL] = useState(valueL);
    const [errorValR, setErrorValR] = useState(false);
    const [errorValL, setErrorValL] = useState(false);
    const [errorR, setErrorR] = useState(false);
    const [errorL, setErrorL] = useState(false);
    const [errorMsgR, setErrorMsgR] = useState("Number Should be 0 to 180");
    const [errorMsgL, setErrorMsgL] = useState("Number Should be 0 to 180");
    const [ir1Left, setIr1Left] = useState("");
    const [ir1Right, setIr1Right] = useState("");
    const [anteriorR,setanteriorR]=useState("")


    useEffect(() => {
        setCountR(valueR)
        setCountL(valueL)
    }, [valueR, valueL])

useEffect(()=>{
    setanteriorR(RadioValue)
},[RadioValue])
    // regex for avoiding negative values
    const regex = /^\-?[1-9]\d{0,2}(\.\d*)?$/;

    const handleChangeR = (event) => {
        const { value } = event.target;
        let newValue = value;
        setErrorValR(validateInput(validators, value));
        if (newValue >= 0 && newValue <= 180.0) {
            setCountR(parseFloat(newValue));
            onChangeR(parseFloat(newValue));
            setErrorR(false);
            console.log("✅ num is between values");
        } else {
            console.log("⛔️ num is NOT between values");
            setCountR(0);
            setErrorR(true);
        }
    };

    // Create handleIncrement event handler
    const handleIncrementR = () => {
        if (isNaN(countR) || countR === "") {
            setCountR(1);
          } else {
        setCountR(countR >= 180 ? 180 : Number(countR) + 1);
        onChangeR(countR >= 180 ? 180 : Number(countR) + 1);
          }
    };

    //Create handleDecrement event handler
    const handleDecrementR = () => {
        if (regex.test(countR)) {
            setCountR(countR <= 0 ? 0 : countR - 1);
            onChangeR(countR <= 0 ? 0 : countR - 1);
        }
    };

    const handleChangeL = (event) => {
        const { value } = event.target;
        let newValue = value;
        setErrorValL(validateInput(validators, value));

        if (newValue >= 0 && newValue <= 180.0) {
            setCountL(parseFloat(newValue));
            onChangeL(parseFloat(newValue));
            setErrorL(false);
            console.log("✅ num is between values");
        } else {
            console.log("⛔️ num is NOT between values");
            setCountL(0);
            setErrorL(true);
        }
    };

    // Create handleIncrement event handler
    const handleIncrementL = () => {
        setCountL(countL >= 180 ? 180 : countL + 1);
        onChangeL(countL >= 180 ? 180 : countL + 1);
    };

    //Create handleDecrement event handler
    const handleDecrementL = () => {
        if (regex.test(countL)) {
            setCountL(countL <= 0 ? 0 : countL - 1);
            onChangeL(countL <= 0 ? 0 : countL - 1);
        }
    };

    const ir1ChangeL = (e) => {
        setIr1Left(e);
        RadioChange(e)
    };
    const ir1ChangeR = (e) => {
        setIr1Right(e)
        RadioChange(e)
    }
    const handleAnteriorR=(e)=>{
        setanteriorR(e)
        RadioDegree(e)
    }
    return (
        <>

            <div className={`${degreeWidgetStyles.input_box_main}`}>
                <h4 className={`${degreeWidgetStyles.title_txt}`}>{title}</h4>
           

                    <RadioSwitch 
                     labelLeft={"Negative"}
                     labelRight={"Positive"}
                     idLeft={radioSwitchL}
                     idRight={radioSwitchR}
                    //  name={translation.anteriorR}
                     value={anteriorR}
                     onChange={handleAnteriorR}
                    formGroupClass={"d-flex  mt-4"}/>
  
             {anteriorR==="1" &&(

                <div className={`${degreeWidgetStyles.number_box_row}`}>
                    <div className={`${degreeWidgetStyles.number_box_col}`}>

                        <div className={`${degreeWidgetStyles.degree_box}`}>

                            <span
                                className={`${degreeWidgetStyles.qty_minus}`}
                                onClick={handleDecrementR}
                            >
                                -
                            </span>
                            <input
                                type="number"
                                max={180}
                                min={0}
                                step={0.01}
                                className="qty trim"
                                name={nameR}
                                value={countR}
                                placeholder="000"
                                onChange={handleChangeR}
                                onBlur={handleChangeR}
                            />
                            <span
                                className={`${degreeWidgetStyles.qty_plus}`}
                                onClick={handleIncrementR}
                            >
                                +
                            </span>
                        </div>
                        <span className={`${degreeWidgetStyles.degree_txt}`}>Degrees</span>
                        {/* {valueR ==0 || valueR==null  &&(
            <> */}
                        {errorR && (
                            <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                {errorMsgR}
                            </span>
                        )}
                        {/* </>
          )} */}

                        {valueR == "" || valueR == null ? (
                            <>
                                {validator === true && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        This field is required
                                    </span>
                                )}
                            </>
                        ) : null}

                        {errorValR && (
                            <span className="text-danger">{errorValR.message}</span>
                        )}
                    </div>
                    <div className={`${degreeWidgetStyles.number_box_col}`}>

                        <div className={`${degreeWidgetStyles.degree_box}`}>







                        </div>


                        {valueL !== "" && (
                            <>
                                {errorL && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        {errorMsgL}
                                    </span>
                                )}
                            </>
                        )}

                        {valueL == "" || valueL == null ? (
                            <>
                                {validatorL === true && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        This field is required
                                    </span>
                                )}
                            </>
                        ) : null}
                        {errorValL && (
                            <span className="text-danger">{errorValL.message}</span>
                        )}
                    </div>
                </div>
             )} 
            </div>
        </>


    )
}

KneeRadioDegree.propTypes = {
    validators: PropTypes.array,
};

KneeRadioDegree.defaultProps = {
    value: "",
    title: "",
    labelR: "",
    labelL: "",
    nameR: "",
    nameL: "",
    validators: [],
};
