import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import Button from "../../../custom/button/Button";
import { useSelector, useDispatch } from "react-redux";
import Tab from "../../tab-section/ShoulderTab"
import { useSearchParams } from "react-router-dom";
import Satisfaction from "../satisfaction/Satisfaction";
import useTransition from '../../../customHooks/translations';

import {
  updateShoulderFunctionFieldScore,
  updateShoulderFunctionScores,
  updateShoulder,
  updateShoulderPainScores,
  updateWOSIFunctionScores,
  updateSACSFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
} from "../../../../redux/actions/shoulderAction";
import useAxios from "../../../../axiosinstance";
import Feedback from "react-bootstrap/esm/Feedback";
import NameCustom from "../../../custom/name/NameCustom";
import SubmitModel from "../../SubmissionModel/SubmitModel"
function Complete() {
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const { rId } = useParams();
  const axiosInstance = useAxios();
  const translation = useTransition();
  const [isLoading, setIsLoading] = useState(true);
  const [painSco] = useSelector((state) => state.shoulderScore.painScore);
  const [funScore] = useSelector((state) => state.shoulderScore.functionScore);
  const [wosiScore] = useSelector((state) => state.shoulderScore.wosiScore);
  const [sacsScore] = useSelector((state) => state.shoulderScore.sacsScore);
  const [safisScore] = useSelector((state) => state.shoulderScore.safisScore);
  const [TotalScores] = useSelector((state) => state.shoulderScore.TotalScores);
  const [docFeedback] = useSelector((state) => state.shoulderScore.doctorFeedback)
  const [isdoctorform, setisdoctorform] = useState(localStorage.getItem('isdoctorForm')==='true'?1:0)

  // const [TotalScore] = useSelector((state) => state.shoulderScore.TotalScore);
  const [chk, setChk] = useState(false);
  const [patientID, setpatientID] = useState('');
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        setpatientID(res.data.data2[0].patient_id)
        // setisdoctorform(res.data.data2[0].is_doctor_form)
        // dispatch(updateTotalScores(res.data.data2[0].TotalScore));
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });

   
  };
  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    save();
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }

  // CONSTANT SCORE CALCULATION

  const describe_pain_right = painSco.describe_pain_right === "0" ? 15 :
    painSco.describe_pain_right === "1" ? 10 :
      painSco.describe_pain_right === "2" ? 5 :
        painSco.describe_pain_right === "3" ? 0 : 0

  const describe_pain_left = painSco.describe_pain_left === "0" ? 15 :
    painSco.describe_pain_left === "1" ? 10 :
      painSco.describe_pain_left === "2" ? 5 :
        painSco.describe_pain_left === "3" ? 0 : 0

  const ConstantNightR = painSco.shoulder_pain_night_right === "0" ? 2 :
    painSco.shoulder_pain_night_right === "1" ? 0 : 0

  const ConstantNightL = painSco.shoulder_pain_night_left === "0" ? 2 :
    painSco.shoulder_pain_night_left === "1" ? 0 : 0

  const Constantwork_right = funScore.fullusual_work_right === "0" ? 0 :
    funScore.fullusual_work_right === "1" ? 0 :
      funScore.fullusual_work_right === "2" ? 0 :
        funScore.fullusual_work_right === "3" ? 4 : 0

  const Constantwork_left = funScore.fullusual_work_left === "0" ? 0 :
    funScore.fullusual_work_left === "1" ? 0 :
      funScore.fullusual_work_left === "2" ? 0 :
        funScore.fullusual_work_left === "3" ? 4 : 0

  const Constantsport_right = funScore.usual_sport_right === "0" ? 0 :
    funScore.usual_sport_right === "1" ? 0 :
      funScore.usual_sport_right === "2" ? 0 :
        funScore.usual_sport_right === "3" ? 4 : 0

  const Constantsport_left = funScore.usual_sport_left === "0" ? 0 :
    funScore.usual_sport_left === "1" ? 0 :
      funScore.usual_sport_left === "2" ? 0 :
        funScore.usual_sport_left === "3" ? 4 : 0

  const ConstantTR = sumAll(
    describe_pain_right,
    ConstantNightR,
    Constantwork_right,
    Constantsport_right,
  );


  // const constant_score_right = (ConstantTR).toFixed(2);
  // const ConstantTL = sumAll(
  //   describe_pain_left,
  //   ConstantNightL,
  //   Constantwork_left,
  //   Constantsport_left,
  // );
  // const constant_score_Left = (ConstantTL).toFixed(2);
  //VAS
  const pain_today_rating_right = painSco.pain_today_rating_right;
  const pain_today_rating_left = painSco.pain_today_rating_left;



  //WOSI Start    
  //Physical Symptoms(Left)


  const wosi_q1_left = parseFloat(wosiScore.wosi_q1_left);
  const wosi_q2_left = parseFloat(wosiScore.wosi_q2_left);
  const wosi_q3_left = parseFloat(wosiScore.wosi_q3_left);
  const wosi_q4_left = parseFloat(wosiScore.wosi_q4_left);
  const wosi_q5_left = parseFloat(wosiScore.wosi_q5_left);
  const wosi_q6_left = parseFloat(wosiScore.wosi_q6_left);
  const wosi_q7_left = parseFloat(wosiScore.wosi_q7_left);
  const wosi_q8_left = parseFloat(wosiScore.wosi_q8_left);
  const wosi_q9_left = parseFloat(wosiScore.wosi_q9_left);
  const wosi_q10_left = parseFloat(wosiScore.wosi_q10_left);

  //Physical Symptoms(Right)
  const wosi_q1_right = parseFloat(wosiScore.wosi_q1_right);
  const wosi_q2_right = parseFloat(wosiScore.wosi_q2_right);
  const wosi_q3_right = parseFloat(wosiScore.wosi_q3_right);
  const wosi_q4_right = parseFloat(wosiScore.wosi_q4_right);
  const wosi_q5_right = parseFloat(wosiScore.wosi_q5_right);
  const wosi_q6_right = parseFloat(wosiScore.wosi_q6_right);
  const wosi_q7_right = parseFloat(wosiScore.wosi_q7_right);
  const wosi_q8_right = parseFloat(wosiScore.wosi_q8_right);
  const wosi_q9_right = parseFloat(wosiScore.wosi_q9_right);
  const wosi_q10_right = parseFloat(wosiScore.wosi_q10_right);


  //Sports/Recreation/Work
  const wosi_q11_left = parseFloat(wosiScore.wosi_q11_left)
  const wosi_q12_left = parseFloat(wosiScore.wosi_q12_left)
  const wosi_q13_left = parseFloat(wosiScore.wosi_q13_left)
  const wosi_q14_left = parseFloat(wosiScore.wosi_q14_left)

  const wosi_q11_right = parseFloat(wosiScore.wosi_q11_right)
  const wosi_q12_right = parseFloat(wosiScore.wosi_q12_right)
  const wosi_q13_right = parseFloat(wosiScore.wosi_q13_right)
  const wosi_q14_right = parseFloat(wosiScore.wosi_q14_right)

  //Lifestyle
  const wosi_q15_left = parseFloat(wosiScore.wosi_q15_left)
  const wosi_q16_left = parseFloat(wosiScore.wosi_q16_left)
  const wosi_q17_left = parseFloat(wosiScore.wosi_q17_left)
  const wosi_q18_left = parseFloat(wosiScore.wosi_q18_left)

  const wosi_q15_right = parseFloat(wosiScore.wosi_q15_right)
  const wosi_q16_right = parseFloat(wosiScore.wosi_q16_right)
  const wosi_q17_right = parseFloat(wosiScore.wosi_q17_right)
  const wosi_q18_right = parseFloat(wosiScore.wosi_q18_right)



  //Emotions
  const wosi_q19_left = parseFloat(wosiScore.wosi_q19_left)
  const wosi_q20_left = parseFloat(wosiScore.wosi_q20_left)
  const wosi_q21_left = parseFloat(wosiScore.wosi_q21_left)



  const wosi_q19_right = parseFloat(wosiScore.wosi_q19_right)
  const wosi_q20_right = parseFloat(wosiScore.wosi_q20_right)
  const wosi_q21_right = parseFloat(wosiScore.wosi_q21_right)

  // console.log(wosi_q1_right, "this is new/................");
  // console.log(typeof (wosi_q1_right));
  // console.log(typeof(wosiScore.wosi_q1_right)  ,"whedgcdgcdgcdgcdgcdgcdgcdgcdgc");
  let WosiNumR = 0;
  // console.log(WosiNumR,"nnn");
  if (wosi_q1_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q2_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q3_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q4_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q5_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q6_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q7_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q8_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q9_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q10_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q11_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q12_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q13_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q14_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q15_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q16_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q17_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q18_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q19_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q20_right > 0) {
    WosiNumR = WosiNumR + 1
  }
  if (wosi_q21_right > 0) {
    WosiNumR = WosiNumR + 1
  }

  let WosiNum = 0;
  if (wosi_q1_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q2_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q3_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q4_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q5_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q6_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q7_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q8_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q9_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q10_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q11_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q12_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q13_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q14_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q15_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q16_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q17_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q18_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q19_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q20_left > 0) {
    WosiNum = WosiNum + 1
  }
  if (wosi_q21_left > 0) {
    WosiNum = WosiNum + 1
  }

  // console.log(WosiNumR,"num of value.....................");
  const WosiFR = sumAll(
    wosi_q1_right,
    wosi_q2_right,
    wosi_q3_right,
    wosi_q4_right,
    wosi_q5_right,
    wosi_q6_right,
    wosi_q7_right,
    wosi_q8_right,
    wosi_q9_right,
    wosi_q10_right,
    wosi_q11_right,
    wosi_q12_right,
    wosi_q13_right,
    wosi_q14_right,
    wosi_q15_right,
    wosi_q16_right,
    wosi_q17_right,
    wosi_q18_right,
    wosi_q19_right,
    wosi_q20_right,
    wosi_q21_right,
  )
  const WosiFL = sumAll(
    wosi_q1_left,
    wosi_q2_left,
    wosi_q3_left,
    wosi_q4_left,
    wosi_q5_left,
    wosi_q6_left,
    wosi_q7_left,
    wosi_q8_left,
    wosi_q9_left,
    wosi_q10_left,
    wosi_q11_left,
    wosi_q12_left,
    wosi_q13_left,
    wosi_q14_left,
    wosi_q15_left,
    wosi_q16_left,
    wosi_q17_left,
    wosi_q18_left,
    wosi_q19_left,
    wosi_q20_left,
    wosi_q21_left,
  )
  //   let WosiTp=(WosiNumR * 10)
  //   console.log(WosiNumR,"hhhhhhhhhhh");
  // console.log(typeof(WosiFR));
  // console.log(typeof(WosiNumR));
  // console.log(WosiFR,"this is right");
  // console.log(wosi_q1_right,
  //   wosi_q2_right,"rifhjhjhjh");
  // console.log(wosi_q1_left,
  //   wosi_q2_left,"rifhjhjhjh");
  //   console.log(wosiScore.wosi_q1_right,"this is wosi rigth");
  //   let x=WosiNumR*10
  let wosi_score_right;
  // let wosiNan= Math.floor(((WosiFR) / (WosiNumR * 10) * 100))
  let wosiNan = ((WosiFR / 21) * 10).toFixed()
  if (isNaN(wosiNan)) {
    wosi_score_right = 0
  } else {
    wosi_score_right = wosiNan
  }
  //  console.log(WosiFR*100/WosiNumR*10);
  // console.log(WOSIR);
  // let wosi_score_right;


  //  console.log(typeof(wosi_score_right).length,"jgcjhghjg");
  // if (WOSIR.length===undefined) {
  //   wosi_score_right = 0
  // } if(WOSIR.length !==undefined) {
  //   wosi_score_right = WOSIR
  // }

  let wosiScoreLeft;

  // let wosiLNan = Math.floor((WosiFL / (WosiNum * 10) * 100))
  let wosiLNan = ((WosiFL / 21) * 10).toFixed()

  if (isNaN(wosiLNan)) {
    wosiScoreLeft = 0
  } else {
    wosiScoreLeft = wosiLNan
  }

  //SACS SCORE CALCULATION(Right)

  const sacs_q1_left = parseFloat(sacsScore.sacs_q1_left)
  const sacs_q2_left = parseFloat(sacsScore.sacs_q2_left)
  const sacs_q3_left = parseFloat(sacsScore.sacs_q3_left)
  const sacs_q4_left = parseFloat(sacsScore.sacs_q4_left)
  const sacs_q5_left = parseFloat(sacsScore.sacs_q5_left)
  const sacs_q6_left = parseFloat(sacsScore.sacs_q6_left)
  const sacs_q7_left = parseFloat(sacsScore.sacs_q7_left)
  const sacs_q8_left = parseFloat(sacsScore.sacs_q8_left)
  const sacs_q9_left = parseFloat(sacsScore.sacs_q9_left)
  const sacs_q10_left = parseFloat(sacsScore.sacs_q10_left)
  const sacs_q11_left = parseFloat(sacsScore.sacs_q11_left)
  const sacs_q12_left = parseFloat(sacsScore.sacs_q12_left)
  const sacs_q13_left = parseFloat(sacsScore.sacs_q13_left)
  const sacs_q14_left = parseFloat(sacsScore.sacs_q14_left)
  const sacs_q15_left = parseFloat(sacsScore.sacs_q15_left)
  const sacs_q16_left = parseFloat(sacsScore.sacs_q16_left)
  const sacs_q17_left = parseFloat(sacsScore.sacs_q17_left)
  const sacs_q18_left = parseFloat(sacsScore.sacs_q18_left)
  const sacs_q19_left = parseFloat(sacsScore.sacs_q19_left)
  const sacs_q20_left = parseFloat(sacsScore.sacs_q20_left)
  let SCCSNum = 0;
  if (sacs_q1_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q2_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q3_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q4_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q5_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q6_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q7_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q8_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q9_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q10_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q11_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q12_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q13_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q14_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q15_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q16_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q17_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q18_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q19_left > 0) {
    SCCSNum = SCCSNum + 1
  } if (sacs_q20_left > 0) {
    SCCSNum = SCCSNum + 1
  }
  let leftvalue = 160
  let check2 = sacsScore.check2 === false ? 10 : 0
  let check4 = sacsScore.check4 === false ? 10 : 0
  let check6 = sacsScore.check6 === false ? 10 : 0
  let check8 = sacsScore.check8 === false ? 10 : 0
  let check1 = sacsScore.check1 === false ? 10 : 0
  let check3 = sacsScore.check3 === false ? 10 : 0
  let check5 = sacsScore.check5 === false ? 10 : 0
  let check7 = sacsScore.check7 === false ? 10 : 0
  const SASCFL = sumAll(
    sacs_q1_left,
    sacs_q2_left,
    sacs_q3_left,
    sacs_q4_left,
    sacs_q5_left,
    sacs_q6_left,
    sacs_q7_left,
    sacs_q8_left,
    sacs_q9_left,
    sacs_q10_left,
    sacs_q11_left,

    sacs_q12_left,
    sacs_q13_left,
    sacs_q14_left,
    sacs_q15_left,

    sacs_q16_left,
    sacs_q17_left,
    sacs_q18_left,
    sacs_q19_left,
    sacs_q20_left
  )
  // console.log(    sacs_q1_left,
  //   sacs_q2_left,
  //   sacs_q3_left,
  //   sacs_q4_left,
  //   sacs_q5_left,
  //   sacs_q6_left,
  //   sacs_q7_left,
  //   sacs_q8_left,
  //   sacs_q9_left,
  //   sacs_q10_left,
  //   sacs_q11_left,

  //   sacs_q12_left,
  //   sacs_q13_left,
  //   sacs_q14_left,
  //   sacs_q15_left,

  //   sacs_q16_left,
  //   sacs_q17_left,
  //   sacs_q18_left,
  //   sacs_q19_left,
  //   sacs_q20_left,"SACS LEFT");


  // let SACSL = Math.floor((SASCFL / (SCCSNum * 10) * 100))
  // let SACSL = ((SASCFL / (20 * 10) * 100)).toFixed()
  // let SACSL = ((SASCFL / (leftvalue + check2 + check4 + check6 + check8) * 100)).toFixed()
  let SACSL = ((SASCFL / (leftvalue + check1 + check3 + check5 + check7) * 100)).toFixed()
  // console.log("tot",SACSL,SASCFL,leftvalue +check2+check4+check6+check8,check2,"check");
  // console.log(SASCFL,"TotalSACSL");
  let sacs_score_left;
  if (SACSL === "NaN") {
    sacs_score_left = 0
  } else {
    sacs_score_left = SACSL
  }

  const sacs_q1_right = parseFloat(sacsScore.sacs_q1_right)
  const sacs_q2_right = parseFloat(sacsScore.sacs_q2_right)
  const sacs_q3_right = parseFloat(sacsScore.sacs_q3_right)
  const sacs_q4_right = parseFloat(sacsScore.sacs_q4_right)
  const sacs_q5_right = parseFloat(sacsScore.sacs_q5_right)
  const sacs_q6_right = parseFloat(sacsScore.sacs_q6_right)
  const sacs_q7_right = parseFloat(sacsScore.sacs_q7_right)
  const sacs_q8_right = parseFloat(sacsScore.sacs_q8_right)
  const sacs_q9_right = parseFloat(sacsScore.sacs_q9_right)
  const sacs_q10_right = parseFloat(sacsScore.sacs_q10_right)
  const sacs_q11_right = parseFloat(sacsScore.sacs_q11_right)
  const sacs_q12_right = parseFloat(sacsScore.sacs_q12_right)
  const sacs_q13_right = parseFloat(sacsScore.sacs_q13_right)
  const sacs_q14_right = parseFloat(sacsScore.sacs_q14_right)
  const sacs_q15_right = parseFloat(sacsScore.sacs_q15_right)
  const sacs_q16_right = parseFloat(sacsScore.sacs_q16_right)
  const sacs_q17_right = parseFloat(sacsScore.sacs_q17_right)
  const sacs_q18_right = parseFloat(sacsScore.sacs_q18_right)
  const sacs_q19_right = parseFloat(sacsScore.sacs_q19_right)
  const sacs_q20_right = parseFloat(sacsScore.sacs_q20_right)


  let SCCSNumR = 0;
  if (sacs_q1_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q2_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q3_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q4_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q5_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q6_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q7_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q8_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q9_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q10_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q11_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q12_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q13_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q14_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q15_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q16_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q17_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q18_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q19_right > 0) {
    SCCSNumR = SCCSNumR + 1
  } if (sacs_q20_right > 0) {
    SCCSNumR = SCCSNumR + 1
  }

  let value = 160

  const SASCFR = sumAll(
    sacs_q1_right,
    sacs_q2_right,
    sacs_q3_right,
    sacs_q4_right,
    sacs_q5_right,
    sacs_q6_right,
    sacs_q7_right,
    sacs_q8_right,
    sacs_q9_right,
    sacs_q10_right,
    sacs_q11_right,

    sacs_q12_right,
    sacs_q13_right,
    sacs_q14_right,
    sacs_q15_right,

    sacs_q16_right,
    sacs_q17_right,
    sacs_q18_right,
    sacs_q19_right,
    sacs_q20_right
  )
  // console.log(  sacs_q1_right,
  //   sacs_q2_right,
  //   sacs_q3_right,
  //   sacs_q4_right,
  //   sacs_q5_right,
  //   sacs_q6_right,
  //   sacs_q7_right,
  //   sacs_q8_right,
  //   sacs_q9_right,
  //   sacs_q10_right,
  //   sacs_q11_right,

  //   sacs_q12_right,
  //   sacs_q13_right,
  //   sacs_q14_right,
  //   sacs_q15_right,

  //   sacs_q16_right,
  //   sacs_q17_right,
  //   sacs_q18_right,
  //   sacs_q19_right,
  //   sacs_q20_right,"SACS RIGHT");

  // const SACSR = Math.floor((SASCFR / (SCCSNumR * 10) * 100))
  // let SACSR = ((SASCFR / (20 * 10) * 100)).toFixed()
  let SACSR = ((SASCFR / (value + check1 + check3 + check5 + check7) * 100)).toFixed()
  let sacs_score_right;

  if (SACSR === "NaN") {
    sacs_score_right = 0
  } else {
    sacs_score_right = SACSR
  }
  //   //SST
  //   let totalNum = 0;
  //   if (painSco.best_statment_pain_right !== 0) {
  //     totalNum = totalNum + 1
  //   } if (painSco.shoulder_pain_night_right !== 0) {
  //     totalNum = totalNum + 1
  //   } if (funScore.lift05_right !== 0) {
  //     totalNum = totalNum + 1
  //   } if (funScore.lift45_right !== 0) {
  //     totalNum = totalNum + 1
  //   } if (funScore.lift10_right !== 0) {
  //     totalNum = totalNum + 1
  //   } if (funScore.throwunder_hand_right !== 0) {
  //     totalNum = totalNum + 1
  //   } if (funScore.throwover_hand_right !== 0) {
  //     totalNum = totalNum + 1
  //   } if (funScore.wash_back_right !== 0) {
  //     totalNum = totalNum + 1
  //   } if (funScore.fullusual_work_right !== 0) {
  //     totalNum = totalNum + 1
  //   }
  //   else {
  //     totalNum = 0
  //   }

  //   let totalNumL = 0;
  //   if (painSco.best_statment_pain_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   } if (painSco.shoulder_pain_night_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   } if (funScore.lift05_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   } if (funScore.lift45_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   } if (funScore.lift10_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   } if (funScore.throwunder_hand_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   } if (funScore.throwover_hand_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   } if (funScore.wash_back_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   } if (funScore.fullusual_work_left !== 0) {
  //     totalNumL = totalNumL + 1
  //   }
  //   else {
  //     totalNumL = 0
  //   }



  //   const SstdescribesR = painSco.best_statment_pain_right === "0" ? 1 :
  //     painSco.best_statment_pain_right === "1" ? 1 :
  //       painSco.best_statment_pain_right === "2" ? 0 :
  //         painSco.best_statment_pain_right === "3" ? 0 :
  //           painSco.best_statment_pain_right === "4" ? 0 :
  //             painSco.best_statment_pain_right === "5" ? 0 : 0

  //   const SstdescribesL = painSco.best_statment_pain_left === "0" ? 1 :
  //     painSco.best_statment_pain_left === "1" ? 1 :
  //       painSco.best_statment_pain_left === "2" ? 0 :
  //         painSco.best_statment_pain_left === "3" ? 0 :
  //           painSco.best_statment_pain_left === "4" ? 0 :
  //             painSco.best_statment_pain_left === "5" ? 0 : 0


  //   const shoulder_pain_night_left = parseFloat(painSco.shoulder_pain_night_left);

  //   const shoulder_pain_night_right = parseFloat(painSco.shoulder_pain_night_right);

  //   const lift05_right = funScore.lift05_right === "0" ? 0 :
  //     funScore.lift05_right === "1" ? 0 :
  //       funScore.lift05_right === "2" ? 0 :
  //         funScore.lift05_right === "3" ? 1 : 0

  //   const lift45_right = funScore.lift45_right === "0" ? 0 :
  //     funScore.lift45_right === "1" ? 0 :
  //       funScore.lift45_right === "2" ? 0 :
  //         funScore.lift45_right === "3" ? 1 : 0

  //   const lift10_right = funScore.lift10_right === "0" ? 0 :
  //     funScore.lift10_right === "1" ? 0 :
  //       funScore.lift10_right === "2" ? 0 :
  //         funScore.lift10_right === "3" ? 1 : 0

  //   const throwunder_hand_right = funScore.throwunder_hand_right === "0" ? 0 :
  //     funScore.throwunder_hand_right === "1" ? 0 :
  //       funScore.throwunder_hand_right === "2" ? 0 :
  //         funScore.throwunder_hand_right === "3" ? 1 : 0

  //   const throwover_hand_right = funScore.throwover_hand_right === "0" ? 0 :
  //     funScore.throwover_hand_right === "1" ? 0 :
  //       funScore.throwover_hand_right === "2" ? 0 :
  //         funScore.throwover_hand_right === "3" ? 1 : 0

  //   const wash_back_right = funScore.wash_back_right === "0" ? 0 :
  //     funScore.wash_back_right === "1" ? 0 :
  //       funScore.wash_back_right === "2" ? 0 :
  //         funScore.wash_back_right === "3" ? 1 : 0

  //   const fullusual_work_right = funScore.fullusual_work_right === "0" ? 0 :
  //     funScore.fullusual_work_right === "1" ? 0 :
  //       funScore.fullusual_work_right === "2" ? 0 :
  //         funScore.fullusual_work_right === "3" ? 1 : 0

  //   const lift05_left = funScore.lift05_left === "0" ? 0 :
  //     funScore.lift05_left === "1" ? 0 :
  //       funScore.lift05_left === "2" ? 0 :
  //         funScore.lift05_left === "3" ? 1 : 0

  //   const lift45_left = funScore.lift45_left === "0" ? 0 :
  //     funScore.lift45_left === "1" ? 0 :
  //       funScore.lift45_left === "2" ? 0 :
  //         funScore.lift45_left === "3" ? 1 : 0

  //   const lift10_left = funScore.lift10_left === "0" ? 0 :
  //     funScore.lift10_left === "1" ? 0 :
  //       funScore.lift10_left === "2" ? 0 :
  //         funScore.lift10_left === "3" ? 1 : 0

  //   const throwunder_hand_left = funScore.throwunder_hand_left === "0" ? 0 :
  //     funScore.throwunder_hand_left === "1" ? 0 :
  //       funScore.throwunder_hand_left === "2" ? 0 :
  //         funScore.throwunder_hand_left === "3" ? 1 : 0

  //   const throwover_hand_left = funScore.throwover_hand_left === "0" ? 0 :
  //     funScore.throwover_hand_left === "1" ? 0 :
  //       funScore.throwover_hand_left === "2" ? 0 :
  //         funScore.throwover_hand_left === "3" ? 1 : 0

  //   const wash_back_left = funScore.wash_back_left === "0" ? 0 :
  //     funScore.wash_back_left === "1" ? 0 :
  //       funScore.wash_back_left === "2" ? 0 :
  //         funScore.wash_back_left === "3" ? 1 : 0

  //   const fullusual_work_left = funScore.fullusual_work_left === "0" ? 0 :
  //     funScore.fullusual_work_left === "1" ? 0 :
  //       funScore.fullusual_work_left === "2" ? 0 :
  //         funScore.fullusual_work_left === "3" ? 1 : 0

  //   const SSTR = sumAll(
  //     SstdescribesR,
  //     shoulder_pain_night_right,
  //     lift05_right,
  //     lift45_right,
  //     lift10_right,
  //     throwunder_hand_right,
  //     throwover_hand_right,
  //     wash_back_right,
  //     fullusual_work_right
  //   )
  // const SSTcR=((SSTR / totalNum) * 100).toFixed(2)
  //   let sst_score_right ;
  //   if(SSTcR==="NaN"){
  //     sst_score_right=0
  //     }else{
  //       sst_score_right=SSTcR
  //     }
  //   const SSTL = sumAll(
  //     SstdescribesL,
  //     shoulder_pain_night_left,
  //     lift05_left,
  //     lift45_left,
  //     lift10_left,
  //     throwunder_hand_left,
  //     throwover_hand_left,
  //     wash_back_left,
  //     fullusual_work_left
  //   )
  // const SSTcL= ((SSTL / totalNumL) * 100).toFixed(2)
  //   let sst_score_left ;
  //   if(SSTcL==="NaN"){
  //     sst_score_left=0
  //     }else{
  //       sst_score_left=SSTcL
  //     }
  //ASES
  const ASESPainR = parseFloat(painSco.pain_today_rating_right);

  // const affected_shoulder_right = funScore.affected_shoulder_right === "0" ? 0 :
  //   funScore.affected_shoulder_right === "1" ? 1 :
  //     funScore.affected_shoulder_right === "2" ? 2 :
  //       funScore.affected_shoulder_right === "3" ? 3 : 0

  const put_shirt_right = funScore.put_shirt_right === "0" ? 0 :  //
    funScore.put_shirt_right === "1" ? 1 :
      funScore.put_shirt_right === "2" ? 2 :
        funScore.put_shirt_right === "3" ? 3 : 0
  const affected_side_right = funScore.affected_side_right === "0" ? 0 ://
    funScore.affected_side_right === "1" ? 1 :
      funScore.affected_side_right === "2" ? 2 :
        funScore.affected_side_right === "3" ? 3 : 0
  const ASESwash_back_right = funScore.wash_back_right === "0" ? 0 ://
    funScore.wash_back_right === "1" ? 1 :
      funScore.wash_back_right === "2" ? 2 :
        funScore.wash_back_right === "3" ? 3 : 0
  const manage_toilet_right = funScore.manage_toilet_right === "0" ? 0 ://
    funScore.manage_toilet_right === "1" ? 1 :
      funScore.manage_toilet_right === "2" ? 2 :
        funScore.manage_toilet_right === "3" ? 3 : 0
  const comb_hair_right = funScore.comb_hair_right === "0" ? 0 ://
    funScore.comb_hair_right === "1" ? 1 :
      funScore.comb_hair_right === "2" ? 2 :
        funScore.comb_hair_right === "3" ? 3 : 0
  const reach_shelf_right = funScore.reach_shelf_right === "0" ? 0 ://
    funScore.reach_shelf_right === "1" ? 1 :
      funScore.reach_shelf_right === "2" ? 2 :
        funScore.reach_shelf_right === "3" ? 3 : 0
  const ASESlift45_right = funScore.lift45_right === "0" ? 0 ://
    funScore.lift45_right === "1" ? 1 :
      funScore.lift45_right === "2" ? 2 :
        funScore.lift45_right === "3" ? 3 : 0

  const throwover_hand_right = funScore.throwover_hand_right === "0" ? 0 ://
    funScore.throwover_hand_right === "1" ? 1 :
      funScore.throwover_hand_right === "2" ? 2 :
        funScore.throwover_hand_right === "3" ? 3 : 0

  // const ASESthrowunder_right = funScore.throwunder_hand_right === "0" ? 0 :
  //   funScore.throwunder_hand_right === "1" ? 1 :
  //     funScore.throwunder_hand_right === "2" ? 2 :
  //       funScore.throwunder_hand_right === "3" ? 3 : 0

  const ASESwork_right = funScore.fullusual_work_right === "0" ? 0 :
    funScore.fullusual_work_right === "1" ? 1 :
      funScore.fullusual_work_right === "2" ? 2 :
        funScore.fullusual_work_right === "3" ? 3 : 0

  const ASESusual_right = funScore.usual_sport_right === "0" ? 0 :
    funScore.usual_sport_right === "1" ? 1 :
      funScore.usual_sport_right === "2" ? 2 :
        funScore.usual_sport_right === "3" ? 3 : 0



  const sumOfFunR = sumAll(
    throwover_hand_right,
    put_shirt_right,
    affected_side_right,
    ASESwash_back_right,
    manage_toilet_right,
    comb_hair_right,
    reach_shelf_right,
    ASESlift45_right,
    // ASESthrowunder_right,
    ASESwork_right,
    ASESusual_right
  )
  // console.log(typeof (sumOfFunR), "this");


  const ASESPainL = parseFloat(painSco.pain_today_rating_left);
  const affected_shoulder_left = parseFloat(funScore.affected_shoulder_left)
  const put_shirt_left = parseFloat(funScore.put_shirt_left)
  const affected_side_left = parseFloat(funScore.affected_side_left)
  const ASESwash_back_left = parseFloat(funScore.wash_back_left)
  const manage_toilet_left = parseFloat(funScore.manage_toilet_left)
  const comb_hair_left = parseFloat(funScore.comb_hair_left)
  const reach_shelf_left = parseFloat(funScore.reach_shelf_left)
  const ASESlift45_left = parseFloat(funScore.lift45_left)
  const ASESthrowunder_left = parseFloat(funScore.throwover_hand_left)
  const ASESwork_left = parseFloat(funScore.fullusual_work_left)
  const ASESusual_left = parseFloat(funScore.usual_sport_left)



  const sumOfFunL = sumAll(
    put_shirt_left,
    affected_side_left,
    ASESwash_back_left,
    manage_toilet_left,
    comb_hair_left,
    reach_shelf_left,
    ASESlift45_left,
    ASESthrowunder_left,
    ASESwork_left,
    ASESusual_left
  )


  const sumOfFunFL = (sumOfFunL * 1.5)

  const ases_score_left = (((10 - ASESPainL) * 5 + (sumOfFunL * 5 / 3))).toFixed();

  const ases_score_right = (((10 - ASESPainR) * 5 + (sumOfFunR * 5 / 3))).toFixed();


  //new code

  //Constant Score

  const describe_pain_right1 =
    painSco.describe_pain_right === "0"
      ? 15
      : painSco.describe_pain_right === "1"
        ? 14
        : painSco.describe_pain_right === "2"
          ? 13
          : painSco.describe_pain_right === "3"
            ? 12
            : painSco.describe_pain_right === "4"
              ? 11
              : painSco.describe_pain_right === "5"
                ? 10
                : painSco.describe_pain_right === "6"
                  ? 9
                  : painSco.describe_pain_right === "7"
                    ? 8
                    : painSco.describe_pain_right === "8"
                      ? 7
                      : painSco.describe_pain_right === "9"
                        ? 6
                        : painSco.describe_pain_right === "10"
                          ? 5
                          : painSco.describe_pain_right === "11"
                            ? 4
                            : painSco.describe_pain_right === "12"
                              ? 3
                              : painSco.describe_pain_right === "13"
                                ? 2
                                : painSco.describe_pain_right === "14"
                                  ? 1
                                  : painSco.describe_pain_right === "15"
                                    ? 0 : 0;

  const describe_pain_left1 =
    painSco.describe_pain_left === "0"
      ? 15
      : painSco.describe_pain_left === "1"
        ? 14
        : painSco.describe_pain_left === "2"
          ? 13
          : painSco.describe_pain_left === "3"
            ? 12
            : painSco.describe_pain_left === "4"
              ? 11
              : painSco.describe_pain_left === "5"
                ? 10
                : painSco.describe_pain_left === "6"
                  ? 9
                  : painSco.describe_pain_left === "7"
                    ? 8
                    : painSco.describe_pain_left === "8"
                      ? 7
                      : painSco.describe_pain_left === "9"
                        ? 6
                        : painSco.describe_pain_left === "10"
                          ? 5
                          : painSco.describe_pain_left === "11"
                            ? 4
                            : painSco.describe_pain_left === "12"
                              ? 3
                              : painSco.describe_pain_left === "13"
                                ? 2
                                : painSco.describe_pain_left === "14"
                                  ? 1
                                  : painSco.describe_pain_left === "15"
                                    ? 0 : 0;

  const constshoulder_pain_night_right =
    painSco.shoulder_pain_night_right === "0"
      ? 2 :
      painSco.shoulder_pain_night_right === "1"
        ? 1
        : painSco.shoulder_pain_night_right === "2"
          ? 0 : 0;

  const constshoulder_pain_night_left =
    painSco.shoulder_pain_night_left === "0"
      ? 2 :
      painSco.shoulder_pain_night_left === "1"
        ? 1
        : painSco.shoulder_pain_night_left === "2"
          ? 0 : 0;

  const const_normal_right = Number(funScore.normal_right)

  const const_normal_left = Number(funScore.normal_left)

  const const_recreational_right = Number(funScore.recreational_right)

  const const_recreational_left = Number(funScore.recreational_left)


  const armposition_right =
    docFeedback.armposition_right === "1"
      ? 2
      : docFeedback.armposition_right === "2"
        ? 4
        : docFeedback.armposition_right === "3"
          ? 6
          : docFeedback.armposition_right === "4"
            ? 8
            : docFeedback.armposition_right === "5"
              ? 10
              : 0;

  const armposition_left =
    docFeedback.armposition_left === "1"
      ? 2
      : docFeedback.armposition_left === "2"
        ? 4
        : docFeedback.armposition_left === "3"
          ? 6
          : docFeedback.armposition_left === "4"
            ? 8
            : docFeedback.armposition_left === "5"
              ? 10
              : 0;

  const prom_forward_right =
    docFeedback.active_forward_degree_right <= 30
      ? 0
      : docFeedback.active_forward_degree_right === 31 ||
        docFeedback.active_forward_degree_right < 61
        ? 2
        : docFeedback.active_forward_degree_right === 61 ||
          docFeedback.active_forward_degree_right < 91
          ? 4
          : docFeedback.active_forward_degree_right === 91 ||
            docFeedback.active_forward_degree_right < 121
            ? 6
            : docFeedback.active_forward_degree_right === 121 ||
              docFeedback.active_forward_degree_right < 151
              ? 8
              : docFeedback.active_forward_degree_right === 151 ||
                docFeedback.active_forward_degree_right < 181
                ? 10
                : 0;

  const prom_forward_left =
    docFeedback.active_forward_degree_left <= 30
      ? 0
      : docFeedback.active_forward_degree_left === 31 ||
        docFeedback.active_forward_degree_left < 61
        ? 2
        : docFeedback.active_forward_degree_left === 61 ||
          docFeedback.active_forward_degree_left < 91
          ? 4
          : docFeedback.active_forward_degree_left === 91 ||
            docFeedback.active_forward_degree_left < 121
            ? 6
            : docFeedback.active_forward_degree_left === 121 ||
              docFeedback.active_forward_degree_left < 151
              ? 8
              : docFeedback.active_forward_degree_left === 151 ||
                docFeedback.active_forward_degree_left < 181
                ? 10
                : 0;

  const lateralelevation_right =
    docFeedback.lateralelevation_right <= 30
      ? 0
      : docFeedback.lateralelevation_right === 31 ||
        docFeedback.lateralelevation_right < 61
        ? 2
        : docFeedback.lateralelevation_right === 61 ||
          docFeedback.lateralelevation_right < 91
          ? 4
          : docFeedback.lateralelevation_right === 91 ||
            docFeedback.lateralelevation_right < 121
            ? 6
            : docFeedback.lateralelevation_right === 121 ||
              docFeedback.lateralelevation_right < 151
              ? 8
              : docFeedback.lateralelevation_right === 151 ||
                docFeedback.lateralelevation_right < 181
                ? 10
                : 0;

  const lateralelevation_left =
    docFeedback.lateralelevation_left <= 30
      ? 0
      : docFeedback.lateralelevation_left === 31 ||
        docFeedback.lateralelevation_left < 61
        ? 2
        : docFeedback.lateralelevation_left === 61 ||
          docFeedback.lateralelevation_left < 91
          ? 4
          : docFeedback.lateralelevation_left === 91 ||
            docFeedback.lateralelevation_left < 121
            ? 6
            : docFeedback.lateralelevation_left === 121 ||
              docFeedback.lateralelevation_left < 151
              ? 8
              : docFeedback.lateralelevation_left === 151 ||
                docFeedback.lateralelevation_left < 181
                ? 10
                : 0;

  const ir1_right =
    docFeedback.ir1_right === "1"
      ? 0
      : docFeedback.ir1_right === "2"
        ? 2
        : docFeedback.ir1_right === "3"
          ? 4
          : docFeedback.ir1_right === "4"
            ? 6
            : docFeedback.ir1_right === "5"
              ? 8
              : docFeedback.ir1_right === "6"
                ? 10
                : 0;

  const ir1_left =
    docFeedback.ir1_left === "1"
      ? 0
      : docFeedback.ir1_left === "2"
        ? 2
        : docFeedback.ir1_left === "3"
          ? 4
          : docFeedback.ir1_left === "4"
            ? 6
            : docFeedback.ir1_left === "5"
              ? 8
              : docFeedback.ir1_left === "6"
                ? 10
                : 0;

  const er2_right =
    docFeedback.er2_right === "1"
      ? 2
      : docFeedback.er2_right === "2"
        ? 4
        : docFeedback.er2_right === "3"
          ? 6
          : docFeedback.er2_right === "4"
            ? 8
            : docFeedback.er2_right === "5"
              ? 10
              : 0;

  const er2_left =
    docFeedback.er2_left === "1"
      ? 2
      : docFeedback.er2_left === "2"
        ? 4
        : docFeedback.er2_left === "3"
          ? 6
          : docFeedback.er2_left === "4"
            ? 8
            : docFeedback.er2_left === "5"
              ? 10
              : 0;

  const lateralelevatioRight_cal = docFeedback.strengh_lateralelevation_right;
  let lateral_cal = Math.round(lateralelevatioRight_cal * 2.204)
  const lateralelevatioRight = lateral_cal < 25 ? lateral_cal : 25

  const lateralelevatioLeft_cal = docFeedback.strengh_lateralelevation_left;
  let lateral_cal_left = Math.round(lateralelevatioLeft_cal * 2.204)
  const lateralelevatioLeft = lateral_cal_left < 25 ? lateral_cal_left : 25
  // const lateralelevatioRight =
  //   docFeedback.strengh_lateralelevation_right === 0 || docFeedback.strengh_lateralelevation_right < 0.23
  //     ? 0
  //     : docFeedback.strengh_lateralelevation_right === 0.23 ||
  //       docFeedback.strengh_lateralelevation_right < 1.59
  //       ? 2
  //       : docFeedback.strengh_lateralelevation_right === 1.59 ||
  //         docFeedback.strengh_lateralelevation_right < 2.95
  //         ? 5
  //         : docFeedback.strengh_lateralelevation_right === 2.95 ||
  //           docFeedback.strengh_lateralelevation_right < 4.32
  //           ? 8
  //           : docFeedback.strengh_lateralelevation_right === 4.32 ||
  //             docFeedback.strengh_lateralelevation_right < 5.68
  //             ? 11
  //             : docFeedback.strengh_lateralelevation_right === 5.68 ||
  //               docFeedback.strengh_lateralelevation_right < 7.04
  //               ? 14
  //               : docFeedback.strengh_lateralelevation_right === 7.04 ||
  //                 docFeedback.strengh_lateralelevation_right < 8.40
  //                 ? 17
  //                 : docFeedback.strengh_lateralelevation_right === 8.40 ||
  //                   docFeedback.strengh_lateralelevation_right < 9.76
  //                   ? 20
  //                   : docFeedback.strengh_lateralelevation_right === 9.76 ||
  //                     docFeedback.strengh_lateralelevation_right < 11.12
  //                     ? 23
  //                     : docFeedback.strengh_lateralelevation_right >= 11.12
  //                       ? 25
  //                       : 0;

  // const lateralelevatioLeft =
  //   docFeedback.strengh_lateralelevation_left === 0 || docFeedback.strengh_lateralelevation_left < 0.23
  //     ? 0
  //     : docFeedback.strengh_lateralelevation_left === 0.23 ||
  //       docFeedback.strengh_lateralelevation_left < 1.59
  //       ? 2
  //       : docFeedback.strengh_lateralelevation_left === 1.59 ||
  //         docFeedback.strengh_lateralelevation_left < 2.95
  //         ? 5
  //         : docFeedback.strengh_lateralelevation_left === 2.95 ||
  //           docFeedback.strengh_lateralelevation_left < 4.32
  //           ? 8
  //           : docFeedback.strengh_lateralelevation_left === 4.32 ||
  //             docFeedback.strengh_lateralelevation_left < 5.68
  //             ? 11
  //             : docFeedback.strengh_lateralelevation_left === 5.68 ||
  //               docFeedback.strengh_lateralelevation_left < 7.04
  //               ? 14
  //               : docFeedback.strengh_lateralelevation_left === 7.04 ||
  //                 docFeedback.strengh_lateralelevation_left < 8.40
  //                 ? 17
  //                 : docFeedback.strengh_lateralelevation_left === 8.40 ||
  //                   docFeedback.strengh_lateralelevation_left < 9.76
  //                   ? 20
  //                   : docFeedback.strengh_lateralelevation_left === 9.76 ||
  //                     docFeedback.strengh_lateralelevation_left < 11.12
  //                     ? 23
  //                     : docFeedback.strengh_lateralelevation_left >= 11.12
  //                       ? 25
  //                       : 0;

  const constant_score_right = sumAll(
    const_recreational_right,
    const_normal_right,
    constshoulder_pain_night_right,
    describe_pain_right1,

    armposition_right,
    prom_forward_right,
    lateralelevation_right,
    ir1_right,
    er2_right,
    lateralelevatioRight
  );
  // console.log(
  //   usual_sport_right,
  //   constfullusual_work_right,
  //   constshoulder_pain_night_right,
  //   describe_pain_right1,
  //   armposition_right,
  //   prom_forward_right,
  //   lateralelevation_right,
  //   ir1_right,
  //   er2_right,
  //   lateralelevatioRight, 'consent score right');
  // const constant_score_right = 6;

  const constant_score_Left = sumAll(
    const_recreational_left,
    const_normal_left,
    constshoulder_pain_night_left,
    describe_pain_left1,

    armposition_left,
    prom_forward_left,
    lateralelevation_left,
    ir1_left,
    er2_left,
    lateralelevatioLeft
  );


  // const constant_score_Left = 6;

  // UCLA Score

  //UCLA SCORE CALCULATION
  const PaindescribesR =
    painSco.best_statment_pain_right === "0"
      ? 10
      : painSco.best_statment_pain_right === "1"
        ? 8
        : painSco.best_statment_pain_right === "2"
          ? 6
          : painSco.best_statment_pain_right === "3"
            ? 4
            : painSco.best_statment_pain_right === "4"
              ? 2
              : painSco.best_statment_pain_right === "5"
                ? 1
                : 0;

  const PaindescribesL =
    painSco.best_statment_pain_left === "0"
      ? 10
      : painSco.best_statment_pain_left === "1"
        ? 8
        : painSco.best_statment_pain_left === "2"
          ? 6
          : painSco.best_statment_pain_left === "3"
            ? 4
            : painSco.best_statment_pain_left === "4"
              ? 2
              : painSco.best_statment_pain_left === "5"
                ? 1
                : 0;

  const PainaffshoulderR =
    funScore.affected_shoulder_right === "0"
      ? 10
      : funScore.affected_shoulder_right === "1"
        ? 8
        : funScore.affected_shoulder_right === "2"
          ? 6
          : funScore.affected_shoulder_right === "3"
            ? 4
            : funScore.affected_shoulder_right === "4"
              ? 2
              : funScore.affected_shoulder_right === "5"
                ? 1
                : 0;

  const PainaffshoulderL =
    funScore.affected_shoulder_left === "0"
      ? 10
      : funScore.affected_shoulder_left === "1"
        ? 8
        : funScore.affected_shoulder_left === "2"
          ? 6
          : funScore.affected_shoulder_left === "3"
            ? 4
            : funScore.affected_shoulder_left === "4"
              ? 2
              : funScore.affected_shoulder_left === "5"
                ? 1
                : 0;

  const SatisfactionL =
    safisScore.satisfaction_left === "0"
      ? 0
      : safisScore.satisfaction_left === "1"
        ? 5
        : 0;

  const SatisfactionR =
    safisScore.satisfaction_right === "0"
      ? 0
      : safisScore.satisfaction_right === "1"
        ? 5
        : 0;


  // const ucla_score_right = sumAll(

  // )
  // const ucla_score_left = sumAll(

  // )

  const UCLAprom_forward_right =
    docFeedback.active_forward_degree_right < 30
      ? 0
      : docFeedback.active_forward_degree_right >= 30 &&
        docFeedback.active_forward_degree_right <= 44
        ? 1
        : docFeedback.active_forward_degree_right >= 45 &&
          docFeedback.active_forward_degree_right <= 89
          ? 2
          : docFeedback.active_forward_degree_right >= 90 &&
            docFeedback.active_forward_degree_right <= 119
            ? 3
            : docFeedback.active_forward_degree_right >= 120 &&
              docFeedback.active_forward_degree_right <= 149
              ? 4
              : docFeedback.active_forward_degree_right >= 150
                ? 5
                : 0;

  const UCLAprom_forward_left =
    docFeedback.active_forward_degree_left < 30
      ? 0
      : docFeedback.active_forward_degree_left >= 30 &&
        docFeedback.active_forward_degree_left <= 44
        ? 1
        : docFeedback.active_forward_degree_left >= 45 &&
          docFeedback.active_forward_degree_left <= 89
          ? 2
          : docFeedback.active_forward_degree_left >= 90 &&
            docFeedback.active_forward_degree_left <= 119
            ? 3
            : docFeedback.active_forward_degree_left >= 120 &&
              docFeedback.active_forward_degree_left <= 149
              ? 4
              : docFeedback.active_forward_degree_left >= 150
                ? 5
                : 0;



  const active_forward_grade_right =
    docFeedback.active_forward_grade_right === "0"
      ? 0
      : docFeedback.active_forward_grade_right === "1"
        ? 1
        : docFeedback.active_forward_grade_right === "2"
          ? 2
          : docFeedback.active_forward_grade_right === "3"
            ? 3
            : docFeedback.active_forward_grade_right === "4"
              ? 4
              : docFeedback.active_forward_grade_right === "5"
                ? 5
                : 0;
  const active_forward_grade_left =
    docFeedback.active_forward_grade_left === "0"
      ? 0
      : docFeedback.active_forward_grade_left === "1"
        ? 1
        : docFeedback.active_forward_grade_left === "2"
          ? 2
          : docFeedback.active_forward_grade_left === "3"
            ? 3
            : docFeedback.active_forward_grade_left === "4"
              ? 4
              : docFeedback.active_forward_grade_left === "5"
                ? 5
                : 0;

  const ucla_score_right = sumAll(
    UCLAprom_forward_right,
    active_forward_grade_right,
    PaindescribesR,
    PainaffshoulderR,
    SatisfactionR
  );

  // const ucla_score_right = 5;
  const ucla_score_left = sumAll(
    UCLAprom_forward_left,
    active_forward_grade_left,
    PaindescribesL,
    PainaffshoulderL,
    SatisfactionL
  );



  // const ucla_score_left = 5;

  //SST
  let totalNum = 0;
  if (painSco.best_statment_pain_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (painSco.shoulder_pain_night_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.lift05_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.lift45_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.lift10_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.throwunder_hand_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.throwover_hand_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.wash_back_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.fullusual_work_right !== 0) {
    totalNum = totalNum + 1;
  } else {
    totalNum = 0;
  }

  let totalNumL = 0;
  if (painSco.best_statment_pain_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (painSco.shoulder_pain_night_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.lift05_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.lift45_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.lift10_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.throwunder_hand_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.throwover_hand_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.wash_back_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.fullusual_work_left !== 0) {
    totalNumL = totalNumL + 1;
  } else {
    totalNumL = 0;
  }

  const sster2_right =
    docFeedback.er2_right === "1"
      ? 0
      : docFeedback.er2_right === "2"
        ? 0
        : docFeedback.er2_right === "3"
          ? 0
          : docFeedback.er2_right === "4"
            ? 1
            : docFeedback.er2_right === "5"
              ? 1
              : 0;

  const sster2_left =
    docFeedback.er2_left === "1"
      ? 0
      : docFeedback.er2_left === "2"
        ? 0
        : docFeedback.er2_left === "3"
          ? 0
          : docFeedback.er2_left === "4"
            ? 1
            : docFeedback.er2_left === "5"
              ? 1
              : 0;

  const sstarmposition_right =
    docFeedback.armposition_right === "1"
      ? 0
      : docFeedback.armposition_right === "2"
        ? 0
        : docFeedback.armposition_right === "3"
          ? 1
          : docFeedback.armposition_right === "4"
            ? 1
            : docFeedback.armposition_right === "5"
              ? 1
              : 0;

  const sstarmposition_left =
    docFeedback.armposition_left === "1"
      ? 0
      : docFeedback.armposition_left === "2"
        ? 0
        : docFeedback.armposition_left === "3"
          ? 1
          : docFeedback.armposition_left === "4"
            ? 1
            : docFeedback.armposition_left === "5"
              ? 1
              : 0;

  const sstir1_right =
    docFeedback.ir1_right === "1"
      ? 0
      : docFeedback.ir1_right === "2"
        ? 0
        : docFeedback.ir1_right === "3"
          ? 0
          : docFeedback.ir1_right === "4"
            ? 1
            : docFeedback.ir1_right === "5"
              ? 1
              : docFeedback.ir1_right === "6"
                ? 1
                : 0;
  const sstir1_left =
    docFeedback.ir1_left === "1"
      ? 0
      : docFeedback.ir1_left === "2"
        ? 0
        : docFeedback.ir1_left === "3"
          ? 0
          : docFeedback.ir1_left === "4"
            ? 1
            : docFeedback.ir1_left === "5"
              ? 1
              : docFeedback.ir1_left === "6"
                ? 1
                : 0;

  const SstdescribesR =
    painSco.best_statment_pain_right === "0"
      ? 1
      : painSco.best_statment_pain_right === "1"
        ? 1
        : painSco.best_statment_pain_right === "2"
          ? 0
          : painSco.best_statment_pain_right === "3"
            ? 0
            : painSco.best_statment_pain_right === "4"
              ? 0
              : painSco.best_statment_pain_right === "5"
                ? 0
                : 0;

  const SstdescribesL =
    painSco.best_statment_pain_left === "0"
      ? 1
      : painSco.best_statment_pain_left === "1"
        ? 1
        : painSco.best_statment_pain_left === "2"
          ? 0
          : painSco.best_statment_pain_left === "3"
            ? 0
            : painSco.best_statment_pain_left === "4"
              ? 0
              : painSco.best_statment_pain_left === "5"
                ? 0
                : 0;

  const shoulder_pain_night_left = painSco.shoulder_pain_night_left === "0"
    ? 1 : 0;

  const shoulder_pain_night_right = painSco.shoulder_pain_night_right === "0"
    ? 1 : 0;

  const lift05_right =
    funScore.lift05_right === "0"
      ? 0
      : funScore.lift05_right === "1"
        ? 0
        : funScore.lift05_right === "2"
          ? 0
          : funScore.lift05_right === "3"
            ? 1
            : 0;

  const lift45_right =
    funScore.lift45_right === "0"
      ? 0
      : funScore.lift45_right === "1"
        ? 0
        : funScore.lift45_right === "2"
          ? 0
          : funScore.lift45_right === "3"
            ? 1
            : 0;

  const lift10_right =
    funScore.lift10_right === "0"
      ? 0
      : funScore.lift10_right === "1"
        ? 0
        : funScore.lift10_right === "2"
          ? 0
          : funScore.lift10_right === "3"
            ? 1
            : 0;

  const throwunder_hand_right =
    funScore.throwunder_hand_right === "0"
      ? 0
      : funScore.throwunder_hand_right === "1"
        ? 0
        : funScore.throwunder_hand_right === "2"
          ? 0
          : funScore.throwunder_hand_right === "3"
            ? 1
            : 0;

  const throwover_hand_right1 =
    funScore.throwover_hand_right === "0"
      ? 0
      : funScore.throwover_hand_right === "1"
        ? 0
        : funScore.throwover_hand_right === "2"
          ? 0
          : funScore.throwover_hand_right === "3"
            ? 1
            : 0;

  const wash_back_right =
    funScore.wash_back_right === "0"
      ? 0
      : funScore.wash_back_right === "1"
        ? 0
        : funScore.wash_back_right === "2"
          ? 0
          : funScore.wash_back_right === "3"
            ? 1
            : 0;

  const fullusual_work_right =
    funScore.fullusual_work_right === "0"
      ? 0
      : funScore.fullusual_work_right === "1"
        ? 0
        : funScore.fullusual_work_right === "2"
          ? 0
          : funScore.fullusual_work_right === "3"
            ? 1
            : 0;

  const lift05_left =
    funScore.lift05_left === "0"
      ? 0
      : funScore.lift05_left === "1"
        ? 0
        : funScore.lift05_left === "2"
          ? 0
          : funScore.lift05_left === "3"
            ? 1
            : 0;

  const lift45_left =
    funScore.lift45_left === "0"
      ? 0
      : funScore.lift45_left === "1"
        ? 0
        : funScore.lift45_left === "2"
          ? 0
          : funScore.lift45_left === "3"
            ? 1
            : 0;

  const lift10_left =
    funScore.lift10_left === "0"
      ? 0
      : funScore.lift10_left === "1"
        ? 0
        : funScore.lift10_left === "2"
          ? 0
          : funScore.lift10_left === "3"
            ? 1
            : 0;

  const throwunder_hand_left =
    funScore.throwunder_hand_left === "0"
      ? 0
      : funScore.throwunder_hand_left === "1"
        ? 0
        : funScore.throwunder_hand_left === "2"
          ? 0
          : funScore.throwunder_hand_left === "3"
            ? 1
            : 0;

  const throwover_hand_left =
    funScore.throwover_hand_left === "0"
      ? 0
      : funScore.throwover_hand_left === "1"
        ? 0
        : funScore.throwover_hand_left === "2"
          ? 0
          : funScore.throwover_hand_left === "3"
            ? 1
            : 0;

  const wash_back_left =
    funScore.wash_back_left === "0"
      ? 0
      : funScore.wash_back_left === "1"
        ? 0
        : funScore.wash_back_left === "2"
          ? 0
          : funScore.wash_back_left === "3"
            ? 1
            : 0;

  const fullusual_work_left =
    funScore.fullusual_work_left === "0"
      ? 0
      : funScore.fullusual_work_left === "1"
        ? 0
        : funScore.fullusual_work_left === "2"
          ? 0
          : funScore.fullusual_work_left === "3"
            ? 1
            : 0;

  const SSTR = sumAll(
    SstdescribesR,
    shoulder_pain_night_right,
    lift05_right,
    lift45_right,
    lift10_right,
    throwunder_hand_right,
    throwover_hand_right1,
    wash_back_right,
    fullusual_work_right,
    sster2_right,
    sstarmposition_right,
    sstir1_right
  );

  // console.log(SstdescribesR,
  //   shoulder_pain_night_right,
  //   lift05_right,
  //   lift45_right,
  //   lift10_right,
  //   throwunder_hand_right,
  //   throwover_hand_right1,
  //   wash_back_right,
  //   fullusual_work_right,
  //   sster2_right,
  //   sstarmposition_right,
  //   sstir1_right, "Right sst");

  // const SSTcR = Math.floor(((SSTR / totalNum) * 100))
  const SSTcR = ((SSTR / 12) * 100).toFixed();
  let sst_score_right;
  if (SSTcR === "NaN") {
    sst_score_right = null;
  } else {
    sst_score_right = SSTcR;
  }
  const SSTL = sumAll(
    SstdescribesL,
    shoulder_pain_night_left,
    lift05_left,
    lift45_left,
    lift10_left,
    throwunder_hand_left,
    throwover_hand_left,
    wash_back_left,
    fullusual_work_left,
    sster2_left,
    sstarmposition_left,
    sstir1_left
  );
  // console.log(SstdescribesL,
  //   shoulder_pain_night_left,
  //   lift05_left,
  //   lift45_left,
  //   lift10_left,
  //   throwunder_hand_left,
  //   throwover_hand_left,
  //   wash_back_left,
  //   fullusual_work_left,
  //   sster2_left,
  //   sstarmposition_left,
  //   sstir1_left,"Left sst");
  // const SSTcL = Math.floor(((SSTL / totalNumL) * 100))
  const SSTcL = ((SSTL / 12) * 100).toFixed();
  let sst_score_left;
  if (SSTcL === "NaN") {
    sst_score_left = null;
  } else {
    sst_score_left = SSTcL;
  }

  const json = useSelector((state) => state.shoulderScore);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const updateDate = () => {
    axiosInstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=1`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
        if (isdoctorform == 1) {
          setDisplayConfirmationModal(true)
          
          // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + window.btoa(patientID) ;
        } else {
          navigate("/result/" + rId);
        }
      });
  }

  const [submit, setSubmit] = useState(false);

  const continueRedirect = (e) => {
   
    dispatch(
      updateTotalScores([
        {
          constant_score_Left,
          constant_score_right,
          sst_score_left,
          sst_score_right,
          ucla_score_left,
          ucla_score_right,
          ases_score_left,
          ases_score_right,
          wosiScoreLeft,
          wosi_score_right,
          sacs_score_left,
          sacs_score_right,
          pain_today_rating_left,
          pain_today_rating_right

        }
      ])
    )
    setSubmit(true)
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (submit) {
      updateDate()
    }
  }, [json])

  const Back = () => {
    navigate(-1, { replace: false });
  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="complete_screen mb_100">

          <div className="panel">
            <div className="panel-body p_25">
              {/* {doctorEnable === "true" && (
                <Tab rId={rId} />
              )} */}

              <NameCustom />
              <h4 className="ph_title f_40">{`${translation.patient_shoulder_complete}`}</h4>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_20 pt_20">
                  <p className="thanks_txt">{translation.patient_shoulder_complete_text1}<br /> {translation.patient_shoulder_complete_text2}</p>
                  <Button
                    onClick={continueRedirect}
                    value={translation.submit}
                    buttonStyle="btn_fill cmp_submit_btn"
                  />
                  <span className="answer_txt">{translation.patient_shoulder_complete_text3}</span>
                </div>
              </div>

              <div className="row mb_30 button_box">
                <div
                  onClick={() => Back()}
                  className="assm_back_btn c_pointer"
                >
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <SubmitModel
        showModal={displayConfirmationModal}
        patientID={window.btoa(patientID)}

      />
      {/* <SubmitModel showModal={displayConfirmationModal}  patientID={window.btoa(patientID)}/> */}
    </>
  );
}

export default Complete;
