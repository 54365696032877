import React from "react";
import { UserDateFormat } from "../../../utilities/DateFormate";

export default function AdultKneeDoctor({ doctorFeedback, AllData,profileAllData }) {

  return (
    <>
      {/* {Step ============ 1} */}

      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Knee Doctor Feedback Form</h2>
          <div className="treatment-header-right">
          <p>Assessment Date : {UserDateFormat (AllData.assessment_date,profileAllData.ing_date_formate)}</p>

          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row ">
            <div className="treatment-table">
              <div className="treatment-row ">
                <div className="treatment-col">
                  <label>Gait Right:</label>
                  <p>
                    {doctorFeedback.GaitRadioR === "0"
                      ? "Normal"
                      : doctorFeedback.GaitRadioR === "1"
                      ? "Abnormal"
                      : ""}
                  </p>
                </div>
                <div className="treatment-col">
                  <label>Gait Left:</label>
                  <p>
                    {doctorFeedback.GaitRadioL === "0"
                      ? "Normal"
                      : doctorFeedback.GaitRadioL === "1"
                      ? "Abnormal"
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <div className="treatment-row">
                <div className="treatment-col">
                  {doctorFeedback.GaitRadioR === "1" && (
                    <>
                      <label>Right Gait Abnormality:</label>
                      <p>
                        {doctorFeedback.gaitR === "1"
                          ? "Antalgic"
                          : doctorFeedback.gaitR === "2"
                          ? "Stiff"
                          : doctorFeedback.gaitR === "3"
                          ? "Varus thrust"
                          : doctorFeedback.gaitR === "4"
                          ? "Hyperextension thrust"
                          : doctorFeedback.gaitR === "5"
                          ? "Others"
                          : ""}
                      </p>

                      {doctorFeedback.gaitR == "5" && (
                        <p>{doctorFeedback.gaitRText}</p>
                      )}
                    </>
                  )}
                </div>

                <div className="treatment-col">
                  {doctorFeedback.GaitRadioL === "1" && (
                    <>
                      <label>Left Gait Abnormality:</label>
                      <p>
                        {doctorFeedback.gaitL === "1"
                          ? "Antalgic"
                          : doctorFeedback.gaitL === "2"
                          ? "Stiff"
                          : doctorFeedback.gaitL === "3"
                          ? "Varus thrust"
                          : doctorFeedback.gaitL === "4"
                          ? "Hyperextension thrust"
                          : doctorFeedback.gaitL === "5"
                          ? "Others"
                          : ""}
                      </p>
                      {doctorFeedback.gaitL == "5" && (
                        <p>{doctorFeedback.gaitLText}</p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Alignment</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Anatomic alignment :</td>

                    <td>
                      {doctorFeedback.anatomicR !== "" &&
                      doctorFeedback.anatomicR !== null ? (
                        <>{`${doctorFeedback.anatomicR} degrees`}</>
                      ) : (
                        ""
                      )}

                      <p>
                        {doctorFeedback.radiovalueR === "1"
                          ? "Neutral"
                          : doctorFeedback.radiovalueR === "2"
                          ? "Varus"
                          : doctorFeedback.radiovalueR === "3"
                          ? "Valgus"
                          : ""}
                      </p>
                    </td>
                    <td>
                      {/* {doctorFeedback.anat ? `${doctorFeedback.anat} degrees` : ""} */}
                      {doctorFeedback.anat !== "" &&
                      doctorFeedback.anat !== null ? (
                        <>{`${doctorFeedback.anat} degrees`}</>
                      ) : (
                        ""
                      )}

                      <p>
                        {doctorFeedback.radiovalueL === "1"
                          ? "Neutral"
                          : doctorFeedback.radiovalueL === "2"
                          ? "Varus"
                          : doctorFeedback.radiovalueL === "3"
                          ? "Valgus"
                          : ""}
                      </p>
                    </td>

                    {/* <td>{doctorFeedback.anatomicL}</td> */}
                  </tr>
                  {/* <tr>
                                        <td>Left Anatomic alignment (Based on weightbearing AP radiograph):</td>
                                        <td>{doctorFeedback.anat}</td>
                                        <td>{doctorFeedback.anatt}</td>
                                    </tr> */}
                  <tr>
                    <td> Q angle:</td>
                    <td>
                      {/* {doctorFeedback.angleLr ? `${doctorFeedback.angleLr} degrees` : ""}   */}
                      {doctorFeedback.angleLr !== "" &&
                      doctorFeedback.angleLr !== null ? (
                        <>{`${doctorFeedback.angleLr} degrees`}</>
                      ) : (
                        ""
                      )}

                      <p>
                        {doctorFeedback.radioAngleR === "1"
                          ? "Neutral"
                          : doctorFeedback.radioAngleR === "2"
                          ? "Varus"
                          : doctorFeedback.radioAngleR === "3"
                          ? "Valgus"
                          : ""}
                      </p>
                    </td>
                    <td>
                      {/* {doctorFeedback.langR ? `${doctorFeedback.langR} degrees` : ""} */}
                      {doctorFeedback.langR !== "" &&
                      doctorFeedback.langR !== null ? (
                        <>{`${doctorFeedback.langR} degrees`}</>
                      ) : (
                        ""
                      )}
                      <p>
                        {doctorFeedback.radioAngleL === "1"
                          ? "Neutral"
                          : doctorFeedback.radioAngleL === "2"
                          ? "Varus"
                          : doctorFeedback.radioAngleL === "3"
                          ? "Valgus"
                          : ""}
                      </p>
                    </td>

                    {/* <td>{doctorFeedback.angleLrr}</td> */}
                  </tr>
                  {/* <tr>
                                        <td>Left Q angle:</td>
                                        <td>{doctorFeedback.langR}</td>
                                        <td>{doctorFeedback.langL}</td>
                                    </tr> */}
                  {/* <tr>
                                        <td>Right Fixed flexion contracture:</td>
                                        <td>{doctorFeedback.fixeddR}</td>
                                        <td>{doctorFeedback.fixeddL}</td>
                                    </tr>
                                    <tr>
                                        <td>Left Fixed flexion contracture:</td>
                                        <td>{doctorFeedback.LfixeddR}</td>
                                        <td>{doctorFeedback.LfixeddL}</td>
                                    </tr>
                                    <tr>
                                        <td>Right Extension lag:</td>
                                        <td>{doctorFeedback.lagsR}</td>
                                        <td>{doctorFeedback.lagR}</td>
                                    </tr>
                                    <tr>
                                        <td>Left Extension lag:</td>
                                        <td>{doctorFeedback.lagLR}</td>
                                        <td>{doctorFeedback.lagLL}</td>
                                    </tr> */}
                </tbody>
              </table>
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Deformity</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fixed flexion contracture:</td>

                    <td>
                      <p>
                        {doctorFeedback.DegreeFixedR === "0"
                          ? "Negative"
                          : doctorFeedback.DegreeFixedR === "1"
                          ? "Positive"
                          : ""}
                      </p>
                      {/* {doctorFeedback.fixeddR ? `${doctorFeedback.fixeddR} degrees` : ""} */}
                      {doctorFeedback.fixeddR !== "" &&
                      doctorFeedback.fixeddR !== null ? (
                        <>{`${doctorFeedback.fixeddR} degrees`}</>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <p>
                        {doctorFeedback.DegreeFixedL === "0"
                          ? "Negative"
                          : doctorFeedback.DegreeFixedL === "1"
                          ? "Positive"
                          : ""}
                      </p>
                      <p>
                        {/* {doctorFeedback.LfixeddR ? `${doctorFeedback.LfixeddR} degrees` : ""} */}
                        {doctorFeedback.LfixeddR !== "" &&
                        doctorFeedback.LfixeddR !== null ? (
                          <>{`${doctorFeedback.LfixeddR} degrees`}</>
                        ) : (
                          ""
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Extension lag:</td>

                    <td>
                      <p>
                        {doctorFeedback.ExtensionR === "0"
                          ? "Negative"
                          : doctorFeedback.ExtensionR === "1"
                          ? "Positive"
                          : ""}
                      </p>
                      {/* {doctorFeedback.lagsR ? `${doctorFeedback.lagsR} degrees` : ""} */}
                      {doctorFeedback.lagsR !== "" &&
                      doctorFeedback.lagsR !== null ? (
                        <>{`${doctorFeedback.lagsR} degrees`}</>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <p>
                        {doctorFeedback.ExtensionL === "0"
                          ? "Negative"
                          : doctorFeedback.ExtensionL === "1"
                          ? "Positive"
                          : ""}
                      </p>
                      {/* {doctorFeedback.lagLR ? `${doctorFeedback.lagLR} degrees` : ""} */}
                      {doctorFeedback.lagLR !== "" &&
                      doctorFeedback.lagLR !== null ? (
                        <>{`${doctorFeedback.lagLR} degrees`}</>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Recurvatum:</td>

                    <td>
                      {doctorFeedback.recurvatumR === "0"
                        ? "Negative"
                        : doctorFeedback.recurvatumR === "1"
                        ? "Positive"
                        : ""}
                      <p>
                        {/* {doctorFeedback.lagLL ? `${doctorFeedback.lagLL} degrees` : ""} */}
                        {doctorFeedback.lagLL !== "" &&
                        doctorFeedback.lagLL !== null ? (
                          <>{`${doctorFeedback.lagLL} degrees`}</>
                        ) : (
                          ""
                        )}
                      </p>
                    </td>
                    <td>
                      {doctorFeedback.recurvatumL === "0"
                        ? "Negative"
                        : doctorFeedback.recurvatumL === "1"
                        ? "Positive"
                        : ""}
                      <p>
                        {/* {doctorFeedback.lagR ? `${doctorFeedback.lagR} degrees` : ""} */}
                        {doctorFeedback.lagR !== "" &&
                        doctorFeedback.lagR !== null ? (
                          <>{`${doctorFeedback.lagR} degrees`}</>
                        ) : (
                          ""
                        )}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Others:</td>
                    <td>{doctorFeedback.DeformityRText}</td>
                    <td>{doctorFeedback.DeformityLText}</td>
                  </tr>
                </tbody>
              </table>

              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Range of Motion</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <td>{doctorFeedback.text1 ? `${doctorFeedback.text1} mm` : ""}</td> */}

                  <tr>
                    <td>Passive extension to flexion:</td>

                    <td>
                      {doctorFeedback.lExtensionR === null
                        ? ""
                        : doctorFeedback.lExtensionR + " to"}{" "}
                      {doctorFeedback.lExtensionL === null
                        ? ""
                        : doctorFeedback.lExtensionL + " degrees"}
                    </td>
                    <td>
                      {doctorFeedback.rExtensionR === null
                        ? ""
                        : doctorFeedback.rExtensionR + " to"}{" "}
                      {doctorFeedback.rExtensionL === null
                        ? ""
                        : doctorFeedback.rExtensionL + " degrees"}
                    </td>
                  </tr>
                  <tr>
                    <td>Active extension to flexion:</td>

                    <td>
                      {doctorFeedback.lFlexionR === null
                        ? ""
                        : doctorFeedback.lFlexionR + " to"}{" "}
                      {doctorFeedback.lFlexionL === null
                        ? ""
                        : doctorFeedback.lFlexionL + " degrees"}
                    </td>
                    <td>
                      {doctorFeedback.rFlexionR === null
                        ? ""
                        : doctorFeedback.rFlexionR + " to"}{" "}
                      {doctorFeedback.rFlexionL === null
                        ? ""
                        : doctorFeedback.rFlexionL + " degrees"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Step ---------- 2  */}
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>ACL</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Anterior drawer test :</td>

                    <td>
                      <p>
                        {doctorFeedback.anteriorR === "0"
                          ? "Negative"
                          : doctorFeedback.anteriorR === "1"
                          ? "Positive"
                          : ""}
                      </p>

                      {doctorFeedback.anteriorR === "1" ? (
                        <p>
                          {doctorFeedback.anterdropR === "0"
                            ? "> 10mm"
                            : doctorFeedback.anterdropR === "1"
                            ? "5-10mm"
                            : doctorFeedback.anterdropR === "2"
                            ? "< 5mm"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      <p>
                        {doctorFeedback.anteriorL == "0"
                          ? "Negative"
                          : doctorFeedback.anteriorL === "1"
                          ? "Positive"
                          : ""}
                      </p>

                      {doctorFeedback.anteriorL === "1" ? (
                        <p>
                          {doctorFeedback.anterdropL === "0"
                            ? "> 10mm"
                            : doctorFeedback.anterdropL === "1"
                            ? "5-10mm"
                            : doctorFeedback.anterdropL === "2"
                            ? "< 5mm"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td> Lachman test :</td>

                    <td>
                      <p>
                        {doctorFeedback.lachmanR === "0"
                          ? "Negative"
                          : doctorFeedback.lachmanR === "1"
                          ? "Positive"
                          : ""}
                      </p>
                      {doctorFeedback.lachmanR === "1" ? (
                        <p>
                          {doctorFeedback.lachmandropR === "0"
                            ? "> 10mm"
                            : doctorFeedback.lachmandropR === "1"
                            ? "5-10mm"
                            : doctorFeedback.lachmandropR === "2"
                            ? "< 5mm"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      <p>
                        {doctorFeedback.lachmanL === "0"
                          ? "Negative"
                          : doctorFeedback.lachmanL === "1"
                          ? "Positive"
                          : ""}
                      </p>
                      {doctorFeedback.lachmanL === "1" ? (
                        <p>
                          {doctorFeedback.lachmandropL === "0"
                            ? "> 10mm"
                            : doctorFeedback.lachmandropL === "1"
                            ? "5-10mm"
                            : doctorFeedback.lachmandropL === "2"
                            ? "< 5mm"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td> Pivot shift test:</td>

                    <td>
                      <p>
                        {doctorFeedback.pivotR === "0"
                          ? "Negative"
                          : doctorFeedback.pivotR === "1"
                          ? "Positive"
                          : ""}
                      </p>
                      {doctorFeedback.pivotR === "1" ? (
                        <p>
                          {doctorFeedback.pivotdropR === "0"
                            ? "Grade III"
                            : doctorFeedback.pivotdropR === "1"
                            ? "Grade II"
                            : doctorFeedback.pivotdropR === "2"
                            ? "Grade I"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <p>
                        {doctorFeedback.pivotL === "0"
                          ? "Negative"
                          : doctorFeedback.pivotL === "1"
                          ? "Positive"
                          : ""}
                      </p>
                      {doctorFeedback.pivotL === "1" ? (
                        <p>
                          {doctorFeedback.pivotdropL === "0"
                            ? "Grade III"
                            : doctorFeedback.pivotdropL === "1"
                            ? "Grade II"
                            : doctorFeedback.pivotdropL === "2"
                            ? "Grade I"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>KT-1000</th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Test 1:</td>
                    <td>
                      {doctorFeedback.text1 ? `${doctorFeedback.text1} mm` : ""}
                    </td>
                    <td>
                      {doctorFeedback.textL1
                        ? `${doctorFeedback.textL1} mm`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Test 2:</td>
                    <td>
                      {doctorFeedback.text2 ? `${doctorFeedback.text2} mm` : ""}
                    </td>
                    <td>
                      {doctorFeedback.textL2
                        ? `${doctorFeedback.textL2} mm`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Test 3:</td>
                    <td>
                      {doctorFeedback.text3 ? `${doctorFeedback.text3} mm` : ""}
                    </td>
                    <td>
                      {doctorFeedback.textL3
                        ? `${doctorFeedback.textL3} mm`
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>PCL</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Posterior sag sign:</td>
                    <td>
                      {doctorFeedback.sagR === "0"
                        ? "Negative"
                        : doctorFeedback.sagR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.sagL === "0"
                        ? "Negative"
                        : doctorFeedback.sagL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Posterior drawer test :</td>

                    <td>
                      {doctorFeedback.drawerR === "0"
                        ? "Negative"
                        : doctorFeedback.drawerR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.drawerR === "1" ? (
                        <p>
                          {doctorFeedback.drawdropR === "0"
                            ? "> 10mm"
                            : doctorFeedback.drawdropR === "1"
                            ? "5-10mm"
                            : doctorFeedback.drawdropR === "2"
                            ? "< 5mm"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      {doctorFeedback.drawerL === "0"
                        ? "Negative"
                        : doctorFeedback.drawerL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.drawerL === "1" ? (
                        <p>
                          {doctorFeedback.drawdropL === "0"
                            ? "> 10mm"
                            : doctorFeedback.drawdropL === "1"
                            ? "5-10mm"
                            : doctorFeedback.drawdropL === "2"
                            ? "< 5mm"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Quadriceps active test :</td>
                    <td>
                      {doctorFeedback.activeR === "0"
                        ? "Negative"
                        : doctorFeedback.activeR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.activeL === "0"
                        ? "Negative"
                        : doctorFeedback.activeL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Reverse pivot shift test :</td>
                    <td>
                      {doctorFeedback.reverseR === "0"
                        ? "Negative"
                        : doctorFeedback.reverseR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.reverseL === "0"
                        ? "Negative"
                        : doctorFeedback.reverseL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    {/* <th>Dial test</th> */}
                    <th>PLC</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Dial test at 30 degrees:</td>
                    <td>
                      {doctorFeedback.text30R === "0"
                        ? "Negative"
                        : doctorFeedback.text30R === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.text30L === "0"
                        ? "Negative"
                        : doctorFeedback.text30L === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Dial test at 90 degrees:</td>
                    <td>
                      {doctorFeedback.text90R === "0"
                        ? "Negative"
                        : doctorFeedback.text90R === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.text90L === "0"
                        ? "Negative"
                        : doctorFeedback.text90L === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>ER recurvatum test:</td>
                    <td>
                      {doctorFeedback.recurvatumR === "0"
                        ? "Negative"
                        : doctorFeedback.recurvatumR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.recurvatumL === "0"
                        ? "Negative"
                        : doctorFeedback.recurvatumL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Posterolateral drawer test :</td>
                    <td>
                      {doctorFeedback.postR === "0"
                        ? "Negative"
                        : doctorFeedback.postR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.postL === "0"
                        ? "Negative"
                        : doctorFeedback.postL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>PMC</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Anteromedial rotatory instability:</td>
                    <td>
                      {doctorFeedback.rotatR === "0"
                        ? "Negative"
                        : doctorFeedback.rotatR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.rotatL === "0"
                        ? "Negative"
                        : doctorFeedback.rotatL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Anteromedial drawer test :</td>
                    <td>
                      {doctorFeedback.drawersR === "0"
                        ? "Negative"
                        : doctorFeedback.drawersR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.drawersL === "0"
                        ? "Negative"
                        : doctorFeedback.drawersL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Collaterals</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Varus Stress test in full extension :</td>

                    <td>
                      {doctorFeedback.stressR === "0"
                        ? "Negative"
                        : doctorFeedback.stressR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.stressR === "1" ? (
                        <p>
                          {doctorFeedback.stressdropR === "0"
                            ? "15 degrees"
                            : doctorFeedback.stressdropR === "1"
                            ? "10-14 degrees"
                            : doctorFeedback.stressdropR === "2"
                            ? "6-9 degrees"
                            : doctorFeedback.stressdropR === "3"
                            ? "< 5 degrees"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {doctorFeedback.stressL === "0"
                        ? "Negative"
                        : doctorFeedback.stressL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.stressL === "1" ? (
                        <p>
                          {doctorFeedback.stressdropL === "0"
                            ? "15 degrees"
                            : doctorFeedback.stressdropL === "1"
                            ? "10-14 degrees"
                            : doctorFeedback.stressdropL === "2"
                            ? "6-9 degrees"
                            : doctorFeedback.stressdropL === "3"
                            ? "< 5 degrees"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Varus Stress test at 30 degree flexion :</td>

                    <td>
                      {doctorFeedback.stressflR === "0"
                        ? "Negative"
                        : doctorFeedback.stressflR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.stressflR === "1" ? (
                        <p>
                          {doctorFeedback.stressdropflR === "0"
                            ? "15 degrees"
                            : doctorFeedback.stressdropflR === "1"
                            ? "10-14 degrees"
                            : doctorFeedback.stressdropflR === "2"
                            ? "6-9 degrees"
                            : doctorFeedback.stressdropflR === "3"
                            ? "< 5 degrees"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {doctorFeedback.stressflL === "0"
                        ? "Negative"
                        : doctorFeedback.stressflL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.stressflL === "1" ? (
                        <p>
                          {doctorFeedback.stressdropflL === "0"
                            ? "15 degrees"
                            : doctorFeedback.stressdropflL === "1"
                            ? "10-14 degrees"
                            : doctorFeedback.stressdropflL === "2"
                            ? "6-9 degrees"
                            : doctorFeedback.stressdropflL === "3"
                            ? "< 5 degrees"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Valgus Stress test in full extension :</td>

                    <td>
                      {doctorFeedback.valgusR === "0"
                        ? "Negative"
                        : doctorFeedback.valgusR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.valgusR === "1" ? (
                        <p>
                          {doctorFeedback.valgusdropR === "0"
                            ? "15 degrees"
                            : doctorFeedback.valgusdropR === "1"
                            ? "10-14 degrees"
                            : doctorFeedback.valgusdropR === "2"
                            ? "6-9 degrees"
                            : doctorFeedback.valgusdropR === "3"
                            ? "< 5 degrees"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {doctorFeedback.valgusL === "0"
                        ? "Negative"
                        : doctorFeedback.valgusL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.valgusL === "1" ? (
                        <p>
                          {doctorFeedback.valgusdropL === "0"
                            ? "15 degrees"
                            : doctorFeedback.valgusdropL === "1"
                            ? "10-14 degrees"
                            : doctorFeedback.valgusdropL === "2"
                            ? "6-9 degrees"
                            : doctorFeedback.valgusdropL === "3"
                            ? "< 5 degrees"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Valgus Stress test in 30 degree flexion:</td>

                    <td>
                      {doctorFeedback.valgusflR === "0"
                        ? "Negative"
                        : doctorFeedback.valgusflR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.valgusflR === "1" ? (
                        <p>
                          {doctorFeedback.valgusdropflR === "0"
                            ? "15 degrees"
                            : doctorFeedback.valgusdropflR === "1"
                            ? "10-14 degrees"
                            : doctorFeedback.valgusdropflR === "2"
                            ? "6-9 degrees"
                            : doctorFeedback.valgusdropflR === "3"
                            ? "< 5 degrees"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {doctorFeedback.valgusflL === "0"
                        ? "Negative"
                        : doctorFeedback.valgusflL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.valgusflL === "1" ? (
                        <p>
                          {doctorFeedback.valgusdropflL === "0"
                            ? "15 degrees"
                            : doctorFeedback.valgusdropflL === "1"
                            ? "10-14 degrees"
                            : doctorFeedback.valgusdropflL === "2"
                            ? "6-9 degrees"
                            : doctorFeedback.valgusdropflL === "3"
                            ? "< 5 degrees"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Meniscus</th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Joint line tenderness:</td>

                    <td>
                      {doctorFeedback.tendernessR === "0"
                        ? "Negative"
                        : doctorFeedback.tendernessR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.tendernessR === "1" ? (
                        <p>
                          {doctorFeedback.jointR === "1"
                            ? "Medial"
                            : doctorFeedback.jointR === "2"
                            ? "Lateral"
                            : doctorFeedback.jointR === "3"
                            ? "Both"
                            : "Medial"}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {doctorFeedback.tendernessL === "0"
                        ? "Negative"
                        : doctorFeedback.tendernessL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.tendernessL === "1" ? (
                        <p>
                          {doctorFeedback.jointL === "1"
                            ? "Medial"
                            : doctorFeedback.jointL === "2"
                            ? "Lateral"
                            : doctorFeedback.jointL === "3"
                            ? "Both"
                            : "Medial"}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>McMurray test:</td>

                    <td>
                      {doctorFeedback.murraryR === "0"
                        ? "Negative"
                        : doctorFeedback.murraryR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.murraryR === "1" ? (
                        <p>
                          {doctorFeedback.murraryRdrop === "1"
                            ? "Medial"
                            : doctorFeedback.murraryRdrop === "2"
                            ? "Lateral"
                            : doctorFeedback.murraryRdrop === "3"
                            ? "Both"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {doctorFeedback.murraryL === "0"
                        ? "Negative"
                        : doctorFeedback.murraryL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.murraryL === "1" ? (
                        <p>
                          {doctorFeedback.murraryLdrop === "1"
                            ? "Medial"
                            : doctorFeedback.murraryLdrop === "2"
                            ? "Lateral"
                            : doctorFeedback.murraryLdrop === "3"
                            ? "Both"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Ege’s test:</td>

                    <td>
                      {doctorFeedback.egeR === "0"
                        ? "Negative"
                        : doctorFeedback.egeR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.egeR === "1" ? (
                        <p>
                          {doctorFeedback.egeRdrop === "1"
                            ? "Medial"
                            : doctorFeedback.egeRdrop === "2"
                            ? "Lateral"
                            : doctorFeedback.egeRdrop === "3"
                            ? "Both"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {doctorFeedback.egeL === "0"
                        ? "Negative"
                        : doctorFeedback.egeL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.egeL === "1" ? (
                        <p>
                          {doctorFeedback.egeLdrop === "1"
                            ? "Medial"
                            : doctorFeedback.egeLdrop === "2"
                            ? "Lateral"
                            : doctorFeedback.egeLdrop === "3"
                            ? "Both"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                                        <td>Thessaly test:</td>

                                       

                                        <td>{doctorFeedback.ThessalyR === "0" ? "Negative" : doctorFeedback.ThessalyR === "1" ? "Positive" : ""}
                                            {doctorFeedback.ThessalyR === "1" ? (
                                                <p>
                                                    {doctorFeedback.ThessalyRdrop === "1" ? "Medial" : doctorFeedback.ThessalyRdrop === "2" ? "Lateral" : doctorFeedback.ThessalyRdrop === "3" ? "Both" : ""}
                                                </p>
                                            ) : ""}
                                        </td>
                                        <td>{doctorFeedback.ThessalyL === "0" ? "Negative" : doctorFeedback.ThessalyL === "1" ? "Positive" : ""}
                                            {doctorFeedback.ThessalyL === "1" ? (
                                                <p>
                                                    {doctorFeedback.ThessalyLdrop === "1" ? "Medial" : doctorFeedback.ThessalyLdrop === "2" ? "Lateral" : doctorFeedback.ThessalyLdrop === "3" ? "Both" : ""}
                                                </p>
                                            ) : ""}
                                        </td>
                                    </tr> */}
                  <tr>
                    <td>Apley’s test:</td>

                    <td>
                      {doctorFeedback.apleyR === "0"
                        ? "Negative"
                        : doctorFeedback.apleyR === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.apleyR === "1" ? (
                        <p>
                          {doctorFeedback.apleyRdrop === "1"
                            ? "Medial"
                            : doctorFeedback.apleyRdrop === "2"
                            ? "Lateral"
                            : doctorFeedback.apleyRdrop === "3"
                            ? "Both"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>

                    <td>
                      {doctorFeedback.apleyL === "0"
                        ? "Negative"
                        : doctorFeedback.apleyL === "1"
                        ? "Positive"
                        : ""}
                      {doctorFeedback.apleyL === "1" ? (
                        <p>
                          {doctorFeedback.apleyLdrop === "1"
                            ? "Medial"
                            : doctorFeedback.apleyLdrop === "2"
                            ? "Lateral"
                            : doctorFeedback.apleyLdrop === "3"
                            ? "Both"
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Patella</th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Apprehension :</td>
                    <td>
                      {doctorFeedback.apprR === "0"
                        ? "Negative"
                        : doctorFeedback.apprR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.apprL === "0"
                        ? "Negative"
                        : doctorFeedback.apprL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Patellar glide test :</td>
                    <td>
                      {doctorFeedback.patellarR === "1"
                        ? "<1 quadrant"
                        : doctorFeedback.patellarR === "2"
                        ? "2 quadrants"
                        : doctorFeedback.patellarR === "3"
                        ? "3 quadrants"
                        : doctorFeedback.patellarR === "4"
                        ? "4 quadrants"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.patellarL=== "1"
                        ? "<1 quadrant"
                        : doctorFeedback.patellarL === "2"
                        ? "2 quadrants"
                        : doctorFeedback.patellarL === "3"
                        ? "3 quadrants"
                        : doctorFeedback.patellarL === "4"
                        ? "4 quadrants"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>J-sign :</td>
                    <td>
                      {doctorFeedback.signR
                        ? `Grade ${doctorFeedback.signR}`
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.signL
                        ? `Grade ${doctorFeedback.signL}`
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Patella grind :</td>
                    <td>
                      {doctorFeedback.patellaR === "0"
                        ? "Negative"
                        : doctorFeedback.patellaR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.patellaL === "0"
                        ? "Negative"
                        : doctorFeedback.patellaL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th>Extensor Mechanism</th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Active straight leg raise test :</td>
                    <td>
                      {doctorFeedback.legR === "0"
                        ? "Negative"
                        : doctorFeedback.legR === "1"
                        ? "Positive"
                        : ""}
                    </td>
                    <td>
                      {doctorFeedback.legL === "0"
                        ? "Negative"
                        : doctorFeedback.legL === "1"
                        ? "Positive"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                  <td>Isometric knee extension strength :</td>
                    <td>
                      {(doctorFeedback.strengthR!=="" &&doctorFeedback.strengthR!==null)
                        ? `${doctorFeedback.strengthR} kg`
                        : ""}
                    </td>
                    <td>
                      {(doctorFeedback.strengthL!==""&&doctorFeedback.strengthL!==null)
                        ? `${doctorFeedback.strengthL} kg`
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Other Tests :</td>
                    <td>{doctorFeedback.others}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
