import React, { useState, useEffect } from "react";
import Button from "../../custom/button/Button";
import "../../../css/ViewTreatmentForm.css";
import PersonalInformation from "./PersonalInformation";
import ElbowPain from "./ElbowPain";
import ElbowFunction from "./ElbowFunction";
import ElbowSymtoms from "./ElbowSymtoms";
import ElbowQOL from "./ElbowQOL";
import ElbowSatisfaction from "./ElbowSatisfaction";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../../../axiosinstance";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import ElbowDoctorFeedback from "./ElbowDoctorFeedback";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import useTranslation from "../../customHooks/translations";
export default function ElbowTreatment() {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { rId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  // const dId = queryParams.get("doctor");
  const doctor_id = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const management = queryParams.get("management");
  const pScore = queryParams.get("form");
  const action = queryParams.get("action");
  const patientEdit = queryParams.get("patientEdit");

  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  let isdoctorForm = queryParams.get("is_doctor_form");
  let orgid=sessionStorage.getItem("orgid")
  useEffect(() => {
    localStorage.setItem("isdoctorForm", isdoctorForm);
  }, [isdoctorForm]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [elbowPain, setElbowPain] = useState({});
  const [elbowFunction, setElbowFunction] = useState({});
  const [elbowSymptoms, setElbowSymptoms] = useState({});
  const [elbowQOL, setElbowQOL] = useState({});
  const [elbowSatisfaction, setElbowSatisfaction] = useState({});
  const [doctorefeedback, setElbowDoctorFeedback] = useState({});
  const [editShow, seteditShow] = useState(false);
  const [editShow1, seteditShow1] = useState(false);
  const [viewDoctor, setViewDoctor] = useState("0");
  const [viewPatient, setViewPatient] = useState("0");
  const [initialAssesment, setInitialAssesment] = useState(0);
  const [doctorView, setdoctorView] = useState(false);

  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const save = () => {
    axiosInstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${rId}&type=22&akpt=${window.atob(
          pId
        )}`
      )
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);
        setElbowPain(res.data.data2[0].painScore[0]);
        setElbowFunction(res.data.data2[0].functionScore[0]);
        setElbowSymptoms(res.data.data2[0].symptomsScore[0]);
        setElbowQOL(res.data.data2[0].qolScore[0]);
        setElbowSatisfaction(res.data.data2[0].satisfactionScore[0]);
        setElbowDoctorFeedback(res.data.data2[0].doctorFeedback[0]);
        seteditShow(res.data.status);
        seteditShow1(res.data.doctorStatus);

        setViewDoctor(res.data.data2[0].doctor_scoring_date);
        setViewPatient(res.data.data2[0].patient_scoring_date);

        setIsLoading(false);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);


  const handleEdit = () => {
  
    localStorage.setItem("doctor", doctor_id);
    navigate(
      `/personal-data-edit/${
        pId
      }/${window.btoa(doctor_id)}/${window.btoa(orgid)}/${window.btoa(
        22
      )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
    );
    // navigate(
    //   "/elbow-pain/" + rId + "?d=true&akpt=" + pId + "&is_doctor_form=true"
    // );
  };
  const handleBack = () => {
    window.open(process.env.REACT_APP_DOCTOR_URL + "patient-view" + "/" + pId);
    window.close();
  };
  const handleEdit1 = () => {
    // localStorage.setItem("doctor",doctor_id)
    // navigate("/elbow-pain/" + rId + "?d=true&akpt=" + pId+"&is_doctor_form=true");

    window.open(
      "/elbow-feedback/" +
        rId +
        `?doctor=${doctor_id}&patient=${pId}&is_doctor_form=true`
    );
  };

  const handlePatientEdit = () => {
    navigate("/elbow-pain/" + rId + "?is_doctor_form=null&edit=true");
  };
  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const submitDelete = (type, e) => {
    handleDelByrID();
  };
  const profileAllData = React.useMemo(() => profileData, [profileData]);
  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rId}`
      );
      if (result.status === 200) {
        window.close();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };
  const handleDelete = (e, te) => {
    setDisplayConfirmationModalDelete(true);
  };

  let ak_dc = sessionStorage.getItem("ak_dc");
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="view_treatment_form">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4>{translation.Elbow_Treatment_Form}</h4>
            </div>
            {management === "true" && (
              <>
                {ak_dc === doctor_id && (
                  <div className={`col-sm-6 col-md-6 text-end`}>
                    <Button
                      onClick={() => handleDelete()}
                      value="Delete Form"
                      buttonStyle="btn_fill"
                    />
                    {editShow || doctorView ? (
                      <Button
                        onClick={() => handleEdit()}
                        value="Edit Form"
                        buttonStyle="btn_fill ml_15 "
                      />
                    ) : (
                      <Button
                        onClick={() => console.log("")}
                        value="Edit Form"
                        buttonStyle=" ml_15 button_disabled "
                      />
                    )}
                  </div>
                )}
              </>
            )}

            {isdoctorForm && management !== "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleBack()}
                  value="Back"
                  buttonStyle="btn_fill ml_15 "
                />

                {editShow === true && ak_dc === doctor_id && (
                  <Button
                    onClick={() => handleEdit1()}
                    value="Edit Form"
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}

            {patientEdit === "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                {editShow && (
                  <Button
                    onClick={() => handlePatientEdit()}
                    value="Edit Form"
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}
          </div>
          <DeleteConfirmation
            showModal={displayConfirmationModalDelete}
            confirmModal={submitDelete}
            hideModal={hideConfirmationModal}
            message={"Form"}
            type="task_id"
            id={rId}
          />
          {pScore === "true" && action === "1" && (
            <>
              <PersonalInformation
                profileAllData={profileAllData}
                AllData={AllData}
              />
              {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  <ElbowPain elbowPain={elbowPain} AllData={AllData}
                  profileAllData={profileAllData}
                   />
                  <ElbowFunction
                    elbowFunction={elbowFunction}
                    AllData={AllData}
                  />
                  <ElbowSymtoms
                    elbowSymptoms={elbowSymptoms}
                    AllData={AllData}
                  />
                  <ElbowQOL elbowQOL={elbowQOL} AllData={AllData} />

                  <ElbowSatisfaction elbowSatisfaction={elbowSatisfaction} />
                </>
              )}
            </>
          )}
          {pScore === "true" && action === "2" && (
            <ElbowDoctorFeedback
              doctorefeedback={doctorefeedback}
              AllData={AllData}
              profileAllData={profileAllData}
            />
          )}

          {management === "true" && (
            <>
              <PersonalInformation
                profileAllData={profileAllData}
                AllData={AllData}
              />
              {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  <ElbowPain elbowPain={elbowPain} AllData={AllData}
                  profileAllData={profileAllData}
                   />
                  <ElbowFunction
                    elbowFunction={elbowFunction}
                    AllData={AllData}
                  />
                  <ElbowSymtoms
                    elbowSymptoms={elbowSymptoms}
                    AllData={AllData}
                  />
                  <ElbowQOL elbowQOL={elbowQOL} AllData={AllData} />

                  <ElbowSatisfaction elbowSatisfaction={elbowSatisfaction} />
                </>
              )}
            </>
          )}
          {viewDoctor !== "0" && management === "true" && (
            <>
              <ElbowDoctorFeedback
                doctorefeedback={doctorefeedback}
                AllData={AllData}
                profileAllData={profileAllData}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
