import React from "react";
import Checkbox from "../../custom/checkbox/Checkbox";
import { returnStrings } from "../../../utilities/FindScoreStatus";
import useTranslation from "../../customHooks/translations";
export default function ViewPast({ pastData }) {
  const translations = useTranslation();

  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h5>{translations.patient_general_past_history}</h5>
      </div>
      <div className="treatment-content ">
        <div className="treatment-table ">
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q1}</label>
            <p>
              {pastData.knowAllergie === "0"
                ? translations.patient_general_past_history_q1_a1
                : pastData.knowAllergie === "1"
                  ? translations.patient_general_past_history_q1_a2
                  : ""}
            </p>

            {pastData.knowAllergie === "1" && (
              <pre>
                <p className="p_description">{pastData.reaction}</p>
              </pre>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q2}</label>

            <p>
              {pastData.allergicLatex === "0"
                ? translations.patient_general_past_history_q2_a1
                : pastData.allergicLatex === "1"
                  ? translations.patient_general_past_history_q2_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q3}</label>
            <p>
              {pastData.bloodPressure === "0"
                ? translations.patient_general_past_history_q3_a1
                : pastData.bloodPressure === "1"
                  ? translations.patient_general_past_history_q3_a2
                  : ""}
            </p>
            {pastData.bloodPressure === "1" && (
              <pre>
                <p className="p_description">{pastData.drop42}</p>
              </pre>
            )}
          </div>
          <div className="treatment-col">
            <label className="text-dark">
              {translations.patient_general_past_history_q4}
            </label>
            <p>
              {pastData.heartIllness === "0"
                ? translations.patient_general_past_history_q4_a1
                : pastData.heartIllness === "1"
                  ? translations.patient_general_past_history_q4_a2
                  : ""}
            </p>
            {pastData.heartIllness === "1" && (
              <>
                {" "}
                <p className="p_description">
                  {" "}
                  {pastData.dropIllness
                    .map((item, index) => <span key={index}>{item.label}</span>)
                    .map((jsxElement) => jsxElement.props.children)
                    .join(" ,")}
                </p>
              </>

            )}
            {pastData.dropIllness.filter((item) => item.value === "7")[0] && (
              <>
                <label className="text-dark">
                  {translations.patient_general_past_history_q4_a2_o8}
                </label>
                <pre>
                  <p className="p_description">{pastData.drop43}</p>
                </pre>
              </>
            )}
            {pastData.dropIllness.filter((item) => item.value === "9")[0] && (
              <>
                <label className="text-dark">
                  {translations.patient_general_past_history_q4_a2_o10}
                </label>
                <pre>
                  {" "}
                  <p className="p_description">{pastData.drop44}</p>
                </pre>
              </>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q5}</label>
            <p>
              {pastData.seriousradio === "0"
                ? translations.patient_general_past_history_q5_a1
                : pastData.seriousradio === "1"
                  ? translations.patient_general_past_history_q5_a2
                  : ""}
            </p>
            {pastData.seriousradio === "1" && (
              <>
                <pre>
                  <p className="p_description">{pastData.cardiologist}</p>
                </pre>
              </>
            )}
          </div>
          <div className="">
            {pastData.seriousradio2 === "1" && (
              <>
                <label className="text-dark">
                  {translations.patient_general_past_history_q6}
                </label>
                <p>
                  {pastData.seriousradio2 === "0"
                    ? translations.patient_general_past_history_q6_a1
                    : pastData.seriousradio2 === "1"
                      ? translations.patient_general_past_history_q6_a2
                      : ""}
                </p>
                {pastData.seriousradio2 === "1" && (
                  <>
                    <pre>
                      {" "}
                      <p className="p_description">
                        {pastData.vascularDisease}
                      </p>
                    </pre>
                  </>
                )}
              </>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q7}</label>
            <p>
              {pastData.drop9 === "0"
                ? translations.patient_general_past_history_q7_a1
                : pastData.drop9 === "1"
                  ? translations.patient_general_past_history_q7_a2
                  : ""}
            </p>
            {pastData.drop9 === "1" && (
              <>
                <label>
                  {translations.patient_general_past_history_q7_a2_q1}
                </label>
                <p>
                  {/* {pastData.drop10 && pastData.drop10.map((item)=>(
  <span>{item.label}</span>
))} */}
                  {pastData.drop10 &&
                    pastData.drop10
                      .map((item, index) => (
                        <span key={index}>{item.label}</span>
                      ))
                      .map((jsxElement) => jsxElement.props.children)
                      .join(" ,")}
                </p>
              </>
            )}
          </div>
          <div className="treatment-col">
            {pastData.drop10.filter((item) => item.value === "0")[0] && (
              <>
                {pastData.drop503 !== "" && (
                  <>
                    <label>Others</label>
                    <pre>
                      {" "}
                      <p className="p_description">{pastData.drop503}</p>
                    </pre>
                  </>
                )}
              </>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q8}</label>
            {/* <p>{pastData.drop10==="1"?"Any trouble with your breathing":pastData.drop10==="2"?"Cough with sputum?":"Selected"}</p> */}
            {pastData.seriousradio3 === "0"
              ? translations.patient_general_past_history_q8_a1
              : pastData.seriousradio3 === "1"
                ? translations.patient_general_past_history_q8_a2
                : ""}
            {/* <p>{pastData.checkvalue}</p> */}
            {/* {pastData?.drop10 === "1" && ( */}
          </div>
          <div className="treatment-col">
            {pastData.seriousradio3 === "1" && (
              <>
                {pastData.droplung
                  .map((item, index) => <span key={index}>{item.label}</span>)
                  .map((jsxElement) => jsxElement.props.children)
                  .join(" ,")}
              </>
            )}
          </div>

          <div className="">
            {/* {droplung.filter((item)=>item.value==="1")[0] && (
              <> */}
            <label className="text-dark">
              {translations.patient_general_past_history_q8_a2_o2}
            </label>
            <p>
              {/* {pastData.checkAsth && translations.patient_general_past_history_q8_a2_o2_o1} */}
              {pastData.checkAsth &&
                `${translations.patient_general_past_history_q8_a2_o2_o1}, `}

              {pastData.checkCOPD &&
                `${translations.patient_general_past_history_q8_a2_o2_o2}, `}
              {pastData.checkHigh &&
                `${translations.patient_general_past_history_q8_a2_o2_o3}, `}
              {pastData.checkCystic &&
                `${translations.patient_general_past_history_q8_a2_o2_o4}, `}
              {pastData.checkPulmo &&
                `${translations.patient_general_past_history_q8_a2_o2_o5}, `}
              {pastData.checkTB &&
                `${translations.patient_general_past_history_q8_a2_o2_o6}, `}
              {pastData.checkSarco &&
                `${translations.patient_general_past_history_q8_a2_o2_o7}, `}
              {pastData.checkOther &&
                `${translations.patient_general_past_history_q8_a2_o2_o8}, `}
            </p>

            {pastData.droplung.filter((item) => item.value === "2")[0] && (
              <>
                <pre>
                  <p className="p_description">{pastData.drop510}</p>
                </pre>
              </>
            )}
          </div>

          <div className="treatment-col">
            {pastData.checkHigh && (
              <>
                <label>Pulmonary Fibrosis</label>
                <p>{pastData.drop511}</p>
              </>
            )}
          </div>
          <div className="treatment-col">
            {pastData.checkOther && (
              <>
                {pastData.drop512 !== "" && (
                  <>
                    {" "}
                    <label className="text-dark">Others</label>
                    <p>{pastData.drop512}</p>
                  </>
                )}
              </>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q9}</label>
            <p>
              {pastData.drop11 === "0"
                ? translations.patient_general_past_history_q9_a1
                : pastData.drop11 === "1"
                  ? translations.patient_general_past_history_q9_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q10}</label>
            <p>
              {pastData.drop12 === "0"
                ? translations.patient_general_past_history_q10_a1
                : pastData.drop12 === "1"
                  ? translations.patient_general_past_history_q10_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q11} </label>
            <p>
              {pastData.drop99 === "0"
                ? translations.patient_general_past_history_q11_a1
                : pastData.drop99 === "1"
                  ? translations.patient_general_past_history_q11_a2
                  : ""}
            </p>
            {pastData.drop99 === "1" && (
              <>
                <pre>
                  <p className="p_description">{pastData.drop13}</p>
                </pre>
              </>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q12}</label>
            <p>
              {pastData.drop14 === "0"
                ? translations.patient_general_past_history_q12_a1
                : pastData.drop14 === "1"
                  ? translations.patient_general_past_history_q12_a2_q1_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q12_a2_q1}</label>
            <p>
              {pastData.drop15 === "0"
                ? translations.patient_general_past_history_q12_a2_q1_a1
                : pastData.drop15 === "1"
                  ? translations.patient_general_past_history_q12_a2_q1_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q13}</label>
            <p>
              {pastData.drop16 === "0"
                ? translations.patient_general_past_history_q13_a1
                : pastData.drop16 === "1"
                  ? translations.patient_general_past_history_q13_a2
                  : ""}
            </p>
            {pastData.drop16 === "1" && (
              <p>
                {returnStrings([
                  pastData.checkvalue80 &&
                  translations.patient_general_past_history_q13_a2_o2,
                  pastData.checkvalue81 &&
                  translations.patient_general_past_history_q13_a2_o3,
                  pastData.checkvalue82 &&
                  translations.patient_general_past_history_q13_a2_o4,
                  pastData.checkvalue50 &&
                  translations.patient_general_past_history_q13_a2_o5,
                ])}
              </p>
            )}
            {pastData.checkvalue50 && (
              <>
                {pastData.drop50 !== "" && (
                  <>
                    <label className="text-dark">
                      {translations.patient_general_past_history_q13_a2_o5}
                    </label>
                    <pre>
                      <p className="p_description">{pastData.drop50} </p>
                    </pre>
                  </>
                )}
              </>
            )}
          </div>

          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q14}</label>
            <p>
              {pastData.drop17 === "0"
                ? translations.patient_general_past_history_q14_a1
                : pastData.drop17 === "1"
                  ? translations.patient_general_past_history_q14_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q15}</label>
            <p>
              {pastData.drop18 === "0"
                ? translations.patient_general_past_history_q15_a1
                : pastData.drop18 === "1"
                  ? translations.patient_general_past_history_q15_a2
                  : ""}
            </p>
            {pastData.drop18 === "1" && (
              <>
                <pre>
                  <p className="p_description">{pastData.drop52}</p>
                </pre>
              </>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q15_a2_q1}</label>
            <p>
              {pastData.drop19 === "0"
                ? translations.patient_general_past_history_q15_a2_q1_a1
                : pastData.drop19 === "1"
                  ? translations.patient_general_past_history_q15_a2_q1_a2
                  : ""}
            </p>
            {pastData.drop19 === "1" && (
              <>
                <pre>
                  {" "}
                  <p className="p_description">{pastData.drop501}</p>
                </pre>
              </>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q16}</label>
            <p>
              {pastData.drop20 === "0"
                ? translations.patient_general_past_history_q16_a1
                : pastData.drop20 === "1"
                  ? translations.patient_general_past_history_q16_a2
                  : ""}
            </p>
          </div>
          {pastData.drop20 === "1" && (
            <div className="treatment-col">
              <label>
                {translations.patient_general_past_history_q16_a2_q1}
              </label>
              <pre>
                <p className="p_description">{pastData.drop87}</p>
              </pre>
            </div>
          )}

          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q17}</label>
            <p>
              {pastData.drop21 === "0"
                ? translations.patient_general_past_history_q17_a1
                : pastData.drop21 === "1"
                  ? translations.patient_general_past_history_q17_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q18}</label>
            <p>
              {pastData.drop22 === "0"
                ? translations.patient_general_past_history_q18_a1
                : pastData.drop22 === "1"
                  ? translations.patient_general_past_history_q18_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            {pastData.drop22 === "1" && (
              <>
                <label>
                  {translations.patient_general_past_history_q18_a2_q1}
                </label>
                <pre>
                  {" "}
                  <p className="p_description">{pastData.drop23}</p>
                </pre>
              </>
            )}
          </div>
          <div className="treatment-col">
            <label className="text-dark">
              {translations.patient_general_past_history_q19}
            </label>
            <p>
              {pastData.drop75 === "0"
                ? translations.patient_general_past_history_q19_a1
                : pastData.drop75 === "1"
                  ? translations.patient_general_past_history_q19_a2
                  : ""}
            </p>
            {pastData.drop75 === "1" && (
              <>
                {returnStrings([
                  pastData.checkvalue3 &&
                  translations.patient_general_past_history_q19_a2_o1,
                  pastData.checkvalue4 &&
                  translations.patient_general_past_history_q19_a2_o2,
                  pastData.checkvalue5 &&
                  translations.patient_general_past_history_q19_a2_o3,
                  pastData.checkvalue6 &&
                  "Neurologic disorder (examples: Multiple sclerosis, ALS, Alzheimer’s)",
                  pastData.checkvalue7 &&
                  "Muscular disorder (examples: Myasthenia gravis, Muscular dystrophy)",
                  pastData.checkvalue8 &&
                  "Problems with hearing, vision or memory",
                  pastData.checkvalue9 && "Chronic Pain",
                  pastData.checkvalue10 && "Others",
                ])}
              </>
            )}{" "}
          </div>
          <div className="treatment-col">
            {pastData.checkvalue3 === true && (
              <>
                <label className="text-dark">Seizures</label>
                <pre>
                  <p className="p_description">{pastData.drop24}</p>
                </pre>
              </>
            )}
          </div>
          {pastData.checkvalue4 === true && (
            <>
              <label className="text-dark">
                {translations.patient_general_past_history_q19_a2_o2}
              </label>
              <pre>
                {" "}
                <p className="p_description">{pastData.drop24Ch}</p>
              </pre>
            </>
          )}
          {pastData.checkvalue6 === true && (
            <>
              <label className="text-dark">
                {translations.patient_general_past_history_q19_a2_o4}
              </label>
              <p>{pastData.neurologicinput}</p>
            </>
          )}
          {pastData.checkvalue7 === true && (
            <>
              <label className="text-dark">
                {
                  translations.patient_general_past_history_q19_a2_o5
                }
              </label>
              <p>{pastData.muscularinput}</p>
            </>
          )}
          {pastData.checkvalue10 === true && (
            <>
              {pastData.otherinput !== "" && (
                <>
                  <label className="text-dark">Others</label>
                  <pre>
                    {" "}
                    <p className="p_description">{pastData.otherinput}</p>
                  </pre>
                </>
              )}
            </>
          )}

          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q20}</label>
            <p>
              {pastData.arthritisdrop75 === "0"
                ? translations.patient_general_past_history_q20_a1
                : pastData.arthritisdrop75 === "1"
                  ? translations.patient_general_past_history_q20_a2
                  : ""}
            </p>
            {pastData.arthritisdrop75 === "1" && (
              <>
                <p>
                  {returnStrings([
                    pastData.Osteoarthritischeck &&
                    translations.patient_general_past_history_q20_a2_o1,
                    pastData.Rheumatoidcheck &&
                    translations.patient_general_past_history_q20_a2_o2,
                    pastData.Othercheck &&
                    translations.patient_general_past_history_q20_a2_o3,
                  ])}
                </p>
              </>
            )}
            {pastData.Othercheck && (
              <>
                {pastData.dropArt !== "" && (
                  <>
                    {" "}
                    <label className="text-dark">{translations.patient_general_past_history_q20_a2_o3}</label>
                    <pre>
                      {" "}
                      <p className="p_description">{pastData.dropArt}</p>
                    </pre>{" "}
                  </>
                )}
              </>
            )}
          </div>
          {/* <div className="treatment-col">
            <label>
             Osteoarthritis? Rheumatoid arthritis or
              other?
            </label>
            <p>{pastData.drop24}</p>
          </div> */}
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q21}</label>
            <p>
              {pastData.psychiatric75 === "0"
                ? translations.patient_general_past_history_q21_a1
                : pastData.psychiatric75 === "1"
                  ? translations.patient_general_past_history_q21_a2
                  : ""}
            </p>
            {pastData.psychiatric75 === "1" && (
              <>
                <p>
                  {returnStrings([
                    pastData.psychiatriccheck1 && translations.patient_general_past_history_q21_a2_o1,
                    pastData.psychiatriccheck2 && translations.patient_general_past_history_q21_a2_o2,
                    pastData.psychiatriccheck3 && translations.patient_general_past_history_q21_a2_o3,
                  ])}
                </p>
              </>
            )}
            {pastData.psychiatriccheck3 && (
              <>
                {pastData.drop25 !== "" && (
                  <>
                    <label className="text-dark">{translations.patient_general_past_history_q21_a2_o3}</label>
                    <pre>
                      {" "}
                      <p className="p_description">{pastData.drop25}</p>
                    </pre>
                  </>
                )}
              </>
            )}
          </div>

          <div className="treatment-col">
            <label>
              {translations.patient_general_past_history_q22}
            </label>
            <p>
              {pastData.bleeding75 === "0"
                ? translations.patient_general_past_history_q22_a1
                : pastData.bleeding75 === "1"
                  ? translations.patient_general_past_history_q22_a2
                  : ""}
            </p>
            {pastData.bleeding75 === "1" && (
              <>
                {returnStrings([
                  pastData.bleedingcheck1 && translations.patient_general_past_history_q22_a2_o1,
                  pastData.bleedingcheck2 && translations.patient_general_past_history_q22_a2_o2,
                  pastData.bleedingcheck3 && translations.patient_general_past_history_q22_a2_o3,
                  pastData.bleedingcheck4 && translations.patient_general_past_history_q22_a2_o4,
                  pastData.bleedingcheck5 && translations.patient_general_past_history_q22_a2_o5,
                  pastData.bleedingcheck6 && translations.patient_general_past_history_q22_a2_o6,
                ])}
              </>
            )}
            {pastData.bleedingcheck6 && (
              <>
                {" "}
                {pastData.bleedingcheck6input !== "" && (
                  <>
                    <label className="text-dark">{translations.patient_general_past_history_q22_a2_o6}</label>
                    <pre>
                      {" "}
                      <p className="p_description">
                        {pastData.bleedingcheck6input}
                      </p>
                    </pre>
                  </>
                )}
              </>
            )}
          </div>

          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q23}</label>
            <p>
              {pastData.drop26 === "0"
                ? translations.patient_general_past_history_q23_a1
                : pastData.drop26 === "1"
                  ? translations.patient_general_past_history_q23_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q24}</label>
            <p>
              {pastData.drop27 === "0"
                ? translations.patient_general_past_history_q24_a1
                : pastData.drop27 === "1"
                  ? translations.patient_general_past_history_q24_a2
                  : ""}
            </p>
          </div>
          {pastData.drop27 === "1" && (
            <div className="treatment-col">
              <label>
                {translations.patient_general_past_history_q25}
              </label>
              <label>{translations.patient_general_past_history_q25_a1}</label>
              <p>
                {pastData.drop2445 === "0"
                  ? translations.patient_general_past_history_q25_a1_o1
                  : pastData.drop2445 === "1"
                    ? translations.patient_general_past_history_q25_a1_o2
                    : ""}
              </p>
              <label>{translations.patient_general_past_history_q25_a2}</label>
              <p>
                {pastData.drop2446 === "0"
                  ? translations.patient_general_past_history_q25_a2_o1
                  : pastData.drop2446 === "1"
                    ? translations.patient_general_past_history_q25_a2_o2
                    : ""}
              </p>
              <label>{translations.patient_general_past_history_q25_a3}</label>
              <p>
                {pastData.drop2447 === "0"
                  ? translations.patient_general_past_history_q25_a3_o1
                  : pastData.drop2447 === "1"
                    ? translations.patient_general_past_history_q25_a3_o2
                    : ""}
              </p>
            </div>
          )}
          {/* <div className="treatment-col">
            <label>Bleeding with tooth extractions</label>
            <p>
              {pastData.drop2446 === "0"
                ? "No"
                : pastData.drop2446 === "1"
                ? "Yes"
                : ""}
            </p>
          </div> */}
          {/* <div className="treatment-col">
            <label>Bleeding after surgery</label>
            <p>
              {pastData.drop2447 === "0"
                ? "No"
                : pastData.drop2447 === "1"
                ? "Yes"
                : ""}
            </p>
          </div> */}
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q26}</label>
            <p>
              {pastData.drop29 === "0"
                ? translations.patient_general_past_history_q26_a1
                : pastData.drop29 === "1"
                  ? translations.patient_general_past_history_q26_a2
                  : ""}
            </p>
            {pastData.drop29 === "1" && (
              <>
                <pre>
                  {" "}
                  <p className="p_description">{pastData.cancerinput}</p>
                </pre>
                <pre>
                  {" "}
                  <p className="p_description">{pastData.cancerinput2}</p>
                </pre>
              </>
            )}
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_general_past_history_q27}
            </label>
            <p>
              {pastData.drop30 === "0"
                ? translations.patient_general_past_history_q27_a1
                : pastData.drop30 === "1"
                  ? translations.patient_general_past_history_q27_a2
                  : ""}
            </p>
            {pastData.drop30 === "1" && (
              <>
                <pre>
                  <p className="p_description">{pastData.drop31}</p>
                </pre>
              </>
            )}
          </div>

          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q27_a2_placeholder2}</label>
            <p>{new Date(pastData.startDate).toLocaleDateString()}</p>
          </div>

          {/* {pastData.drop30 === "1" && (
            <div className="treatment-col">
              <label>Name of treatment</label>
              <p>{pastData.drop31}</p>
              <label>Date Problem Began:</label>
              <p>{pastData.startDate}</p>
            </div>
          )} */}

          <div className="treatment-col">
            <label className="text-dark">
              {translations.patient_general_past_history_q28}
            </label>
            <p>
              {pastData.drop33 === "0"
                ? translations.patient_general_past_history_q28_a1
                : pastData.drop33 === "1"
                  ? translations.patient_general_past_history_q28_a2
                  : ""}
            </p>
            {pastData.drop33 === "1" && (
              <div>
                <label className="text-dark">{translations.patient_general_past_history_q28_a2_q1}</label>
                <p>
                  {pastData.drop33check1
                    .map((item, index) => <span key={index}>{item.label}</span>)
                    .map((jsxElement) => jsxElement.props.children)
                    .join(" ,")}
                </p>
              </div>
            )}
            {pastData.drop33check1.filter((val) => val.value === "5")[0] ? (
              <>
                {pastData.drop33checkinput !== "" && (
                  <>
                    {" "}
                    <label className="text-dark">{translations.patient_general_past_history_q28_a2_q1_o5}</label>
                    <pre>
                      {" "}
                      <p className="p_description">
                        {pastData.drop33checkinput}
                      </p>
                    </pre>
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q29}</label>
            <label> {translations.patient_general_past_history_q29_q1}</label>
            <p>
              {pastData.drop35 === "0"
                ? translations.patient_general_past_history_q29_q1_a1
                : pastData.drop35 === "1"
                  ? translations.patient_general_past_history_q29_q1_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q29_q2}</label>
            <p>
              {pastData.drop36 === "0"
                ? translations.patient_general_past_history_q29_q2_a1
                : pastData.drop36 === "1"
                  ? translations.patient_general_past_history_q29_q2_a2
                  : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q29_q3}</label>
            <p>
              {pastData.drop37 === "0"
                ? translations.patient_general_past_history_q29_q3_a1
                : pastData.drop37 === "1"
                  ? translations.patient_general_past_history_q29_q3_a2
                  : ""}
            </p>
          </div>

          <div className="treatment-col">
            <label>{translations.patient_general_past_history_q30}</label>
            <p>
              {pastData.drop38 === "0"
                ? translations.patient_general_past_history_q30_a1
                : pastData.drop38 === "1"
                  ? translations.patient_general_past_history_q30_a2
                  : "Not applicable"}
            </p>
          </div>
          {!pastData.pregnantcheck && (
            <>
              <div className="treatment-col">
                <label>{translations.patient_general_past_history_q30_a2_q1}</label>
                <p>{pastData.drop502 ? new Date(pastData.drop502).toLocaleDateString() : ""}</p>
              </div>
            </>
          )}

          <div className="treatment-col">
            <label>{translations.patient_general_past_Medical_history} :</label>
            <pre>
              <p className="p_description">{pastData.drop39}</p>
            </pre>
            <label>{translations.patient_general_past_Medication_name} :</label>
            <pre>
              {" "}
              <p className="p_description">{pastData.drop41}</p>
            </pre>
            {/* <label>Dose </label>
            <p>{pastData.drop42}</p> */}
            {/* <label>How often </label>
            <p>{pastData.drop43}</p> */}
            {/* <label>reason of taking it </label>
            <p>{pastData.drop44}</p> */}
            <label>{translations.patient_general_past_history_q31_q6}</label>
            <pre>
              <p className="p_description">{pastData.drop45}</p>
            </pre>
          </div>
        </div>
        <div className="treatment-col"></div>
      </div>
    </div>
  );
}
